import { memo, useMemo } from 'react'
import useSWR from 'swr'

import PortfolioHeader from '../../patterns/PortfolioHeader'
import getPublicIdFromCloudinaryUrl from '../../utils/getPublicIdFromCloudinaryUrl'
import makeCloudinaryImageUrl from '../../utils/makeCloudinaryImageUrl'
import remakeCloudinaryImageUrl from '../../utils/remakeCloudinaryImageUrl'
import { PortfolioHeaderStoryblok } from '../storyblok.generated'
import useMemoCompare from '../../utils/useMemoCompare'
import arraysEqual from '../../utils/arraysEqual'
import useViewportSize from '../../utils/useViewportSize'
import { mapVideo } from '../../utils/mapVideo'

// TODO Add support for Bynder
const useImageSequence = (
  scrollImageSequence: Array<{ filename: string }> | undefined,
  scrollImageSequenceTag: string | undefined,
) => {
  const { data: scrollImageSequenceTagData } = useSWR<string[]>(
    `/api/image-sequence?tag=${scrollImageSequenceTag}`,
    {
      isPaused: () => !scrollImageSequenceTag,
    },
  )
  const viewportSize = useViewportSize()
  const isPortrait = viewportSize && viewportSize.width < viewportSize.height
  const rawSequence = useMemo(() => {
    const transformations = isPortrait
      ? // 640x1080 might seem excessive, but on retina displays any less will result in a noticable quality reduction
        ['f_auto', 'q_70', 'c_fill,w_640,h_1080']
      : ['f_auto', 'q_70']

    if (scrollImageSequenceTagData) {
      return scrollImageSequenceTagData.map((publicId) =>
        makeCloudinaryImageUrl({
          publicId,
          transformations,
        }),
      )
    }

    if (scrollImageSequence) {
      return scrollImageSequence
        .map((image) => getPublicIdFromCloudinaryUrl(image.filename))
        .map((url) =>
          remakeCloudinaryImageUrl({
            url,
            transformations,
          }),
        )
    }

    return undefined
  }, [scrollImageSequenceTagData, scrollImageSequence, isPortrait])
  // The reference to rawSequence may change even though its contents are the
  // same. I think this happens when Next loads in the JSON for the page and I
  // couldn't find a way to avoid the update altogether. This hook ensures the
  // image sequence loading doesn't start twice unnecessarily.
  const sequence = useMemoCompare(rawSequence, arraysEqual)

  return sequence
}

interface Props {
  content: PortfolioHeaderStoryblok
}

const PortfolioHeaderBlok = ({
  content: {
    mainVideo,
    mobileMainVideo,
    scrollImageSequence,
    scrollImageSequenceTag,
    scrollImageSequenceVelocity,
    text,
  },
  ...others
}: Props) => {
  const imageSequence = useImageSequence(
    scrollImageSequence,
    scrollImageSequenceTag,
  )

  return (
    <PortfolioHeader
      mainVideo={mapVideo(mainVideo)?.src}
      mobileMainVideo={mapVideo(mobileMainVideo)?.src}
      scrollImageSequence={imageSequence}
      scrollVelocity={Number(scrollImageSequenceVelocity)}
      text={text}
      {...others}
    />
  )
}

export default memo(PortfolioHeaderBlok)
