const getImageSequenceLoadOrder = (
  numItems: number,
  maxSimultaneously: number,
): number[] => {
  const results: number[] = []

  let batchFrameCount = maxSimultaneously
  while (results.length < numItems) {
    for (let i = 1; i <= batchFrameCount; i += 1) {
      // -1 is required since numItems is the total length (going from
      // 1-numItems), while we need indexes (going from 0-(numItems-1))
      const frame = Math.round(i * (numItems / batchFrameCount)) - 1
      if (!results.includes(frame)) {
        results.push(frame)
      }
    }
    batchFrameCount = Math.min(numItems, batchFrameCount * 2)
  }

  return results
}

export default getImageSequenceLoadOrder
