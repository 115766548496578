import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import visibleNonBreakingSpace from '../utils/visibleNonBreakingSpace'
import { isSiteYachting } from '../themes'
import Image from './Image'

const Header = styled.header(
  ({ theme }) => css`
    padding: ${theme.spacing.x2}px ${theme.spacing.x4}px 0;
    margin-bottom: -${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: -${theme.spacing.x15}px;
    }
  `,
)
const ImageContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    top: -${theme.spacing.x2}px;
    left: -${theme.spacing.x4}px;
    right: -${theme.spacing.x4}px;
    width: calc(100% + ${theme.spacing.x4}px * 2);
    height: 246px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      height: 810px;
    }
  `,
)
const Content = styled.div(
  ({ theme }) => css`
    max-width: 1089px;
    margin: -60px auto 0;
    padding: ${theme.spacing.x3}px ${theme.spacing.x4}px 0;
    background: ${theme.colors.yachting.white};
    position: relative;
    z-index: 1;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      margin: -135px auto 0;
      padding: ${theme.spacing.x10}px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin: ${theme.spacing.x2}px auto 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: ${theme.spacing.x4}px auto 0;
    }
  `,
)
const ArticleInfo = styled.div(({ theme }) => [
  css`
    color: ${theme.colors.amels.beach};
    text-transform: uppercase;
    letter-spacing: 3.43px;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;

    :not(:last-of-type) {
      margin-right: ${theme.spacing.x6}px;
    }
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)}
      margin-bottom: ${theme.spacing.x1}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: 0;
      }
    `,
])
const EventLocation = styled(ArticleInfo)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)}
      display: block;
      margin-bottom: ${theme.spacing.x1}px;
      margin-top: ${theme.spacing.x4}px;
      color: ${theme.colors.yachting.beach};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: inline-block;
        margin-bottom: 0;
        color: ${theme.colors.yachting.beach};
      }

      :not(:last-of-type) {
        padding-right: ${theme.spacing.x2 + 5}px;
        margin-right: ${theme.spacing.x2 + 5}px;

        ::after {
          display: none;

          @media screen and (min-width: ${theme.breakpoints.tablet}px) {
            display: block;
          }
        }
      }
    `,
)

interface Props {
  isEvent?: boolean
  title: string
  type: string
  publishedAt: string
  image: string
  video?: string
  dateShort?: string
  location?: string
}

const ArticleHeaderYachting = ({
  isEvent,
  title,
  type,
  publishedAt,
  image,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  video,
  dateShort,
  location,
}: Props) => {
  const { locale } = useRouter()

  return (
    <Header>
      <ImageContainer>
        <Image src={image} alt={title} fill style={{ objectFit: 'cover' }} />
      </ImageContainer>
      <Content>
        {type && (
          <ArticleInfo data-testid="news.article.type">{type}</ArticleInfo>
        )}
        {dateShort && (
          <ArticleInfo data-testid="news.article.dateShort">
            {dateShort}
          </ArticleInfo>
        )}
        {!isEvent && (
          <ArticleInfo data-testid="news.article.date">
            {new Date(publishedAt).toLocaleDateString(locale, {
              day: 'numeric',
            })}{' '}
            {new Date(publishedAt).toLocaleDateString(locale, {
              month: 'long',
            })}{' '}
            {new Date(publishedAt).toLocaleDateString(locale, {
              year: 'numeric',
            })}
          </ArticleInfo>
        )}
        <Title>{visibleNonBreakingSpace(title)}</Title>
        {location?.split('/').map((subLocation, index) => (
          <EventLocation
            // eslint-disable-next-line react/no-array-index-key
            key={`${subLocation}-${index}`}
          >
            {subLocation.trim()}
          </EventLocation>
        ))}
      </Content>
    </Header>
  )
}

export default ArticleHeaderYachting
