import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import IconLink from '../components/IconLink'
import ChevronRight from '../icons/ChevronRight.svg'
import VideoPlayer from '../components/VideoPlayer'
import Section from '../components/Section'
import {
  isSiteAmels,
  isThemeAmelsLandingspage,
  isSiteYachting,
} from '../themes'
import makeVideoSources from '../utils/makeVideoSources'
import { createImageLoader } from '../utils/createImageLoader'

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'assetLocation',
})<{ assetLocation: '' | 'left' | 'right' }>(
  ({ theme, assetLocation }) => css`
    margin: ${theme.spacing.x10}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: flex;
      flex-direction: ${assetLocation === 'left' || assetLocation === ''
        ? 'row'
        : 'row-reverse'};
      gap: ${theme.spacing.x6}px;
      margin: ${assetLocation === 'left' || assetLocation === ''
        ? `${theme.spacing.x20}px ${theme.spacing.x7}px ${theme.spacing.x20}px 0`
        : `${theme.spacing.x20}px 0 ${theme.spacing.x20}px ${theme.spacing.x7}px`};
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin: ${assetLocation === 'left' || assetLocation === ''
        ? `${theme.spacing.x20}px ${theme.spacing.x20}px ${theme.spacing.x20}px 0`
        : `${theme.spacing.x20}px 0 ${theme.spacing.x20}px ${theme.spacing.x20}px`};
    }
  `,
)
const ContentColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 1 1 50%;
      margin-top: 0;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex: 1 1 33%;
    }
  `,
)
const MediaColumn = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 1 1 50%;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex: 1 1 66%;
    }
  `,
)
const ImageContainer = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.amels.beach};
    font-size: ${theme.spacing.x4}px;
    letter-spacing: 0;
    margin: 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      ${isSiteYachting(theme) &&
      `
        font-size: ${theme.spacing.x6}px;
      `}
      ${isSiteAmels(theme) &&
      `
        font-size: ${theme.spacing.x6}px;
      `}
      ${isThemeAmelsLandingspage(theme) &&
      `
        ${theme.text.heading2(theme)}
      `}
    }
  `,
)
const Text = styled.div(
  ({ theme }) =>
    css`
      margin-top: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-top: ${theme.spacing.x7}px;
      }
    `,
)
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`
const LinkWrapper = styled.div(
  ({ theme }) =>
    css`
      margin-top: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-top: ${theme.spacing.x6}px;
      }
    `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
  video?: string
  assetLocation: '' | 'left' | 'right'
  linkText?: ReactNode
  href?: string
}

const TextWithWideImage = ({
  title,
  text,
  image,
  video,
  assetLocation = 'left',
  linkText,
  href,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-300px 0px',
    triggerOnce: true,
  })

  const imageLoader = createImageLoader(image.src)

  const poster = imageLoader.loader
    ? imageLoader.loader({ src: imageLoader.src as string, width: 1920 })
    : image.src

  return (
    <Container
      sectionIndicatorVariant="dark"
      data-testid="textWithWideImage"
      className={!inView ? hiddenClassName : undefined}
      assetLocation={assetLocation}
      ref={ref}
      {...others}
    >
      <MediaColumn>
        <ImageContainer>
          {video ? (
            <VideoPlayer poster={poster}>{makeVideoSources(video)}</VideoPlayer>
          ) : (
            <Image
              src={image.src}
              alt={image.alt}
              width={866}
              height={516}
              sizes="50vw"
              style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
            />
          )}
        </ImageContainer>
      </MediaColumn>
      <ContentColumn>
        <AnimateText>
          <Title>{title}</Title>
        </AnimateText>
        <AnimateText delay={60}>
          <Text>{text}</Text>
        </AnimateText>
        {href && linkText && (
          <LinkWrapper>
            <IconLink
              icon={<StyledChevronRight aria-label="" />}
              href={href}
              data-testid="textWithWideImage.link"
            >
              {linkText}
            </IconLink>
          </LinkWrapper>
        )}
      </ContentColumn>
    </Container>
  )
}

export default TextWithWideImage
