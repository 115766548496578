import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FC, HTMLAttributes, SVGAttributes } from 'react'

import useDimensions from '../utils/useDimensions'
import useMousePosition from '../utils/useMousePosition'
import {
  isSiteXplorer,
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'
import { currentSite } from '../sites'

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: none;
`
const Cursor = styled('div')(({ theme }) => [
  css`
    position: absolute;
    left: 0;
    top: 0;
    background: ${theme.colors.amels.silk};
    color: ${theme.colors.amels.clearBayAqua};
    width: 40px;
    height: 40px;
    // transform is used for the mouse position
    margin-left: -20px;
    margin-top: -20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    will-change: transform;
  `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.orange};
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      color: ${theme.colors.womenInYachtingPage.terraBlush};
    `,
  isSiteXplorer(theme) &&
    css`
      background: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
      color: ${theme.colors.xplorer.white};
    `,
])
const Ring = styled('div')(({ theme }) => [
  css`
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%;
    border: 1px solid ${theme.colors.amels.silk};
  `,
  isSiteXplorer(theme) &&
    css`
      border: none;
    `,
])
const iconCss = css`
  width: auto;
  height: auto;
`

interface Props extends HTMLAttributes<HTMLDivElement> {
  icon: FC<SVGAttributes<SVGElement>>
  iconWidth?: number | 'auto'
  iconHeight?: number | 'auto'
}

const CursorOverlay = ({
  icon: Icon,
  iconWidth = 18,
  iconHeight,
  ...others
}: Props) => {
  const mousePosition = useMousePosition()
  const [ref, { x, y }] = useDimensions()

  if (mousePosition.x === undefined || mousePosition.y === undefined) {
    return null
  }

  return (
    <Container ref={ref} {...others}>
      <Cursor
        style={{
          transform: `translate(${mousePosition.x - (x ?? 0)}px, ${
            mousePosition.y - (y ?? 0)
          }px)`,
        }}
      >
        <Icon
          css={iconCss}
          style={{
            width: iconWidth,
            height: iconHeight,
          }}
          aria-label=""
        />
        {currentSite !== 'yachting' && <Ring />}
      </Cursor>
    </Container>
  )
}

export default CursorOverlay
