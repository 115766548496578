import { ReactElement, ReactNode } from 'react'

/**
 * Replaces a part of a string with a React element.
 */
const reactStringReplace = (
  string: string,
  searchValue: string,
  replaceValue: ReactElement,
): ReactNode => {
  if (!string) {
    return string
  }
  const reactIndex = string.indexOf(searchValue)
  if (reactIndex === -1) {
    return string
  }

  const before = string.substring(0, reactIndex)
  const after = string.substring(reactIndex + searchValue.length)

  return (
    <>
      {before}
      {replaceValue}
      {reactStringReplace(after, searchValue, replaceValue)}
    </>
  )
}

export default reactStringReplace
