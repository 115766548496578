import patterns from '.'
import BlokZone from '../../components/BlokZone'
import TabbedContent from '../../patterns/TabbedContent'
import slugify from '../../utils/slugify'
import { TabbedContentStoryblok } from '../storyblok.generated'

interface Props {
  content: TabbedContentStoryblok
}

const TabbedContentBlok = ({
  content: {
    sectionTitle,
    title,
    overline,
    description,
    tabBlocks,
    backgroundColor,
  },
  ...others
}: Props) => (
  <TabbedContent
    id={slugify(sectionTitle)}
    title={title}
    overline={overline}
    description={description}
    tabs={tabBlocks.map(({ _uid, title, contentBlocks }) => ({
      key: _uid,
      title,
      content:
        // Content may be undefined when the page hasn't been saved yet
        contentBlocks && contentBlocks.length > 0 && (
          <BlokZone components={patterns} content={contentBlocks} />
        ),
    }))}
    backgroundColor={backgroundColor}
    {...others}
  />
)

export default TabbedContentBlok
