import {
  ContactCallToActionActionStoryblok,
  FormStoryblok,
} from '../storyblok.generated'
import { usePageContext } from '../PageContext'
import { StoryblokStory } from '../../utils/storyblok'
import FormBlok from '../FormBlok'
import ContactCallToActionAction from '../../patterns/ContactCallToActionAction'

interface Props {
  content: ContactCallToActionActionStoryblok
}

const ContactCallToActionActionBlok = ({
  content: { _uid, title, formReference },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  const form = rels.find(
    (rel) => rel.uuid === formReference,
  ) as StoryblokStory<FormStoryblok>
  return (
    <ContactCallToActionAction
      key={_uid}
      title={title}
      form={<FormBlok form={form} />}
      {...others}
    />
  )
}

export default ContactCallToActionActionBlok
