import NewsletterFormWomenInYachting from '../../patterns/PageForm'
import { mapImage } from '../../utils/mapImage'
import { StoryblokStory } from '../../utils/storyblok'
import FormBlok from '../FormBlok'
import { usePageContext } from '../PageContext'
import { FormStoryblok, PageFormStoryblok } from '../storyblok.generated'

interface Props {
  content: PageFormStoryblok
}

const PageFormBlok = ({ content: { image, form }, ...others }: Props) => {
  const { rels } = usePageContext()

  return (
    <NewsletterFormWomenInYachting
      form={
        <FormBlok
          form={
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            rels.find(
              (rel): rel is StoryblokStory<FormStoryblok> => rel.uuid === form,
            )!
          }
        />
      }
      image={mapImage(image)}
      {...others}
    />
  )
}

export default PageFormBlok
