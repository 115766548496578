import { css } from '@emotion/react'
import styled from '@emotion/styled'

const FinishOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
const Top = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${theme.spacing.x6}px;
    background: #fff;
    transform: scaleY(var(--disappear-progress));
    transform-origin: top;
    transition: transform 200ms ease-out;
  `,
)
const Bottom = styled(Top)`
  top: auto;
  bottom: 0;
  transform-origin: bottom;
`
const Left = styled(Top)(
  ({ theme }) => css`
    height: 100%;
    width: ${theme.spacing.x6}px;
    transform: scaleX(var(--disappear-progress));
    transform-origin: left;
  `,
)
const Right = styled(Left)`
  left: auto;
  right: 0;
  transform-origin: right;
`

interface Props {
  progress: number
}

const EdgeBorderOverlay = ({ progress }: Props) => (
  <FinishOverlay
    style={{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ['--disappear-progress' as any]: progress,
      display: progress === 0 ? 'none' : undefined,
    }}
  >
    <Top />
    <Right />
    <Bottom />
    <Left />
  </FinishOverlay>
)

export default EdgeBorderOverlay
