import { FollowUsStoryblok } from '../storyblok.generated'
import FollowUs from '../../patterns/FollowUs'
import { usePageContext } from '../PageContext'

interface Props {
  content: FollowUsStoryblok
}

const FollowUsBlok = ({
  content: { title, titleColorChangeAfterWord },
  ...others
}: Props) => {
  const { siteStory } = usePageContext()
  const socials = siteStory?.story?.content?.footerBlock[0]?.socialBlocks

  return (
    <FollowUs
      title={title}
      titleColorChangeAfterWord={titleColorChangeAfterWord}
      socials={socials}
      {...others}
    />
  )
}

export default FollowUsBlok
