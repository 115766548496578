import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import { useInView } from 'react-intersection-observer'
import Section from '../components/Section'
import Image from '../components/Image'
import YachtingCarousel from '../components/YachtingCarousel'
import { hiddenClassName } from '../components/AnimateText'
import {
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{
  background: Props['background']
}>(({ theme, background }) => [
  isSiteYachting(theme) &&
    css`
      overflow: visible;
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
      z-index: 3;
    `,
  background === 'silver-linear' &&
    isSiteYachting(theme) &&
    css`
      z-index: 0;
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
      background: linear-gradient(
        180deg,
        ${theme.colors.yachting.silver30} 0%,
        ${theme.colors.yachting.silver30} 38.01%,
        ${theme.colors.yachting.white} 38.02%
      );

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x20}px ${theme.spacing.x4}px;
      }
    `,
  background === 'beach10-linear' &&
    isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      z-index: 0;
      padding: 0 ${theme.spacing.x4}px;
      background: linear-gradient(
        180deg,
        ${theme.colors.yachting.beach10} 0%,
        ${theme.colors.yachting.beach10} 38.01%,
        ${theme.colors.yachting.white} 38.02%
      );

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: 0 ${theme.spacing.x4}px;
      }
    `,
])
const ContentContainer = styled.div(
  () => css`
    max-width: 1086px;
    margin: 0 auto;
  `,
)
const Slide = styled.div(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      position: relative;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.x6}px;
      overflow: hidden;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        flex-direction: row;
      }
    `}
  `,
)
const Overline = styled.div(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)};
      color: ${theme.colors.yachting.beach};
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `}
  `,
)
const Title = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin: 0 0 ${theme.spacing.x8}px 0;
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      ${theme.text.heading3(theme)}
      font-family: ${theme.fonts.HelveticaNeue.style.fontFamily}, sans-serif;
    `,
])
const Name = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading3Regular(theme)}
      margin: 0 0 ${theme.spacing.x2}px 0;
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Georgia.style.fontFamily};
    `,
])
const Role = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)};
      color: ${theme.colors.yachting.beach};
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      color: ${theme.colors.womenInYachtingPage.terraBlush};
    `,
])
const TextContainer = styled.div(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      flex: 1;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        text-align: center;
      }
    `}
  `,
)
const ImageContainer = styled.div(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      position: relative;

      > div {
        position: relative;
        display: flex;
        justify-content: center;
        overflow: hidden;
        aspect-ratio: 1/1;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          max-width: 360px;
          height: auto;
          padding-left: ${theme.spacing.x2}px;
        }

        @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
          max-width: 460px;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.tabletMax}px) {
        img {
          width: 100%;
          height: auto;
        }
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: 50%;
        margin: 0 auto;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        max-width: 360px;
        /* fix issue on OSX where sometimes a pixel from the previous slide shows */
        padding-right: 3px;
        margin: 0;

        img {
          max-width: 360px;
          height: auto;
        }
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        max-width: 460px;

        img {
          max-width: 460px;
        }
      }
    `}
  `,
)

const QuoteMark = styled.span(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      position: absolute;
      top: -42px; //Align according to design
      left: 50%;
      transform: translateX(-50%);
      font-size: 140px;
      line-height: 168px;
      color: ${theme.colors.yachting.darkBlue};

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        left: calc(((100% - 96px) / 2) - 314px / 2);
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        left: calc(((100% - 96px) / 2) - 358px / 2);
      }
    `,
])

const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'background',
})<{
  background: Props['background']
}>(({ theme, background }) => [
  background === 'silver-linear' &&
    isSiteYachting(theme) &&
    css`
      max-width: 1086px;
      margin: 0 auto;
      padding: ${theme.spacing.x10}px ${theme.spacing.x7}px;
      background-color: ${theme.colors.yachting.white};

      ${QuoteMark} {
        top: 37px;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          top: 118px;
        }
      }
    `,
  background === 'beach10-linear' &&
    isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      max-width: 1086px;
      margin: 0 auto;
      padding: ${theme.spacing.x10}px ${theme.spacing.x7}px;
      background-color: ${theme.colors.yachting.white};

      ${QuoteMark} {
        top: -43px;
      }
    `,
])

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'children'> {
  testimonials: Array<{
    overline: string | undefined
    title: string
    name: string
    role: string | undefined
    image: {
      src: string
      alt?: string
    }
  }>
  background?: '' | 'silver-linear' | 'beach10-linear'
}

const TestimonialCarousel = ({
  testimonials,
  background,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  return (
    <Container
      sectionIndicatorVariant="dark"
      background={background}
      {...others}
    >
      <ContentWrapper background={background}>
        <QuoteMark>”</QuoteMark>
        <ContentContainer>
          <YachtingCarousel
            className={!inView ? hiddenClassName : undefined}
            data-testid="testimonialCarousel"
            arrowsPosition="responsive"
            ref={ref}
            customArrows={false}
            customDots
            customDotsPosition="testimonial"
            variableWidth={false}
          >
            {testimonials.map((testimonial) => (
              <Slide key={testimonial.title}>
                <TextContainer>
                  <Overline>{testimonial.overline}</Overline>
                  <Title>{testimonial.title}</Title>
                  <Name>{testimonial.name}</Name>
                  <Role>{testimonial.role}</Role>
                </TextContainer>
                <ImageContainer>
                  <div>
                    <Image
                      src={testimonial.image.src}
                      alt={testimonial.image.alt}
                      width={376}
                      height={376}
                      style={{ objectFit: 'cover' }}
                    />
                  </div>
                </ImageContainer>
              </Slide>
            ))}
          </YachtingCarousel>
        </ContentContainer>
      </ContentWrapper>
    </Container>
  )
}

export default TestimonialCarousel
