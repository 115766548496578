import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer'

import Image from '../components/Image'
import RichText, { dummyResolver } from '../components/RichText'
import FormFieldSelect from '../components/FormFieldSelect'
import FormFieldText from '../components/FormFieldText'
import { FormContactStoryblok } from './storyblok.generated'
import stripUndefined from '../utils/stripUndefined'
import FormFieldConditionsCheckbox from '../components/FormFieldConditionsCheckbox'
import Button, {
  primaryButtonCssYachting,
  primaryOutlineButtonCss,
  primaryButtonCssXplorer,
  primaryButtonCssYachtSupport,
} from '../components/Button'
import AnimateText from '../components/AnimateText'
import FormResult from '../components/FormResult'
import FormFieldTextarea from '../components/FormFieldTextarea'
import { useRecaptchaForm } from '../utils/useRecaptchaForm'
import RecaptchaLoader from '../components/RecaptchaLoader'
import RecaptchaDisclaimer from '../components/RecaptchaDisclaimer'
import RecaptchaContainer from '../components/RecaptchaContainer'
import FormFieldSelectCountry from '../components/FormFieldSelectCountry'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'
import { Site, currentSite } from '../sites'
import { operations } from '../api/form2lead.generated'
import { mapImage } from '../utils/mapImage'
import Icon from '../icons/yachtsupport/Explore.svg'

const Form = styled('form', {
  shouldForwardProp: (prop) => prop !== 'colorScheme',
})<{
  colorScheme?: 'light' | 'dark'
}>(({ theme, colorScheme }) => [
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};
    `,
  isSiteXplorer(theme) &&
    css`
      color: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.black};
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.black};
    `,
])
const Title = styled('h3', {
  shouldForwardProp: (prop) => prop !== 'colorScheme',
})<{ colorScheme?: 'light' | 'dark' }>(({ theme, colorScheme }) => [
  css`
    ${colorScheme === 'dark'
      ? theme.text.heading3(theme, colorScheme)
      : theme.text.heading2(theme)}
    margin: ${theme.spacing.x5}px 0 ${theme.spacing.x3}px;
  `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading3(theme, colorScheme)}
      font-family: ${theme.fonts.Chaney.style.fontFamily};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 32px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading2(theme, colorScheme)}
      text-align: center;
      text-transform: none;
    `,
])
const Text = styled('div', {
  shouldForwardProp: (prop) => prop !== 'colorScheme',
})<{ colorScheme?: 'light' | 'dark' }>(({ theme, colorScheme }) => [
  css`
    color: ${colorScheme === 'dark'
      ? theme.colors.xplorer.white
      : theme.colors.amels.deepBayAqua};
    margin-bottom: ${theme.spacing.x6}px;
  `,
])
const Disclaimer = styled.div<{ colorScheme?: 'light' | 'dark' }>(
  ({ theme, colorScheme }) => [
    css`
      margin-top: 24px;
    `,
    isSiteAmels(theme) &&
      css`
        font-size: 12px;
        line-height: 1.5;
        color: ${theme.colors.amels.deepBayAqua60};
      `,
    isSiteXplorer(theme) &&
      css`
        font-size: 16px;
        line-height: 1.5;
        color: ${colorScheme === 'dark'
          ? theme.colors.xplorer.white
          : theme.colors.xplorer.primaryXPLavaBlack};

        a {
          color: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
        }
      `,
    isSiteYachtSupport(theme) &&
      css`
        ${theme.text.body(theme)}
        color: ${colorScheme === 'dark'
          ? theme.colors.yachtSupport.white
          : theme.colors.yachtSupport.secondaryYSWarmGrey};

        a {
          color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
        }
      `,
  ],
)
const Submit = styled(Button)(({ theme }) => [
  css`
    margin-top: ${theme.spacing.x5}px;
    ${isSiteYachtSupport(theme) &&
    css`
      margin-top: ${theme.spacing.x3}px;
    `}
  `,
  isSiteAmels(theme) && primaryOutlineButtonCss(theme),
  isSiteYachting(theme) && primaryButtonCssYachting(theme),
  isSiteXplorer(theme) && primaryButtonCssXplorer(theme),
  isSiteYachtSupport(theme) && primaryButtonCssYachtSupport(theme),
])

const Success = styled.div(
  ({ theme }) => css`
    ${isSiteXplorer(theme) &&
    css`
      width: 100%;
      max-width: 480px;
      text-align: center;
    `}
    ${isSiteYachtSupport(theme) &&
    css`
      width: 100%;
      max-width: 375px;
      text-align: center;
    `}
  `,
)

const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    width: ${theme.spacing.x7}px;
    height: ${theme.spacing.x7}px;
  `,
)

interface Props {
  form: FormContactStoryblok
  colorScheme?: 'light' | 'dark'
}

const ContactFormBlok = ({
  colorScheme = 'light',
  form: {
    title,
    text,
    titleBlock,
    firstNameBlock,
    lastNameBlock,
    emailBlock,
    messageBlock,
    countryBlock,
    jobTitleBlock,
    companyNameBlock,
    privacyPolicyBlock,
    submitBlock,
    pardotConfiguration,
    thankYouBlock,
    errorBlock,
    ...others
  },
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [greatSuccess, setGreatSuccess] = useState(false)
  // const [formError, setFormError] = useState<string | undefined>()
  const [formError, setFormError] = useState(false)
  interface FieldValues {
    title: string
    firstName: string
    lastName: string
    email: string
    message: string | undefined
    country?: string
    jobTitle?: string
    companyName?: string
    acceptPrivacyPolicy?: boolean
  }
  const {
    recaptchaElementId,
    isLoading,
    getRecaptchaToken,
    register,
    handleSubmit,
    formState: { errors },
  } = useRecaptchaForm<FieldValues>()
  const onSubmit = async (data: FieldValues) => {
    if (!data.acceptPrivacyPolicy) {
      return
    }

    setIsSubmitting(true)

    try {
      const recaptchaResponse = await getRecaptchaToken()
      // We could also map this on API, but it would be nice to keep fieldnames in sync with backend API
      const request: NonNullable<
        operations['CreateProspect']['requestBody']
      >['content']['application/json'] = {
        salutation: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        message: data.message,
        country: data.country,
        jobRole: data.jobTitle,
        company: data.companyName,
        pardotEndpoint: pardotConfiguration,
        ...recaptchaResponse,
      }
      const response = await fetch('/api/prospect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(stripUndefined(request)),
      })
      const result = await response.json()

      if (!result.success) {
        setFormError(true)
        return
      }
      setGreatSuccess(true)
    } catch (err) {
      setFormError(true)
      throw err
    } finally {
      setIsSubmitting(false)
    }
  }

  const thankYouBlockImage = mapImage(thankYouBlock[0].image)

  if (greatSuccess) {
    return (
      <Success data-testid="contactForm.success">
        {thankYouBlockImage && (
          <Image
            src={thankYouBlockImage.src}
            alt={thankYouBlockImage.alt}
            width={200}
            height={200}
            style={{ objectFit: 'cover' }}
          />
        )}

        {Site.YachtSupport && !thankYouBlockImage && <StyledIcon />}

        <FormResult
          title={thankYouBlock[0].title}
          text={thankYouBlock[0].text}
          colorScheme={colorScheme}
        />
      </Success>
    )
  }

  if (formError) {
    return (
      <div data-testid="contactForm.error">
        <FormResult
          title={errorBlock[0].title}
          text={errorBlock[0].text}
          colorScheme={colorScheme}
        />
      </div>
    )
  }

  /* eslint-disable no-plusplus */
  let field = 0

  return (
    <Form
      // If ...others is placed after colorScheme, it will not receive the correct value, but always receives the default set in this file
      onSubmit={handleSubmit(onSubmit)}
      {...others}
      colorScheme={colorScheme}
    >
      <AnimateText delay={100}>
        <Title colorScheme={colorScheme}>{title}</Title>
      </AnimateText>
      <AnimateText delay={100 + ++field * 60}>
        <Text colorScheme={colorScheme}>{text}</Text>
      </AnimateText>
      <AnimateText delay={100 + ++field * 60}>
        <FormFieldSelect
          label={titleBlock[0].label}
          options={titleBlock[0].options.map(({ id, value }) => ({
            key: id,
            value,
          }))}
          error={errors.title}
          colorScheme={colorScheme}
          {...register('title')}
          autoComplete="honorific-prefix"
          data-testid="contactForm.title"
          fullWidth={currentSite !== Site.Amels}
        />
      </AnimateText>
      <AnimateText delay={100 + ++field * 60}>
        <FormFieldText
          label={firstNameBlock[0].label}
          error={errors.firstName}
          {...register('firstName', {
            required: firstNameBlock[0].errorText,
          })}
          autoComplete="given-name"
          required
          data-testid="contactForm.firstName"
        />
      </AnimateText>
      <AnimateText delay={100 + ++field * 60}>
        <FormFieldText
          label={lastNameBlock[0].label}
          error={errors.lastName}
          {...register('lastName', {
            required: lastNameBlock[0].errorText,
          })}
          autoComplete="family-name"
          required
          data-testid="contactForm.lastName"
        />
      </AnimateText>
      <AnimateText delay={100 + ++field * 60}>
        <FormFieldText
          label={emailBlock[0].label}
          error={errors.email}
          {...register('email', {
            required: emailBlock[0].errorText,
          })}
          type="email"
          autoComplete="email"
          required
          data-testid="contactForm.email"
        />
      </AnimateText>
      <AnimateText delay={100 + ++field * 60}>
        <FormFieldTextarea
          label={messageBlock[0].label}
          error={errors.message}
          {...register('message', {
            required: messageBlock[0].errorText,
          })}
          type="text"
          autoComplete="off"
          required
          data-testid="contactForm.message"
        />
      </AnimateText>
      {countryBlock?.[0] && (
        <AnimateText delay={100 + ++field * 60}>
          {countryBlock[0].component === 'formFieldSelect' ? (
            <FormFieldSelect
              label={countryBlock[0].label}
              colorScheme={colorScheme}
              options={countryBlock[0].options.map(({ id, value }) => ({
                key: id,
                value,
              }))}
              error={errors.country}
              {...register('country')}
              autoComplete="country"
              data-testid="requestBrochureForm.country"
            />
          ) : (
            <FormFieldSelectCountry
              label={countryBlock[0].label}
              error={errors.country}
              colorScheme={colorScheme}
              {...register('country')}
              autoComplete="country"
              data-testid="requestBrochureForm.country"
            />
          )}
        </AnimateText>
      )}
      {jobTitleBlock && (
        <AnimateText delay={100 + ++field * 60}>
          <FormFieldText
            label={jobTitleBlock[0].label}
            error={errors.jobTitle}
            {...register('jobTitle', {
              required: jobTitleBlock[0].errorText,
            })}
            type="text"
            autoComplete="organization-title"
            required
            data-testid="contactForm.jobTitle"
          />
        </AnimateText>
      )}
      {companyNameBlock && (
        <AnimateText delay={100 + ++field * 60}>
          <FormFieldText
            label={companyNameBlock[0].label}
            error={errors.companyName}
            {...register('companyName', {
              required: companyNameBlock[0].errorText,
            })}
            type="text"
            autoComplete="organization"
            required
            data-testid="contactForm.companyName"
          />
        </AnimateText>
      )}
      <AnimateText delay={100 + ++field * 60}>
        {privacyPolicyBlock[0].text && (
          <Disclaimer colorScheme={colorScheme}>
            <RichText>{privacyPolicyBlock[0].text}</RichText>
          </Disclaimer>
        )}
      </AnimateText>
      <AnimateText delay={100 + ++field * 60}>
        <RecaptchaLoader />
        <RecaptchaDisclaimer />
        <RecaptchaContainer id={recaptchaElementId} />
      </AnimateText>
      <AnimateText delay={100 + ++field * 60}>
        <FormFieldConditionsCheckbox
          label={
            <RichText nodeResolvers={{ [NODE_PARAGRAPH]: dummyResolver }}>
              {privacyPolicyBlock[0].label}
            </RichText>
          }
          error={errors.acceptPrivacyPolicy}
          {...register('acceptPrivacyPolicy', {
            required: privacyPolicyBlock[0].errorText,
          })}
          colorScheme={colorScheme}
          required
          data-testid="contactForm.acceptPrivacyPolicy"
        />
      </AnimateText>

      <AnimateText delay={100 + ++field * 60}>
        <Submit
          type="submit"
          data-testid="contactForm.submit"
          disabled={isSubmitting || isLoading}
          variant="primary"
        >
          {submitBlock[0].title}
        </Submit>
      </AnimateText>
    </Form>
  )
}

export default ContactFormBlok
