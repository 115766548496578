import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useEffect, useRef, useState } from 'react'
import Button from '../components/Button'
import SidePanelFixed from '../components/SidePanelFixed'
import SidePanelTransition from '../components/SidePanelTransition'
import { isSiteXplorer, isSiteYachtSupport } from '../themes'

const StyledButton = styled(Button)(({ theme }) => [
  css`
    position: fixed;
    right: ${theme.spacing.x4}px;
    bottom: ${theme.spacing.x4}px;
    z-index: 10;
    // This button has unique styling that's not used anywhere else
    background: ${theme.colors.amels.hazySunsetOrange};
    padding: ${theme.spacing.x1}px ${theme.spacing.x4}px;
    border-radius: 28px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3.5px;
    color: ${theme.colors.amels.body};
    border: 1px solid transparent;
    font-weight: 600;

    :hover {
      color: ${theme.colors.amels.hazySunsetOrange};
      background: ${theme.colors.amels.body};
      border: 1px solid ${theme.colors.amels.hazySunsetOrange};
    }

    @media (prefers-reduced-motion: no-preference) {
      transition: all 300ms ease-in-out;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x1 + 4}px ${theme.spacing.x4}px;
      right: ${theme.spacing.x6}px;
      bottom: ${theme.spacing.x6}px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      background: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
      color: ${theme.colors.xplorer.white};
      border: none;
      width: fit-content;

      :hover {
        color: ${theme.colors.xplorer.white};
        border: none;

        ::before {
          background: ${theme.colors.xplorer.primaryXPLavaBlack};
        }
      }
    `,

  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;
      border-radius: 0;
      ${theme.shadows.xl};
      background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      color: ${theme.colors.yachtSupport.white};
      font-size: 14px;
      font-weight: 500;
      letter-spacing: normal;
      border: none;
      width: fit-content;

      :hover {
        color: ${theme.colors.yachtSupport.white};
        border: none;

        ::before {
          background: ${theme.colors.yachtSupport.primaryYSOceanBlue};
        }
      }
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 16px;
        padding: ${theme.spacing.x1 + 4}px ${theme.spacing.x3}px;
      }
    `,
])

interface Props {
  text: ReactNode
  alwaysVisible: boolean
  form: ReactNode
}

const FloatingCallToAction = ({
  text,
  alwaysVisible,
  form,
  ...others
}: Props) => {
  const [isOpen, setOpen] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const elem = containerRef.current
    if (!elem || alwaysVisible) {
      return
    }

    const handleScroll = () => {
      const margin = 100 // px
      const elemTop = elem.getBoundingClientRect().top + margin
      const isVisible = elemTop < window.innerHeight

      setIsVisible(isVisible)
    }
    document.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [alwaysVisible])

  return (
    <div {...others}>
      <div ref={containerRef} />

      <StyledButton
        data-testid="floatingCallToAction"
        onClick={() => setOpen(true)}
        style={{
          opacity: isVisible || alwaysVisible ? 1 : 0,
          touchAction: isVisible || alwaysVisible ? 'all' : 'none',
        }}
      >
        {text}
      </StyledButton>

      <SidePanelTransition isOpen={isOpen}>
        {isOpen && (
          <SidePanelFixed
            close={() => setOpen(false)}
            data-testid="floatingCallToAction.sidePanel"
          >
            {form}
          </SidePanelFixed>
        )}
      </SidePanelTransition>
    </div>
  )
}

export default FloatingCallToAction
