import PortfolioHighlights from '../../patterns/PortfolioHighlights'
import RichText from '../../components/RichText'
import slugify from '../../utils/slugify'
import { PortfolioHighlightStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import { mapVideo } from '../../utils/mapVideo'

interface Props {
  content: PortfolioHighlightStoryblok
}

const PortfolioHighlightsBlok = ({
  content: {
    sectionTitle,
    title,
    subTitle,
    image,
    locationBlocks,
    mobileMargins,
    backgroundOption,
    backgroundColor1,
    backgroundColor2,
    gradientType,
  },
  ...others
}: Props) => (
  <PortfolioHighlights
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    image={mapImage(image) ?? getRequiredImageFallback()}
    buttons={locationBlocks.map((locationBlock) => ({
      key: locationBlock._uid,
      x: `${locationBlock.locationX}%`,
      y: `${locationBlock.locationY}%`,
      title: locationBlock.title,
      video: mapVideo(locationBlock.video).src,
      asideTitle: locationBlock.asideTitle,
      asideText: locationBlock.asideText && (
        <RichText>{locationBlock.asideText}</RichText>
      ),
      asideVariant: locationBlock.asideVariant,
    }))}
    mobileMargins={Number(mobileMargins)}
    backgroundOption={backgroundOption}
    backgroundColor1={backgroundColor1?.color}
    backgroundColor2={backgroundColor2?.color}
    gradientType={gradientType}
    {...others}
  />
)

export default PortfolioHighlightsBlok
