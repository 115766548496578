import RichText from '../../components/RichText'
import {
  FormStoryblok,
  RichtextStoryblok,
  SimpleHeaderStoryblok,
} from '../storyblok.generated'
import SimpleHeader from '../../patterns/SimpleHeader'
import { StoryblokStory } from '../../utils/storyblok'
import FormBlok from '../FormBlok'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { usePageContext } from '../PageContext'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: SimpleHeaderStoryblok
}

interface StoryblokRichText {
  type: string
  content: StoryblokRichText[]
}

const isRichTextEmpty = (
  richText?: StoryblokRichText | RichtextStoryblok | undefined,
) =>
  !richText ||
  !richText.content ||
  richText.content.length === 0 ||
  richText.content.every((item) => !item.content || item.content.length === 0)

const SimpleHeaderBlok = ({
  content: {
    title,
    tagLine,
    text,
    image,
    overlayImage,
    callToAction: callToActionBlocks,
    scroll,
    hideTitle,
    logo,
    colorOption,
    variants,
    size,
    contentAlignment,
  },
  ...others
}: Props) => {
  const { rels } = usePageContext()

  const callToAction = callToActionBlocks?.[0]

  return (
    <SimpleHeader
      title={
        typeof title === 'object' ? (
          <RichText as="span">{title}</RichText>
        ) : (
          title
        )
      }
      hideTitle={hideTitle}
      tagLine={tagLine}
      text={!isRichTextEmpty(text) && <RichText>{text}</RichText>}
      image={mapImage(image)}
      overlayImage={mapImage(overlayImage)}
      callToAction={
        callToAction &&
        (callToAction.component === 'linkWithTitle'
          ? {
              title: callToAction.title,
              href: storyblokLinkResolver(callToAction.link),
              target: callToAction.linkTarget,
            }
          : {
              label: callToAction.label,
              form: (
                <FormBlok
                  form={
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    rels.find(
                      (rel): rel is StoryblokStory<FormStoryblok> =>
                        rel.uuid === callToAction.form,
                    )!
                  }
                />
              ),
            })
      }
      scroll={scroll}
      logo={mapImage(logo)}
      colorOption={colorOption}
      variants={variants}
      size={size}
      contentAlignment={contentAlignment}
      {...others}
    />
  )
}

export default SimpleHeaderBlok
