import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useCallback, useState } from 'react'

import mergeRefs from '../utils/mergeRefs'
import useDimensions from '../utils/useDimensions'
import useDisableBodyScroll from '../utils/useDisableBodyScroll'
import AnimateText, { textAnimationStatic } from './AnimateText'
import { DeckButton, DeckSelection, FloorplanDeck } from '../patterns/Floorplan'
import SidePanelFixed from './SidePanelFixed'
import PinchZoomView from './PinchZoomView'
import PinchToZoomIcon from '../icons/PinchToZoom.svg'
import Image from './Image'
import { isSiteAmels, isSiteYachting } from '../themes'

const StyledSidePanelFixed = styled(SidePanelFixed)(({ theme }) => [
  css`
    padding: 0;
    display: flex;
    flex-flow: column;
  `,
  isSiteAmels(theme) &&
    css`
      background: ${theme.colors.amels.silk};
    `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.beach10};
    `,
])

const StyledPinchZoomView = styled(PinchZoomView)`
  flex: 1 1 auto;
`
const ImageContainer = styled.div`
  position: relative;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${textAnimationStatic} 800ms ease-in-out forwards;
    opacity: 0;
    will-change: opacity;
  }
`
const ImagePadding = styled.div`
  position: absolute;
  --scale: 1;
  --padding: calc(32px * var(--scale));
  top: var(--padding);
  left: var(--padding);
  width: calc(100% - var(--padding) * 2);
  height: calc(100% - var(--padding) * 2);
`
const PinchToZoomOverlay = styled.div(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.spacing.x4}px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    text-align: center;
    pointer-events: none;
  `,
)
const StyledPinchToZoomIcon = styled(PinchToZoomIcon)(({ theme }) => [
  css`
    width: ${theme.spacing.x5}px;
    height: ${theme.spacing.x5}px;
    margin-bottom: ${theme.spacing.x3}px;
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};
    `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `,
])
const PinchToZoomText = styled.div(({ theme }) => [
  css`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 6px;
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.beach};
    `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `,
])

interface Props {
  close: () => void
  decks: FloorplanDeck[]
  zoomFactor: number
}

const FloorplanModal = ({ close, decks, zoomFactor }: Props) => {
  const [ref, { width, height }] = useDimensions()
  const [activeDeck, setActiveDeck] = useState(0)
  const [hasScaled, setHasScaled] = useState(false)

  const scrollContainerRef = useDisableBodyScroll()
  const handlePinch = useCallback(() => {
    setHasScaled(true)
  }, [])

  return (
    <StyledSidePanelFixed
      close={close}
      data-testid="floorplan.modal"
      data-active-deck={activeDeck}
      disableBodyScroll={false}
    >
      <DeckSelection>
        {decks.map((deck, index) => (
          <DeckButton
            key={deck.key}
            active={activeDeck === index}
            onClick={() => setActiveDeck(index)}
            data-testid={`floorplan.deck.${index}`}
            data-active={activeDeck === index}
          >
            <AnimateText delay={index * 200}>{deck.title}</AnimateText>
          </DeckButton>
        ))}
      </DeckSelection>
      <StyledPinchZoomView
        ref={mergeRefs(ref, scrollContainerRef)}
        minScale={1}
        maxScale={zoomFactor}
        onPinch={handlePinch}
      >
        {(scale, ref) => (
          <ImageContainer
            style={{
              width: width ? width * scale : 0,
              height: height ? height * scale : 0,
              animationDelay: `${400 + decks.length * 200}ms`,
            }}
            ref={ref}
          >
            <ImagePadding>
              <Image
                // eslint-disable-next-line security/detect-object-injection
                src={decks[activeDeck].detailedImage}
                alt=""
                sizes={`${zoomFactor * 100}vw`}
                fill
                style={{ objectFit: 'contain' }}
                priority
              />
            </ImagePadding>
          </ImageContainer>
        )}
      </StyledPinchZoomView>
      {!hasScaled && (
        <AnimateText direction="static" delay={400 + decks.length * 200}>
          <PinchToZoomOverlay>
            <StyledPinchToZoomIcon aria-label="" />
            <PinchToZoomText>Pinch to zoom</PinchToZoomText>
          </PinchToZoomOverlay>
        </AnimateText>
      )}
    </StyledSidePanelFixed>
  )
}

export default FloorplanModal
