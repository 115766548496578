import { ComponentProps, forwardRef } from 'react'
import useSWR from 'swr'

import FormFieldSelect from './FormFieldSelect'

type Props = Omit<ComponentProps<typeof FormFieldSelect>, 'options'>

// eslint-disable-next-line react/display-name
const FormFieldSelectCountry = forwardRef<HTMLSelectElement, Props>(
  (props: Props, ref) => {
    const { data } = useSWR<{ name: string; value: string }[]>(
      '/api/countriesbyiso',
    )
    const countries =
      data?.map((value) => ({
        key: value.name,
        value: value.value,
      })) || []

    return <FormFieldSelect {...props} options={countries} ref={ref} />
  },
)

export default FormFieldSelectCountry
