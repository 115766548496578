import { ImageWithTextGridStoryblok } from '../storyblok.generated'
import ImageWithTextGrid from '../../patterns/ImageWithTextGrid'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import RichText from '../../components/RichText'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: ImageWithTextGridStoryblok
}

const ImageWithTextGridBlok = ({
  content: { content, layoutVariant, backgroundColor, backgroundImage },
  ...others
}: Props) => (
  <ImageWithTextGrid
    layoutVariant={layoutVariant}
    backgroundColor={backgroundColor}
    backgroundImage={mapImage(backgroundImage)}
    content={content.map(
      ({
        _uid,
        contentItemType,
        image,
        linkWithTitle,
        overline,
        overlineIcon,
        description,
        title,
      }) => ({
        key: _uid,
        contentItemType,
        image: mapImage(image),
        linkWithTitle: linkWithTitle?.[0] && {
          title: linkWithTitle?.[0].title,
          href:
            linkWithTitle?.[0] &&
            storyblokLinkResolver(linkWithTitle?.[0].link),
          target: linkWithTitle?.[0].linkTarget,
        },
        title,
        description: <RichText>{description}</RichText>,
        overline,
        overlineIcon,
      }),
    )}
    {...others}
  />
)

export default ImageWithTextGridBlok
