import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import TabbedContentDefault from './TabbedContent'
import TabbedContentXplorer from './TabbedContent.xplorer'
import Section from '../../components/Section'

export interface TabbedContentProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  overline?: ReactNode
  description?: ReactNode
  tabs: Array<{
    key: string
    title: ReactNode
    content: ReactNode
  }>
  backgroundColor?: string
}

const TabbedContent = (props: TabbedContentProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <TabbedContentXplorer {...props} />
    default:
      return <TabbedContentDefault {...props} />
  }
}

export default TabbedContent
