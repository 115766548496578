import {
  FloatingCallToActionStoryblok,
  FormStoryblok,
} from '../storyblok.generated'
import { usePageContext } from '../PageContext'
import { StoryblokStory } from '../../utils/storyblok'
import FloatingCallToAction from '../../patterns/FloatingCallToAction'
import FormBlok from '../FormBlok'

interface Props {
  content: FloatingCallToActionStoryblok
}

const FloatingCallToActionBlok = ({
  content: { text, alwaysVisible, formReference },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  const form = rels.find(
    (rel) => rel.uuid === formReference,
  ) as StoryblokStory<FormStoryblok>

  return (
    <FloatingCallToAction
      text={text}
      alwaysVisible={alwaysVisible || false}
      form={<FormBlok form={form} />}
      {...others}
    />
  )
}

export default FloatingCallToActionBlok
