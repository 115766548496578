import { PortfolioFloorplanStoryblok } from '../storyblok.generated'
import Floorplan from '../../patterns/Floorplan'
import slugify from '../../utils/slugify'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: PortfolioFloorplanStoryblok
}

const PortfolioFloorplanBlok = ({
  content: {
    sectionTitle,
    title,
    subTitle,
    overline,
    mobilePreviewImage,
    deckBlocks,
    desktopZoomFactor,
    mobileZoomFactor,
  },
  ...others
}: Props) => (
  <Floorplan
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    overline={overline}
    mobilePreviewImage={mapImage(mobilePreviewImage)?.src ?? ''}
    decks={deckBlocks.map(({ _uid, title, baseImage, detailedImage }) => ({
      key: _uid,
      title,
      baseImage: mapImage(baseImage)?.src ?? '',
      detailedImage: mapImage(detailedImage)?.src ?? '',
    }))}
    desktopZoomFactor={Number(desktopZoomFactor)}
    mobileZoomFactor={Number(mobileZoomFactor)}
    {...others}
  />
)

export default PortfolioFloorplanBlok
