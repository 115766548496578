import { useMemo } from 'react'

import FullWidthCarousel from '../../patterns/FullWidthCarousel'
import slugify from '../../utils/slugify'
import visibleNonBreakingSpace from '../../utils/visibleNonBreakingSpace'
import { FullWidthCarouselStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: FullWidthCarouselStoryblok
}

const FullWidthCarouselBlok = ({
  content: { sectionTitle, imageBlocks, title, overline, navigationVariant },
  ...others
}: Props) => {
  const images = useMemo(
    () =>
      imageBlocks?.map((slide) => ({
        key: slide._uid,
        image: mapImage(slide.image) ?? getRequiredImageFallback(),
        title: slide.title ? visibleNonBreakingSpace(slide.title) : undefined,
      })),
    [imageBlocks],
  )

  if (!images || images.length === 0) {
    return null
  }

  return (
    <FullWidthCarousel
      id={slugify(sectionTitle)}
      images={images}
      componentTitle={title}
      overline={overline}
      navigationVariant={navigationVariant}
      {...others}
    />
  )
}

export default FullWidthCarouselBlok
