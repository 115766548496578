import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'
import { isSiteYachting } from '../themes'

const Container = styled(Section)(
  ({ theme }) => css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x7}px;
    }
  `,
)
const ContentContainer = styled.div(({ theme }) => [
  css`
    max-width: 1024px;
    margin: 0 auto;
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.layout.maxContentWidth(theme)};
    `,
])
const TimelineItem = styled('div')(
  ({ theme }) => css`
    position: relative;
    display: flex;
    align-items: start;
    overflow: hidden;

    @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
      padding-left: ${theme.spacing.x6}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 50%;

      > div {
        max-width: 410px;
      }
    }

    ::before {
      content: '';
      position: absolute;
      top: ${theme.spacing.x11}px;
      left: -5px;
      width: 32px;
      height: 1px;
      background: ${theme.colors.yachting.orange};
      transform: rotate(-20deg);

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        top: ${theme.spacing.x13}px;
        width: 46px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        top: ${theme.spacing.x16}px;
        width: 70px;
      }
    }

    :nth-of-type(1) {
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding-right: ${theme.spacing.x10}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding-right: ${theme.spacing.x16}px;
      }

      ::before {
        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          left: auto;
          right: -5px;
          transform: rotate(20deg);
        }
      }
    }

    :nth-of-type(2) {
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding-left: ${theme.spacing.x10}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding-left: ${theme.spacing.x16}px;
      }
    }
  `,
)
const TimelineRow = styled('div')(({ theme }) => [
  css`
    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: ${theme.spacing.x6}px;
    background-image: linear-gradient(
      ${theme.colors.yachting.orange},
      ${theme.colors.yachting.orange}
    );
    background-size: 1px 100%;
    background-position: 0 50%;
    gap: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding-bottom: ${theme.spacing.x18}px;
      background-position: 50% 50%;
      flex-direction: row;
      gap: 0;
    }

    /* first row behaves differently */
    :nth-of-type(1) {
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding-bottom: calc(200px + ${theme.spacing.x18}px);
      }

      ${TimelineItem}:nth-of-type(2) {
        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          align-self: end;
          bottom: -200px;
        }
      }
    }

    :nth-of-type(n + 2) {
      ${TimelineItem}:nth-of-type(1) {
        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          top: -200px;
        }
      }
    }

    :nth-of-type(odd) {
      ${TimelineItem}:nth-of-type(1) {
        justify-content: end;
      }
      ${TimelineItem}:nth-of-type(2) {
        justify-content: start;
      }
    }

    :nth-of-type(even) {
      ${TimelineItem}:nth-of-type(1) {
        justify-content: start;
      }
      ${TimelineItem}:nth-of-type(2) {
        justify-content: end;
      }
    }

    :last-of-type {
      padding-bottom: 0;

      ${TimelineItem}:only-of-type {
        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin-bottom: -200px;
        }
      }
    }
  `,
])

type TimelineItem = {
  key: string
  itemContent: ReactNode
}

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant'
  > {
  items: TimelineItem[]
}

const Timeline = ({ items, ...others }: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-300px 0px',
    triggerOnce: true,
  })

  const rows = useMemo(() => {
    const rows: [TimelineItem, TimelineItem | undefined][] = []
    for (let i = 0; i < items.length; i += 2) {
      // eslint-disable-next-line security/detect-object-injection
      rows.push([items[i], items[i + 1]])
    }
    return rows
  }, [items])

  return (
    <Container
      ref={ref}
      data-testid="timeline"
      className={!inView ? hiddenClassName : undefined}
      sectionIndicatorVariant="light"
      {...others}
    >
      <ContentContainer>
        {rows.map(([leftItem, rightItem], _index) => (
          <TimelineRow key={leftItem.key}>
            <TimelineItem>{leftItem.itemContent}</TimelineItem>
            {rightItem && <TimelineItem>{rightItem.itemContent}</TimelineItem>}
          </TimelineRow>
        ))}
      </ContentContainer>
    </Container>
  )
}

export default Timeline
