import { ExploreTeaserStoryblok } from '../storyblok.generated'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import ExploreTeaser from '../../patterns/ExploreTeaser'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: ExploreTeaserStoryblok
}

const ExploreTeaserBlok = ({
  content: { heading, backgroundImage, overlayImage, link, ...others },
}: Props) => {
  return (
    <ExploreTeaser
      backgroundImage={mapImage(backgroundImage)}
      overlayImage={mapImage(overlayImage)}
      heading={heading}
      link={storyblokLinkResolver(link)}
      {...others}
    />
  )
}

export default ExploreTeaserBlok
