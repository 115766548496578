import { ReactElement, useCallback, useState } from 'react'

interface Props {
  children: (values: {
    toggle: () => void
    isExpanded: boolean
  }) => ReactElement
  defaultValue?: boolean
}

const Collapsible = ({ children, defaultValue = false }: Props) => {
  const [isExpanded, setExpanded] = useState(defaultValue)
  const toggle = useCallback(() => {
    setExpanded((value) => !value)
  }, [])

  return children({ toggle, isExpanded })
}

export default Collapsible
