import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import AnimateHeight from 'react-animate-height'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'
import ExpandIcon from '../components/ExpandIcon'

const Container = styled(Section)(
  ({ theme }) => css`
    background: ${theme.colors.yachting.silver10};
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x12}px ${theme.spacing.x7}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x20}px;
    }
  `,
)
const ContentContainer = styled.div(
  () => css`
    max-width: 860px;
    margin: 0 auto;
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.yachting.darkBlue};
    margin: 0 0 ${theme.spacing.x7}px;
  `,
)
const HarmonicaItem = styled.div``
const ItemTitleBar = styled.button(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${theme.spacing.x3}px;
    margin-bottom: ${theme.spacing.x4}px;
    width: 100%;
    cursor: pointer;
    border: none;

    > h3 {
      ${theme.text.heading3(theme)}
      color: ${theme.colors.yachting.darkBlue};
      text-align: left;
      margin: 0;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  items: Array<{
    key: string
    title: ReactNode
    itemContent: ReactNode
  }>
}

const Harmonica = ({ title, items, ...others }: Props) => {
  const [expandedItem, setExpandedItem] = useState('')
  const { ref, inView } = useInView({
    rootMargin: '-300px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      ref={ref}
      data-testid="harmonica"
      className={!inView ? hiddenClassName : undefined}
      sectionIndicatorVariant="light"
      {...others}
    >
      <ContentContainer>
        {title && (
          <AnimateText>
            <Title>{title}</Title>
          </AnimateText>
        )}

        {items.map(({ key, title, itemContent }, index) => {
          const isExpanded = expandedItem === key
          const animationDelay = index * 400
          return (
            <HarmonicaItem key={key}>
              <ItemTitleBar
                onClick={() => setExpandedItem((p) => (p === key ? '' : key))}
                aria-expanded={isExpanded}
                aria-controls={key}
              >
                <h3>
                  <AnimateText delay={animationDelay}>{title}</AnimateText>
                </h3>
                <AnimateText delay={animationDelay + 200}>
                  <ExpandIcon isExpanded={isExpanded} />
                </AnimateText>
              </ItemTitleBar>

              <AnimateHeight duration={800} height={isExpanded ? 'auto' : 0}>
                {itemContent}
              </AnimateHeight>
            </HarmonicaItem>
          )
        })}
      </ContentContainer>
    </Container>
  )
}

export default Harmonica
