import TextWithLinkList from '../../patterns/TextWithLinkList'
import slugify from '../../utils/slugify'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { TextWithLinkListStoryblok } from '../storyblok.generated'

interface Props {
  content: TextWithLinkListStoryblok
}

const TextWithLinkListBlok = ({
  content: { blocks, sectionTitle },
  ...others
}: Props) => (
  <TextWithLinkList
    id={slugify(sectionTitle)}
    blocks={blocks?.map(({ _uid, title, text, linkBlock }) => ({
      key: _uid,
      title,
      text,
      link: {
        title: linkBlock[0].title,
        url: storyblokLinkResolver(linkBlock[0]?.link),
      },
    }))}
    {...others}
  />
)

export default TextWithLinkListBlok
