import { FeatureWithModalListStoryblok } from '../storyblok.generated'
import FeatureWithModalList from '../../patterns/FeatureWithModalList'
import patterns from '.'
import BlokZone from '../../components/BlokZone'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: FeatureWithModalListStoryblok
}

const FeatureWithModalListBlok = ({
  content: { features },
  ...others
}: Props) => (
  <FeatureWithModalList
    features={features.map(({ _uid, title, icon, modalContent }) => ({
      key: _uid,
      title,
      icon: mapImage(icon)?.src ?? '',
      // Content may be undefined when the page hasn't been saved yet
      modalContent: modalContent && modalContent.length > 0 && (
        <BlokZone components={patterns} content={modalContent} />
      ),
    }))}
    {...others}
  />
)

export default FeatureWithModalListBlok
