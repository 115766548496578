import { MutableRefObject, useEffect } from 'react'

import smoothScrollTo from './smoothScrollTo'
import useViewportSize from './useViewportSize'

const useElementScrollLock = (
  element: MutableRefObject<HTMLElement | null>,
  enabled = true,
  scrollDuration = 0,
  centered = false,
) => {
  const viewportSize = useViewportSize()
  useEffect(() => {
    if (!element.current || !enabled) {
      return
    }
    const abortController = new AbortController()
    smoothScrollTo(element.current, scrollDuration, centered, abortController)
    // eslint-disable-next-line consistent-return
    return () => abortController.abort()
  }, [element, enabled, scrollDuration, centered, viewportSize])
}

export default useElementScrollLock
