import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import AnimateText from './AnimateText'

import Button, { whiteOutlineButtonCss } from './Button'
import Image from './Image'

const Content = styled.figcaption(
  ({ theme }) => css`
    position: absolute;
    z-index: 1;
    left: ${theme.spacing.x4}px;
    bottom: ${theme.spacing.x4}px;
    max-width: calc(100vw - ${theme.spacing.x4}px * 2);

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      left: 7.5%;
      bottom: 11%;
      max-width: 580px;
    }
  `,
)
const Title = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    margin: 0;
    color: ${theme.colors.amels.silk};
    letter-spacing: 0;
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x6}px;
    color: ${theme.colors.amels.silk};
    font-size: 18px;
  `,
)
const MoreInformationButton = styled(Button)(
  ({ theme }) => css`
    ${whiteOutlineButtonCss(theme)}
    position: absolute;
    bottom: ${theme.spacing.x3}px;
    left: 50%;
    transform: translateX(-50%);
  `,
)

export interface CarouselImage {
  image: {
    src: string
    alt?: string
  }
  title?: ReactNode
  text?: ReactNode
}

interface Props extends CarouselImage {
  // TODO
  textPresentation?: 'static' | 'modal' | 'none'
  openSidePanel?: () => void
  preload?: boolean
}

const ImageCarouselSlideContent = ({
  image,
  title,
  text,
  textPresentation = 'static',
  openSidePanel,
  preload,
}: Props) => (
  <>
    <Image
      src={image.src}
      alt={image.alt}
      style={{ objectFit: 'cover' }}
      fill
      priority={preload}
    />

    {textPresentation === 'static' && (
      <Content>
        {title && (
          <Title>
            <AnimateText>{title}</AnimateText>
          </Title>
        )}
        {text && (
          <Text>
            <AnimateText delay={200}>{text}</AnimateText>
          </Text>
        )}
      </Content>
    )}

    {textPresentation === 'modal' && title && text && openSidePanel && (
      <MoreInformationButton
        onClick={openSidePanel}
        data-testid="imageCarousel.moreInformation"
      >
        More information
      </MoreInformationButton>
    )}
  </>
)

export default ImageCarouselSlideContent
