import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'
import Link from './Link'
import { Site, currentSite } from '../sites'
import ArrowSlant from '../icons/yachting/ArrowSlant.svg'

const StyledLink = styled(Link)(({ theme }) => [
  css`
    color: ${theme.colors.yachting.orange};
  `,
])
export const Arrow = styled(ArrowSlant)(({ theme }) => [
  css`
    display: inline-block;
    width: 1.9em;
    margin-left: ${theme.spacing.x1}px;
  `,
])

type Props = ComponentProps<typeof Link>

/**
 * Link with arrow is a generic component to support multi-site links that that
 * have some sort of icon (can be anything, not just an arrow) to indicate that
 * the link will take the user to a new page.
 *
 * This component is not for use in running text, as those will be handled by
 * the `Link` component and no arrow would be present there.
 * This is for standalone links.
 */
const LinkWithArrow = ({ children, ...others }: Props) => (
  <StyledLink {...others}>
    {children}
    {currentSite === Site.Yachting && <Arrow />}
  </StyledLink>
)

export default LinkWithArrow
