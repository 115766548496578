import { ComponentProps, ReactNode } from 'react'
import Section from '../../components/Section'
import { currentSite, Site } from '../../sites'
import NavigationTeaserAmels from './NavigationTeaser.amels'
import NavigationTeaserYachting from './NavigationTeaser.yachting'
import NavigationTeaserXplorer from './NavigationTeaser.xplorer'
import NavigationTeaserYachtSupport from './NavigationTeaser.yachtsupport'

export interface NavigationTeaserProps
  extends Omit<
    ComponentProps<typeof Section>,
    'title' | 'sectionIndicatorVariant' | 'children'
  > {
  columns: Array<{
    key: string
    title: string
    subTitle?: string
    image: { src: string; alt?: string }
    mobileImage?: { src: string; alt?: string }
    logo?: { src?: string; alt?: string }
    link: string
    linkText: string
    hoverTitle: string
    hoverSubtitle?: string
    hoverText: ReactNode
  }>
  hasParallax?: boolean
}

const NavigationTeaser = (props: NavigationTeaserProps) => {
  switch (currentSite) {
    case Site.Yachting:
      return <NavigationTeaserYachting {...props} />
    case Site.Xplorer:
      return <NavigationTeaserXplorer {...props} />
    case Site.YachtSupport:
      return <NavigationTeaserYachtSupport {...props} />
    default:
      return <NavigationTeaserAmels {...props} />
  }
}

export default NavigationTeaser
