import HeroImageHeaderWithLogo from '../../patterns/HeroImageHeaderWithLogo'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import reactStringReplace from '../../utils/reactStringReplace'
import { HeroImageHeaderWithLogoStoryblok } from '../storyblok.generated'

interface Props {
  content: HeroImageHeaderWithLogoStoryblok
}

const HeroImageHeaderBlok = ({
  content: { title, image, logo, scrollIndicatorText, ...others },
}: Props) => (
  <HeroImageHeaderWithLogo
    id="top"
    title={title}
    image={mapImage(image) ?? getRequiredImageFallback()}
    logo={mapImage(logo) ?? getRequiredImageFallback()}
    scrollIndicatorText={
      scrollIndicatorText &&
      reactStringReplace(scrollIndicatorText, '\n', <br />)
    }
    {...others}
  />
)

export default HeroImageHeaderBlok
