import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer'

import Image from '../components/Image'
import RichText, { dummyResolver } from '../components/RichText'
import FormFieldText from '../components/FormFieldText'
import { FormNewsletterWomenInYachtingStoryblok } from './storyblok.generated'
import stripUndefined from '../utils/stripUndefined'
import FormFieldConditionsCheckbox from '../components/FormFieldConditionsCheckbox'
import Button from '../components/Button'
import AnimateText from '../components/AnimateText'
import FormResult from '../components/FormResult'
import { useRecaptchaForm } from '../utils/useRecaptchaForm'
import RecaptchaLoader from '../components/RecaptchaLoader'
import RecaptchaDisclaimer from '../components/RecaptchaDisclaimer'
import RecaptchaContainer from '../components/RecaptchaContainer'
import { operations } from '../api/form2lead.generated'
import { mapImage } from '../utils/mapImage'

const Form = styled.form(({ theme }) => [
  css`
    color: ${theme.colors.womenInYachtingPage.darkBlue};
  `,
])
const Title = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin: 0 0 ${theme.spacing.x6}px;
  `,
)
const Disclaimer = styled.div(({ theme }) => [
  css`
    margin-top: ${theme.spacing.x5}px;
  `,
])
const Submit = styled(Button)(({ theme }) => [
  css`
    margin-top: ${theme.spacing.x3}px;
  `,
])

interface Props {
  form: FormNewsletterWomenInYachtingStoryblok
}

const NewsletterFormWiYBlok = ({
  form: {
    title,
    firstNameBlock,
    lastNameBlock,
    emailBlock,
    jobTitleBlock,
    companyNameBlock,
    privacyPolicyBlock,
    submitBlock,
    pardotConfiguration,
    thankYouBlock,
    errorBlock,
    ...others
  },
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [greatSuccess, setGreatSuccess] = useState(false)
  const [formError, setFormError] = useState(false)
  interface FieldValues {
    title: string
    firstName: string
    lastName: string
    email: string
    country: string
    jobTitle: string
    companyName: string
    acceptPrivacyPolicy?: boolean
  }
  const {
    recaptchaElementId,
    isLoading,
    getRecaptchaToken,
    register,
    handleSubmit,
    formState: { errors },
  } = useRecaptchaForm<FieldValues>()
  const onSubmit = async (data: FieldValues) => {
    if (!data.acceptPrivacyPolicy) {
      return
    }

    setIsSubmitting(true)

    try {
      const recaptchaResponse = await getRecaptchaToken()
      // We could also map this on API, but it would be nice to keep fieldnames in sync with backend API
      const request: NonNullable<
        operations['CreateProspect']['requestBody']
      >['content']['application/json'] = {
        salutation: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        country: data.country,
        // for news letter subscriptions we can hardcode this as this is an implicit checkbox
        receiveNewsletter: true,
        jobRole: data.jobTitle,
        company: data.companyName,
        pardotEndpoint: pardotConfiguration,
        ...recaptchaResponse,
      }
      const response = await fetch('/api/prospect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(stripUndefined(request)),
      })
      const result = await response.json()

      if (!result.success) {
        setFormError(true)
        return
      }
      setGreatSuccess(true)
    } catch (err) {
      setFormError(true)
      throw err
    } finally {
      setIsSubmitting(false)
    }
  }

  const thankYouBlockImage = mapImage(thankYouBlock[0].image)

  if (greatSuccess) {
    return (
      <div data-testid="newsletterFormWiY.success">
        {thankYouBlockImage && (
          <Image
            src={thankYouBlockImage.src}
            alt={thankYouBlockImage.alt}
            width={200}
            height={200}
            style={{ objectFit: 'cover' }}
          />
        )}

        <FormResult
          title={thankYouBlock[0].title}
          text={thankYouBlock[0].text}
        />
      </div>
    )
  }

  if (formError) {
    return (
      <div data-testid="newsletterFormWiY.error">
        <FormResult title={errorBlock[0].title} text={errorBlock[0].text} />
      </div>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} {...others}>
      <AnimateText delay={100}>
        <Title>{title}</Title>
      </AnimateText>
      <AnimateText delay={220}>
        <FormFieldText
          label={firstNameBlock[0].label}
          error={errors.firstName}
          {...register('firstName', {
            required: firstNameBlock[0].errorText,
          })}
          autoComplete="given-name"
          required
          data-testid="newsletterFormWiY.firstName"
        />
      </AnimateText>
      <AnimateText delay={280}>
        <FormFieldText
          label={lastNameBlock[0].label}
          error={errors.lastName}
          {...register('lastName', {
            required: lastNameBlock[0].errorText,
          })}
          autoComplete="family-name"
          required
          data-testid="newsletterFormWiY.lastName"
        />
      </AnimateText>
      <AnimateText delay={340}>
        <FormFieldText
          label={emailBlock[0].label}
          error={errors.email}
          {...register('email', {
            required: emailBlock[0].errorText,
          })}
          type="email"
          autoComplete="email"
          required
          data-testid="newsletterFormWiY.email"
        />
      </AnimateText>
      <AnimateText delay={400}>
        <FormFieldText
          label={jobTitleBlock[0].label}
          error={errors.jobTitle}
          {...register('jobTitle', {
            required: jobTitleBlock[0].errorText,
          })}
          required
          data-testid="newsletterFormWiY.jobTitle"
        />
      </AnimateText>
      {companyNameBlock && (
        <AnimateText delay={460}>
          <FormFieldText
            label={companyNameBlock[0].label}
            error={errors.companyName}
            {...register('companyName', {
              required: companyNameBlock[0].errorText,
            })}
            required
            data-testid="newsletterFormWiY.companyName"
          />
        </AnimateText>
      )}
      <AnimateText delay={520}>
        {privacyPolicyBlock[0].text && (
          <Disclaimer>
            <RichText>{privacyPolicyBlock[0].text}</RichText>
          </Disclaimer>
        )}
      </AnimateText>
      <AnimateText delay={580}>
        <RecaptchaLoader />
        <RecaptchaDisclaimer />
        <RecaptchaContainer id={recaptchaElementId} />
      </AnimateText>
      <AnimateText delay={640}>
        <FormFieldConditionsCheckbox
          label={
            <RichText nodeResolvers={{ [NODE_PARAGRAPH]: dummyResolver }}>
              {privacyPolicyBlock[0].label}
            </RichText>
          }
          error={errors.acceptPrivacyPolicy}
          {...register('acceptPrivacyPolicy', {
            required: privacyPolicyBlock[0].errorText,
          })}
          required
          data-testid="newsletterFormWiY.acceptPrivacyPolicy"
        />
      </AnimateText>

      <AnimateText delay={700}>
        <Submit
          type="submit"
          data-testid="newsletterFormWiY.submit"
          disabled={isSubmitting || isLoading}
          variant="primary"
        >
          {submitBlock[0].title}
        </Submit>
      </AnimateText>
    </Form>
  )
}

export default NewsletterFormWiYBlok
