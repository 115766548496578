import PlainSpecifications from '../../patterns/PlainSpecifications'
import RichText from '../../components/RichText'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { PlainSpecificationsStoryblok } from '../storyblok.generated'

interface Props {
  content: PlainSpecificationsStoryblok
}

const PlainSpecificationsBlok = ({
  content: { sectionTitle, title, subTitle, specificationGroupBlocks },
  ...others
}: Props) => (
  <PlainSpecifications
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    groups={specificationGroupBlocks.map((block) =>
      block.component === 'specificationGroup'
        ? {
            key: block._uid,
            title: block.title,
            values: block.specificationBlocks.map(
              ({ _uid, label, value, additionalValue }) => ({
                key: _uid,
                label,
                value: reactStringReplace(value, '\n', <br />),
                additionalValue: reactStringReplace(
                  additionalValue ?? '',
                  '\n',
                  <br />,
                ),
              }),
            ),
            halved: block.halved,
          }
        : {
            key: block._uid,
            title: block.title,
            text: <RichText>{block.text}</RichText>,
          },
    )}
    {...others}
  />
)

export default PlainSpecificationsBlok
