import Quote from '../../patterns/Quote'
import RichText from '../../components/RichText'
import { QuoteStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: QuoteStoryblok
}

const QuoteBlok = ({
  content: { title, subTitle, text, image },
  ...others
}: Props) => (
  <Quote
    title={title}
    subTitle={subTitle}
    text={<RichText>{text}</RichText>}
    image={mapImage(image) ?? getRequiredImageFallback()}
    {...others}
  />
)

export default QuoteBlok
