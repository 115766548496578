import { css } from '@emotion/react'
import styled from '@emotion/styled'

import AnimateText from '../../components/AnimateText'
import Image from '../../components/Image'
import Section from '../../components/Section'
import { QuoteProps } from '.'

const StyledSection = styled(Section)(
  ({ theme }) => css`
    position: relative;
    margin: ${theme.spacing.x10}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: ${theme.spacing.x19}px ${theme.spacing.x5}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin: ${theme.spacing.x10}px ${theme.spacing.x6}px;
    }
  `,
)
const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    max-width: 1138px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: ${theme.colors.amels.marble};
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-direction: row;
      padding: ${theme.spacing.x10}px;
      gap: ${theme.spacing.x10}px;
    }
  `,
)
const ContentWrapper = styled.div``
/* eslint-disable no-irregular-whitespace */
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)};
    margin: -2px 0;
    color: ${theme.colors.amels.bayGold};
    position: relative;

    ::before {
      // The space needs to be a nbsp
      content: '- ';
      position: absolute;
      left: 0;
      transform: translateX(-100%);
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: ${theme.spacing.x6}px;
    }
  `,
)
const SubTitle = styled.h2(
  ({ theme }) => css`
    ${theme.text.subHeading(theme)};
    margin-bottom: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 14px;
      letter-spacing: 10px;
    }
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    ${theme.text.body(theme)}

    p:last-child {
      margin: 0;
    }
  `,
)
const ImageWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacing.x3}px;
    max-width: 310px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      min-width: 232px;
      margin-bottom: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      min-width: 310px;
    }
  `,
)

const Quote = ({ title, subTitle, text, image, ...others }: QuoteProps) => (
  <StyledSection sectionIndicatorVariant="dark" data-testid="quote" {...others}>
    <Container {...others}>
      <ImageWrapper>
        <Image
          src={image.src}
          alt={image.alt ?? ''}
          width={310}
          height={310}
          style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
        />
      </ImageWrapper>

      <ContentWrapper>
        <AnimateText delay={120}>
          <Title>{title}</Title>
        </AnimateText>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <Text>
          <AnimateText delay={240}>{text}</AnimateText>
        </Text>
      </ContentWrapper>
    </Container>
  </StyledSection>
)

export default Quote
