import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useEffect, useRef, useState } from 'react'

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: crosshair;
`

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  children: (values: {
    coords: [number, number] | undefined
    scale: number
    isTransitionEnabled: boolean
  }) => ReactNode
  zoomFactor: number
}

const HoverZoomView = ({ children, zoomFactor, ...others }: Props) => {
  const [coords, setCoords] = useState<[number, number] | undefined>()
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(true)
  const containerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const elem = containerRef.current
    if (!elem) {
      return
    }

    const handleMouseMove = (e: MouseEvent) => {
      const elemRect = elem.getBoundingClientRect()
      setCoords([
        ((e.clientX - elemRect.x) / elemRect.width) * (1 - 1 / zoomFactor),
        ((e.clientY - elemRect.y) / elemRect.height) * (1 - 1 / zoomFactor),
      ])
    }
    const handleMouseEnter = (e: MouseEvent) => {
      document.addEventListener('mousemove', handleMouseMove, {
        passive: true,
      })
      setIsTransitionEnabled(true)
      handleMouseMove(e)
      setTimeout(() => {
        setIsTransitionEnabled(false)
      }, 400)
    }
    const handleMouseLeave = () => {
      setIsTransitionEnabled(true)
      setCoords(undefined)
      document.removeEventListener('mousemove', handleMouseMove)
    }

    elem.addEventListener('mouseenter', handleMouseEnter)
    elem.addEventListener('mouseleave', handleMouseLeave)
    // eslint-disable-next-line consistent-return
    return () => {
      elem.removeEventListener('mouseenter', handleMouseEnter)
      elem.removeEventListener('mouseleave', handleMouseLeave)
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [containerRef, zoomFactor])

  return (
    <Container
      {...others}
      ref={containerRef}
      data-is-zoom-active={coords !== undefined}
    >
      {children({
        coords,
        scale: zoomFactor,
        isTransitionEnabled,
      })}
    </Container>
  )
}

export default HoverZoomView
