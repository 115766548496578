import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'

import Section from '../../components/Section'
import AnimateText, { hiddenClassName } from '../../components/AnimateText'
import Image from '../../components/Image'
import Link from '../../components/Link'
import LinkButton from '../../components/LinkButton'
import { ArticleFeedProps } from '.'
import {
  secondaryOutlineButtonCssXplorer,
  secondaryOutlineButtonHoverStyleCssXplorer,
} from '../../components/Button'

const ArticlesInnerWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'feed' | 'related' }>(({ theme, variant }) => [
  css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      flex-direction: row;
      gap: ${theme.spacing.x4}px;
    }
  `,
  variant === 'related' &&
    css`
      align-items: center;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        flex-direction: column;
        gap: ${theme.spacing.x10}px;
      }
    `,
])

const ArticleHeading = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'feed' | 'related' }>(({ theme, variant }) => [
  css`
    display: flex;
    flex-direction: column;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 360px;
    }
  `,
  variant === 'related' &&
    css`
      text-align: center;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        max-width: 100%;
        width: 100%;
      }
    `,
])

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: 'lightGreen' | 'lightSand' }>(({ theme, background }) => [
  css`
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x15}px;
    }
  `,
  background === 'lightGreen' &&
    css`
      background-color: ${theme.colors.xplorer.xpMistGrey70};
    `,
  background === 'lightSand' &&
    css`
      background: ${theme.colors.xplorer.secondaryXPBronze10Extra};
    `,
])

const TitleBar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'feed' | 'related' }>(({ theme, variant }) => [
  css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.x4}px;
    gap: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      flex-direction: row;
      align-items: center;
      margin-bottom: ${theme.spacing.x7}px;
    }
  `,
  variant === 'related' &&
    css`
      justify-content: center;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        margin-bottom: 0;
      }
    `,
])

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
    margin: 0;
  `,
)
const SubTitle = styled.p(
  ({ theme }) => css`
    ${theme.text.subHeading(theme)}
    line-height: 21px;
    color: ${theme.colors.xplorer.secondaryXPBronze};
    margin-bottom: ${theme.spacing.x2}px;
    text-transform: uppercase;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
)

const AutomatedArticlesButtonContainer = styled.div(
  () => css`
    display: flex;
    width: 100%;
    margin-top: 12px;
  `,
)
const Articles = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  `,
)
const ArticleImageContainer = styled('div')(
  () => css`
    overflow: hidden;
    position: relative;
    filter: grayscale(100%);
    transition: linear 200ms;
  `,
)
const ArticleTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading4(theme)}
    color: ${theme.colors.xplorer.primaryXPLavaBlack};
    margin: 0;
    transition: linear 200ms;
  `,
  css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-weight: 400;
    }
  `,
])
const ArticleButton = styled.div(
  ({ theme }) => css`
    ${secondaryOutlineButtonCssXplorer(theme)};
    margin-top: ${theme.spacing.x1}px;
    width: fit-content;
  `,
)
const ArticleLink = styled(Link)(
  ({ theme }) =>
    css`
      display: block;
      height: 100%;

      > div {
        height: 100%;
      }

      img {
        transition: transform ease-out 3000ms;
      }

      :hover {
        text-decoration: none;

        img {
          transform: scale(1.2);
        }

        ${ArticleImageContainer} {
          filter: none;
        }

        ${ArticleTitle} {
          color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
        }

        ${ArticleButton} {
          ${secondaryOutlineButtonHoverStyleCssXplorer(theme)}
        }
      }
    `,
)

const Article = styled.article(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x3}px;
    height: 100%;
  `,
)
const ArticleImage = styled(Image)(
  () => css`
    position: relative;
    width: 100%;
    height: auto;
    min-height: 282px;
  `,
)
const ArticleDescription = styled.p(({ theme }) => [
  css`
    ${theme.text.body(theme)}
    font-size: 12px;
    color: ${theme.colors.xplorer.xpLavaBlack70Solid};
    margin: 0;
  `,
  css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-weight: 400;
    }
  `,
])

const ArticleContent = styled.div(({ theme }) => [
  css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x3}px;
  `,
])
const ArticleInfo = styled.div(({ theme }) => [
  css`
    display: inline-block;
  `,
  css`
    ${theme.text.body(theme)}
    font-size: 12px;
    color: ${theme.colors.xplorer.grey04};
    position: relative;

    :not(:last-of-type) {
      padding-right: ${theme.spacing.x2 + 5}px;
      margin-right: ${theme.spacing.x2 + 5}px;

      ::after {
        content: '';
        position: absolute;
        right: 0;
        top: -1px;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='27' viewBox='0 0 12 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4893 0.519424L1.00027 26.4806' stroke='%23EC7404'/%3E%3C/svg%3E%0A");
        width: 12px;
        height: 27px;
        transform: translateX(50%);
      }
    }
  `,
])

const SeeAllButton = styled(LinkButton)(
  ({ theme }) =>
    css`
      ${secondaryOutlineButtonCssXplorer(theme)};
    `,
)

const ArticleFeed = ({
  title,
  subTitle,
  variant,
  background,
  viewAll,
  articles,
  automatedArticles,
  ...others
}: ArticleFeedProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const { locale } = useRouter()

  const activeArticles =
    automatedArticles && automatedArticles?.length > 0
      ? automatedArticles
      : articles

  if (!activeArticles) {
    return null
  }

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      background={background}
      data-testid="articleFeed"
      ref={containerRef}
      {...others}
    >
      <ArticlesInnerWrapper variant={variant}>
        <ArticleHeading variant={variant}>
          {subTitle && variant !== 'related' && (
            <AnimateText>
              <SubTitle>{subTitle}</SubTitle>
            </AnimateText>
          )}
          <TitleBar variant={variant}>
            {title && (
              <AnimateText>
                <Title>{title}</Title>
              </AnimateText>
            )}
          </TitleBar>
          {viewAll && variant !== 'related' ? (
            <AutomatedArticlesButtonContainer>
              <AnimateText delay={400}>
                <SeeAllButton
                  variant="secondary"
                  href={viewAll.href}
                  target={viewAll.target}
                >
                  {viewAll.title}
                </SeeAllButton>
              </AnimateText>
            </AutomatedArticlesButtonContainer>
          ) : undefined}
        </ArticleHeading>
        <Articles>
          {activeArticles.map(
            (
              { key, variant, slug, title, image, summary, publishedAt },
              index,
            ) => (
              <ArticleLink
                key={key}
                href={
                  variant === 'article' ? `/news/${slug}` : `/events/${slug}`
                }
              >
                <AnimateText delay={1200 + index * 600}>
                  <Article>
                    <ArticleImageContainer>
                      <ArticleImage
                        src={image}
                        alt={title}
                        width={275}
                        height={282}
                        aspectRatio="275:282"
                        style={{ objectFit: 'cover' }}
                      />
                    </ArticleImageContainer>
                    <ArticleContent>
                      {publishedAt && (
                        <ArticleInfo>
                          {new Date(publishedAt).toLocaleDateString(locale, {
                            day: 'numeric',
                          })}{' '}
                          {new Date(publishedAt).toLocaleDateString(locale, {
                            month: 'long',
                          })}{' '}
                          {new Date(publishedAt).toLocaleDateString(locale, {
                            year: 'numeric',
                          })}
                        </ArticleInfo>
                      )}
                      <ArticleTitle>{title}</ArticleTitle>
                      <ArticleDescription>{summary}</ArticleDescription>
                    </ArticleContent>
                    <ArticleButton>Read more</ArticleButton>
                  </Article>
                </AnimateText>
              </ArticleLink>
            ),
          )}
        </Articles>
      </ArticlesInnerWrapper>
    </Container>
  )
}

export default ArticleFeed
