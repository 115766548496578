import { ComponentProps, ReactNode } from 'react'
import Section from '../../components/Section'
import { currentSite, Site } from '../../sites'
import TestimonialXplorer from './Testimonial.xplorer'

export interface TestimonialProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant'
  > {
  image: {
    src: string
    alt?: string
  }
  quote: ReactNode
  quoteeName?: string
  quoteeFunction?: string
  imagePosition?: 'left' | 'right'
  backgroundColorVariants?: 'white' | 'lightGreen' | 'lightSand'
  quoteTextColorVariants?: '' | 'darkOcean' | 'lavaBlack' | 'bronze'
}

const Testimonial = (props: TestimonialProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <TestimonialXplorer {...props} />
    default:
      return <TestimonialXplorer {...props} />
  }
}

export default Testimonial
