import { css } from '@emotion/react'
import styled from '@emotion/styled'
import SocialIcon from './SocialIcon'
import Link from './Link'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'

const SocialList = styled.ul(({ theme }) => [
  css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing.x3}px;
    list-style: none;
  `,
])

const StyledSocialLink = styled(Link)(({ theme }) => [
  css`
    display: block;
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};
      width: 32px;
      height: 32px;
    `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.orange};
      width: 24px;
      height: 24px;
      transition: color 400ms;

      :hover {
        color: ${theme.colors.yachting.silver};
      }

      svg {
        height: 100%;
        max-width: 100%;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
      width: 24px;
      height: 24px;
      transition: opacity 400ms;

      :hover {
        opacity: 0.8;
      }

      svg {
        height: 100%;
        max-width: 100%;
      }
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      color: ${theme.colors.womenInYachtingPage.terraBlush};

      :hover {
        color: ${theme.colors.womenInYachtingPage.rockyGrey};
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      width: 32px;
      height: 32px;

      svg {
        height: 100%;
        max-width: 100%;
      }
    `,
])

export type Social = {
  url: string
  type:
    | 'vimeo'
    | 'facebook'
    | 'instagram'
    | 'linkedin'
    | 'twitter'
    | 'youtube'
    | 'whatsApp'
    | 'mail'
}

interface Props {
  socials?: Social[]
}

const SocialsList = ({ socials }: Props) => {
  return (
    <SocialList>
      {socials?.map((social) => (
        <li key={social.type}>
          <StyledSocialLink
            href={social.url}
            target="_blank"
            rel="noopener noreferer"
          >
            <SocialIcon icon={social.type} />
          </StyledSocialLink>
        </li>
      ))}
    </SocialList>
  )
}

export default SocialsList
