import { SocialShareStoryblok } from '../storyblok.generated'
import SocialShare from '../../patterns/SocialShare'

interface Props {
  content: SocialShareStoryblok
}

const SocialShareBlok = ({
  content: { title, titleColorChangeAfterWord },
  ...others
}: Props) => (
  <SocialShare
    title={title}
    titleColorChangeAfterWord={titleColorChangeAfterWord}
    {...others}
  />
)

export default SocialShareBlok
