/* eslint-disable security/detect-object-injection */
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Button, {
  activeTabButtonCss,
  invisibleButtonCss,
  tabButtonCss,
  tabCssYachting,
  tabCssXplorer,
  tabCssYachtSupport,
  activeTabCssYachting,
  activeTabCssXplorer,
  activeTabCssYachtSupport,
} from '../components/Button'
import Image from '../components/Image'
import Toggle from '../components/Toggle'
import IconButton from '../components/IconButton'
import HoverZoomView from '../components/HoverZoomView'
import ChevronRight from '../icons/ChevronRight.svg'
import FloorplanModal from '../components/FloorplanModal'
import SidePanelTransition from '../components/SidePanelTransition'
import Section from '../components/Section'
import makeCloudinaryImageUrl from '../utils/makeCloudinaryImageUrl'
import getPublicIdFromCloudinaryUrl from '../utils/getPublicIdFromCloudinaryUrl'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'
import { currentSite, Site } from '../sites'

const Container = styled(Section)(({ theme }) => [
  css`
    padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
    text-align: center;
  `,
  isSiteAmels(theme) &&
    css`
      background: ${theme.colors.amels.silk};
    `,
  isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.beach10};
    `,
  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
      }
    `,
])
const Title = styled.h2(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      ${theme.text.heading1(theme)}
      margin-top: 0;
      margin-bottom: ${theme.spacing.x2}px;
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin-top: 0;
      margin-bottom: ${theme.spacing.x5}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x10}px;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin-top: 0;
      margin-bottom: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x6}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin-top: 0;
      margin-bottom: ${theme.spacing.x4}px;
    `,
])
const SubTitle = styled.div(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      ${theme.text.subHeadingWide(theme)}
      margin-bottom: ${theme.spacing.x5}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x9}px;
      }
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      margin-bottom: ${theme.spacing.x2}px;
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.bodySmall(theme)}
      margin-bottom: ${theme.spacing.x6}px;
    `,
])
const OverLine = styled.div(({ theme }) => [
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.overLineMedium(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-bottom: ${theme.spacing.x2}px;
    `,
])
export const DeckSelection = styled.div(({ theme }) => [
  css`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: ${theme.spacing.x2}px;
    margin: ${theme.spacing.x10}px ${theme.spacing.x4}px ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0;
      margin-bottom: ${theme.spacing.x6}px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      justify-content: center;
      margin: 0;
      margin-bottom: 630px;

      @media screen and (min-width: ${theme.breakpoints.mobileMedium}px) {
        margin-bottom: 548px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-wrap: nowrap;
        margin-bottom: ${theme.spacing.x4}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      justify-content: center;
      margin: 0;
      margin-bottom: 480px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-wrap: nowrap;
        margin-bottom: ${theme.spacing.x4}px;
      }
    `,
])
export const DeckButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => [
  isSiteAmels(theme) &&
    css`
      ${tabButtonCss(theme)}
      color: ${theme.colors.amels.beach};
    `,
  isSiteYachting(theme) &&
    css`
      ${tabCssYachting(theme)}
      color: ${theme.colors.yachting.darkBlue};
    `,
  isSiteXplorer(theme) &&
    css`
      ${tabCssXplorer(theme)}
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${tabCssYachtSupport(theme)}
    `,
  active && isSiteAmels(theme) && activeTabButtonCss(theme),
  active && isSiteYachting(theme) && activeTabCssYachting(theme),
  active && isSiteXplorer(theme) && activeTabCssXplorer(theme),
  active && isSiteYachtSupport(theme) && activeTabCssYachtSupport(theme),
])

const DesktopViewer = styled.div(
  ({ theme }) => css`
    max-width: 1152px;
    margin: 0 auto;
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const MobilePreview = styled.div(({ theme }) => [
  css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      transform: rotate(-90deg);
      height: 500px;
    `,
  isSiteYachtSupport(theme) &&
    css`
      display: flex;
      align-items: center;
      transform: rotate(-90deg);
      height: 500px;
    `,
])
const ImageContainer = styled.div(({ theme }) => [
  css`
    position: relative;
    width: 100%;
    height: 150px;
    margin-bottom: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      height: 400px;
      margin-bottom: ${theme.spacing.x10}px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      margin: 0;
      height: 500px;

      img {
        width: auto !important;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        height: 400px;
        margin-bottom: ${theme.spacing.x10}px;

        img {
          width: 100% !important;
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin: 0;

      width: 800px;
      height: 200px;

      img {
        width: auto !important;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        height: 400px;
        width: auto;
        margin-bottom: ${theme.spacing.x10}px;

        img {
          width: 100% !important;
        }
      }
    `,
])
const ZoomableImage = styled.img(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: ${isSiteXplorer(theme) || isSiteYachtSupport(theme)
      ? 'cover'
      : 'contain'};
    transform-origin: top left;
  `,
)
const ToggleDetails = styled(Button)(({ theme }) => [
  css`
    ${invisibleButtonCss()}
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.deepBayAqua};
      font-size: 12px;
      letter-spacing: 3.43px;
      font-weight: 500;
      text-transform: uppercase;
    `,
  isSiteYachting(theme) &&
    css`
      font-family: ${theme.fonts.Georgia.style.fontFamily};
      font-size: 16px;
      line-height: 1.5;
      color: ${theme.colors.yachting.darkBlue};
    `,
])
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`

const ContentWrapper = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
  `,
  isSiteYachting(theme) &&
    css`
      flex-direction: column-reverse;
    `,
  isSiteYachtSupport(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 806px;
        margin: 0 auto;
      }
    `,
])

const ToggleContentWrapper = styled.div(() => [
  css`
    display: flex;
    align-items: center;
  `,
])

const StyledIconButton = styled(IconButton)(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      display: none;
    `,
])

const StyledButton = styled(Button)(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      display: none;
    `,
])

export interface FloorplanDeck {
  key: string
  title: ReactNode
  baseImage: string
  detailedImage: string
}

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'className' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  subTitle: ReactNode
  overline: ReactNode
  mobilePreviewImage: string
  decks: FloorplanDeck[]
  desktopZoomFactor?: number
  mobileZoomFactor?: number
}

const Floorplan = ({
  title,
  subTitle,
  overline,
  mobilePreviewImage,
  decks,
  desktopZoomFactor = 3,
  mobileZoomFactor = 5,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  const [activeDeck, setActiveDeck] = useState(0)
  const [showDetails, setShowDetails] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleDetails = () => setShowDetails(!showDetails)

  if (currentSite === Site.Xplorer || currentSite === Site.YachtSupport) {
    return (
      <Container
        id="floorplan"
        sectionIndicatorVariant="dark"
        ref={ref}
        className={!inView ? hiddenClassName : undefined}
        data-testid="floorplan"
        data-active-deck={activeDeck}
        {...others}
      >
        <ContentWrapper>
          {currentSite === Site.YachtSupport && (
            <AnimateText>
              <OverLine>{overline}</OverLine>
            </AnimateText>
          )}
          <AnimateText>
            <Title>{title}</Title>
          </AnimateText>
          {currentSite === Site.YachtSupport && (
            <AnimateText delay={400}>
              <SubTitle>{subTitle}</SubTitle>
            </AnimateText>
          )}
        </ContentWrapper>
        <DeckSelection>
          {decks.map((deck, index) => (
            <DeckButton
              key={deck.key}
              active={activeDeck === index}
              onClick={() => setActiveDeck(index)}
              data-testid={`floorplan.deck.${index}`}
            >
              <AnimateText delay={400 + index * 200}>{deck.title}</AnimateText>
            </DeckButton>
          ))}
        </DeckSelection>

        <DesktopViewer>
          <AnimateText delay={400 + decks.length * 200} direction="static">
            <ImageContainer>
              <HoverZoomView
                zoomFactor={desktopZoomFactor}
                data-testid="floorplan.zoomView"
              >
                {({ coords, scale, isTransitionEnabled }) => (
                  // eslint-disable-next-line @next/next/no-img-element
                  <ZoomableImage
                    src={makeCloudinaryImageUrl({
                      publicId: getPublicIdFromCloudinaryUrl(
                        showDetails
                          ? decks[activeDeck].detailedImage
                          : decks[activeDeck].baseImage,
                      ),
                      transformations: ['q_auto', 'f_auto'],
                    })}
                    alt=""
                    style={{
                      willChange: 'transition, transform',
                      transition: isTransitionEnabled
                        ? 'transform 400ms ease-out'
                        : undefined,
                      transform: coords
                        ? `scale(${scale}) translate(-${coords[0] * 100}%, -${
                            coords[1] * 100
                          }%)`
                        : `scale(1) translate(0%, 0%)`,
                    }}
                  />
                )}
              </HoverZoomView>
            </ImageContainer>
          </AnimateText>
        </DesktopViewer>
        <MobilePreview>
          <ImageContainer>
            <Image
              src={decks[activeDeck].detailedImage}
              alt=""
              sizes="100vw"
              fill
              style={{ objectFit: 'cover' }}
              priority
            />
          </ImageContainer>
        </MobilePreview>
      </Container>
    )
  }

  return (
    <Container
      id="floorplan"
      sectionIndicatorVariant="dark"
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      data-testid="floorplan"
      data-active-deck={activeDeck}
      {...others}
    >
      <ContentWrapper>
        <AnimateText>
          <Title>{title}</Title>
        </AnimateText>
        <AnimateText delay={400}>
          <SubTitle>{subTitle}</SubTitle>
        </AnimateText>
      </ContentWrapper>

      <DesktopViewer>
        <DeckSelection>
          {decks.map((deck, index) => (
            <DeckButton
              key={deck.key}
              active={activeDeck === index}
              onClick={() => setActiveDeck(index)}
              data-testid={`floorplan.deck.${index}`}
              data-active={activeDeck === index}
            >
              <AnimateText delay={400 + index * 200}>{deck.title}</AnimateText>
            </DeckButton>
          ))}
        </DeckSelection>

        <AnimateText delay={400 + decks.length * 200} direction="static">
          <ImageContainer>
            <HoverZoomView
              zoomFactor={desktopZoomFactor}
              data-testid="floorplan.zoomView"
            >
              {({ coords, scale, isTransitionEnabled }) => (
                // eslint-disable-next-line @next/next/no-img-element
                <ZoomableImage
                  src={makeCloudinaryImageUrl({
                    publicId: getPublicIdFromCloudinaryUrl(
                      showDetails
                        ? decks[activeDeck].detailedImage
                        : decks[activeDeck].baseImage,
                    ),
                    transformations: ['q_auto', 'f_auto'],
                  })}
                  alt=""
                  style={{
                    willChange: 'transition, transform',
                    transition: isTransitionEnabled
                      ? 'transform 400ms ease-out'
                      : undefined,
                    transform: coords
                      ? `scale(${scale}) translate(-${coords[0] * 100}%, -${
                          coords[1] * 100
                        }%)`
                      : `scale(1) translate(0%, 0%)`,
                  }}
                />
              )}
            </HoverZoomView>
          </ImageContainer>
        </AnimateText>

        <AnimateText delay={400 + decks.length * 300} direction="static">
          <ToggleDetails onClick={toggleDetails}>
            <ToggleContentWrapper>
              <Toggle active={showDetails} /> Show information
            </ToggleContentWrapper>
          </ToggleDetails>
        </AnimateText>
      </DesktopViewer>
      <MobilePreview>
        <ImageContainer>
          <Image
            src={mobilePreviewImage}
            alt=""
            sizes="100vw"
            fill
            style={{ objectFit: 'contain' }}
          />
        </ImageContainer>

        <StyledIconButton
          icon={<StyledChevronRight aria-label="" />}
          onClick={() => setIsModalOpen(true)}
          data-testid="floorplan.browseFloorplans"
        >
          Browse floorplans
        </StyledIconButton>

        <StyledButton
          variant="primary"
          onClick={() => setIsModalOpen(true)}
          data-testid="floorplan.browseFloorplansYachting"
        >
          Browse floorplans
        </StyledButton>

        <SidePanelTransition isOpen={isModalOpen}>
          {isModalOpen && (
            <FloorplanModal
              close={() => setIsModalOpen(false)}
              decks={decks}
              zoomFactor={mobileZoomFactor}
            />
          )}
        </SidePanelTransition>
      </MobilePreview>
    </Container>
  )
}

export default Floorplan
