import { ComponentProps, ReactNode } from 'react'
import Section from '../../components/Section'
import { currentSite } from '../../sites'
import TextWithVideoAmels from './TextWithVideo.amels'
import TextWithVideoYachting from './TextWithVideo.yachting'

export interface TextWithVideoProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant' | 'title'
  > {
  overline?: string
  sectionTitle?: string
  title: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
  video: string
  imageLocation?: 'left' | 'right' | 'bottom' | 'top'
  background?: 'white' | 'beach10'
}

const TextWithVideo = (props: TextWithVideoProps) => {
  switch (currentSite) {
    case 'yachting':
      return <TextWithVideoYachting {...props} />
    default:
      return <TextWithVideoAmels {...props} />
  }
}

export default TextWithVideo
