import { TwoColumnsTextStoryblok } from '../storyblok.generated'
import TwoColumnsText from '../../patterns/TwoColumnsText'
import RichText from '../../components/RichText'

interface Props {
  content: TwoColumnsTextStoryblok
}

const TwoColumnsTextBlok = ({
  content: {
    heading,
    subheading,
    headingInColumn,
    hideHeading,
    leftSide,
    rightSide,
    ...others
  },
}: Props) => (
  <TwoColumnsText
    heading={heading}
    subHeading={subheading}
    headingInColumn={headingInColumn}
    hideHeading={hideHeading}
    leftSide={<RichText>{leftSide}</RichText>}
    rightSide={<RichText>{rightSide}</RichText>}
    {...others}
  />
)

export default TwoColumnsTextBlok
