import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { ContentGroupStoryblok } from '../storyblok.generated'
import Waves from '../../icons/Waves.svg'
import { PageContextProvider, usePageContext } from '../PageContext'
import Section from '../../components/Section'
import BlokZone from '../../components/BlokZone'
import patterns from '.'
import { isSiteYachting } from '../../themes'

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{ background?: 'blue' | 'waves-left' }>(({ theme, background }) => [
  css`
    position: relative;
    overflow: hidden;
  `,
  background === 'blue' &&
    isSiteYachting(theme) &&
    css`
      background-color: ${theme.colors.yachting.silver10};
    `,
])
const StyledWaves = styled(Waves)(
  ({ theme }) => css`
    position: absolute;
    z-index: -1;
    left: 0;
    transform: translateX(-39%) translateY(-50%) scaleX(-1);
    height: 100vh;
    top: 50%;
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      height: 120vh;
    }
  `,
)

interface Props {
  content: ContentGroupStoryblok
}

const ContentGroupBlok = ({
  content: { content, background, backgroundOffset },
  ...others
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { SectionIndicator, ...pageContextWithoutSectionIndicator } =
    usePageContext()

  return (
    <Container
      sectionIndicatorVariant="dark"
      // It's not actually a section, it just combines two patterns
      as="div"
      background={background}
      {...others}
    >
      {/** Fool the DesignTextWithExpandableImage into thinking we don't want a section indicator */}
      <PageContextProvider value={pageContextWithoutSectionIndicator}>
        <BlokZone components={patterns} content={content} />
        {background === 'waves-left' && (
          <StyledWaves
            aria-label=""
            style={{
              top: backgroundOffset ? `${backgroundOffset}%` : undefined,
            }}
          />
        )}
      </PageContextProvider>
    </Container>
  )
}

export default ContentGroupBlok
