import { currentSite, Site } from '../../sites'
import ColumnContentYachtSupport from './ColumnContent.yachtsupport'

export interface ColumnContentProps {
  columns: Array<{
    key: string
    image: {
      src: string
      alt?: string
    }
    heading: string
    overline?: string
    description: string
    buttonLabel?: string
    buttonLink?: string
  }>
}

const ColumnContent = (props: ColumnContentProps) => {
  switch (currentSite) {
    case Site.YachtSupport:
      return <ColumnContentYachtSupport {...props} />
    default:
      return <ColumnContentYachtSupport {...props} />
  }
}

export default ColumnContent
