import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import ContactHeaderDefault from './ContactHeader'
import SimpleHeader from '../SimpleHeader'
import ContactHeaderXplorer from './ContactHeader.xplorer'

export interface ContactHeaderProps
  extends Omit<ComponentProps<typeof SimpleHeader>, 'children'> {
  form: ReactNode
  linkText?: string
  overline?: string
}

const ContactHeader = (props: ContactHeaderProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <ContactHeaderXplorer {...props} />
    case Site.YachtSupport:
      return <ContactHeaderXplorer {...props} />
    default:
      return <ContactHeaderDefault {...props} />
  }
}

export default ContactHeader
