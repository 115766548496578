import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import AnimateHeight from 'react-animate-height'

import AnimateText, { hiddenClassName } from './AnimateText'
import ChevronRight from '../icons/ChevronRight.svg'
import YachtSupportPlus from '../icons/yachtsupport/Plus.svg'
import YachtSupportMinus from '../icons/yachtsupport/Minus.svg'
import Collapsible from './Collapsible'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'
import { currentSite, Site } from '../sites'

const GroupTitle = styled.button(({ theme }) => [
  css`
    font-size: 24px;
    margin-bottom: ${theme.spacing.x6}px;
    font-weight: 400;
    border: 0;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 32px;
    }
  `,
  isSiteAmels(theme) &&
    css`
      font-family: ${theme.fonts.Zermatt.style.fontFamily};
      color: ${theme.colors.amels.bayGold};
      text-transform: uppercase;
      letter-spacing: 5px;
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading3(theme)}
      text-align: left;
      margin-bottom: 0;
      padding-bottom: ${theme.spacing.x4}px;
      border-bottom: 1px solid ${theme.colors.yachting.silver70};
      font-size: 26px;

      @media screen and (min-width: ${theme.breakpoints.mobileMedium}px) {
        font-size: 26px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 30px;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading5(theme)}
      font-family: ${theme.fonts.TTFirsNeue.style.fontFamily};
      margin: 0 auto;
      padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;
      width: auto;
      color: ${theme.colors.xplorer.white};
      background: ${theme.colors.xplorer.secondaryXPBronze};
      text-transform: uppercase;
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading6(theme)}
      color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
      margin: 0 auto;
      text-transform: uppercase;
      text-align: left;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 16px;
      }
      border: 1px solid ${theme.colors.yachtSupport.grey03};
      border-bottom: 0;
      padding: ${theme.spacing.x1}px ${theme.spacing.x2}px;
    `,
])
const GroupTitleText = styled.div`
  flex: 1;
`
const ActionIcon = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.amels.oyster};
    border: 1px solid ${theme.colors.amels.oyster};
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
)
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`

const ActionIconYachtSupport = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)

const StyledYachtSupportPlus = styled(YachtSupportPlus)<{
  isExpanded?: boolean
}>(({ isExpanded }) => [
  css`
    width: 24px;
    height: 24px;
    ${isExpanded &&
    css`
      display: none;
    `}
  `,
])

const StyledYachtSupportMinus = styled(YachtSupportMinus)<{
  isExpanded?: boolean
}>(({ isExpanded }) => [
  css`
    width: 24px;
    height: 24px;
    ${!isExpanded &&
    css`
      display: none;
    `}
  `,
])

const SpecificationsGroup = styled.div<{ isExpanded?: boolean }>(
  ({ theme, isExpanded }) => [
    css`
      margin-bottom: ${theme.spacing.x6}px;

      ${ActionIcon} {
        transition: transform 300ms;
        transform: rotate(${isExpanded ? 90 : -90}deg);
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x15}px;
      }
    `,
    isSiteYachting(theme) &&
      css`
        margin-bottom: ${theme.spacing.x8}px;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin-bottom: ${theme.spacing.x8}px;
        }
      `,
    isSiteXplorer(theme) &&
      css`
        margin-bottom: ${theme.spacing.x3}px;
        height: 100%;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin-bottom: ${theme.spacing.x3}px;
          width: 100%;
        }
      `,
    isSiteYachtSupport(theme) &&
      css`
        flex: 1;
        margin-bottom: 0;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          margin-bottom: 0;
        }

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          margin-bottom: 0;
        }
      `,
  ],
)

const Values = styled.div<{ hasMoreThanThree: boolean }>(
  ({ theme, hasMoreThanThree }) => [
    css`
      display: flex;
      flex-flow: column;
      justify-content: center;
      gap: ${theme.spacing.x3}px;
      text-align: center;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-flow: row;
        gap: ${theme.spacing.x7}px;
      }
    `,
    isSiteYachting(theme) &&
      css`
        gap: 0;
        text-align: left;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          flex-flow: column;
          gap: 0;
        }
      `,
    isSiteXplorer(theme) &&
      css`
        gap: 0;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          flex-flow: column;
          gap: 0;
        }

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          flex-flow: row;
          gap: 0;
          height: calc(100% - ${theme.spacing.x8}px);
        }
      `,
    isSiteYachtSupport(theme) &&
      css`
        gap: 0;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          gap: 0;

          ${hasMoreThanThree &&
          css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            min-width: 600px;
          `}
        }
      `,
  ],
)
const Spec = styled.div(({ theme }) => [
  css`
    flex: 0 1 33%;
    white-space: nowrap;
  `,
  isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x4}px 0;
      white-space: wrap;

      &:not(:last-of-type) {
        border-bottom: 1px solid ${theme.colors.yachting.silver70};
      }
    `,
  isSiteXplorer(theme) &&
    css`
      flex: 1;
      padding: 36px ${theme.spacing.x3}px;
      border-left: 1px solid ${theme.colors.xplorer.secondaryXPBronze};
      border-bottom: 1px solid ${theme.colors.xplorer.secondaryXPBronze};
      border-right: 1px solid ${theme.colors.xplorer.secondaryXPBronze};
      white-space: pre-wrap;

      &:first-of-type {
        border-top: 1px solid ${theme.colors.xplorer.secondaryXPBronze};
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        border-top: 1px solid ${theme.colors.xplorer.secondaryXPBronze};
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        &:first-of-type {
          border-left: 1px solid ${theme.colors.xplorer.secondaryXPBronze};
        }

        &:not(:last-of-type) {
          border-right: none;
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      flex: 1;
      border: 1px solid ${theme.colors.yachtSupport.grey03};
      padding: ${theme.spacing.x3}px;
    `,
])
const SpecAdditionalValue = styled.div(({ theme }) => [
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading3(theme)};
      color: ${theme.colors.xplorer.xpLavaBlack30Solid};
    `,
])
const SpecValue = styled.div(({ theme }) => [
  css`
    font-size: ${theme.spacing.x5}px;
    line-height: 1.1;
    margin-bottom: 6px;
  `,
  isSiteAmels(theme) &&
    css`
      font-family: ${theme.fonts.Zermatt.style.fontFamily};
      letter-spacing: 0;
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading3(theme)}
      font-weight: 300;
      font-size: 20px;
      margin-bottom: 0;

      @media screen and (min-width: ${theme.breakpoints.mobileMedium}px) {
        font-size: 20px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 24px;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading2(theme)}
      font-family: ${theme.fonts.Chaney.style.fontFamily};
      letter-spacing: 0;
      color: ${theme.colors.xplorer.white};
      margin-bottom: 4px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 36px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading5(theme)}
      font-family: ${theme.fonts.Rubik.style.fontFamily};
      color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
      text-transform: none;
    `,
])
const SpecTitle = styled.div(({ theme }) => [
  css`
    text-transform: uppercase;
    letter-spacing: 3.43px;
    font-weight: 500;
    font-size: 12px;
  `,
  isSiteAmels(theme) &&
    css`
      color: ${theme.colors.amels.bayGold};
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)}
      margin-bottom: ${theme.spacing.x2}px;
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      color: ${theme.colors.xplorer.secondaryXPBronze};
      letter-spacing: normal;
      margin-bottom: 4px;
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.overLineSmall(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-bottom: ${theme.spacing.x1}px;
    `,
])

interface Props {
  defaultIsExpanded?: boolean
  collapsible?: boolean
  title: ReactNode
  values: Array<{
    key: string
    label: ReactNode
    value: ReactNode
    additionalValue?: ReactNode
  }>
}

const SpecificationGroup = ({
  defaultIsExpanded,
  collapsible,
  title,
  values,
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  })

  if (!collapsible) {
    return (
      <SpecificationsGroup
        ref={ref}
        className={!inView ? hiddenClassName : undefined}
      >
        <GroupTitle>
          <GroupTitleText>
            <AnimateText>{title}</AnimateText>
          </GroupTitleText>
        </GroupTitle>
        <Values hasMoreThanThree={values.length > 3}>
          {values.map(({ key, label, value, additionalValue }, index) => (
            <Spec key={key}>
              {currentSite === Site.Yachting && (
                <>
                  <SpecTitle>
                    <AnimateText delay={200 + 160 * index} direction="static">
                      {label}
                    </AnimateText>
                  </SpecTitle>
                  <SpecValue>
                    <AnimateText delay={600 + 160 * index} direction="static">
                      {value}
                    </AnimateText>
                  </SpecValue>
                </>
              )}
              {currentSite === Site.Amels && (
                <>
                  <SpecValue>
                    <AnimateText delay={200 + 160 * index} direction="static">
                      {value}
                    </AnimateText>
                  </SpecValue>
                  <SpecTitle>
                    <AnimateText delay={600 + 160 * index} direction="static">
                      {label}
                    </AnimateText>
                  </SpecTitle>
                </>
              )}
              {currentSite === Site.YachtSupport && (
                <>
                  <SpecTitle>
                    <AnimateText delay={600 + 160 * index} direction="static">
                      {label}
                    </AnimateText>
                  </SpecTitle>
                  <SpecValue>
                    <AnimateText delay={200 + 160 * index} direction="static">
                      {value}
                    </AnimateText>
                  </SpecValue>
                </>
              )}
              {currentSite === Site.Xplorer && (
                <>
                  <SpecTitle>
                    <AnimateText delay={600 + 160 * index} direction="static">
                      {label}
                    </AnimateText>
                  </SpecTitle>
                  <SpecValue>
                    <AnimateText delay={200 + 160 * index} direction="static">
                      {value}
                    </AnimateText>
                  </SpecValue>
                  {additionalValue && (
                    <SpecAdditionalValue>
                      <AnimateText delay={200 + 160 * index} direction="static">
                        {additionalValue}
                      </AnimateText>
                    </SpecAdditionalValue>
                  )}
                </>
              )}
            </Spec>
          ))}
        </Values>
      </SpecificationsGroup>
    )
  }

  return (
    <Collapsible defaultValue={defaultIsExpanded}>
      {({ toggle, isExpanded }) => (
        <SpecificationsGroup
          isExpanded={isExpanded}
          ref={ref}
          className={!inView ? hiddenClassName : undefined}
        >
          <GroupTitle type="button" onClick={toggle}>
            <GroupTitleText>
              <AnimateText>{title}</AnimateText>
            </GroupTitleText>
            <ActionIcon>
              <StyledChevronRight
                aria-label={isExpanded ? 'Collapse' : 'Expand'}
              />
            </ActionIcon>
            <ActionIconYachtSupport>
              <StyledYachtSupportPlus
                isExpanded={isExpanded}
                aria-label={isExpanded ? 'Collapse' : 'Expand'}
              />
              <StyledYachtSupportMinus
                isExpanded={isExpanded}
                aria-label={isExpanded ? 'Collapse' : 'Expand'}
              />
            </ActionIconYachtSupport>
          </GroupTitle>

          <AnimateHeight duration={800} height={isExpanded ? 'auto' : 0}>
            <Values hasMoreThanThree={values.length > 3}>
              {values.map(({ key, label, value }, index) => (
                <Spec key={key}>
                  {currentSite === Site.YachtSupport ? (
                    <>
                      <SpecTitle>
                        <AnimateText
                          delay={600 + 160 * index}
                          direction="static"
                        >
                          {label}
                        </AnimateText>
                      </SpecTitle>
                      <SpecValue>
                        <AnimateText delay={200 + 160 * index}>
                          {value}
                        </AnimateText>
                      </SpecValue>
                    </>
                  ) : (
                    <>
                      <SpecValue>
                        <AnimateText delay={200 + 160 * index}>
                          {value}
                        </AnimateText>
                      </SpecValue>
                      <SpecTitle>
                        <AnimateText
                          delay={600 + 160 * index}
                          direction="static"
                        >
                          {label}
                        </AnimateText>
                      </SpecTitle>
                    </>
                  )}
                </Spec>
              ))}
            </Values>
          </AnimateHeight>
        </SpecificationsGroup>
      )}
    </Collapsible>
  )
}

export default SpecificationGroup
