import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import Image from '../components/Image'

import Section from '../components/Section'
import { isSiteXplorer, isThemeAmelsLandingspage } from '../themes'
import { currentSite, Site } from '../sites'

const getColors = (theme: Theme, background?: Background) => {
  switch (background) {
    case 'light':
      return `
        background: ${theme.colors.landingspage.shell};
        color: ${theme.colors.landingspage.beach};
      `

    case 'dark':
      return `
        background: ${theme.colors.landingspage.beach};
        color: ${theme.colors.landingspage.white};
      `

    case 'white':
    default:
      return `
        background: ${theme.colors.landingspage.body};
        color: ${theme.colors.landingspage.bodyText};
      `
  }
}

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'background',
})<{
  background?: Background
}>(({ theme, background }) => [
  css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x12}px ${theme.spacing.x7}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x20}px;
    }
  `,
  isThemeAmelsLandingspage(theme) &&
    css`
      ${getColors(theme, background)};
    `,
  isSiteXplorer(theme) &&
    css`
      position: relative;
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;

      p {
        margin-bottom: 0 !important;
      }
    `,
])

const ContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'background',
})<{
  align?: 'left' | 'center' | 'right'
  width?: 'standard' | 'wide'
}>(({ theme, width }) => [
  css`
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 auto;
    padding: 0 ${theme.spacing.x4}px;
    max-width: 867px;
    letter-spacing: 1px;
    overflow: visible;
  `,
  isSiteXplorer(theme) &&
    css`
      max-width: none;
      padding: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: fit-content;
      }
    `,
  width === 'wide' &&
    css`
      max-width: 1080px;
    `,
])

const StyledImage = styled(Image)(({ theme }) => [
  css`
    margin-bottom: ${theme.spacing.x4}px;
    height: auto;
  `,
  isSiteXplorer(theme) &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      display: none;
      margin-bottom: 0;
      transform: translate(-50%, -50%);

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: block;
        max-width: 80%;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        max-width: none;
      }
    `,
])

const Content = styled.div(({ theme }) => [
  css`
    width: 100%;
    text-align: left;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading2(theme)}

      z-index: 1;
      text-align: center;
      color: ${theme.colors.xplorer.secondaryXPBronze};
    `,
])

type Background = '' | 'light' | 'dark' | 'white'

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant'
  > {
  logo: {
    src: string
    alt?: string
  }
  children: ReactNode
  width?: 'standard' | 'wide'
  background?: Background
}

const LogoWithText = ({
  children,
  logo,
  width,
  background,
  ...others
}: Props) => (
  <Container
    sectionIndicatorVariant="dark"
    background={background}
    data-testid="text"
    {...others}
  >
    <ContentWrapper width={width}>
      <StyledImage
        src={logo.src}
        alt={logo.alt}
        width={currentSite === Site.Xplorer ? 1207 : 280}
        height={currentSite === Site.Xplorer ? 136 : 280}
        style={{ objectFit: 'contain' }}
      />
      <Content>{children}</Content>
    </ContentWrapper>
  </Container>
)

export default LogoWithText
