import reactStringReplace from '../../utils/reactStringReplace'
import { HarmonicaItemStoryblok } from '../storyblok.generated'
import HarmonicaItem from '../../patterns/HarmonicaItem'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: HarmonicaItemStoryblok
}

const HarmonicaItemBlok = ({
  content: { _uid, text, image },
  ...others
}: Props) => (
  <HarmonicaItem
    id={_uid}
    text={reactStringReplace(text, '\n', <br />)}
    image={mapImage(image)}
    {...others}
  />
)

export default HarmonicaItemBlok
