import DesignTextWithExpandableImage from '../../patterns/DesignTextWithExpandableImage'
import RichText from '../../components/RichText'
import slugify from '../../utils/slugify'
import { DesignTextWithExpandableImageStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: DesignTextWithExpandableImageStoryblok
}

const DesignTextWithExpandableImageBlok = ({
  content: {
    sectionTitle,
    title,
    subTitle,
    modalSubTitle,
    text,
    image,
    imageLocation,
    buttonText,
    carouselImages,
  },
  ...others
}: Props) => (
  <DesignTextWithExpandableImage
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    modalSubTitle={<RichText>{modalSubTitle}</RichText>}
    text={<RichText>{text}</RichText>}
    image={mapImage(image) ?? getRequiredImageFallback()}
    imageLocation={imageLocation}
    buttonText={buttonText}
    carouselImages={carouselImages?.map(({ _uid, image, text, title }) => ({
      key: _uid,
      image: mapImage(image) ?? getRequiredImageFallback(),
      title,
      text,
    }))}
    {...others}
  />
)

export default DesignTextWithExpandableImageBlok
