import LargeHeroImageHeader from '../../patterns/LargeHeroImageHeader'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { LargeHeroImageHeaderStoryblok } from '../storyblok.generated'

interface Props {
  content: LargeHeroImageHeaderStoryblok
}

const LargeHeroImageHeaderBlok = ({
  content: { sectionTitle, image, text, scrollIndicatorText },
  ...others
}: Props) => (
  <LargeHeroImageHeader
    id={slugify(sectionTitle)}
    image={mapImage(image) ?? getRequiredImageFallback()}
    text={text}
    scrollIndicatorText={reactStringReplace(scrollIndicatorText, '\n', <br />)}
    {...others}
  />
)

export default LargeHeroImageHeaderBlok
