import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../../components/AnimateText'
import Image from '../../components/Image'
import Section from '../../components/Section'

import type { TextWithImageProps } from './index'
import {
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../../themes'

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  imageLocation: TextWithImageProps['imageLocation']
  backgroundImage: TextWithImageProps['backgroundImage']
}>(({ imageLocation, backgroundImage }) => [
  css`
    width: 100%;
    height: auto;
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    backgroundImage &&
    css`
      z-index: 1;
    `,
])
const ImageContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  imageLocation: TextWithImageProps['imageLocation']
  backgroundImage: TextWithImageProps['backgroundImage']
}>(({ theme, imageLocation, backgroundImage }) => [
  css`
    position: relative;
    display: flex;
    flex: 0 0 auto;
    max-width: 100%;
    overflow: hidden;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
      flex-direction: column;
      align-items: center;
      max-height: 540px;

      ${StyledImage} {
        width: 100%;
        height: auto;
      }
    }
  `,
  (imageLocation === 'top' || imageLocation === 'bottom') &&
    css`
      max-height: 800px;

      ${StyledImage} {
        width: 100%;
        height: auto;
      }
    `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    backgroundImage &&
    css`
      overflow: visible;
    `,
])
const Content = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 600px;
    }
  `,
)
const Overline = styled.span(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      display: block;
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      display: block;
      margin-bottom: ${theme.spacing.x2}px;
    `,
])
const Title = styled.h2(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin: 0 0 ${theme.spacing.x4}px;
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin: 0 0 ${theme.spacing.x4}px;
    `,
])
const Text = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `,
)
const StyledBackgroundImage = styled(Image)`
  position: absolute;
  top: 50%;
  left: -30%;
  width: 160%;
  height: auto;
  transform: translateY(-50%);
`
const ContentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'imageLocation',
})<{
  imageLocation: TextWithImageProps['imageLocation']
}>(({ theme, imageLocation }) => [
  css`
    ${theme.layout.maxContentWidth(theme)};
    display: flex;
    position: relative;
    gap: ${theme.spacing.x4}px;
    justify-content: center;
    flex-flow: column;

    ${Overline},
    ${Text} {
      margin-left: ${theme.spacing.x2}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      ${Overline},
      ${Text} {
        margin-left: ${theme.spacing.x3}px;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x10}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      gap: ${theme.spacing.x15}px;
    }
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    css`
      align-items: stretch;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-flow: ${imageLocation === 'left' ? 'row' : 'row-reverse'};
        align-items: center;

        > div {
          width: 50%;
        }
      }
    `,
  (imageLocation === 'top' || imageLocation === 'bottom') &&
    css`
      flex-flow: ${imageLocation === 'top' ? 'column' : 'column-reverse'};

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        gap: ${theme.spacing.x4}px;
      }

      ${Text} {
        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          margin-left: 0;
        }
      }

      ${Content} {
        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          display: flex;
          justify-content: space-between;
          gap: ${theme.spacing.x10}px;
          max-width: none;

          > * {
            width: 50%;
          }
        }
      }

      ${Overline} {
        margin-bottom: ${theme.spacing.x2}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        max-width: 1086px;
        margin: 0 auto;
      }
    `,
])

const Container = styled(Section, {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  imageLocation: TextWithImageProps['imageLocation']
  background: TextWithImageProps['background']
}>(
  ({ theme, background, imageLocation }) =>
    isSiteYachting(theme) && [
      css`
        padding: ${theme.spacing.x6}px ${theme.spacing.x4}px;
        z-index: 0;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          padding: ${theme.spacing.x20}px ${theme.spacing.x10}px;
        }
        @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
          padding: ${theme.spacing.x20}px;
        }
      `,
      background === 'white' &&
        css`
          background: ${theme.colors.yachting.white};
        `,
      (imageLocation === 'left' || imageLocation === 'right') &&
        background === 'beach10' &&
        css`
          background: ${isThemeYachtingWomenInYachtingLandingspage(theme)
            ? theme.colors.womenInYachtingPage.beach10
            : theme.colors.yachting.beach10};
        `,
      (imageLocation === 'left' || imageLocation === 'right') &&
        background === 'silver10' &&
        css`
          background: ${theme.colors.yachting.silver10};
        `,
      (imageLocation === 'top' || imageLocation === 'bottom') &&
        background === 'darkBlue' &&
        css`
          background: ${theme.colors.yachting.darkBlue};

          ${Title},
          ${Overline},
          ${Text} {
            color: ${theme.colors.yachting.white};
          }
        `,
    ],
)

const TextWithImage = ({
  overline,
  title,
  text,
  image,
  imageLocation = 'right',
  background,
  backgroundImage,
  ...others
}: TextWithImageProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  const imageOrientation =
    imageLocation === 'left' || imageLocation === 'right'
      ? 'portrait'
      : 'landscape'

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      background={background}
      imageLocation={imageLocation}
      data-testid="textWithImage"
      ref={containerRef}
      {...others}
    >
      <ContentContainer imageLocation={imageLocation}>
        <AnimateText direction="static">
          <ImageContainer
            imageLocation={imageLocation}
            backgroundImage={backgroundImage}
          >
            {imageOrientation === 'portrait' && (
              <StyledImage
                src={image.src}
                alt={image.alt}
                width={585}
                height={780}
                style={{ objectFit: 'cover' }}
                imageLocation={imageLocation}
                backgroundImage={backgroundImage}
              />
            )}
            {imageOrientation === 'landscape' && (
              <StyledImage
                src={image.src}
                alt={image.alt}
                width={1200}
                height={800}
                style={{ objectFit: 'cover' }}
                imageLocation={imageLocation}
                backgroundImage={backgroundImage}
              />
            )}
            {(imageLocation === 'left' || imageLocation === 'right') &&
              backgroundImage && (
                <StyledBackgroundImage
                  src={backgroundImage.src}
                  alt={backgroundImage.alt}
                  width={1200}
                  height={800}
                  style={{ objectFit: 'cover' }}
                />
              )}
          </ImageContainer>
        </AnimateText>
        <Content>
          <AnimateText>
            {overline && <Overline>{overline}</Overline>}
            <Title>{title}</Title>
          </AnimateText>
          <AnimateText delay={400}>
            <Text>{text}</Text>
          </AnimateText>
        </Content>
      </ContentContainer>
    </Container>
  )
}

export default TextWithImage
