import { ReactNode } from 'react'
import ArticleHeaderAmels from './ArticleHeaderAmels'
import { Site, currentSite } from '../sites'
import ArticleHeaderYachting from './ArticleHeaderYachting'
import SocialShare from '../patterns/SocialShare'
import ArticleHeaderXplorer from './ArticleHeaderXplorer'
import ArticleHeaderYachtsupport from './ArticleHeaderYachtsupport'

interface Props {
  isEvent?: boolean
  title: string
  type: string
  publishedAt: string
  image?: {
    src: string
    alt?: string
  }
  video?: string
  socialShare: boolean
  defaultSocialShareTitle?: string
  children: ReactNode
  dateShort?: string
  location?: string
}

const Article = ({
  isEvent,
  title,
  type,
  publishedAt,
  image,
  video,
  socialShare,
  defaultSocialShareTitle,
  children,
  dateShort,
  location,
}: Props) => {
  return (
    <article data-testid="news.article">
      {currentSite === Site.Amels && (
        <ArticleHeaderAmels
          title={title}
          type={type}
          publishedAt={publishedAt}
          image={image?.src ?? ''}
          video={video}
        />
      )}
      {currentSite === Site.Yachting && (
        <ArticleHeaderYachting
          isEvent={isEvent}
          title={title}
          type={type}
          publishedAt={publishedAt}
          image={image?.src ?? ''}
          video={video}
          dateShort={dateShort}
          location={location}
        />
      )}
      {currentSite === Site.Xplorer && (
        <ArticleHeaderXplorer
          title={title}
          publishedAt={publishedAt}
          image={image?.src ?? ''}
        />
      )}
      {currentSite === Site.YachtSupport && (
        <ArticleHeaderYachtsupport
          title={title}
          publishedAt={publishedAt}
          image={image?.src ?? ''}
        />
      )}

      {children}

      {socialShare && (
        <SocialShare
          title={
            currentSite === Site.Yachting
              ? defaultSocialShareTitle
              : 'Share this story'
          }
        />
      )}
    </article>
  )
}

export default Article
