import HeroImageHeader from '../../patterns/HeroImageHeader'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import reactStringReplace from '../../utils/reactStringReplace'
import { HeroImageHeaderStoryblok } from '../storyblok.generated'

interface Props {
  content: HeroImageHeaderStoryblok
}

const HeroImageHeaderBlok = ({
  content: { title, subTitle, image, scrollIndicatorText, ...others },
}: Props) => (
  <HeroImageHeader
    id="top"
    title={title}
    subTitle={subTitle}
    image={mapImage(image) ?? getRequiredImageFallback()}
    scrollIndicatorText={
      scrollIndicatorText &&
      reactStringReplace(scrollIndicatorText, '\n', <br />)
    }
    {...others}
  />
)

export default HeroImageHeaderBlok
