import styled from '@emotion/styled'
import {
  useRef,
  useEffect,
  useState,
  useLayoutEffect,
  ReactNode,
  forwardRef,
  useCallback,
  MutableRefObject,
} from 'react'

import mergeRefs from '../utils/mergeRefs'

declare global {
  interface TouchEvent {
    scale?: number
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  /* -webkit-overflow-scrolling: touch; */
`

interface Props {
  children: (
    scale: number,
    ref: MutableRefObject<HTMLDivElement | null>,
  ) => ReactNode
  minScale?: number
  maxScale?: number
  onPinch?: () => void
}

/**
 * The ZoomView component aims to match zoom views within native apps with
 * native scrollbars and scrolling while allowing full pinch zooming.
 */
const PinchZoomView = forwardRef<HTMLDivElement, Props>(
  (
    { children, minScale = 1, maxScale = 10, onPinch, ...others }: Props,
    ref,
  ) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [scale, setScale] = useState(1)

    const onUpdateScale = useRef<() => void>()

    const clampPinchScale = useCallback(
      (pinchScale: number) => {
        if (scale * pinchScale > maxScale) {
          return maxScale / scale
          // eslint-disable-next-line no-else-return
        } else if (scale * pinchScale < minScale) {
          return minScale / scale
        } else {
          return pinchScale
        }
      },
      [scale, minScale, maxScale],
    )
    const applyTempZoom = useCallback(
      (pinchScale: number, middleX: number, middleY: number) => {
        const container = containerRef.current
        const scrollContainer = scrollContainerRef.current
        if (!container || !scrollContainer) {
          return
        }

        container.style.transform = `scale(${pinchScale})`
        const originX = scrollContainer.scrollLeft + middleX
        const originY = scrollContainer.scrollTop + middleY
        container.style.transformOrigin = `${originX}px ${originY}px`
      },
      [containerRef, scrollContainerRef],
    )
    const applyZoom = useCallback(
      (pinchScale: number, middleX: number, middleY: number) => {
        const container = containerRef.current
        const scrollContainer = scrollContainerRef.current
        if (!container || !scrollContainer) {
          return
        }

        container.style.transform = `none`
        container.style.transformOrigin = `unset`
        const newScale = scale * pinchScale
        const newScrollLeft =
          ((scrollContainer.scrollLeft + middleX) / scale) * newScale - middleX
        const newScrollTop =
          ((scrollContainer.scrollTop + middleY) / scale) * newScale - middleY
        onUpdateScale.current = () => {
          scrollContainer.scrollLeft = newScrollLeft
          scrollContainer.scrollTop = newScrollTop
        }
        setScale(newScale)
      },
      [containerRef, scrollContainerRef, scale, setScale],
    )

    useEffect(() => {
      const container = containerRef.current
      const scrollContainer = scrollContainerRef.current
      if (!container || !scrollContainer) {
        return
      }

      // Based on https://gist.github.com/larsneo/bb75616e9426ae589f50e8c8411020f6
      let startX = 0
      let startY = 0
      let initialPinchDistance = 0
      let pinchScale = 1
      const scRect = scrollContainer.getBoundingClientRect()
      const reset = () => {
        // eslint-disable-next-line no-multi-assign
        startX = startY = initialPinchDistance = 0
        pinchScale = 1
      }
      const handleTouchStart = (e: TouchEvent) => {
        if (e.touches.length > 1) {
          // Needs to be relative to the container not the page
          startX =
            (e.touches[0].clientX + e.touches[1].clientX) / 2 - scRect.left
          startY =
            (e.touches[0].clientY + e.touches[1].clientY) / 2 - scRect.top
          initialPinchDistance = Math.hypot(
            e.touches[1].clientX - e.touches[0].clientX,
            e.touches[1].clientY - e.touches[0].clientY,
          )
        } else {
          initialPinchDistance = 0
        }
      }
      const handleTouchMove = (e: TouchEvent) => {
        if (initialPinchDistance <= 0 || e.touches.length < 2) {
          return
        }
        const pinchDistance = Math.hypot(
          e.touches[1].clientX - e.touches[0].clientX,
          e.touches[1].clientY - e.touches[0].clientY,
        )
        pinchScale = clampPinchScale(pinchDistance / initialPinchDistance)
        // TODO: Allow pinching a bit further but reset to max
        applyTempZoom(pinchScale, startX, startY)
        if (pinchScale !== 1) {
          onPinch?.()
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const handleTouchEnd = (e: TouchEvent) => {
        if (initialPinchDistance <= 0) {
          return
        }
        applyZoom(pinchScale, startX, startY)
        reset()
      }
      document.addEventListener('touchstart', handleTouchStart)
      document.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      })
      document.addEventListener('touchend', handleTouchEnd)
      // eslint-disable-next-line consistent-return
      return () => {
        document.removeEventListener('touchstart', handleTouchStart)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.removeEventListener('touchmove', handleTouchMove, {
          passive: false,
        })
        document.removeEventListener('touchend', handleTouchEnd)
      }
    }, [
      clampPinchScale,
      containerRef,
      scrollContainerRef,
      applyTempZoom,
      applyZoom,
      onPinch,
    ])
    // Prevent native document scaling on iOS
    useEffect(() => {
      const handleTouchMove = (e: TouchEvent) => {
        if ('scale' in e && e.scale !== 1) {
          // eslint-disable-next-line no-console
          console.log('Preventing zooming on app level')
          e.preventDefault()
        }
      }
      document.addEventListener('touchmove', handleTouchMove, {
        passive: false,
      })

      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.removeEventListener('touchmove', handleTouchMove, {
          passive: false,
        })
      }
    }, [])
    useLayoutEffect(() => {
      if (onUpdateScale.current) {
        onUpdateScale.current()
        onUpdateScale.current = undefined
      }
    }, [scale])
    // Prevent Mac touchpad pinch zoom
    useEffect(() => {
      const elem = scrollContainerRef.current
      if (!elem) {
        return
      }

      const handleWheel = (e: WheelEvent) => {
        if (e.ctrlKey) {
          // when pinching to zoom on the touchpad, the ctrlKey will be set
          e.preventDefault()
        }
      }

      elem.addEventListener('wheel', handleWheel)
      // eslint-disable-next-line consistent-return
      return () => elem.removeEventListener('wheel', handleWheel)
    }, [scrollContainerRef])

    return (
      <Container ref={mergeRefs(scrollContainerRef, ref)} {...others}>
        {children(scale, containerRef)}
      </Container>
    )
  },
)
PinchZoomView.displayName = 'PinchZoomView'

export default PinchZoomView
