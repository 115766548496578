import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { isSiteAmels, isSiteYachting } from '../themes'

const Container = styled.div(({ theme }) => [
  css`
    display: inline-block;
    vertical-align: text-bottom;
    border-radius: 11px;
    padding: 3px;
    width: 38px;
    height: 22px;
    margin-right: ${theme.spacing.x1}px;
  `,
  isSiteAmels(theme) &&
    css`
      background: ${theme.colors.amels.deepBayAqua30};
    `,
  isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.darkBlue30};
    `,
])
const Ball = styled.div(({ theme }) => [
  css`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: margin-left 400ms ease-in-out;
  `,
  isSiteAmels(theme) &&
    css`
      background: ${theme.colors.amels.deepBayAqua};
    `,
  isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.darkBlue};
    `,
])
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const activeCss = (theme: Theme) => css`
  ${Ball} {
    margin-left: 16px;
  }
`

interface Props {
  active?: boolean
}

const Toggle = ({ active }: Props) => (
  <Container css={active ? activeCss : undefined}>
    <Ball />
  </Container>
)

export default Toggle
