import { ComponentProps, ReactNode, useState } from 'react'

import ImageSection from './ImageSection'
import SidePanelFixed from '../components/SidePanelFixed'
import SidePanelTransition from '../components/SidePanelTransition'

interface Props
  extends Omit<ComponentProps<typeof ImageSection>, 'sectionIndicatorVariant'> {
  form: ReactNode
}

const RequestBrochureSection = ({
  title,
  callToActionText,
  form,
  backgroundImage,
  ...others
}: Props) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <ImageSection
        backgroundImage={backgroundImage}
        title={title}
        callToActionText={callToActionText}
        onCallToActionClick={() => setOpen(true)}
        id="brochure"
        data-testid="requestBrochureSection"
        sectionIndicatorVariant="none"
        {...others}
      />

      <SidePanelTransition isOpen={isOpen}>
        {isOpen && (
          <SidePanelFixed
            close={() => setOpen(false)}
            data-testid="requestBrochureSection.sidePanel"
          >
            {form}
          </SidePanelFixed>
        )}
      </SidePanelTransition>
    </>
  )
}

export default RequestBrochureSection
