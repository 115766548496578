import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import AnimateHeight from 'react-animate-height'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'
import LinkButton from '../components/LinkButton'
import Button from '../components/Button'
import ExpandIcon from '../components/ExpandIcon'
import ContactCard, { ContactCardProps } from '../components/ContactCard'

type CardOrientation = 'landscape' | 'portrait'

const Container = styled(Section)(
  ({ theme }) => css`
    background: ${theme.colors.yachting.silver10};
    padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x12}px ${theme.spacing.x7}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x20}px;
    }
  `,
)
const ContentContainer = styled.div(
  ({ theme }) => css`
    ${theme.layout.maxContentWidth(theme)}
  `,
)
const TitleBar = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.x7}px;
    gap: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x2}px;
      flex-direction: row;
      align-items: end;
      text-align: left;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      align-items: start;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.yachting.darkBlue};
    margin: 0;
  `,
)
const ItemList = styled('ul', {
  shouldForwardProp: (prop) => prop !== 'cardOrientation',
})<{ cardOrientation: CardOrientation }>(({ theme, cardOrientation }) => [
  css`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: ${theme.spacing.x8}px;
    grid-row-gap: ${theme.spacing.x8}px;
    grid-column-gap: ${theme.spacing.x3}px;

    @media screen and (max-width: ${theme.breakpoints.tabletMax}px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
      display: grid;
      grid-template-columns: 1fr;
    }
  `,
  cardOrientation === 'portrait' &&
    css`
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    `,
  cardOrientation === 'landscape' &&
    css`
      justify-content: center;
      grid-column-gap: ${theme.spacing.x6}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        li {
          flex-direction: row;
          align-items: center;
        }
      }
    `,
])
const ShowMore = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      justify-content: center;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      justify-content: end;
    }

    button {
      border: none;
    }

    svg {
      width: 20px;
      color: ${theme.colors.yachting.orange};
      margin-left: ${theme.spacing.x2}px;
    }
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  showMoreTitle: ReactNode
  link?: {
    title: ReactNode
    href: string
    target?: string
  }
  contacts?: ContactCardProps['contact'][]
}

const Contacts = ({
  title,
  contacts,
  link,
  showMoreTitle,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-300px 0px',
    triggerOnce: true,
  })

  const [isExpanded, setExpanded] = useState(false)
  const { visibleContacts, moreContacts } = useMemo(
    () =>
      showMoreTitle
        ? {
            visibleContacts: contacts?.slice(0, 3),
            moreContacts: contacts?.slice(3),
          }
        : { visibleContacts: contacts },
    [contacts, showMoreTitle],
  )

  const controlId = others.id ?? 'extra-contacts'
  const hasMore = moreContacts && moreContacts.length > 0
  const cardOrientation: CardOrientation =
    !contacts || contacts.length < 3 ? 'landscape' : 'portrait'

  const renderContacts = (contacts: Props['contacts']) => (
    <ItemList cardOrientation={cardOrientation}>
      {contacts?.map((contact, index) => (
        <ContactCard
          key={contact.key}
          contact={contact}
          animationDelay={(index + 1) * 400}
        />
      ))}
    </ItemList>
  )

  return (
    <Container
      ref={ref}
      data-testid="contacts"
      className={!inView ? hiddenClassName : undefined}
      sectionIndicatorVariant="light"
      {...others}
    >
      <ContentContainer>
        <TitleBar>
          {title && (
            <AnimateText>
              <Title>{title}</Title>
            </AnimateText>
          )}
          {link && (
            <LinkButton variant="primary" href={link.href} target={link.target}>
              {link.title}
            </LinkButton>
          )}
        </TitleBar>

        {renderContacts(visibleContacts)}

        <AnimateHeight
          id={controlId}
          duration={800}
          height={isExpanded ? 'auto' : 0}
        >
          {renderContacts(moreContacts)}
        </AnimateHeight>

        {hasMore && (
          <ShowMore>
            <Button
              onClick={() => setExpanded((p) => !p)}
              aria-expanded={isExpanded}
              aria-controls={controlId}
            >
              {showMoreTitle}
              <ExpandIcon isExpanded={isExpanded} />
            </Button>
          </ShowMore>
        )}
      </ContentContainer>
    </Container>
  )
}

export default Contacts
