import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'

import Button, {
  activeTabCssXplorer,
  tabCssXplorer,
} from '../../components/Button'
import Section from '../../components/Section'
import { useShowNavigationBar } from '../../components/StickyNavigationBar'
import FormFieldSelect from '../../components/FormFieldSelect'
import { TabbedContentProps } from '.'
import useViewportSize from '../../utils/useViewportSize'
import { tablet } from '../../themes/breakpoints'
import { x12 } from '../../themes/spacing'

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'backgroundColor',
})<{
  backgroundColor?: string
}>(({ theme, backgroundColor }) => [
  css`
    padding: ${theme.spacing.x8}px 0;
    overflow: visible;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x15}px 0;
    }
  `,
  backgroundColor === 'green' &&
    css`
      background-color: ${theme.colors.xplorer.xpDarkOceanGreen10Solid};
    `,
  backgroundColor === 'bronze' &&
    css`
      background-color: ${theme.colors.xplorer.xpBronze10Solid};
    `,
  backgroundColor === 'white' &&
    css`
      background-color: ${theme.colors.xplorer.white};
    `,
])

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    text-align: center;
    margin: 0 ${theme.spacing.x5}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 ${theme.spacing.x5}px ${theme.spacing.x5}px;
    }
  `,
)

const Overline = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      align-items: center;
      ${theme.text.overlineMedium && theme.text.overlineMedium(theme)}
      color: ${theme.colors.xplorer.secondaryXPBronze};
      gap: ${theme.spacing.x1}px;
      margin: 0 auto ${theme.spacing.x2}px;
      padding: 0 ${theme.spacing.x3}px;
      width: fit-content;
      text-align: center;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
)

const Description = styled.p(
  ({ theme }) =>
    css`
      ${theme.text.body(theme)}
      max-width: 910px;
      margin: 0 auto ${theme.spacing.x6}px;
      padding: 0 ${theme.spacing.x3}px;
      text-align: center;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x7}px;
      }
    `,
)

const SelectContainer = styled.div(({ theme }) => [
  css`
    position: relative;
    display: block;
    padding-top: ${theme.spacing.x1}px;
    margin: 0 ${theme.spacing.x1}px;
    height: ${theme.spacing.x9}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
])

const StickyContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSticky',
})<{
  isSticky?:
    | 'withNavigationMobile'
    | 'withNavigationDesktop'
    | 'withoutNavigation'
    | false
}>(({ theme, isSticky }) => [
  css`
    position: sticky;
    z-index: 2;
    top: 0;
    padding: 0 ${theme.spacing.x3}px;
    margin-bottom: ${theme.spacing.x8}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: 0 ${theme.spacing.x4}px;
      margin-bottom: ${theme.spacing.x15}px;
    }

    transition: 600ms ease-out;
  `,
  isSticky === 'withNavigationDesktop' &&
    css`
      background-color: ${theme.colors.xplorer.white};
      top: ${theme.spacing.x12}px;
      ${theme.shadows.md};
    `,
  isSticky === 'withNavigationMobile' &&
    css`
      background-color: ${theme.colors.xplorer.white};
      top: ${theme.spacing.x7}px;
      ${theme.shadows.md}
    `,
  isSticky === 'withoutNavigation' &&
    css`
      background-color: ${theme.colors.xplorer.white};
      top: 0;
      ${theme.shadows.md};
    `,
  !isSticky &&
    css`
      top: 0;
    `,
])

const TabButtons = styled.div(({ theme }) => [
  css`
    position: relative;
    display: none;
    gap: ${theme.spacing.x3}px ${theme.spacing.x3}px;
    z-index: 1;
    justify-content: center;
    flex-wrap: wrap;
    padding: ${theme.spacing.x2}px 0;
    width: fit-content;
    margin: 0 auto;
    overflow: hidden;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: flex;
      gap: ${theme.spacing.x3}px ${theme.spacing.x10}px;
    }
  `,
])
const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'backgroundColor',
})<{ active?: boolean; backgroundColor?: string }>(
  ({ theme, active, backgroundColor }) => [
    tabCssXplorer(theme, 'secondary'),
    backgroundColor === 'green' &&
      css`
        background-color: ${theme.colors.xplorer.xpDarkOceanGreen10Solid};
      `,
    backgroundColor === 'bronze' &&
      css`
        background-color: ${theme.colors.xplorer.xpBronze10Solid};
      `,
    backgroundColor === 'white' &&
      css`
        background-color: ${theme.colors.xplorer.white};
      `,
    active && activeTabCssXplorer(theme, 'secondary'),
    !active &&
      css`
        box-shadow: ${theme.shadows.md};
      `,
    css`
      border: 1px solid ${theme.colors.xplorer.xpDarkOceanGreen30Solid};

      :not(:last-child) {
        ::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          height: 2px;
          transform: translate(100%, -50%);
          background: url('data:image/svg+xml,<svg width="6" height="2" viewBox="0 0 6 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 1.5H46" stroke="%23B2CAC7" stroke-width="2" stroke-dasharray="2 4"/></svg>');
          background-repeat: repeat-x;
        }
      }
    `,
  ],
)
const Tab = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active }) => [
  css`
    display: none;
  `,
  active &&
    css`
      display: block;
    `,
])

const TabbedContent = ({
  title,
  overline,
  description,
  tabs,
  backgroundColor,
  ...others
}: TabbedContentProps) => {
  const viewport = useViewportSize()
  const [_ref, show] = useShowNavigationBar()
  const [activeTab, setActiveTab] = useState(0)
  const [isSticky, setIsSticky] = useState<
    | 'withNavigationDesktop'
    | 'withNavigationMobile'
    | 'withoutNavigation'
    | false
  >(false)
  const tabsRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (!containerRef.current || !viewport) return

      const topOffset = containerRef.current.getBoundingClientRect().top

      if (show && topOffset <= x12) {
        if (viewport.width >= tablet) {
          setIsSticky('withNavigationDesktop')
        } else {
          setIsSticky('withNavigationMobile')
        }
      } else if (topOffset <= x12) {
        setIsSticky('withoutNavigation')
      } else {
        setIsSticky(false)
      }
    }

    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [show, viewport])

  return (
    <Container
      data-testid="tabbedContent"
      sectionIndicatorVariant="dark"
      backgroundColor={backgroundColor}
      {...others}
    >
      {overline && <Overline>{overline}</Overline>}
      <Title>{title}</Title>

      {description && <Description>{description}</Description>}
      <StickyContainer ref={containerRef} isSticky={isSticky}>
        <SelectContainer>
          <FormFieldSelect
            label=""
            noInitialOption
            onChange={(e) => setActiveTab(+e.target.value)}
            options={tabs.map(({ title }, index) => ({
              key: `${index}`,
              value: String(title),
            }))}
          />
        </SelectContainer>
        <TabButtons ref={tabsRef}>
          {tabs.map(({ key, title }, index) => (
            <TabButton
              key={key}
              active={activeTab === index}
              onClick={() => setActiveTab(index)}
              data-testid={`tabbedContent.tabButton.${index}`}
              data-active={activeTab === index}
              backgroundColor={backgroundColor}
            >
              {title}
            </TabButton>
          ))}
        </TabButtons>
      </StickyContainer>

      {tabs.map(({ key, content }, index) => (
        <Tab
          key={key}
          active={activeTab === index}
          data-testid={`tabbedContent.tab.${index}`}
          data-active={activeTab === index}
        >
          {content}
        </Tab>
      ))}
    </Container>
  )
}

export default TabbedContent
