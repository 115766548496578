import { css, keyframes, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import squarePolygon from '../utils/squarePolygon'
import ImageCarousel from '../components/ImageCarousel'
import useDimensions from '../utils/useDimensions'
import useIsMobile from '../utils/useIsMobile'
import Section from '../components/Section'

const Container = styled(Section)(
  ({ theme }) =>
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;
      background: ${theme.colors.amels.marble};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x20}px ${theme.spacing.x6}px;
        display: flex;
        flex-direction: row;
        gap: ${theme.spacing.x6}px;
        justify-content: center;
      }
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        gap: ${theme.spacing.x20}px;
      }
    `,
)
const adjacentContainerCss = (theme: Theme) => css`
  & + ${Container} {
    // Collapse some of the padding by moving the second component slightly over
    // the first.
    margin-top: -${theme.spacing.x4}px;
    padding-top: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: -${theme.spacing.x10}px;
      padding-top: ${theme.spacing.x10}px;
    }
  }
`
const carouselLeftCss = (theme: Theme) => css`
  @media screen and (min-width: ${theme.breakpoints.tablet}px) {
    flex-direction: row-reverse;
  }
`
const ContentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 1 1 50%;
      padding: ${theme.spacing.x5}px 0;
    }
  `,
)
const Content = styled.div`
  width: 100%;
`
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.amels.beach};
    letter-spacing: 0;
    margin-top: ${theme.spacing.x2}px;
    margin-bottom: ${theme.spacing.x7}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-left: ${theme.spacing.x14}px;
    }
  `,
)
const SubTitle = styled.div(
  ({ theme }) => css`
    ${theme.text.subHeading(theme)};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-left: ${theme.spacing.x6}px;
    }
  `,
)
const Text = styled.div(
  ({ theme }) =>
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-left: ${theme.spacing.x14}px;
      }
    `,
)
const MobileImageContainer = styled.div(
  ({ theme }) =>
    css`
      display: block;
      margin-bottom: ${theme.spacing.x7}px;
      overflow: hidden;
      max-width: 100%;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: none;
      }

      @media (prefers-reduced-motion: no-preference) {
        :not(.${hiddenClassName} &) {
          clip-path: ${squarePolygon(0, 0, 0, 1)};
          animation: ${keyframes`
            from {
              clip-path: ${squarePolygon(0, 0, 0, 1)};
            }
            to {
              clip-path: ${squarePolygon(0, 0, 0, 0)};
            }
          `} 1400ms ease-in-out forwards;
        }
      }
    `,
)
const TabletImageColumn = styled.div(
  ({ theme }) => css`
    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
      flex: 1 1 50%;
      overflow: hidden;
    }
  `,
)
const TabletImagePositioner = styled.div(
  () =>
    css`
      @media (prefers-reduced-motion: no-preference) {
        clip-path: ${squarePolygon(0, 0, 0, 1)};

        :not(.${hiddenClassName} &) {
          animation: ${keyframes`
            from {
              clip-path: ${squarePolygon(0, 0, 0, 1)};
            }
            to {
              clip-path: ${squarePolygon(0, 0, 0, 0)};
            }
          `} 1400ms ease-in-out forwards;
        }
      }
    `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'imageLocation' | 'title' | 'sectionIndicatorVariant' | 'children'
  > {
  title: ReactNode
  subTitle: ReactNode
  text: ReactNode
  carouselImages: ComponentProps<typeof ImageCarousel>['images']
  carouselLocation?: 'left' | 'right'
  'data-testid'?: string
}

const DesignTextWithCarousel = ({
  title,
  subTitle,
  text,
  carouselImages,
  carouselLocation = 'right',
  className,
  'data-testid': testid = 'designTextWithCarousel',
  ...others
}: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const [ref, { width = 100 }] = useDimensions()

  // While this is generally bad practice, in this case it's the only way to get
  // a ref to the correct element. The result is essential for the carousel size,
  // so there's no way to avoid this. It only affects the final size, so its
  // impact on SSG isn't too big
  const isMobile = useIsMobile()

  return (
    <Container
      ref={containerRef}
      className={`${className || ''} ${!inView ? hiddenClassName : ''}`}
      css={[
        adjacentContainerCss,
        carouselLocation === 'left' ? carouselLeftCss : undefined,
      ]}
      data-testid={testid}
      sectionIndicatorVariant="dark"
      {...others}
    >
      <ContentContainer>
        <Content>
          <AnimateText>
            <SubTitle>{subTitle}</SubTitle>
          </AnimateText>
          <AnimateText delay={60}>
            <Title>{title}</Title>
          </AnimateText>

          <MobileImageContainer>
            <div
              ref={isMobile ? ref : undefined}
              style={{
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ['--width' as any]: `${width}px`,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ['--height' as any]: `${(width * 531) / 660}px`,
              }}
            >
              <ImageCarousel
                images={carouselImages}
                navigationVariant="overlay"
                pagerVariant="below"
                splitOnMobile={false}
              />
            </div>
          </MobileImageContainer>

          <Text>
            <AnimateText delay={300}>{text}</AnimateText>
          </Text>
        </Content>
      </ContentContainer>

      <TabletImageColumn ref={!isMobile ? ref : undefined}>
        <TabletImagePositioner
          style={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ['--width' as any]: `${width}px`,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ['--height' as any]: `${(width * 531) / 660}px`,
          }}
        >
          <ImageCarousel
            images={carouselImages}
            navigationVariant="static"
            pagerVariant="below"
          />
        </TabletImagePositioner>
      </TabletImageColumn>
    </Container>
  )
}

export default DesignTextWithCarousel
