import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import Image from '../components/Image'
import Section from '../components/Section'
import {
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'

const Container = styled(Section)(({ theme }) => [
  css`
    margin: ${theme.spacing.x20}px auto;
    max-width: 1200px;
    text-align: center;
  `,
  isSiteYachting(theme) &&
    css`
      max-width: none;
      margin: 0;
      background-color: ${theme.colors.yachting.silver30};
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      background-color: ${theme.colors.womenInYachtingPage.shell};
    `,
])

const Wrapper = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      display: flex;
      flex-flow: column;
      gap: ${theme.spacing.x6}px;
      padding: ${theme.spacing.x10}px ${theme.spacing.x5}px;
      text-align: left;
      max-width: 1088px;
      margin: 0 auto;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        flex-flow: row;
        gap: ${theme.spacing.x16}px;
        padding: ${theme.spacing.x20}px ${theme.spacing.x5}px;
      }
    `,
])

const ImageContainer = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: 33.33%;
      }
    `,
])

const FormContainer = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      position: relative;
      width: 100%;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: 66.66%;
      }
    `,
])

const StyledImage = styled(Image)(({ theme }) => [
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      max-width: 179px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        max-width: none;
      }
    `,
])

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant'
  > {
  image?: {
    src: string
    alt?: string
  }
  form: ReactNode
}

const PageForm = ({ image, form, ...others }: Props) => (
  <Container sectionIndicatorVariant="light" {...others}>
    <Wrapper>
      {image && (
        <ImageContainer>
          <StyledImage
            src={image.src}
            alt={image.alt}
            width={248}
            height={289}
            style={{ width: '100%', height: 'auto' }}
          />
        </ImageContainer>
      )}
      <FormContainer>{form}</FormContainer>
    </Wrapper>
  </Container>
)

export default PageForm
