import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import {
  BackgroundWaveColorVariants,
  QuoteCarouselProps,
  QuoteTextColorVariants,
} from '.'
import YachtingCarousel from '../../components/YachtingCarousel'
import { hiddenClassName } from '../../components/AnimateText'
import { heading1, overLineMedium } from '../../themes/yachtsupport/text'
import Quote from '../../icons/yachtsupport/Quote.svg'
import ImageAspectRatio from '../../components/ImageAspectRatio'
import Image from '../../components/Image'
import WaveWhite from '../../../public/yachtsupport/wave-white.png'
import WaveDarkGreen from '../../../public/yachtsupport/wave-darkgreen.png'
import WaveLightGreen from '../../../public/yachtsupport/wave-lightgreen.png'

const getTextColorVariant = (
  theme: Theme,
  variantName: QuoteTextColorVariants,
) => {
  switch (variantName) {
    case 'white':
      return theme.colors.yachtSupport.white
    case 'oceanBlue':
      return theme.colors.yachtSupport.primaryYSOceanBlue
    default:
      return theme.colors.yachtSupport.white
  }
}

const getBackgroundColorVariant = (
  variantName: BackgroundWaveColorVariants,
) => {
  switch (variantName) {
    case 'white':
      return WaveWhite
    case 'lightGreen':
      return WaveLightGreen
    case 'darkGreen':
      return WaveDarkGreen
    default:
      return WaveWhite
  }
}

const Container = styled.div(
  () => css`
    position: relative;
    max-height: 600px;
  `,
)
const Slide = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2}px;
    overflow: hidden;
  `,
)

const Overline = styled.div<{ variantName: QuoteTextColorVariants }>(
  ({ theme, variantName }) => css`
    ${overLineMedium(theme)}
    display: flex;
    color: ${getTextColorVariant(theme, variantName)};
    justify-content: center;
  `,
)
const Heading = styled.div<{ variantName: QuoteTextColorVariants }>(
  ({ theme, variantName }) => css`
    ${heading1(theme)}
    font-size: 24px;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 34px;
    }
    color: ${getTextColorVariant(theme, variantName)};
  `,
)

const QuoteIcon = styled(Quote)`
  width: 148px;
  height: 120px;
  opacity: 0.5;
`

const QuoteIconLeft = styled(QuoteIcon)<{
  variantName: QuoteTextColorVariants
}>(
  ({ theme, variantName }) => css`
    color: ${getTextColorVariant(theme, variantName)};
  `,
)

const QuoteIconRight = styled(QuoteIcon)<{
  variantName: QuoteTextColorVariants
}>(
  ({ theme, variantName }) => css`
    color: ${getTextColorVariant(theme, variantName)};
    transform: rotate(180deg);
  `,
)

const BackgroundImageDesktopContainer = styled(ImageAspectRatio)(
  ({ theme }) =>
    css`
      display: none;
      img {
        max-height: 600px;
      }

      @media screen and (min-width: ${theme.breakpoints.mobileMax}px) {
        display: block;
      }
    `,
)
const BackgroundImageMobileContainer = styled(ImageAspectRatio)(
  ({ theme }) =>
    css`
      img {
        max-height: 600px;
      }

      @media screen and (min-width: ${theme.breakpoints.mobileMax}px) {
        display: none;
      }
    `,
)

const Content = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: ${theme.spacing.x8}px 0;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
      gap: ${theme.spacing.x9}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-direction: row;
        gap: ${theme.spacing.x7}px;

        ${QuoteIconLeft}, ${QuoteIconRight} {
          display: none;
        }
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        ${QuoteIconLeft}, ${QuoteIconRight} {
          display: flex;
        }
      }
    `,
)

const CarousalContainer = styled.div(
  ({ theme }) => css`
    max-width: 100%;
    padding: 0 ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 776px;
      padding: 0;
    }
  `,
)

const QuoteCarouselYachtSupport = ({
  carouselItems,
  quoteTextColorVariant,
  backgroundWaveColorVariant,
  ...others
}: QuoteCarouselProps) => {
  const { ref, inView } = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  return (
    <Container {...others} data-testid="quoteCarousel">
      <BackgroundImageDesktopContainer aspectRatio="16:9">
        <Image
          src={getBackgroundColorVariant(backgroundWaveColorVariant)}
          alt="background wave"
          style={{ objectFit: 'cover', width: '100%' }}
          fill
          aspectRatio="16:9"
        />
      </BackgroundImageDesktopContainer>
      <BackgroundImageMobileContainer aspectRatio="375:667">
        <Image
          src={getBackgroundColorVariant(backgroundWaveColorVariant)}
          alt="background wave"
          style={{ objectFit: 'cover', width: '100%' }}
          fill
          aspectRatio="375:667"
        />
      </BackgroundImageMobileContainer>
      <Content>
        <QuoteIconLeft aria-label="Quote" variantName={quoteTextColorVariant} />
        <CarousalContainer>
          <YachtingCarousel
            className={!inView ? hiddenClassName : undefined}
            arrowsPosition="responsive"
            ref={ref}
            customArrows={false}
            variableWidth={false}
            customDots
            customDotsPosition="quote"
          >
            {carouselItems.map((carouselItem) => (
              <Slide key={carouselItem.key}>
                <Overline variantName={quoteTextColorVariant}>
                  {carouselItem.overline}
                </Overline>
                <Heading variantName={quoteTextColorVariant}>
                  {carouselItem.heading}
                </Heading>
              </Slide>
            ))}
          </YachtingCarousel>
        </CarousalContainer>
        <QuoteIconRight
          aria-label="Quote"
          variantName={quoteTextColorVariant}
        />
      </Content>
    </Container>
  )
}

export default QuoteCarouselYachtSupport
