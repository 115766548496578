import DesignTextWithImage from '../../patterns/DesignTextWithImage'
import RichText from '../../components/RichText'
import slugify from '../../utils/slugify'
import { DesignTextWithImageStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: DesignTextWithImageStoryblok
}

const DesignTextWithImageBlok = ({
  content: { sectionTitle, title, subTitle, text, image, imageLocation },
  ...others
}: Props) => (
  <DesignTextWithImage
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    text={<RichText>{text}</RichText>}
    image={mapImage(image) ?? getRequiredImageFallback()}
    imageLocation={imageLocation}
    {...others}
  />
)

export default DesignTextWithImageBlok
