import { ColumnContentStoryblok } from '../storyblok.generated'
import ColumnContent from '../../patterns/ColumnContent'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: ColumnContentStoryblok
}

const ColumnContentBlok = ({ content: { columns }, ...others }: Props) => (
  <ColumnContent
    columns={columns.map(
      ({
        _uid,
        image,
        heading,
        overline,
        description,
        buttonLabel,
        buttonLink,
      }) => ({
        key: _uid,
        image: mapImage(image) ?? getRequiredImageFallback(),
        heading,
        overline,
        description,
        buttonLabel,
        buttonLink: buttonLink ? storyblokLinkResolver(buttonLink) : '',
      }),
    )}
    {...others}
  />
)

export default ColumnContentBlok
