// Taken from https://usehooks-typescript.com/react-hook/use-interval

import { useEffect, useRef } from 'react'

/**
 * useInterval
 *
 * @param {() => void} callback
 * @param {number} delay delay in milliseconds
 * @param {boolean} shouldTrigger default true
 */

function useInterval(
  callback: () => void,
  delay: number | null,
  shouldTrigger = true,
) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    let id: NodeJS.Timeout
    if (delay !== null && shouldTrigger) {
      id = setInterval(() => savedCallback.current(), delay)
    }

    return () => {
      clearInterval(id)
    }
  }, [delay, shouldTrigger])
}

export default useInterval
