import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import SubHeaderXplorer from './SubHeader.xplorer'
import Section from '../../components/Section'

export interface SubHeaderProps
  extends Omit<
    ComponentProps<typeof Section>,
    'title' | 'sectionIndicatorVariant' | 'children'
  > {
  heading: ReactNode
  subHeadingText?: string
  backgroundImage?: { src: string; alt?: string }
  overlayImage?: { src: string; alt?: string }
}

const SubHeader = (props: SubHeaderProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <SubHeaderXplorer {...props} />
    default:
      return <SubHeaderXplorer {...props} />
  }
}

export default SubHeader
