import Contacts from '../../patterns/Contacts'
import slugify from '../../utils/slugify'
import { ContactsStoryblok } from '../storyblok.generated'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import normaliseContactStorybloks from '../../utils/normaliseContactStorybloks'
import { usePageContext } from '../PageContext'

interface Props {
  content: ContactsStoryblok
}

const ContactsBlok = ({
  content: { sectionTitle, title, link, contacts, showMoreTitle },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  return (
    <Contacts
      id={slugify(sectionTitle)}
      title={title}
      showMoreTitle={showMoreTitle}
      link={
        link?.map(({ title, link, linkTarget }) => ({
          title,
          href: storyblokLinkResolver(link),
          target: linkTarget,
        }))[0]
      }
      contacts={normaliseContactStorybloks(contacts, rels)}
      {...others}
    />
  )
}

export default ContactsBlok
