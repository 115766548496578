import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useState } from 'react'

import Button, {
  activeTabButtonCss,
  tabButtonCss,
  activeTabCssYachting,
  tabCssYachting,
} from '../../components/Button'
import Section from '../../components/Section'
import { isSiteAmels, isSiteYachting } from '../../themes'

const Container = styled(Section)(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      padding: ${theme.spacing.x15}px 0;
    `,
  isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x10}px 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x15}px 0;
      }
    `,
])
const Title = styled.h2(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    text-align: center;
    margin: 0 ${theme.spacing.x5}px ${theme.spacing.x6}px;
  `,
  isSiteAmels(theme) &&
    css`
      text-transform: uppercase;
      letter-spacing: 10px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: ${theme.spacing.x6}px;
      }
    `,
  isSiteYachting(theme) &&
    css`
      margin: 0 ${theme.spacing.x5}px ${theme.spacing.x10}px;
    `,
])
const TabButtons = styled.div(({ theme }) => [
  css`
    margin: 0 ${theme.spacing.x4}px;
    display: flex;
    gap: ${theme.spacing.x3}px ${theme.spacing.x3}px;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x3}px ${theme.spacing.x10}px;
    }
  `,
  isSiteYachting(theme) &&
    css`
      margin-bottom: ${theme.spacing.x7}px;
    `,
])
const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => [
  isSiteAmels(theme) && [
    tabButtonCss(theme),
    active && activeTabButtonCss(theme),
  ],
  isSiteYachting(theme) && [
    tabCssYachting(theme),
    active && activeTabCssYachting(theme),
  ],
])
const Tab = styled('div', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ active }) => [
  css`
    display: none;
  `,
  active &&
    css`
      display: block;
    `,
])

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  tabs: Array<{
    key: string
    title: ReactNode
    content: ReactNode
  }>
}

const TabbedContent = ({ title, tabs, ...others }: Props) => {
  const [activeTab, setActiveTab] = useState(0)

  return (
    <Container
      data-testid="tabbedContent"
      sectionIndicatorVariant="dark"
      {...others}
    >
      <Title>{title}</Title>

      <TabButtons>
        {tabs.map(({ key, title }, index) => (
          <TabButton
            key={key}
            active={activeTab === index}
            onClick={() => setActiveTab(index)}
            data-testid={`tabbedContent.tabButton.${index}`}
            data-active={activeTab === index}
          >
            {title}
          </TabButton>
        ))}
      </TabButtons>

      {tabs.map(({ key, content }, index) => (
        <Tab
          key={key}
          active={activeTab === index}
          data-testid={`tabbedContent.tab.${index}`}
          data-active={activeTab === index}
        >
          {content}
        </Tab>
      ))}
    </Container>
  )
}

export default TabbedContent
