import Timeline from '../../patterns/Timeline'
import { TimelineStoryblok } from '../storyblok.generated'
import BlokZone from '../../components/BlokZone'
import patterns from '.'

interface Props {
  content: TimelineStoryblok
}

const TimelineBlok = ({ content: { items }, ...others }: Props) => (
  <Timeline
    items={items.map((block) => ({
      key: block._uid,
      itemContent: <BlokZone components={patterns} content={[block]} />,
    }))}
    {...others}
  />
)

export default TimelineBlok
