import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useState } from 'react'

import Button, {
  primaryOutlineButtonCssYachtSupport,
  secondaryButtonCssXplorer,
} from '../../components/Button'
import SidePanelFixed from '../../components/SidePanelFixed'
import SidePanelTransition from '../../components/SidePanelTransition'
import SimpleHeader from '../SimpleHeader'
import { isSiteXplorer, isSiteYachtSupport } from '../../themes'

const Container = styled.div(({ theme }) => [
  css`
    text-align: center;
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
  `,
  isSiteXplorer(theme) &&
    css`
      background: ${theme.colors.xplorer.xpDarkOceanGreen10Solid};
    `,
])

const StyledButton = styled(Button)(({ theme }) => [
  css`
    width: fit-content;
  `,
  isSiteXplorer(theme) &&
    css`
      ${secondaryButtonCssXplorer(theme)}
      margin-top: ${theme.spacing.x2}px;
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${primaryOutlineButtonCssYachtSupport(theme)}
      margin-top: ${theme.spacing.x2}px;
    `,
])
const Title = styled('h2')(({ theme }) => [
  css`
    margin-top: ${theme.spacing.x2}px;
  `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading2(theme)};
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading2(theme)};
    `,
])
const Overline = styled.span(({ theme }) => [
  css``,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      color: ${theme.colors.xplorer.secondaryXPBronze};
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.overLineMedium(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    `,
])
const Description = styled.div(() => [css``])

interface Props extends Omit<ComponentProps<typeof SimpleHeader>, 'children'> {
  form: ReactNode
  linkText?: string
  overline?: string
}

const ContactHeaderXplorer = ({
  form,
  linkText,
  title,
  overline,
  text,
}: Props) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Container>
      {overline && <Overline>{overline}</Overline>}
      {title && <Title>{title}</Title>}
      {text && <Description>{text}</Description>}
      {linkText && form && (
        <StyledButton
          onClick={() => setOpen(true)}
          data-testid="contactHeader.callToAction"
        >
          {linkText}
        </StyledButton>
      )}

      {form && (
        <SidePanelTransition isOpen={isOpen}>
          {isOpen && (
            <SidePanelFixed
              close={() => setOpen(false)}
              data-testid="contactHeader.sidePanel"
            >
              {form}
            </SidePanelFixed>
          )}
        </SidePanelTransition>
      )}
    </Container>
  )
}

export default ContactHeaderXplorer
