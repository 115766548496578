import { useRef } from 'react'
import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'
import RoundedTriangle from '../icons/yachting/RoundedTriangle.svg'

import Section from '../components/Section'
import {
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Link from '../components/Link'
import YachtingCarousel from '../components/YachtingCarousel'

const Container = styled(Section)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding: ${theme.spacing.x15}px;
      }
    `,
)
const Wrapper = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      max-width: 1078px;
      margin: 0 auto;

      .slick-slide {
        margin: 0 ${theme.spacing.x1}px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        .slick-slide {
          margin: 0 ${theme.spacing.x4}px;
        }
      }
    `,
)
const TitleBar = styled.div(
  ({ theme }) => css`
    display: block;
    margin-bottom: ${theme.spacing.x7}px;
    gap: ${theme.spacing.x4}px;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x10}px;
    }
  `,
)
const Title = styled.h2(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.yachting.darkBlue};
    margin: 0;
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      color: ${theme.colors.womenInYachtingPage.terraBlush};
    `,
])
const Event = styled.article(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x3}px;
    text-align: center;
    width: 167px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 217px;
    }
  `,
)
const EventTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading3(theme)}
    margin: 0;
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.HelveticaNeue.style.fontFamily};
      font-weight: 200;
    `,
])
const EventDetails = styled.div(({ theme }) => [
  css`
    border-radius: 200px;
    transition: background 300ms ease-in;
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      background: ${theme.colors.womenInYachtingPage.white};
      border: 3px solid ${theme.colors.womenInYachtingPage.shell};
    `,
])
const EventDate = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-flow: column;
    font-size: 88px;
    line-height: 88px;
    text-align: center;
    padding: ${theme.spacing.x7}px 0;
    transition: color 300ms ease-in;
  `,
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.silver};
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Floreal.style.fontFamily};
      color: ${theme.colors.womenInYachtingPage.apricotPeach};
    `,
])
const DefaultEventDateStyling = css`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
const EventDay = styled.div(({ theme }) => [
  DefaultEventDateStyling,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Floreal.style.fontFamily};
    `,
])
const EventMonth = styled.div(({ theme }) => [
  DefaultEventDateStyling,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Floreal.style.fontFamily};
    `,
])
const EventYear = styled.div(({ theme }) => [
  DefaultEventDateStyling,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      font-family: ${theme.fonts.Floreal.style.fontFamily};
    `,
])
const EventDateSlantWrapper = styled.div(({ theme }) => [
  css`
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      bottom: 0;
      width: 15px;
      height: 100%;
      opacity: 0;
      transition: opacity, background 300ms ease-in;
    }
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      &::after {
        background: ${theme.colors.womenInYachtingPage.white};
      }
    `,
])
const ActiveWomenInYachtingEventStyling = (theme: Theme) => css`
  ${EventDetails} {
    background: ${theme.colors.womenInYachtingPage.shell};
  }
  ${EventDate} {
    color: ${theme.colors.womenInYachtingPage.terraBlush};
  }
  ${EventDay} {
    ${EventDateSlantWrapper} {
      &::after {
        right: 0;
        opacity: 1;
        transform: rotate(24deg) translateX(8px);
        background: ${theme.colors.womenInYachtingPage.shell};
        border-left: 1px solid ${theme.colors.womenInYachtingPage.terraBlush};
      }
    }
  }
  ${EventYear} {
    ${EventDateSlantWrapper} {
      &::after {
        left: 0;
        opacity: 1;
        transform: rotate(24deg) translateX(-16px);
        background: ${theme.colors.womenInYachtingPage.shell};
        border-right: 1px solid ${theme.colors.womenInYachtingPage.terraBlush};
      }
    }
  }
`

const EventLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => [
  css`
    display: flex;
    justify-content: center;
    width: 100%;
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      &:hover {
        text-decoration: none;

        ${EventTitle} {
          text-decoration: underline;
          text-underline-offset: 4px;
        }

        ${!isActive &&
        css`
          ${EventDay} {
            ${EventDateSlantWrapper} {
              &::after {
                right: 0;
                opacity: 1;
                transform: rotate(24deg) translateX(8px);
                background: ${theme.colors.womenInYachtingPage.white};
                border-left: 1px solid ${theme.colors.womenInYachtingPage.shell};
              }
            }
          }
          ${EventYear} {
            ${EventDateSlantWrapper} {
              &::after {
                left: 0;
                opacity: 1;
                transform: rotate(24deg) translateX(-16px);
                background: ${theme.colors.womenInYachtingPage.white};
                border-right: 1px solid
                  ${theme.colors.womenInYachtingPage.shell};
              }
            }
          }
        `}
      }

      ${isActive &&
      css`
        ${ActiveWomenInYachtingEventStyling(theme)}
      `}
    `,
])

interface Props {
  title: string
  events: Array<{
    key: string
    link: string
    title: string
    date: string
    isActive?: boolean
  }>
}

const EventCarousel = ({ title, events, ...others }: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const { locale } = useRouter()
  const sliderRef = useRef(null)

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="eventCarousel"
      ref={containerRef}
      {...others}
    >
      <Wrapper>
        <TitleBar>
          {title && (
            <AnimateText>
              <Title>{title}</Title>
            </AnimateText>
          )}
        </TitleBar>
        <div>
          <AnimateText delay={300}>
            <YachtingCarousel
              arrowsPosition="event-carousel"
              className={!inView ? hiddenClassName : undefined}
              data-testid="eventCarousel"
              ref={sliderRef}
              slidesToShow={3}
              slidesToScroll={1}
              variableWidth={false}
              waitForAnimate={false}
              draggable
              centerMode={false}
              customArrows
              dots={false}
              customArrow={<RoundedTriangle />}
              responsive={[
                {
                  breakpoint: 760,
                  settings: {
                    slidesToShow: 1,
                  },
                },
                {
                  breakpoint: 1146,
                  settings: {
                    slidesToShow: 2,
                    centerMode: false,
                  },
                },
              ]}
            >
              {events.map(({ key, link, title, date, isActive }) => (
                <EventLink key={key} href={link} isActive={isActive}>
                  <Event>
                    <EventDetails>
                      {date && (
                        <EventDate>
                          <EventDay>
                            <EventDateSlantWrapper>
                              {new Date(date).toLocaleDateString(locale, {
                                day: '2-digit',
                              })}
                            </EventDateSlantWrapper>
                          </EventDay>
                          <EventMonth>
                            {new Date(date).toLocaleDateString(locale, {
                              month: '2-digit',
                            })}
                          </EventMonth>
                          <EventYear>
                            <EventDateSlantWrapper>
                              {new Date(date).toLocaleDateString(locale, {
                                year: '2-digit',
                              })}
                            </EventDateSlantWrapper>
                          </EventYear>
                        </EventDate>
                      )}
                    </EventDetails>
                    <EventTitle>{title}</EventTitle>
                  </Event>
                </EventLink>
              ))}
            </YachtingCarousel>
          </AnimateText>
        </div>
      </Wrapper>
    </Container>
  )
}

export default EventCarousel
