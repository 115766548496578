import { VideoHighlightStoryblok } from '../storyblok.generated'
import VideoHighlight from '../../patterns/VideoHighlight'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: VideoHighlightStoryblok
}

const VideoHighlightBlok = ({
  content: {
    heading,
    overline,
    videoTitle,
    videoCaption,
    videoImage,
    videoSummary,
    videoButtonLabel,
    videoLink,
  },
  ...others
}: Props) => {
  return (
    <VideoHighlight
      heading={heading}
      overline={overline}
      video={{
        title: videoTitle,
        caption: videoCaption,
        summary: videoSummary,
        image: mapImage(videoImage)?.src ?? '',
        buttonLabel: videoButtonLabel,
        link: storyblokLinkResolver(videoLink),
      }}
      {...others}
    />
  )
}

export default VideoHighlightBlok
