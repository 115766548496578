import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode, useEffect, useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'

import Image from '../components/Image'
import DelayRender from '../components/DelayRender'
import HighlightButton from '../components/HighlightButton'

const StyledHighlightButton = styled(HighlightButton)(
  () => css`
    position: absolute;
    z-index: 2;
    will-change: opacity;

    &.transition-exit {
      opacity: 1;
    }
    &.transition-exit-active {
      opacity: 0;
      transition: opacity 400ms;
    }
  `,
)

const useRandomAttentionGrabbingButton = (
  buttonCount: number,
  active: boolean,
) => {
  const [buttonNo, setButtonNo] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (!active) {
      setButtonNo(undefined)
      return
    }

    let timeout: ReturnType<typeof setTimeout>
    const selectNewButton = () => {
      setButtonNo((buttonNo) => {
        if (buttonNo !== undefined && buttonCount < 2) {
          return undefined
        }

        let newButtonNo
        do {
          newButtonNo = Math.round(Math.random() * (buttonCount - 1))
        } while (newButtonNo === buttonNo)
        return newButtonNo
      })
      timeout = setTimeout(selectNewButton, 3000 + Math.random() * 2000)
    }
    timeout = setTimeout(selectNewButton, 3000)
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout)
  }, [buttonCount, active])

  return buttonNo
}

export interface HighlightButton {
  key: string
  x: string
  y: string
  title: ReactNode
}

interface Props<Button extends HighlightButton = HighlightButton> {
  image: {
    src: string
    alt?: string
  }
  buttons: Button[]
  onButtonClick?: (highlightButton: Button) => void
  showButtons?: boolean
  getLoadingProgress?: (highlightButton: Button) => number | undefined
}

const PortfolioHighlightsImage = <T extends HighlightButton = HighlightButton>({
  image,
  buttons,
  showButtons = true,
  onButtonClick,
  getLoadingProgress,
  ...others
}: Props<T>) => {
  const attentionGrabbingButton = useRandomAttentionGrabbingButton(
    buttons.length,
    showButtons,
  )

  return (
    <div {...others}>
      <Image src={image.src} alt={image.alt} fill />

      <TransitionGroup component={null}>
        {showButtons &&
          buttons.map((locationBlock, index) => {
            const { key, x, y, title } = locationBlock

            return (
              <CSSTransition key={key} classNames="transition" timeout={400}>
                <DelayRender delay={index * 300}>
                  <StyledHighlightButton
                    style={{
                      left: x,
                      top: y,
                    }}
                    onClick={() => onButtonClick?.(locationBlock)}
                    data-testid={`highlightsBlok.highlight.${key}`}
                    grabAttention={attentionGrabbingButton === index}
                    loadingProgress={getLoadingProgress?.(locationBlock)}
                  >
                    {title}
                  </StyledHighlightButton>
                </DelayRender>
              </CSSTransition>
            )
          })}
      </TransitionGroup>
    </div>
  )
}

export default PortfolioHighlightsImage
