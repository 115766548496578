import { memo } from 'react'

import { ServicesHeaderStoryblok } from '../storyblok.generated'
import ServicesHeader from '../../patterns/ServicesHeader'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import RichText from '../../components/RichText'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: ServicesHeaderStoryblok
}

const ServicesHeaderBlok = ({
  content: {
    title,
    subTitle,
    navigationTeaser: [{ columns, ...teaserOthers }],
    link,
  },
  ...others
}: Props) => (
  <ServicesHeader
    title={title}
    text={subTitle}
    textCallToActionProps={
      link && link.length
        ? {
            link: {
              title: link[0].title,
              href: storyblokLinkResolver(link[0].link),
              target: link[0].linkTarget,
            },
          }
        : undefined
    }
    navigationTeaserProps={{
      columns: columns.map(
        ({
          _uid,
          image,
          mobileImage,
          logo,
          title,
          subtitle,
          link,
          linkText,
          hoverTitle,
          hoverSubtitle,
          hoverText,
        }) => ({
          key: _uid,
          title,
          subtitle,
          logo: mapImage(logo),
          image: mapImage(image) ?? getRequiredImageFallback(),
          mobileImage: mapImage(mobileImage),
          link: storyblokLinkResolver(link),
          linkText,
          hoverTitle,
          hoverSubtitle,
          hoverText: <RichText>{hoverText}</RichText>,
        }),
      ),
      ...teaserOthers,
    }}
    {...others}
  />
)

export default memo(ServicesHeaderBlok)
