import { ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import ArticleFeedXplorer from './ArticleFeed.xplorer'
import ArticleFeedYachtSupport from './ArticleFeed.yachtsupport'
import ArticleFeedDefault from './ArticleFeed'

export interface ArticleFeedProps {
  title: string
  subTitle?: string
  variant: 'feed' | 'related'
  background?: 'lightGreen' | 'lightSand'
  viewAll?: {
    title: ReactNode
    href: string
    target?: string
  }
  mode?: '' | 'Manual' | 'Automatic'
  // TODO: Merge these 2 types into the same ('we just have articles' how we get them doesn't matter)
  automatedArticles:
    | Array<{
        key: string
        variant: 'article' | 'event'
        slug: string
        title: string
        summary?: string
        image: string
        publishedAt?: string
        type: 'news' | 'article' | 'video' | 'event'
        articleFeedFilter?: 'news' | 'events' | 'milestones' | ''
        tags?: string[]
      }>
    | undefined
  articles:
    | Array<{
        key: string
        variant: 'article' | 'event'
        slug: string
        title: string
        image: string
        summary?: string
        publishedAt?: string
      }>
    | undefined
}

const ArticleFeed = (props: ArticleFeedProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <ArticleFeedXplorer {...props} />
    case Site.YachtSupport:
      return <ArticleFeedYachtSupport {...props} />
    default:
      return <ArticleFeedDefault {...props} />
  }
}

export default ArticleFeed
