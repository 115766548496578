import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import Section from '../components/Section'
import { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import Button, { invisibleButtonCss } from '../components/Button'
import Backdrop from '../components/Backdrop'
import CloseButton from '../components/CloseButton'
import useDisableBodyScroll from '../utils/useDisableBodyScroll'
import ModalTransition from '../components/ModalTransition'

const Container = styled(Section)(
  ({ theme }) =>
    css`
      padding: ${theme.spacing.x13}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding: ${theme.spacing.x15}px;
      }
    `,
)
const List = styled.ul(
  ({ theme }) => css`
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      gap: ${theme.spacing.x12}px;
    }
  `,
)
const ListItem = styled.li``
const FeatureButton = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    display: flex;
    flex-flow: column;
    align-items: center;
    cursor: pointer;
    padding: ${theme.spacing.x2}px;
    margin: -${theme.spacing.x2}px;
    ${theme.text.heading3(theme)}
  `,
])
const StaticImage = styled(Image)``
const HoverImage = styled(Image)``
const IconWrapper = styled.div(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.yachting.silver};
    border-radius: 50%;
    width: ${theme.spacing.x12}px;
    height: ${theme.spacing.x12}px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${theme.spacing.x3}px;
    transition: background 300ms ease-in;

    ${HoverImage} {
      display: none;
    }
    ${FeatureButton}:hover & {
      background: ${theme.colors.yachting.orange};
      border-color: ${theme.colors.yachting.orange};
      ${StaticImage} {
        display: none;
      }
      ${HoverImage} {
        display: block;
      }
    }
  `,
)
const Modal = styled.div(
  ({ theme }) => css`
    position: fixed;
    inset: 0;
    z-index: 100;
    background: ${theme.colors.yachting.white};
    display: flex;
    flex-flow: column;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      inset: ${theme.spacing.x8}px;
    }
  `,
)
const StyledCloseButton = styled(CloseButton)(({ theme }) => [
  css`
    position: absolute;
    top: ${theme.spacing.x3}px;
    right: ${theme.spacing.x3}px;
    width: ${theme.spacing.x7}px;
    height: ${theme.spacing.x7}px;
    z-index: 101;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      right: ${theme.spacing.x6}px;
    }
  `,
])
const ModalContent = styled.div`
  overflow: auto;
`

interface Feature {
  key: string
  title: string
  icon: string
  modalContent: ReactNode
}

interface Props
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant' | 'title'
  > {
  features: Feature[]
}

const FeatureWithModalList = ({ features, ...others }: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const [activeFeature, setActiveFeature] = useState<Feature | undefined>(
    undefined,
  )
  const close = () => setActiveFeature(undefined)
  const modalRef = useDisableBodyScroll(activeFeature !== undefined)
  const theme = useTheme()

  return (
    <>
      <Container
        sectionIndicatorVariant="dark"
        className={!inView ? hiddenClassName : undefined}
        data-testid="featureWithModalList"
        ref={containerRef}
        {...others}
      >
        <List>
          {features.map((feature) => {
            const { key, title, icon } = feature

            return (
              <ListItem key={key}>
                <FeatureButton onClick={() => setActiveFeature(feature)}>
                  <IconWrapper>
                    <StaticImage src={icon} alt="" width={40} height={40} />
                    <HoverImage
                      src={icon}
                      transformations={[
                        `co_rgb:${theme.colors.yachting.white.substring(
                          1,
                        )},e_colorize:100`,
                      ]}
                      alt=""
                      width={40}
                      height={40}
                    />
                  </IconWrapper>
                  {title}
                </FeatureButton>
              </ListItem>
            )
          })}
        </List>
      </Container>

      <ModalTransition
        isOpen={activeFeature !== undefined}
        backdropComponent={Backdrop}
        modalComponent={Modal}
      >
        {activeFeature && (
          <>
            <Backdrop onClick={close} className="backdrop" />
            <Modal ref={modalRef} className="modal">
              <StyledCloseButton onClick={close} />
              <ModalContent>{activeFeature.modalContent}</ModalContent>
            </Modal>
          </>
        )}
      </ModalTransition>
    </>
  )
}

export default FeatureWithModalList
