import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import Section from '../components/Section'

const Container = styled(Section)(
  ({ theme }) => css`
    position: relative;
    padding-top: 178%;
    background: ${theme.colors.amels.silk};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding-top: 71%;
    }
  `,
)
const DesktopImageContainer = styled.div(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const MobileImageContainer = styled.div(
  ({ theme }) => css`
    display: block;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)
const Content = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: ${theme.spacing.x4}px;
    right: ${theme.spacing.x4}px;
    bottom: ${theme.spacing.x4}px;
    color: ${theme.colors.amels.body};
    font-weight: 300;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      left: ${theme.spacing.x6}px;
      right: ${theme.spacing.x6}px;
      bottom: ${theme.spacing.x6}px;
      max-width: 450px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      bottom: ${theme.spacing.x15}px;
      left: ${theme.spacing.x10}px;
      max-width: 584px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      bottom: ${theme.spacing.x20}px;
      left: ${theme.spacing.x15}px;
      max-width: 584px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.amels.body};
    margin: 0 0 ${theme.spacing.x4}px;
  `,
)
const Text = styled.div``

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant' | 'title'
  > {
  image: {
    src: string
    alt?: string
  }
  mobileImage?: {
    src: string
    alt?: string
  }
  title: ReactNode
  text: ReactNode
}

const ImageWithTextOverlay = ({
  image,
  mobileImage,
  title,
  text,
  ...others
}: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="imageWithTextOverlay"
      ref={containerRef}
      {...others}
    >
      <DesktopImageContainer>
        <Image
          src={image.src}
          alt={image.alt}
          fill
          style={{ objectFit: 'cover' }}
        />
      </DesktopImageContainer>
      <MobileImageContainer>
        <Image
          src={(mobileImage ?? image).src}
          alt={(mobileImage ?? image).alt}
          fill
          style={{ objectFit: 'cover' }}
        />
      </MobileImageContainer>
      <Content>
        <Title>
          <AnimateText>{title}</AnimateText>
        </Title>
        <Text>
          <AnimateText delay={300}>{text}</AnimateText>
        </Text>
      </Content>
    </Container>
  )
}

export default ImageWithTextOverlay
