import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'

import { useRouter } from 'next/router'
import { useAppContext } from '../components/RuntimeConfigProvider'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'
import SocialsList, { Social } from '../components/SocialsList'
import TwoColorString from '../components/TwoColorString'
import { currentSite, Site } from '../sites'

const Container = styled('section')(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      margin: ${theme.spacing.x15}px ${theme.spacing.x4}px ${theme.spacing.x7}px;
      text-align: center;
    `,
  isSiteYachting(theme) &&
    css`
      margin: ${theme.spacing.x8}px ${theme.spacing.x4}px;
      text-align: center;
    `,
  isSiteXplorer(theme) &&
    css`
      margin: ${theme.spacing.x8}px ${theme.spacing.x4}px;
      text-align: center;
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin: ${theme.spacing.x8}px ${theme.spacing.x4}px;
      text-align: center;
    `,
])
const Title = styled('div')(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: ${theme.spacing.x2}px;
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading3(theme)};
      font-weight: 300;
      margin-bottom: ${theme.spacing.x4}px;
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading5(theme)};
      margin-bottom: ${theme.spacing.x5}px;
      color: ${theme.colors.xplorer.secondaryXPBronze};
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.bodySmall(theme)};
      margin-bottom: ${theme.spacing.x4}px;
      text-transform: uppercase;
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    `,
])

interface SocialShareProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'title'> {
  title?: string
  titleColorChangeAfterWord?: string
}

const SocialShare = ({
  title,
  titleColorChangeAfterWord,
  ...others
}: SocialShareProps) => {
  const { asPath } = useRouter()
  const { publicRuntimeConfig } = useAppContext()
  const url = `${publicRuntimeConfig.NEXT_PUBLIC_HOSTNAME}${asPath}`

  const xplorerSocials: Social[] = [
    {
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(url)}`,
      type: 'whatsApp',
    },
    {
      url: `mailto:?body=${encodeURIComponent(url)}`,
      type: 'mail',
    },
    {
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url,
      )}`,
      type: 'linkedin',
    },
    {
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url,
      )}`,
      type: 'facebook',
    },
    {
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
      type: 'twitter',
    },
  ]

  const socials: Social[] = [
    {
      url: `https://www.instagram.com/?url=${encodeURIComponent(url)}`,
      type: 'instagram',
    },
    {
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url,
      )}`,
      type: 'facebook',
    },
    {
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}`,
      type: 'twitter',
    },
    {
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url,
      )}`,
      type: 'linkedin',
    },
  ]

  const currentSiteSocials =
    currentSite === Site.Xplorer || Site.YachtSupport ? xplorerSocials : socials

  return (
    <Container data-testid="socialShare" {...others}>
      {title && (
        <Title>
          <TwoColorString
            string={title}
            colorChangeAfterWord={
              titleColorChangeAfterWord
                ? Number(titleColorChangeAfterWord)
                : undefined
            }
          />
        </Title>
      )}
      <SocialsList socials={currentSiteSocials} />
    </Container>
  )
}

export default SocialShare
