// This is used by Cypress to get the Storybook slug, therefore it should match
// the Storybook behavior.
const slugify = (input: string | undefined) =>
  input
    ? input
        .toLowerCase()
        .replace(/[^a-z]/g, ' ')
        .replace(/ +/g, ' ')
        .replace(/[/ ]/g, '-')
    : undefined

export default slugify
