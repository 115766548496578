import { ImageWithHeadingStoryblok } from '../storyblok.generated'
import ImageWithHeading from '../../patterns/ImageWithHeading'
import RichText from '../../components/RichText'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: ImageWithHeadingStoryblok
}

const ImageWithHeadingBlok = ({
  content: { heading, overline, backgroundImage },
  ...others
}: Props) => (
  <ImageWithHeading
    heading={
      typeof heading === 'object' ? (
        <RichText as="span">{heading}</RichText>
      ) : (
        heading
      )
    }
    overline={overline}
    backgroundImage={mapImage(backgroundImage) ?? getRequiredImageFallback()}
    {...others}
  />
)

export default ImageWithHeadingBlok
