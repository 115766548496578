import {
  BynderDocumentStoryblok,
  BynderImageStoryblok,
  BynderVideoStoryblok,
} from '../bloks/storyblok.generated'

export const hasBynderAsset = (
  asset:
    | BynderImageStoryblok[]
    | BynderVideoStoryblok[]
    | BynderDocumentStoryblok[]
    | undefined,
) => {
  return asset && asset.length > 0
}
