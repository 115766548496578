import { PressInformationStoryblok } from '../storyblok.generated'
import PressInformation from '../../patterns/PressInformation'
import RichText from '../../components/RichText'

interface Props {
  content: PressInformationStoryblok
}

const PressInformationBlok = ({
  content: { overline, title, text },
  ...others
}: Props) => {
  return (
    <PressInformation
      overline={overline}
      title={title}
      text={text && <RichText>{text}</RichText>}
      {...others}
    />
  )
}

export default PressInformationBlok
