import { memo } from 'react'
import { ProductCarouselStoryblok } from '../storyblok.generated'
import ProductCarousel from '../../patterns/ProductCarousel'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import reactStringReplace from '../../utils/reactStringReplace'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: ProductCarouselStoryblok
}

const ProductCarouselBlok = ({
  content: { carouselItems, title, text, link, overline },
  ...others
}: Props) => (
  <ProductCarousel
    overline={overline}
    title={title}
    text={text && reactStringReplace(text, '\n', <br />)}
    link={
      link?.length
        ? {
            title: link[0].title ?? '',
            href: storyblokLinkResolver(link[0].link),
            target: link[0].linkTarget,
          }
        : undefined
    }
    products={carouselItems.map((item) => ({
      key: item._uid,
      title: item.title,
      description: item.description,
      link: {
        href: storyblokLinkResolver(item.link),
      },
      image: mapImage(item.image) ?? getRequiredImageFallback(),
    }))}
    {...others}
  />
)

export default memo(ProductCarouselBlok)
