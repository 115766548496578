import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { SummaryWithLinkStoryblok } from '../storyblok.generated'
import SummaryWithLink from '../../patterns/SummaryWithLink'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: SummaryWithLinkStoryblok
}

const SummaryWithLinkBlok = ({
  content: {
    _uid,
    variant,
    contentLocation,
    backgroundImage,
    overlayImage,
    summaryWithLinkCard: [{ subTitle, title, description, link, linkText }],
  },
  ...others
}: Props) => (
  <SummaryWithLink
    id={_uid}
    variant={variant}
    contentLocation={contentLocation}
    backgroundImage={mapImage(backgroundImage) ?? getRequiredImageFallback()}
    overlayImage={mapImage(overlayImage)}
    summaryWithLinkCard={{
      title,
      subTitle,
      description,
      linkText,
      link: storyblokLinkResolver(link),
    }}
    {...others}
  />
)

export default SummaryWithLinkBlok
