import { ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import SidePanelButton from '../components/SidePanelButton'
import { isSiteXplorer } from '../themes'
import { secondaryOutlineButtonCssXplorer } from '../components/Button'

interface Props {
  key: string
  title: string
  form: ReactNode
}

const StyledSidePanelButton = styled(SidePanelButton)(({ theme }) => [
  isSiteXplorer(theme) && secondaryOutlineButtonCssXplorer(theme),
  isSiteXplorer(theme) &&
    css`
      width: fit-content;
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
      border-color: ${theme.colors.xplorer.primaryXPLavaBlack};
    `,
])

const ContactCallToActionAction = ({ key, title, form, ...others }: Props) => {
  return (
    <StyledSidePanelButton
      key={key}
      sidePanel={form}
      data-testid="formButton"
      {...others}
    >
      {title}
    </StyledSidePanelButton>
  )
}

export default ContactCallToActionAction
