import FullPageCarousel from '../../patterns/FullPageCarousel'
import RichText from '../../components/RichText'
import { FullPageCarouselStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: FullPageCarouselStoryblok
}

const FullPageCarouselBlok = ({
  content: { slidesBlocks },
  ...others
}: Props) => (
  <FullPageCarousel
    slides={slidesBlocks.map(
      ({ _uid, image, title, callToActionText, sidePanelBlock }) => ({
        id: _uid,
        image: mapImage(image) ?? getRequiredImageFallback(),
        title,
        callToActionText,
        sidePanel: sidePanelBlock[0]
          ? {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              title: sidePanelBlock[0]!.title,
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              text: <RichText>{sidePanelBlock[0]!.content}</RichText>,
            }
          : undefined,
      }),
    )}
    {...others}
  />
)

export default FullPageCarouselBlok
