import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import visibleNonBreakingSpace from '../utils/visibleNonBreakingSpace'
import VideoPlayer from './VideoPlayer'
import Image from './Image'
import makeVideoSources from '../utils/makeVideoSources'
import { createImageLoader } from '../utils/createImageLoader'

const Header = styled.header(
  ({ theme }) => css`
    background: ${theme.colors.amels.oyster};
    padding: ${theme.spacing.x1 * 23}px ${theme.spacing.x5}px
      ${theme.spacing.x7}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      padding-top: ${theme.spacing.x1 * 30}px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    margin: 0 auto ${theme.spacing.x4}px;
    max-width: 868px;
  `,
)
const ArticleInfo = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.amels.beach};
    text-transform: uppercase;
    letter-spacing: 3.43px;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
    margin-right: ${theme.spacing.x6}px;
  `,
)
const ImageContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x7}px;
    padding: 0 ${theme.spacing.x4}px;
    text-align: center;
    position: relative;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 50%;
      background: ${theme.colors.amels.oyster};
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x15}px;
    }
  `,
)

const StyledImage = styled(Image)(
  ({ theme }) => css`
    position: relative;

    @media screen and (max-width: ${theme.breakpoints.tabletMax}px) {
      width: 100%;
      height: auto;
    }
  `,
)
const StyledVideoPlayer = styled(VideoPlayer)`
  max-width: 1089px;
  margin: 0 auto;
`

interface Props {
  title: string
  type: string
  publishedAt: string
  image: string
  video?: string
}

const ArticleHeaderAmels = ({
  title,
  type,
  publishedAt,
  image,
  video,
}: Props) => {
  const { locale } = useRouter()

  const imageLoader = createImageLoader(image)

  const poster = imageLoader.loader
    ? imageLoader.loader({ src: imageLoader.src as string, width: 1920 })
    : image

  return (
    <>
      <Header>
        <Title>{visibleNonBreakingSpace(title)}</Title>
        <ArticleInfo data-testid="news.article.type">{type}</ArticleInfo>
        <ArticleInfo data-testid="news.article.date">
          {new Date(publishedAt).toLocaleDateString(locale, {
            day: 'numeric',
          })}{' '}
          {new Date(publishedAt).toLocaleDateString(locale, {
            month: 'long',
          })}{' '}
          {new Date(publishedAt).toLocaleDateString(locale, {
            year: 'numeric',
          })}
        </ArticleInfo>
      </Header>

      <ImageContainer>
        {video ? (
          <StyledVideoPlayer poster={poster}>
            {makeVideoSources(video)}
          </StyledVideoPlayer>
        ) : (
          <StyledImage
            src={image}
            alt={title}
            width={1089}
            height={613}
            style={{ objectFit: 'cover' }}
          />
        )}
      </ImageContainer>
    </>
  )
}

export default ArticleHeaderAmels
