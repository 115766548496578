import { MutableRefObject, useEffect, useState } from 'react'

const useElementHeight = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const [height, setHeight] = useState<number>(ref.current?.offsetHeight ?? 0)

  useEffect(() => {
    const elem = ref.current
    if (!elem) {
      return
    }

    const updateHeight = () => {
      setHeight(elem.offsetHeight)
    }
    updateHeight()
    const observer = new ResizeObserver(updateHeight)
    observer.observe(elem)
    // eslint-disable-next-line consistent-return
    return () => observer.disconnect()
  }, [ref])

  return height
}

export default useElementHeight
