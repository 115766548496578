import { forwardRef, memo, VideoHTMLAttributes } from 'react'

interface Props extends VideoHTMLAttributes<HTMLVideoElement> {
  video: string
}

/**
 * @param video - Only supports mp4 format
 */

// eslint-disable-next-line react/display-name
const BynderVideo = forwardRef<HTMLVideoElement, Props>(
  ({ video, ...others }: Props, ref) => {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video ref={ref} {...others}>
        <source src={video} type="video/mp4" />
      </video>
    )
  },
)

export default memo(BynderVideo)
