import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import ImagesWithQuoteYachtsupport from './ImagesWithQuote.yachtsupport'
import ImagesWithQuoteXplorer from './ImagesWithQuote.xplorer'
import Section from '../../components/Section'

export interface ImagesWithQuoteProps
  extends Omit<
    ComponentProps<typeof Section>,
    'title' | 'sectionIndicatorVariant' | 'children'
  > {
  columns: Array<
    | {
        key: string
        type: 'image'
        image: { src: string; alt?: string }
      }
    | {
        key: string
        type: 'quote'
        heading: ReactNode
        subHeading?: ReactNode
        backgroundImage?: { src: string; alt?: string }
      }
  >
  noPadding?: boolean
}

const ImagesWithQuote = (props: ImagesWithQuoteProps) => {
  switch (currentSite) {
    case Site.YachtSupport:
      return <ImagesWithQuoteYachtsupport {...props} />
    case Site.Xplorer:
      return <ImagesWithQuoteXplorer {...props} />
    default:
      return null
  }
}

export default ImagesWithQuote
