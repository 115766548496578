import { ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import ImageWithTextGridXplorer from './ImageWithTextGrid.xplorer'
import ImageWithTextGridYachtSupport from './ImageWithTextGrid.yachtsupport'

export interface ImageWithTextGridProps {
  layoutVariant: string
  backgroundColor: 'white' | 'green' | 'sand'
  backgroundImage?: {
    src: string
    alt?: string
  }
  content: {
    key: string
    contentItemType: 'text' | 'image'
    image?:
      | {
          src: string
          alt?: string
        }
      | undefined
    linkWithTitle?: {
      title: string
      href: string | undefined
      target: '' | '_blank' | undefined
    }
    title?: string
    description?: ReactNode
    overline?: string
    overlineIcon?: boolean
  }[]
}

const ImageWithTextGrid = (props: ImageWithTextGridProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <ImageWithTextGridXplorer {...props} />
    case Site.YachtSupport:
      return <ImageWithTextGridYachtSupport {...props} />
    default:
      return <ImageWithTextGridXplorer {...props} />
  }
}

export default ImageWithTextGrid
