/* eslint-disable camelcase */
import { EventCarouselStoryblok } from '../storyblok.generated'
import EventCarousel from '../../patterns/EventCarousel'
import { serializePublicationDate } from '../pages/ArticlePage'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'

interface Props {
  content: EventCarouselStoryblok
}

const EventCarouselBlok = ({
  content: { title, events },
  ...others
}: Props) => (
  <EventCarousel
    title={title}
    events={events.map(({ _uid, link, title, date, isActive }) => {
      return {
        key: _uid,
        link: storyblokLinkResolver(link),
        title,
        date: serializePublicationDate(date),
        isActive,
      }
    })}
    {...others}
  />
)

export default EventCarouselBlok
