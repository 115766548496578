import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import visibleNonBreakingSpace from '../utils/visibleNonBreakingSpace'
import Image from './Image'

const Header = styled.header(
  ({ theme }) => css`
    padding-top: ${theme.spacing.x8}px;
    max-width: 1024px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding-top: ${theme.spacing.x10}px;
    }
  `,
)

const Content = styled.div(
  ({ theme }) => css`
    max-width: 780px;
    margin: ${theme.spacing.x8}px auto ${theme.spacing.x4}px;
    padding: 0 20px;
    background: ${theme.colors.yachting.white};
    position: relative;
    z-index: 1;
    text-align: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x6}px;
      padding: 0;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin: 0 auto ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto ${theme.spacing.x6}px;
    }
  `,
)
const ArticleInfo = styled.div(({ theme }) => [
  css`
    color: ${theme.colors.xplorer.grey05};
    text-transform: uppercase;
    letter-spacing: 3.43px;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;

    :not(:last-of-type) {
      margin-right: ${theme.spacing.x6}px;
    }
  `,
])

const ImageContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x7}px;
    text-align: center;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x15}px;
    }
  `,
)

const StyledImage = styled(Image)(
  () => css`
    position: relative;
    width: 100%;
    height: auto;
  `,
)

interface Props {
  title: string
  publishedAt: string
  image: string
}

const ArticleHeaderXplorer = ({ title, publishedAt, image }: Props) => {
  const { locale } = useRouter()

  return (
    <Header>
      <Content>
        <Title>{visibleNonBreakingSpace(title)}</Title>
        <ArticleInfo data-testid="news.article.date">
          {new Date(publishedAt).toLocaleDateString(locale, {
            day: 'numeric',
          })}{' '}
          {new Date(publishedAt).toLocaleDateString(locale, {
            month: 'long',
          })}{' '}
          {new Date(publishedAt).toLocaleDateString(locale, {
            year: 'numeric',
          })}
        </ArticleInfo>
      </Content>
      <ImageContainer>
        <StyledImage
          src={image}
          alt={title}
          width={1089}
          height={613}
          style={{ objectFit: 'cover' }}
        />
      </ImageContainer>
    </Header>
  )
}

export default ArticleHeaderXplorer
