import Introduction from '../../patterns/Introduction'
import RichText from '../../components/RichText'
import slugify from '../../utils/slugify'
import { IntroductionStoryblok } from '../storyblok.generated'

interface Props {
  content: IntroductionStoryblok
}

const IntroductionBlok = ({
  content: { title, subTitle, overlines, text, sectionTitle, background },
  ...others
}: Props) => (
  <Introduction
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    background={background as 'white' | 'silver10'}
    overlines={overlines?.map((overline) => ({
      key: overline._uid,
      text: overline.title,
    }))}
    text={<RichText>{text}</RichText>}
    {...others}
  />
)

export default IntroductionBlok
