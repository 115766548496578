import { ShowCasesStoryblok } from '../storyblok.generated'
import Showcases from '../../patterns/Showcases'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import slugify from '../../utils/slugify'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: ShowCasesStoryblok
}

const ShowcaseBlok = ({
  content: { sectionTitle, title, showCasesBlocks },
  ...others
}: Props) => (
  <Showcases
    id={slugify(sectionTitle)}
    title={title}
    items={showCasesBlocks.map(({ _uid, title, subTitle, image, link }) => ({
      key: _uid,
      title,
      subTitle,
      image: mapImage(image) ?? getRequiredImageFallback(),
      href: storyblokLinkResolver(link),
    }))}
    {...others}
  />
)

export default ShowcaseBlok
