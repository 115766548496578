import { TestimonialStoryblok } from '../storyblok.generated'
import Testimonial from '../../patterns/Testimonial'
import RichText from '../../components/RichText'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: TestimonialStoryblok
}

const TestimonialBlok = ({
  content: {
    image,
    quote,
    quoteeName,
    quoteeFunction,
    imagePosition,
    backgroundColorVariants,
    quoteTextColorVariants,
    ...others
  },
}: Props) => (
  <Testimonial
    image={mapImage(image) ?? getRequiredImageFallback()}
    quote={<RichText>{quote}</RichText>}
    quoteeName={quoteeName}
    quoteeFunction={quoteeFunction}
    imagePosition={imagePosition}
    backgroundColorVariants={backgroundColorVariants}
    quoteTextColorVariants={quoteTextColorVariants}
    {...others}
  />
)

export default TestimonialBlok
