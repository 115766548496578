import { currentSite, Site } from '../../sites'
import VideoHighlightYachtSupport from './VideoHighlight.yachtsupport'

export interface VideoHighlightProps {
  heading: string
  overline?: string
  video: {
    title: string
    caption?: string
    summary?: string
    image: string
    buttonLabel: string
    link: string
  }
}

const VideoHighlight = (props: VideoHighlightProps) => {
  switch (currentSite) {
    case Site.YachtSupport:
      return <VideoHighlightYachtSupport {...props} />
    default:
      return <VideoHighlightYachtSupport {...props} />
  }
}

export default VideoHighlight
