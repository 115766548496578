import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ImageWithHeadingProps } from './index'
import { heading2, overLineMedium } from '../../themes/yachtsupport/text'
import PlusIcon from '../../icons/yachtsupport/Plus.svg'
import ImageAspectRatio from '../../components/ImageAspectRatio'
import Image from '../../components/Image'

const Container = styled.div(
  () =>
    css`
      position: relative;
      max-height: 600px;
    `,
)

const Content = styled.div(
  () =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 896px;
      margin: 0 auto;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
    `,
)

const TextContent = styled.div(
  ({ theme }) =>
    css`
      padding: ${theme.spacing.x4}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x7}px ${theme.spacing.x4}px;
      }
    `,
)

const Overline = styled.div(
  ({ theme }) =>
    css`
      ${overLineMedium(theme)}
      color: ${theme.colors.yachtSupport.white};
      margin-bottom: ${theme.spacing.x2}px;
    `,
)

const Heading = styled.h3(
  ({ theme }) =>
    css`
      ${heading2(theme)}
      color: ${theme.colors.yachtSupport.white};
      text-transform: none;
      margin: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 32px;
        text-transform: uppercase;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 38px;
        text-transform: uppercase;
      }
    `,
)

const StyledPlusIcon = styled(PlusIcon)(({ theme }) => [
  css`
    height: 21px;
    color: ${theme.colors.yachtSupport.white};
  `,
])

const BackgroundImageDesktopContainer = styled(ImageAspectRatio)(
  ({ theme }) =>
    css`
      display: none;
      img {
        max-height: 600px;
      }

      @media screen and (min-width: ${theme.breakpoints.mobileMax}px) {
        display: block;
      }
    `,
)
const BackgroundImageMobileContainer = styled(ImageAspectRatio)(
  ({ theme }) =>
    css`
      img {
        max-height: 600px;
      }
      @media screen and (min-width: ${theme.breakpoints.mobileMax}px) {
        display: none;
      }
    `,
)

const ImageWithHeading = (props: ImageWithHeadingProps) => {
  const { heading, overline, backgroundImage, ...others } = props

  return (
    <Container data-testid="imageWithHeading" {...others}>
      <BackgroundImageDesktopContainer aspectRatio="16:9">
        <Image
          src={backgroundImage.src}
          alt={backgroundImage.alt ?? ''}
          style={{ objectFit: 'cover', width: '100%' }}
          fill
          aspectRatio="16:9"
        />
      </BackgroundImageDesktopContainer>
      <BackgroundImageMobileContainer aspectRatio="375:667">
        <Image
          src={backgroundImage.src}
          alt={backgroundImage.alt ?? ''}
          style={{ objectFit: 'cover', width: '100%' }}
          fill
          aspectRatio="375:667"
        />
      </BackgroundImageMobileContainer>
      <Content>
        <StyledPlusIcon aria-label="Plus" />
        <TextContent>
          {overline && <Overline>{overline}</Overline>}
          <Heading>{heading}</Heading>
        </TextContent>
        <StyledPlusIcon aria-label="Plus" />
      </Content>
    </Container>
  )
}

export default ImageWithHeading
