import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { forwardRef, InputHTMLAttributes } from 'react'
import { FieldError } from 'react-hook-form'

import FormField from './FormField'
import { isSiteAmels, isSiteXplorer, isSiteYachtSupport } from '../themes'

const Line = styled('div', {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>(({ theme, error }) => [
  css`
    display: block;
    width: 100%;
    height: 1px;
    background: currentColor;
  `,
  error &&
    css`
      background: ${theme.colors.amels.hazySunsetOrange};
    `,
  isSiteAmels(theme) &&
    css`
      display: none;
    `,
])
const Textarea = styled('textarea', {
  shouldForwardProp: (prop) => prop !== 'error' && prop !== 'colorScheme',
})<{
  error?: boolean
  colorScheme?: 'light' | 'dark'
}>(({ theme, error }) => [
  css`
    display: block;
    width: 100%;
    color: inherit;
    resize: none;
    min-height: 8em;
    border: 0;
    padding: ${theme.spacing.x1}px 0;

    :focus {
      outline: 0;
    }
    :focus + ${Line} {
      background: ${theme.colors.yachting.orange};
    }
  `,
  isSiteAmels(theme) &&
    css`
      min-height: 160px;
      padding: ${theme.spacing.x3}px;
      border: solid 1px ${theme.colors.amels.deepBayAqua};
    `,
  error &&
    css`
      color: ${theme.colors.amels.hazySunsetOrange};
      border-color: ${theme.colors.amels.hazySunsetOrange};

      ::placeholder {
        color: ${theme.colors.amels.hazySunsetOrange};
        opacity: 0.3;
      }
    `,
  isSiteXplorer(theme) &&
    css`
      min-height: 125px;
      padding: ${theme.spacing.x2}px 0;

      ::placeholder {
        color: inherit;
      }

      :focus {
        border-color: transparent;
      }
      :focus + ${Line} {
        background: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      min-height: 125px;
      padding: ${theme.spacing.x2}px 0;

      ::placeholder {
        color: inherit;
      }

      :focus {
        border-color: transparent;
      }
      :focus + ${Line} {
        background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      }
    `,
])

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string
  colorScheme?: 'light' | 'dark'
  error?: FieldError
  'data-testid'?: string
}

// eslint-disable-next-line react/display-name
const FormFieldTextarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      label,
      colorScheme,
      error,
      required,
      'data-testid': testid,
      className,
      ...others
    }: Props,
    ref,
  ) => (
    <FormField error={error} data-testid={testid} className={className}>
      <Textarea
        type="text"
        placeholder={`${label} ${required ? '*' : ''}`}
        aria-required={required}
        aria-invalid={Boolean(error)}
        colorScheme={colorScheme}
        error={Boolean(error)}
        ref={ref}
        {...others}
      />
      <Line error={Boolean(error)} />
    </FormField>
  ),
)

export default FormFieldTextarea
