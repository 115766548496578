import { usePageContext } from '../PageContext'
import RequestBrochureFormBlok from '../RequestBrochureFormBlok'
import RequestBrochureSection from '../../patterns/RequestBrochureSection'
import RichText from '../../components/RichText'
import { StoryblokStory } from '../../utils/storyblok'
import {
  FormDownloadStoryblok,
  FormStoryblok,
  PortfolioCampaignBrochureDownloadStoryblok,
} from '../storyblok.generated'
import slugify from '../../utils/slugify'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: PortfolioCampaignBrochureDownloadStoryblok
}

const PortfolioRequestBrochureBlok = ({
  content: { sectionTitle, title, linkText, formReference, image },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  const form = rels.find((rel) => rel.uuid === formReference) as
    | StoryblokStory<FormStoryblok>
    | undefined

  return (
    <RequestBrochureSection
      id={slugify(sectionTitle)}
      title={<RichText>{title}</RichText>}
      callToActionText={linkText}
      form={
        form?.content.formBlock[0] && (
          <RequestBrochureFormBlok
            form={form.content.formBlock[0] as FormDownloadStoryblok}
          />
        )
      }
      backgroundImage={mapImage(image) ?? getRequiredImageFallback()}
      {...others}
    />
  )
}

export default PortfolioRequestBrochureBlok
