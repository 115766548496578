import FullCustomHeader from '../../patterns/FullCustomHeader'
import { mapImage } from '../../utils/mapImage'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { FullCustomHeaderStoryblok } from '../storyblok.generated'

interface Props {
  content: FullCustomHeaderStoryblok
}

const FullCustomHeaderBlok = ({
  content: { sectionTitle, title, subTitle, image, scrollIndicatorText },
  ...others
}: Props) => (
  <FullCustomHeader
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    image={mapImage(image)?.src ?? ''}
    scrollIndicatorText={
      scrollIndicatorText &&
      reactStringReplace(scrollIndicatorText, '\n', <br />)
    }
    {...others}
  />
)

export default FullCustomHeaderBlok
