import { ComponentProps, ReactNode } from 'react'

import { currentSite } from '../../sites'
import Section from '../../components/Section'
import { yachtingTheme } from '../../themes'
import QuoteAmels from './Quote.amels'
import QuoteYachting from './Quote.yachting'

export interface QuoteProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  subTitle?: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
}

const Quote = (props: QuoteProps) => {
  switch (currentSite) {
    case 'yachting':
      return <QuoteYachting theme={yachtingTheme} {...props} />
    default:
      return <QuoteAmels {...props} />
  }
}

export default Quote
