import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'
import { useInView } from 'react-intersection-observer'

import { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import Section from '../components/Section'

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'aspectRatio',
})<{ aspectRatio: AspectRatio }>(({ theme, aspectRatio }) => [
  css`
    position: relative;
    background: ${theme.colors.amels.silk};
  `,
  aspectRatio === '1:1' &&
    css`
      padding-bottom: 100%;
    `,
  aspectRatio === '2:1' &&
    css`
      padding-bottom: 50%;
    `,
  aspectRatio === '16:9' &&
    css`
      padding-bottom: 56.25%;
    `,
  aspectRatio === '4:3' &&
    css`
      padding-bottom: 75%;
    `,
  aspectRatio === 'original' &&
    css`
      padding-bottom: 100%;

      img {
        height: auto !important;
      }
    `,
])
const DesktopImageContainer = styled.div(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const MobileImageContainer = styled.div(
  ({ theme }) => css`
    display: block;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant'
  > {
  image: {
    src: string
    alt?: string
  }
  mobileImage?: {
    src: string
    alt?: string
  }
  aspectRatio: AspectRatio
}

export type AspectRatio = '1:1' | '2:1' | '16:9' | '4:3' | 'original'

const FullWidthImage = ({
  image,
  mobileImage,
  aspectRatio,
  ...others
}: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="fullWidthImage"
      ref={containerRef}
      aspectRatio={aspectRatio}
      {...others}
    >
      <DesktopImageContainer>
        <Image
          src={image.src}
          alt={image.alt}
          fill
          style={{
            objectFit: aspectRatio === 'original' ? 'contain' : 'cover',
          }}
        />
      </DesktopImageContainer>
      <MobileImageContainer>
        <Image
          src={(mobileImage ?? image).src}
          alt={(mobileImage ?? image).alt}
          fill
          style={{
            objectFit: aspectRatio === 'original' ? 'contain' : 'cover',
          }}
        />
      </MobileImageContainer>
    </Container>
  )
}

export default FullWidthImage
