import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useState } from 'react'

import Button, {
  primaryOutlineButtonCss,
  primaryOutlineButtonCssYachtSupport,
} from '../../components/Button'
import SidePanelFixed from '../../components/SidePanelFixed'
import SidePanelTransition from '../../components/SidePanelTransition'
import SimpleHeader from '../SimpleHeader'
import { isSiteYachtSupport } from '../../themes'

const StyledButton = styled(Button)(
  ({ theme }) => css`
    ${css`
      ${isSiteYachtSupport(theme)
        ? primaryOutlineButtonCssYachtSupport(theme)
        : primaryOutlineButtonCss(theme)}
    `}
    margin-top: ${theme.spacing.x3}px;
  `,
)

interface Props extends Omit<ComponentProps<typeof SimpleHeader>, 'children'> {
  form: ReactNode
  linkText?: string
}

const ContactHeader = ({ form, linkText, ...others }: Props) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <SimpleHeader data-testid="contactHeader" {...others}>
        {linkText && form && (
          <StyledButton
            onClick={() => setOpen(true)}
            data-testid="contactHeader.callToAction"
          >
            {linkText}
          </StyledButton>
        )}
      </SimpleHeader>

      {form && (
        <SidePanelTransition isOpen={isOpen}>
          {isOpen && (
            <SidePanelFixed
              close={() => setOpen(false)}
              data-testid="contactHeader.sidePanel"
            >
              {form}
            </SidePanelFixed>
          )}
        </SidePanelTransition>
      )}
    </>
  )
}

export default ContactHeader
