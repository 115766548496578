import { SVGProps } from 'react'

import Phone from '../icons/yachting/contact/Phone.svg'
import Email from '../icons/yachting/contact/Email.svg'
import LinkedIn from '../icons/yachting/contact/LinkedIn.svg'

interface Props extends SVGProps<SVGElement> {
  icon: 'phone' | 'email' | 'linkedin'
}

const ContactIcon = ({ icon, ...others }: Props) => {
  switch (icon) {
    case 'phone':
      return <Phone {...others} />
    case 'email':
      return <Email {...others} />
    case 'linkedin':
      return <LinkedIn {...others} />
    default:
      return null
  }
}

export default ContactIcon
