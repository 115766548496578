import PortfolioSpecifications from '../../patterns/PortfolioSpecifications'
import RichText from '../../components/RichText'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { PortfolioSpecificationsStoryblok } from '../storyblok.generated'

interface Props {
  content: PortfolioSpecificationsStoryblok
}

const PortfolioSpecificationsBlok = ({
  content: { sectionTitle, title, subTitle, text, specificationGroupBlocks },
  ...others
}: Props) => (
  <PortfolioSpecifications
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    text={<RichText>{text}</RichText>}
    groups={specificationGroupBlocks.map((block) =>
      block.component === 'specificationGroup'
        ? {
            key: block._uid,
            title: block.title,
            values: block.specificationBlocks.map(({ _uid, label, value }) => ({
              key: _uid,
              label,
              value: reactStringReplace(value, '\n', <br />),
            })),
          }
        : {
            key: block._uid,
            title: block.title,
            text: <RichText>{block.text}</RichText>,
          },
    )}
    {...others}
  />
)

export default PortfolioSpecificationsBlok
