import { memo } from 'react'

import { TestimonialCarouselStoryblok } from '../storyblok.generated'
import TestimonialCarousel from '../../patterns/TestimonialCarousel'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: TestimonialCarouselStoryblok
}

const TestimonialCarouselBlok = ({
  content: { carouselItems, background },
  ...others
}: Props) => (
  <TestimonialCarousel
    testimonials={carouselItems.map((item) => ({
      overline: item.overline,
      title: item.title,
      name: item.name,
      role: item.role,
      image: mapImage(item.avatar) ?? getRequiredImageFallback(),
    }))}
    background={background}
    {...others}
  />
)

export default memo(TestimonialCarouselBlok)
