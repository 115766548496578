import { ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import CollageWithTextYachtSupport from './CollageWithText.yachtsupport'

export interface CollageWithTextProps {
  title: string
  overline?: string
  description?: ReactNode
  imageLayoutVariant: string
  imageOne: { src: string; alt?: string }
  imageTwo: { src: string; alt?: string }
  buttonLabel?: string
  buttonLink?: string
}

const CollageWithText = (props: CollageWithTextProps) => {
  switch (currentSite) {
    case Site.YachtSupport:
      return <CollageWithTextYachtSupport {...props} />
    default:
      return <CollageWithTextYachtSupport {...props} />
  }
}

export default CollageWithText
