import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

import AnimateText from '../components/AnimateText'
import ScrollDownIndicator from '../components/ScrollDownIndicator'
import Image from '../components/Image'

const Header = styled.header(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;
    color: ${theme.colors.amels.body};
    padding: 240px ${theme.spacing.x4}px ${theme.spacing.x4}px;
    position: relative; // needed by the image

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      align-items: center;
      padding: 240px ${theme.spacing.x5}px ${theme.spacing.x5}px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    color: ${theme.colors.amels.body};
    max-width: 738px;
    margin: 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto;
    }
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  `,
)
const ScrollIndicatorWrapper = styled.div`
  text-align: center;
`

const StyledLogo = styled(Image)(
  ({ theme }) => css`
    width: 100%;
    height: auto;
    margin-bottom: ${theme.spacing.x3}px;
  `,
)

interface Props {
  title: ReactNode
  id?: string
  image: {
    src: string
    alt?: string
  }
  logo: {
    src: string
    alt?: string
  }
  scrollIndicatorText: ReactNode
}

const HeroImageHeaderWithLogo = ({
  id = 'heroImageHeaderWithLogo',
  title,
  logo,
  image,
  scrollIndicatorText,
  ...others
}: Props) => (
  <Header id={id} data-testid="heroImageHeaderWithLogoBlok" {...others}>
    <Image
      src={image.src}
      alt={image?.alt ?? ''}
      fill
      style={{ objectFit: 'cover' }}
    />

    <Title>{title}</Title>

    <AnimateText delay={200}>
      <StyledLogo
        src={logo.src}
        alt={logo?.alt ?? ''}
        width={400}
        height={400}
        style={{ objectFit: 'contain' }}
      />
    </AnimateText>

    {scrollIndicatorText && (
      <ScrollIndicatorWrapper>
        <ScrollDownIndicator
          style={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ['--animation-delay' as any]: '800ms',
          }}
        >
          {scrollIndicatorText}
        </ScrollDownIndicator>
      </ScrollIndicatorWrapper>
    )}
  </Header>
)

export default HeroImageHeaderWithLogo
