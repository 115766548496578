import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import AnimateText from './AnimateText'
import IconLink from './IconLink'
import ChevronRight from '../icons/ChevronRight.svg'

const Container = styled.div()
const Title = styled.div(
  ({ theme }) => css`
    ${theme.text.body(theme)}
    font-weight: 600;
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    ${theme.text.body(theme)}
  `,
)
const LinkWrapper = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x3}px;
  `,
)
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  text: ReactNode
  link: {
    url: string
    title?: string
  }
}

const TextWithLink = ({ title, text, link, ...others }: Props) => (
  <div data-testid="TextWithLink" {...others}>
    <AnimateText>
      <Title>{title}</Title>
      <Text>{text}</Text>

      {link && (
        <LinkWrapper>
          <IconLink
            icon={<StyledChevronRight aria-label="" />}
            href={link.url}
            data-testid="textWithLink.link"
          >
            {link.title}
          </IconLink>
        </LinkWrapper>
      )}
    </AnimateText>
  </div>
)

export default TextWithLink
