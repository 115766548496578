import { PortfolioIntroductionStoryblok } from '../storyblok.generated'
import PortfolioIntroduction from '../../patterns/PortfolioIntroduction'
import RichText from '../../components/RichText'
import slugify from '../../utils/slugify'

interface Props {
  content: PortfolioIntroductionStoryblok
}

const PortfolioIntroductionBlok = ({
  content: { sectionTitle, title, subTitle, description, features },
  ...others
}: Props) => (
  <PortfolioIntroduction
    id={slugify(sectionTitle)}
    title={title}
    subTitle={subTitle}
    description={<RichText>{description}</RichText>}
    features={features.map(({ title }) => title)}
    {...others}
  />
)

export default PortfolioIntroductionBlok
