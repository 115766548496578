import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { heading2, heading5 } from '../../themes/yachtsupport/text'
import Image from '../../components/Image'
import Quote from '../../icons/xplorer/Quote.svg'
import { ImagesWithQuoteProps } from './index'
import useViewportSize from '../../utils/useViewportSize'

const Container = styled.div<{ noPadding?: boolean }>(
  ({ theme, noPadding }) => css`
    text-align: center;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    align-items: center;
    gap: ${theme.spacing.x3}px;
    padding: ${noPadding ? 0 : `${theme.spacing.x8}px ${theme.spacing.x3}px`};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x5}px;
      padding: ${noPadding
        ? 0
        : `${theme.spacing.x20}px ${theme.spacing.x3}px`};
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      grid-template-columns: repeat(2, minmax(290px, 1fr));
      padding: ${noPadding
        ? 0
        : `${theme.spacing.x20}px ${theme.spacing.x10}px`};
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  `,
)

const ContainerQuoteStart = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: center;
    color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    z-index: 1;
  `,
)

const QuoteIcon = styled(Quote)`
  width: 58px;
`

const ImageCard = styled.div(
  ({ theme }) => css`
    height: 500px;
    overflow: hidden;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      height: 460px;
    }
  `,
)

const QuoteCard = styled.div(
  ({ theme }) => css`
    padding: calc(${theme.spacing.x4}px + ${theme.spacing.x8}px)
      ${theme.spacing.x4}px; // vertical padding include quote icon height
    gap: ${theme.spacing.x4}px;
    background: ${theme.colors.yachtSupport.black};
    height: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      height: 540px;
    }
  `,
)

const QuoteCardHeading = styled.div(
  ({ theme }) => css`
    ${heading2(theme)}
    width: 100%;
    font-weight: 900;
    line-height: normal;
    letter-spacing: normal;
    color: ${theme.colors.yachtSupport.white};
    margin: 0;
    text-transform: uppercase;
    overflow-wrap: break-word;
    max-width: 100%;
    z-index: 1;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 26px;
      line-height: normal;
      letter-spacing: normal;
    }
  `,
)

const QuoteCardSubHeading = styled.div(
  ({ theme }) => css`
    ${heading5(theme)}
    color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    margin: 0;
    width: 100%;
    text-transform: uppercase;
    z-index: 1;
  `,
)

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const renderColumn = (
  column: NonNullable<
    | {
        key: string
        type: 'image'
        image: { src: string; alt?: string }
      }
    | {
        key: string
        type: 'quote'
        heading: ReactNode
        subHeading?: ReactNode
        backgroundImage?: { src: string; alt?: string }
      }
  >,
) => {
  if (column.type === 'image') {
    return (
      <ImageCard key={column.key}>
        <Image
          src={column.image.src}
          alt={column.image.alt ?? ''}
          width={603}
          height={546}
          style={{ objectFit: 'cover', width: '100%' }}
          sizes="(max-width: 767px) 603px, 540px"
        />
      </ImageCard>
    )
  }
  return (
    <QuoteCard key={column.key}>
      {column.backgroundImage && (
        <StyledImage
          src={column.backgroundImage.src}
          alt={column.backgroundImage.alt}
          width={603}
          height={546}
          style={{ objectFit: 'cover', width: '100%' }}
          sizes="(max-width: 767px) 603px, 540px"
        />
      )}
      <ContainerQuoteStart>
        <QuoteIcon />
      </ContainerQuoteStart>
      {column?.subHeading && (
        <QuoteCardSubHeading>{column.subHeading}</QuoteCardSubHeading>
      )}
      <QuoteCardHeading>{column.heading}</QuoteCardHeading>
    </QuoteCard>
  )
}

const filterImageWithQuote = ({ columns }: ImagesWithQuoteProps) => {
  return columns.reduce((acc, column) => {
    if (
      (column.type === 'quote' && !acc.some((col) => col.type === 'quote')) ||
      (column.type === 'image' && !acc.some((col) => col.type === 'image'))
    ) {
      acc.push(column)
    }
    return acc
  }, [] as ImagesWithQuoteProps['columns'])
}

const ImagesWithQuote = ({
  columns,
  noPadding,
  ...others
}: ImagesWithQuoteProps) => {
  const viewport = useViewportSize()
  const isMobile = viewport && viewport.width < 768
  const filteredColumns = isMobile ? filterImageWithQuote({ columns }) : columns

  return (
    <Container noPadding={noPadding} {...others}>
      {filteredColumns.map(renderColumn)}
    </Container>
  )
}

export default ImagesWithQuote
