import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { VisualDividerProps } from '.'

const Line = styled.div<{ hasPadding?: boolean; vertical?: boolean }>(
  ({ theme, hasPadding, vertical }) => css`
    position: relative;
    overflow: visible;
    padding: 0 ${theme.spacing.x2}px;

    ${hasPadding &&
    css`
      padding: ${theme.spacing.x7}px ${theme.spacing.x5}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x7}px;
      }
    `}

    &::after {
      content: '';
      position: absolute;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 1px;
      ${vertical &&
      css`
        width: 1px;
        height: 100%;
        left: auto;
      `}
      ${hasPadding &&
      css`
        left: ${theme.spacing.x3}px;
        width: calc(100% - ${theme.spacing.x6}px);

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          left: ${theme.spacing.x5}px;
          width: calc(100% - ${theme.spacing.x10}px);
        }
      `}
      background: ${theme.colors.yachtSupport.grey03};
    }
  `,
)

const IntersectLineOne = styled.div<{
  hasPadding?: boolean
  vertical?: boolean
}>(
  ({ theme, hasPadding, vertical }) => css`
    position: relative;
    overflow: visible;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      ${hasPadding &&
      css`
        left: ${theme.spacing.x2}px;
      `}
      z-index: 2;
      width: 1px;
      height: ${theme.spacing.x5}px;
      transform: translateY(-50%);
      ${vertical &&
      css`
        width: ${theme.spacing.x5}px;
        height: 1px;
        transform: translateX(-50%);
        top: ${theme.spacing.x2}px;
      `}
      background: ${theme.colors.yachtSupport.grey03};
    }
  `,
)

const IntersectLineTwo = styled.div<{
  hasPadding?: boolean
  vertical?: boolean
}>(
  ({ theme, hasPadding, vertical }) => css`
    position: relative;
    overflow: visible;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      ${hasPadding &&
      css`
        right: ${theme.spacing.x2}px;
      `}
      z-index: 2;
      width: 1px;
      height: ${theme.spacing.x5}px;
      transform: translateY(-50%);
      ${vertical &&
      css`
        width: ${theme.spacing.x5}px;
        height: 1px;
        transform: translateX(50%);
        bottom: ${theme.spacing.x2}px;
      `}
      background: ${theme.colors.yachtSupport.grey03};
    }
  `,
)

const VisualDivider = ({
  hasPadding,
  vertical,
  ...others
}: VisualDividerProps) => (
  <Line
    hasPadding={hasPadding}
    vertical={vertical}
    {...others}
    data-testid="visualDivider"
  >
    <IntersectLineOne hasPadding={hasPadding} vertical={vertical} />
    <IntersectLineTwo hasPadding={hasPadding} vertical={vertical} />
  </Line>
)

export default VisualDivider
