import { css } from '@emotion/react'
import styled from '@emotion/styled'

import AnimateText from '../../components/AnimateText'
import Image from '../../components/Image'
import Section from '../../components/Section'
import { QuoteProps } from '.'
import {
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../../themes'

const StyledSection = styled(Section)(
  ({ theme }) => css`
    position: relative;
    margin: ${theme.spacing.x10}px 0;
  `,
)
const ImageContainer = styled.div(
  () => css`
    position: relative;
  `,
)
const ContentWrapper = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-flow: column;
    max-width: 1086px;
    margin: 0 auto;
    padding: ${theme.spacing.x4}px ${theme.spacing.x3}px;
    gap: ${theme.spacing.x4}px;
    background: ${theme.colors.yachting.darkBlue30};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x10}px;
    }
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      background: ${theme.colors.womenInYachtingPage.terraBlush};
    `,
])
const ContentContainer = styled.div(({ theme }) => [
  css`
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 0 ${theme.spacing.x4}px;
    text-align: center;
    margin-top: -${theme.spacing.x5}px;
    z-index: 1;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-top: -${theme.spacing.x14}px;
    }
  `,
])

/* eslint-disable no-irregular-whitespace */
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    position: relative;
    margin: 0;
    color: ${theme.colors.womenInYachtingPage.white};

    ::before {
      // The space needs to be a nbsp
      content: '- ';
      position: absolute;
      left: 0;
      transform: translateX(-100%);
    }
  `,
)
const SubTitle = styled.h2(({ theme }) => [
  css`
    margin: 0;
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      color: ${theme.colors.womenInYachtingPage.white};
    `,
])
const Text = styled.div(({ theme }) => [
  css`
    ${theme.text.body(theme)}

    p:last-child {
      margin: 0;
    }
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      ${theme.text.bodyLarge(theme)}
      color: ${theme.colors.womenInYachtingPage.white};
    `,
])
const ImageWrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacing.x3}px;
    // aspect ratio 2/1
    padding-top: 50%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: 0;
    }
  `,
)

const Quote = ({ title, subTitle, text, image, ...others }: QuoteProps) => (
  <StyledSection sectionIndicatorVariant="dark" data-testid="quote" {...others}>
    <ImageContainer {...others}>
      <ImageWrapper>
        <Image
          src={image.src}
          alt={image.alt ?? ''}
          fill
          style={{ objectFit: 'cover' }}
        />
      </ImageWrapper>
    </ImageContainer>
    <ContentContainer>
      <ContentWrapper>
        <AnimateText delay={120}>
          <Title>{title}</Title>
        </AnimateText>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <Text>
          <AnimateText delay={240}>{text}</AnimateText>
        </Text>
      </ContentWrapper>
    </ContentContainer>
  </StyledSection>
)

export default Quote
