import { ComponentProps, ReactNode } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from '../components/Section'
import Image from '../components/Image'
import SidePanelButton from '../components/SidePanelButton'
import {
  primaryButtonCssXplorer,
  primaryOutlineButtonCssXplorer,
  primaryButtonCssYachtSupport,
  primaryOutlineButtonCssYachtSupport,
} from '../components/Button'
import Link from '../components/Link'
import {
  body as bodyXplorer,
  heading2 as heading2Xplorer,
} from '../themes/xplorer/text'

import {
  body as bodyYachtSupport,
  heading2 as heading2YachtSupport,
} from '../themes/yachtsupport/text'
import { isSiteXplorer, isSiteYachtSupport } from '../themes/utils'

const Container = styled.div(({ theme }) => [
  css`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    background: ${theme.colors.xplorer.primaryXPDarkOceanGreen};

    @media screen and (min-width: ${theme.breakpoints.tabletMax}px) {
      grid-template-columns: 1fr 1fr;
    }
  `,
])

const FormContainer = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;

    ${isSiteYachtSupport(theme) &&
    css`
      background: var(
        --Lineair-Blue,
        linear-gradient(
          45deg,
          ${theme.colors.yachtSupport.primaryYSCarbonBlack} 0%,
          ${theme.colors.yachtSupport.primaryYSOceanBlue} 97.6%
        )
      );
    `}

    @media screen and (min-width: ${theme.breakpoints.tabletMax}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
    }
  `,
])

const TriggerFormContainer = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: ${theme.spacing.x8}px;
    padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
    background: ${theme.colors.xplorer.secondaryXPBronze};

    ${isSiteYachtSupport(theme) &&
    css`
      background: var(
        --Lineair-Blue,
        linear-gradient(
          45deg,
          ${theme.colors.yachtSupport.primaryYSCarbonBlack} 0%,
          ${theme.colors.yachtSupport.primaryYSOceanBlue} 97.6%
        )
      );
    `}

    @media screen and (min-width: ${theme.breakpoints.tabletMax}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      gap: ${theme.spacing.x5}px;
    }
  `,
])

const TriggerFormTitle = styled.div(
  ({ theme }) =>
    css`
      ${isSiteXplorer(theme) && heading2Xplorer(theme)}
      ${isSiteYachtSupport(theme) && heading2YachtSupport(theme)}
      ${isSiteXplorer(theme) &&
      css`
        color: ${theme.colors.xplorer.white};
      `};
      ${isSiteYachtSupport(theme) &&
      css`
        color: ${theme.colors.yachtSupport.white};
      `};
    `,
)

const TriggerFormHeading = styled.div(
  ({ theme }) => css`
    display: grid;
    gap: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x5}px;
    }
  `,
)

const TriggerFormOverline = styled.div(
  ({ theme }) =>
    css`
      ${isSiteYachtSupport(theme) &&
      css`
        ${theme.text.overLineMedium(theme)}
        color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      `};
    `,
)

const TriggerFormDescription = styled.div(
  ({ theme }) =>
    css`
      ${isSiteXplorer(theme) && bodyXplorer(theme)}
      ${isSiteYachtSupport(theme) && bodyYachtSupport(theme)}
      ${isSiteXplorer(theme) &&
      css`
        color: ${theme.colors.xplorer.white};
      `}
      ${isSiteYachtSupport(theme) &&
      css`
        color: ${theme.colors.yachtSupport.white};
      `}
    `,
)

const TriggerFormActions = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.x3}px;

      ${isSiteYachtSupport(theme) &&
      css`
        @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
          flex-direction: row;
          gap: ${theme.spacing.x5}px;
        }
      `}
    `,
)

const ImageContainer = styled.div(({ theme }) => [
  css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tabletMax}px) {
      display: block;
    }
  `,
])

const StyledSidePanelButton = styled(SidePanelButton)(({ theme }) => [
  isSiteXplorer(theme) && primaryButtonCssXplorer(theme),
  isSiteYachtSupport(theme) && primaryButtonCssYachtSupport(theme),
])

const StyledLink = styled(Link)(({ theme }) => [
  isSiteXplorer(theme) && primaryOutlineButtonCssXplorer(theme),
  isSiteYachtSupport(theme) && primaryOutlineButtonCssYachtSupport(theme),
])

type TriggerFormContent = {
  title?: string
  overline?: string
  description?: string
  image?: {
    src: string
    alt?: string
  }
  linkWithTitle?: {
    title: ReactNode
    href?: string
    target?: string
  }
  triggerModalForm: {
    key: string
    title: string
    form: ReactNode
  }
}

export interface ContactCallToActionProps
  extends Omit<
    ComponentProps<typeof Section>,
    'sectionIndicatorVariant' | 'children'
  > {
  asideImage: { src: string; alt?: string }
  contactBlockType?: 'inline-form' | 'trigger-form'
  contactForm: ReactNode | null
  triggerFormContent: TriggerFormContent | null
}

const ContactCallToAction = ({
  contactForm,
  triggerFormContent,
  contactBlockType,
  asideImage,
  ...others
}: ContactCallToActionProps) => {
  if (contactForm === null && triggerFormContent === null) {
    return null
  }

  return (
    <Container {...others}>
      {contactBlockType === 'inline-form' && contactForm !== null && (
        <FormContainer>{contactForm}</FormContainer>
      )}
      {contactBlockType === 'trigger-form' && triggerFormContent !== null && (
        <TriggerFormContainer>
          {triggerFormContent?.image && (
            <Image
              src={triggerFormContent.image.src}
              alt={triggerFormContent.image.alt}
              width={274}
              height={274}
              style={{ objectFit: 'contain' }}
            />
          )}
          <TriggerFormHeading>
            {triggerFormContent.overline && (
              <TriggerFormOverline>
                {triggerFormContent.overline}
              </TriggerFormOverline>
            )}
            <TriggerFormTitle>{triggerFormContent.title}</TriggerFormTitle>
            <TriggerFormDescription>
              {triggerFormContent.description}
            </TriggerFormDescription>
          </TriggerFormHeading>
          <TriggerFormActions>
            <StyledSidePanelButton
              key={triggerFormContent.triggerModalForm.key}
              sidePanel={triggerFormContent.triggerModalForm.form}
              data-testid="formButton"
              variant="primary"
            >
              {triggerFormContent.triggerModalForm.title}
            </StyledSidePanelButton>
            {triggerFormContent.linkWithTitle?.href && (
              <StyledLink
                href={triggerFormContent.linkWithTitle?.href}
                target={triggerFormContent.linkWithTitle.target}
              >
                {triggerFormContent.linkWithTitle?.title}
              </StyledLink>
            )}
          </TriggerFormActions>
        </TriggerFormContainer>
      )}
      <ImageContainer>
        <Image
          src={asideImage.src}
          alt={asideImage.alt}
          width={720}
          height={844}
          style={{ objectFit: 'cover', width: '100%', height: '100%' }}
        />
      </ImageContainer>
    </Container>
  )
}

export default ContactCallToAction
