import { css, keyframes, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import { getPreloadUrl } from '../components/HighlightVideo'
import Section from '../components/Section'
import preloadVideo from '../utils/preloadVideo'
import PortfolioHighlightsImage, {
  HighlightButton,
} from './PortfolioHighlightsImage'
import PortfolioHighlightsVideo from './PortfolioHighlightsVideo'
import mergeRefs from '../utils/mergeRefs'
import PortfolioHighlightsPreloading from './PortfolioHighlightsPreloading'
import PortfolioHighlightsMobileInformation from './PortfolioHighlightsMobileInformation'
import useIsMobile from '../utils/useIsMobile'
import useDisableBodyScroll from '../utils/useDisableBodyScroll'
import { makeVar, useReactiveVar } from '../utils/reactiveVar'
import useElementScrollLock from '../utils/useElementScrollLock'
import {
  ignoreScrollUpVar,
  showNavigationBarVar,
} from '../components/StickyNavigationBar'
import CloseButton from '../components/CloseButton'
import Button, {
  primaryOutlineButtonCss,
  whiteOutlineButtonCss,
} from '../components/Button'
import SidePanelTransition from '../components/SidePanelTransition'
import SidePanelFixed from '../components/SidePanelFixed'
import { showSectionIndicatorVar } from '../components/SectionIndicator'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'
import { heading2 } from '../themes/xplorer/text'

/**
 * This var represents whether the highlight video is "presenting" the feature
 * shown by its final frame.
 */
export const highlightVideoPresentingVar = makeVar(false)

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'hasActiveHighlight',
})<{
  hasActiveHighlight?: boolean
  backgroundOption?: 'solid' | 'gradient'
  backgroundColor1?: string
  backgroundColor2?: string
  gradientType?: 'to bottom' | 'to right'
}>(
  ({
    theme,
    backgroundOption,
    backgroundColor1,
    backgroundColor2,
    gradientType,
  }) => [
    css`
      position: relative;
      z-index: 1;
      padding: ${theme.spacing.x10}px 0;
      background: #f6f3f1; // matches the color of the video
      // Without this the modal does not display correctly on desktop (Safari)
      overflow: visible;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        min-height: auto;
      }
    `,

    isSiteYachtSupport(theme) &&
      css`
        background: linear-gradient(180deg, #eff0f0 0%, #dddddd 100%);
      `,

    backgroundOption === 'solid' &&
      css`
        background: ${backgroundColor1};
      `,

    backgroundOption === 'gradient' &&
      css`
        background: linear-gradient(
          ${gradientType},
          ${backgroundColor1} 0%,
          ${backgroundColor2} 100%
        );
      `,
  ],
)
const Title = styled('h2')(({ theme }) => [
  css`
    ${theme.text.heading2(theme)};
    font-size: ${theme.spacing.x7}px;
    margin: 0;
    transition: opacity 800ms ease-in-out;
    margin-left: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-left: ${theme.spacing.x20}px;
      margin-right: ${theme.spacing.x20}px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      ${heading2(theme)};
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading2(theme)};
      text-align: center;
    `,
])
const SubTitle = styled('h3')(({ theme }) => [
  css`
    ${theme.text.subHeadingWide(theme)};
    margin-top: 12px;
    transition: opacity 800ms ease-in-out;
    margin-left: ${theme.spacing.x2}px;
    margin-bottom: 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-left: ${theme.spacing.x20}px;
      margin-right: ${theme.spacing.x20}px;
    }
  `,
  (isSiteXplorer(theme) || isSiteYachtSupport(theme)) &&
    css`
      display: none;
    `,
])
const Positioner = styled.div(
  ({ theme }) => css`
    position: relative;
    z-index: -1;
    margin: 0 var(--mobile-margins, 0);

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-height: 100vh;
      overflow: hidden;
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin: 0;
      margin-top: -${theme.spacing.x15}px;
    }
  `,
)
const VisualContainer = styled.div(
  () => css`
    position: relative;
    padding-top: 56.25%; // 16:9

    @media (prefers-reduced-motion: no-preference) {
      opacity: 0;
      will-change: opacity;
      :not(.${hiddenClassName} &) {
        animation: ${keyframes`
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        `} 600ms ease-in-out forwards;
      }
    }
  `,
)
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const activeHighlightCss = (theme: Theme) => css`
  ${Title}, ${SubTitle} {
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      opacity: 0;
      // We need pointer-events:none so these elems don't overlap controls such
      // as the close button while hidden
      pointer-events: none;
    }
  }
`

const DesktopControls = styled.div(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const StyledCloseButton = styled(CloseButton, {
  shouldForwardProp: (prop) => prop !== 'showMoreInformation',
})(({ theme, showMoreInformation }) => [
  css`
    position: absolute;
    top: ${theme.spacing.x2}px;
    right: calc(var(--mobile-margins, 0px) * -1 + ${theme.spacing.x2}px);
    width: ${theme.spacing.x6}px;
    height: ${theme.spacing.x6}px;
    color: ${theme.colors.amels.hazySunsetOrange};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      top: ${theme.spacing.x4}px;
      right: ${theme.spacing.x4}px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      padding: 0;
      border-radius: 50%;
      color: ${theme.colors.xplorer.white};
      width: ${theme.spacing.x5}px;
      height: ${theme.spacing.x5}px;
      background-color: transparent;

      :hover {
        ::before {
          background-color: ${theme.colors.xplorer
            .secondaryDamenYachtingOrange};
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    !showMoreInformation &&
    css`
      width: ${theme.spacing.x5}px;
      height: ${theme.spacing.x5}px;
      background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};

      svg {
        display: flex;
        border: none;
        padding: 0;
        background-color: transparent;
        color: ${theme.colors.yachtSupport.white};
      }

      &:hover {
        ::before {
          background: ${theme.colors.yachtSupport.white};
        }

        svg {
          color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
        }
      }
    `,
])
const MoreInformationButton = styled(Button)<{
  asideVariant: 'dark' | 'light'
}>(({ theme, asideVariant }) => [
  isSiteAmels(theme) &&
    asideVariant === 'light' &&
    whiteOutlineButtonCss(theme),
  isSiteAmels(theme) &&
    asideVariant !== 'light' &&
    primaryOutlineButtonCss(theme),
  isSiteYachting(theme) &&
    css`
      border-color: ${theme.colors.yachting.orange};
      color: ${theme.colors.yachting.orange};

      // Default button has 5 px padding top/bottom
      padding: 5px ${theme.spacing.x4}px;
      &:hover {
        transition: all 0.2s ease-in-out;
        color: ${theme.colors.yachting.white};
        background-color: ${theme.colors.yachting.orange};
      }
    `,
  css`
    position: absolute;
    bottom: ${theme.spacing.x3}px;
    left: 50%;
    transform: translateX(-50%);
  `,
  isSiteXplorer(theme) &&
    css`
      width: fit-content;
      color: ${theme.colors.xplorer.white};
      background-color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};

      :hover:before {
        color: ${theme.colors.xplorer.white};
      }

      :hover:before {
        background-color: ${theme.colors.xplorer.primaryXPLavaBlack};
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      width: auto;
    `,
])
const AsideTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    line-height: 1.2;
    margin-top: 0;
    color: inherit;
  `,
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin-bottom: ${theme.spacing.x5}px;
      color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    `,
])

interface ImageInfo {
  src: string
  alt?: string
}

export interface Highlight extends HighlightButton {
  video: string
  asideTitle?: ReactNode
  asideText?: ReactNode
  asideVariant?: 'dark' | 'light'
}

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  subTitle: ReactNode
  image: ImageInfo
  buttons: Highlight[]
  mobileMargins?: number
  backgroundOption?: 'solid' | 'gradient'
  backgroundColor1?: string
  backgroundColor2?: string
}

const PortfolioHighlights = ({
  title,
  subTitle,
  image,
  buttons,
  mobileMargins = 0,
  backgroundOption,
  backgroundColor1,
  backgroundColor2,
  gradientType,
  ...others
}: Props) => {
  const [ref, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const containerRef = useRef<HTMLDivElement>(null)
  const highlightVisualRef = useRef<HTMLDivElement>(null)

  const [loadingHighlightProgress, setLoadingHighlightProgress] = useState<
    [Highlight, number] | undefined
  >()

  const [activeHighlight, setActiveHighlight] = useState<
    Highlight | undefined
  >()

  const openHighlight = async (locationBlock: Highlight) => {
    if (loadingHighlightProgress) {
      // Already loading a highlight
      return
    }

    try {
      await preloadVideo(getPreloadUrl(locationBlock.video), (progress) => {
        setLoadingHighlightProgress([locationBlock, progress])
      })
    } catch (err) {
      // TODO: Log to error logging tool (Sentry?)
      // eslint-disable-next-line no-console
      console.error('Failed to preload highlights video due to error:', err)
    } finally {
      setLoadingHighlightProgress(undefined)
    }

    setActiveHighlight(locationBlock)
  }

  const isMobile = useIsMobile()

  // Disable the body scroll while a highlight is active
  const scrollContainerRef = useDisableBodyScroll(activeHighlight !== undefined)
  // Maintain scroll position on screen resizes
  useElementScrollLock(
    highlightVisualRef,
    // Mobile scrolling is handled by the MobileInformation component
    !isMobile && activeHighlight !== undefined,
    2000,
    true,
  )

  // Disable the nav bar while a highlight is active
  useEffect(() => {
    if (!activeHighlight) {
      return
    }

    // Only hides it if it's currently active, it doesn't hide it permanently
    showNavigationBarVar(false)
    ignoreScrollUpVar(true)

    // eslint-disable-next-line consistent-return
    return () => {
      showNavigationBarVar(false)
      ignoreScrollUpVar(false)
    }
  }, [activeHighlight])
  // Hide the secion indicator while a highlight is active
  useEffect(() => {
    if (!activeHighlight) {
      return
    }

    showSectionIndicatorVar(false)

    // eslint-disable-next-line consistent-return
    return () => {
      showSectionIndicatorVar(true)
    }
  }, [activeHighlight])

  const [isClosing, setIsClosing] = useState(false)
  const handleFinishPlaying = useCallback(() => {
    if (isClosing) {
      setIsClosing(false)
      setActiveHighlight(undefined)
      return
    }

    highlightVideoPresentingVar(true)
  }, [isClosing])
  const handleClose = useCallback(() => {
    setIsClosing(true)
    highlightVideoPresentingVar(false)
  }, [])
  const isPresenting = useReactiveVar(highlightVideoPresentingVar)

  const [showMoreInformation, setShowMoreInformation] = useState(false)

  return (
    <Container
      data-testid="highlightsBlok"
      ref={mergeRefs(ref, containerRef, scrollContainerRef)}
      className={!inView ? hiddenClassName : undefined}
      css={activeHighlight ? activeHighlightCss : undefined}
      sectionIndicatorVariant="dark"
      style={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['--mobile-margins' as any]: `calc(${mobileMargins / 100} * 100vw)`,
      }}
      backgroundOption={backgroundOption}
      backgroundColor1={backgroundColor1}
      backgroundColor2={backgroundColor2}
      gradientType={gradientType}
      {...others}
    >
      <Title>
        <AnimateText delay={120}>{title}</AnimateText>
      </Title>
      <SubTitle>
        <AnimateText delay={240}>{subTitle}</AnimateText>
      </SubTitle>

      <Positioner ref={highlightVisualRef}>
        <VisualContainer>
          <PortfolioHighlightsImage<Highlight>
            image={image}
            buttons={buttons}
            onButtonClick={openHighlight}
            showButtons={!activeHighlight && inView}
            getLoadingProgress={(button) => {
              if (
                !loadingHighlightProgress ||
                button !== loadingHighlightProgress[0]
              ) {
                return undefined
              }

              return loadingHighlightProgress[1]
            }}
          />

          {activeHighlight && (
            <PortfolioHighlightsVideo
              video={activeHighlight.video}
              reverse={isClosing}
              onFinishPlaying={handleFinishPlaying}
            />
          )}
        </VisualContainer>

        {activeHighlight && isPresenting && (
          <>
            <StyledCloseButton
              onClick={handleClose}
              showMoreInformation={showMoreInformation}
              data-testid="highlightsBlok.close"
            />

            <DesktopControls>
              <MoreInformationButton
                onClick={() => setShowMoreInformation(true)}
                data-testid="highlightsBlok.moreInformation"
                asideVariant={activeHighlight.asideVariant || 'dark'}
              >
                More information
              </MoreInformationButton>

              <SidePanelTransition isOpen={showMoreInformation}>
                {showMoreInformation && (
                  <SidePanelFixed
                    close={() => setShowMoreInformation(false)}
                    variant="dark"
                  >
                    {activeHighlight.asideTitle && (
                      <AsideTitle data-testid="highlightsBlok.aside.title">
                        {activeHighlight.asideTitle}
                      </AsideTitle>
                    )}
                    {activeHighlight.asideText}
                  </SidePanelFixed>
                )}
              </SidePanelTransition>
            </DesktopControls>
          </>
        )}
      </Positioner>

      {isMobile && activeHighlight && (
        <PortfolioHighlightsMobileInformation
          title={activeHighlight.asideTitle}
          text={activeHighlight.asideText}
          containerRef={containerRef}
          imageContainerRef={highlightVisualRef}
        />
      )}

      <PortfolioHighlightsPreloading buttons={buttons} />
    </Container>
  )
}

export default PortfolioHighlights
