import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ComponentProps,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

import ScrollImageSequence from '../components/ScrollImageSequence'
import CloudinaryVideo from '../components/CloudinaryVideo'
import useIsClientSideRender from '../utils/useIsClientSideRender'
import AnimateText from '../components/AnimateText'
import ScrollDownIndicator from '../components/ScrollDownIndicator'
import Section from '../components/Section'
import EdgeBorderOverlay from '../components/EdgeBorderOverlay'
import {
  ignoreScrollUpVar,
  showNavigationBarVar,
} from '../components/StickyNavigationBar'
import useViewportSize from '../utils/useViewportSize'
import useIsAtTop from '../utils/useIsAtTop'

const Container = styled(Section)<{ ready?: boolean }>(({ ready }) => [
  css`
    overflow: visible; // needed for position sticky
  `,
  ready
    ? css`
        opacity: 0;
        will-change: opacity;
        animation: ${keyframes`
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        `} 800ms ease-in-out forwards;
      `
    : css`
        opacity: 0;
      `,
])
const VideoContainer = styled.div`
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
`
const StyledVideo = styled(CloudinaryVideo)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  will-change: opacity;
`
const TextOverlay = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disappear',
})<{ disappear?: boolean }>(({ disappear }) => [
  css`
    position: absolute;
    top: 15vh;
    left: 10%;
    z-index: 1;
    color: white;
    transition: top 1000ms linear;
  `,
  disappear &&
    css`
      top: -100vh;
    `,
])
const StyledScrollImageSequence = styled(ScrollImageSequence)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const Name = styled.h1`
  ${(props) => props.theme.text.heading1Light(props.theme)};
  line-height: 1;
`
const StyledScrollDownIndicator = styled(ScrollDownIndicator)`
  --animation-delay: 400ms;
`

const useVideoHeaderDisappearProgress = (
  containerRef: MutableRefObject<HTMLDivElement | null>,
  // How long the last frame remains static and should transition out
  staticLastFrameDuration = 1,
) => {
  const [progress, setProgress] = useState<number>(0)
  const viewportSize = useViewportSize()
  useEffect(() => {
    const updatePosition = () => {
      if (!containerRef.current || !viewportSize) {
        return
      }

      const { bottom } = containerRef.current.getBoundingClientRect()
      const disappearDistance = viewportSize.height * staticLastFrameDuration
      // The percentage of the viewport away from the bottom. If the bottom of
      // the screen is perfectly aligned with the bottom of the container, this
      // will be 1. If the bottom is at the top of the screen this will be 0.
      const percentFromEnd = Math.min(
        1,
        Math.max(0, (bottom - viewportSize.height) / disappearDistance),
      )
      const progress = 1 - percentFromEnd
      setProgress(progress)
    }
    updatePosition()
    document.addEventListener('scroll', updatePosition, {
      passive: true,
    })
    return () => document.removeEventListener('scroll', updatePosition)
  }, [containerRef, staticLastFrameDuration, viewportSize])

  return progress
}
const useBodyScrollLockEffect = (ended: boolean) => {
  const [isAlreadyScrolledDown, setIsAlreadyScrolledDown] = useState<
    boolean | undefined
  >(undefined)
  useEffect(() => {
    // When the user reloads the page or goes forward/back, their scroll
    // position gets restored to where they left off. It takes a fraction of a
    // second to apply though, hence the delay.
    const timer = setTimeout(() => {
      setIsAlreadyScrolledDown(window.scrollY > window.innerHeight)
    }, 300)
    return () => clearTimeout(timer)
  }, [])
  useEffect(() => {
    if (ended) {
      return undefined
    }
    if (isAlreadyScrolledDown === undefined || isAlreadyScrolledDown === true) {
      return undefined
    }

    window.scrollTo(0, 0)
    const elem = document.createElement('div')
    disableBodyScroll(elem, {
      reserveScrollBarGap: true,
    })
    return () => enableBodyScroll(elem)
  }, [ended, isAlreadyScrolledDown])
}
const useIsVideoReady = (video: HTMLVideoElement | undefined) => {
  const [isReady, setIsReady] = useState(false)
  useEffect(() => {
    if (!video) {
      return
    }

    // We can't use autoPlay or the browser will download the entire video
    // that's on the page during SSG, even if it were hidden with a media-query.
    // The only way to control which video to play is through JS.
    // This does mean the video can not start playing before the bundle is
    // loaded in. From experiments with the "Slow 3G" network speed preset, this
    // only took 1.5 seconds. If this turns out to be an issue in practice, an
    // alternative solution would be to use an inline script tag to start the
    // correct video, as that's executed immediately after parsing.
    const play = () => {
      const promise = video.play()

      if (promise) {
        promise
          .then(() => {
            // eslint-disable-next-line no-console
            console.log('Video is ready')
            setIsReady(true)
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.warn(err)
          })
      } else {
        setIsReady(true)
      }
    }

    const VIDEO_READY_STATE_CAN_PLAY_THROUGH = 4
    if (video.readyState >= VIDEO_READY_STATE_CAN_PLAY_THROUGH) {
      // eslint-disable-next-line no-console
      console.log('Video can already play through')
      play()
    } else {
      // eslint-disable-next-line no-console
      console.log('Waiting for video to get ready', video.readyState)
      let timer: ReturnType<typeof setTimeout>
      const handleCanPlayThrough = () => {
        // eslint-disable-next-line no-console
        console.log('Video can play through')
        play()
        clearTimeout(timer)
      }
      video.addEventListener('canplaythrough', handleCanPlayThrough)
      timer = setTimeout(() => {
        // eslint-disable-next-line no-console
        console.log(
          'Timeout expired as video can play through never fired, forcing play',
        )
        play()
        video.removeEventListener('canplaythrough', handleCanPlayThrough)
      }, 5000)
      // This is required for Safari on iOS to actually start loading the video
      video.load()
      // eslint-disable-next-line consistent-return
      return () => {
        video.removeEventListener('canplaythrough', handleCanPlayThrough)
      }
    }
  }, [video])

  // In case loading breaks, have a max waiting time
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true)
    }, 10000)
    return () => clearTimeout(timer)
  }, [])

  return isReady
}
const useIsVideoEnded = (video: HTMLVideoElement | undefined) => {
  const [isEnded, setIsEnded] = useState(false)
  useEffect(() => {
    if (!video) {
      return
    }

    if (video.ended) {
      setIsEnded(true)
    } else {
      const handleEnded = () => {
        setIsEnded(true)
      }
      video.addEventListener('ended', handleEnded)
      // eslint-disable-next-line consistent-return
      return () => {
        video.removeEventListener('ended', handleEnded)
      }
    }
  }, [video])

  // Fallback in case the intro video fails to play or finish. Otherwise the
  // scroll would be blocked permanently, making the page unusable.
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsEnded(true)
    }, 10000)
    return () => clearTimeout(timer)
  }, [])

  return isEnded
}

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant'
  > {
  mainVideo: string
  mobileMainVideo: string
  scrollImageSequence?: string[]
  // Scroll pixels per frame
  scrollVelocity?: number
  text: string
}

const PortfolioHeader = ({
  mainVideo,
  mobileMainVideo,
  scrollImageSequence,
  scrollVelocity = 22,
  text,
  ...others
}: Props) => {
  const isClientSideRender = useIsClientSideRender()

  const containerRef = useRef<HTMLDivElement>(null)
  const staticLastFrameDuration = (scrollVelocity / 22) * 0.5 // percentage of viewport height
  const disappearProgress = useVideoHeaderDisappearProgress(
    containerRef,
    staticLastFrameDuration,
  )
  const isAtTop = useIsAtTop()
  // Disable the nav bar while in the image sequence
  useEffect(() => {
    const isActive = disappearProgress !== 1

    if (isActive) {
      showNavigationBarVar(false)
      ignoreScrollUpVar(true)
      return () => {
        ignoreScrollUpVar(false)
      }
    }
    return undefined
  }, [disappearProgress])
  // Re-enable it when absolutely at the top
  useEffect(() => {
    if (isAtTop) {
      showNavigationBarVar(true)
    } else {
      showNavigationBarVar(false)
    }
  }, [isAtTop])

  const frameCount = scrollImageSequence?.length

  // A reference to the video element. This is only set once the video element
  // is final so it is safe to attach event listeners.
  const [video, setVideo] = useState<HTMLVideoElement | undefined>()

  const isReady = useIsVideoReady(video)
  const isEnded = useIsVideoEnded(video)

  useBodyScrollLockEffect(isEnded)

  const scrollAreaHeight = frameCount
    ? `calc(100vh + ${Math.floor(
        frameCount * scrollVelocity,
      )}px + ${staticLastFrameDuration} * 100vh)`
    : undefined

  const viewportSize = useViewportSize()
  const isPortrait = viewportSize && viewportSize.height > viewportSize.width

  return (
    <Container
      style={{
        height: scrollAreaHeight,
      }}
      data-testid="portfolioHeader"
      data-disappear-progress={disappearProgress}
      ref={containerRef}
      as="header"
      id="top"
      sectionIndicatorVariant="none"
      sectionIndicatorAriaVisible
      ready={isReady}
      {...others}
    >
      <VideoContainer>
        {isPortrait ? (
          <StyledVideo
            // Without keys, the video element would be reused by the browser
            // which runs into a browser bug where the old sources are used once
            // the video plays. With the key, the element is new if the
            // landscape video is replaced with the portrait version.
            key="portrait"
            publicId={mobileMainVideo}
            // Needed for autoPlay to work in modern browsers
            muted
            // Needed for Safari to auto play
            playsInline
            // Beware: load-events may not work properly! The video element is
            // part of the SSG code, so the video is likely to be loaded (and
            // even start playing) before the React app is started and event
            // handlers can be added. If you need to know is the video is
            // loaded, use a ref and check the ready state.
            data-testid="portfolioHeader.video"
            data-orientation="portrait"
            data-ended={isEnded}
            // Hide Edge intrusive PIP button
            disablePictureInPicture
            // We can preload since if isPortrait is set, we are sure to know we
            // are in portrait mode (and it won't change unless the user rotates)
            preload="auto"
            ref={(elem) => setVideo(elem || undefined)}
            transformations={['q_80']}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore It's an experimental attribute
            fetchpriority="high"
          />
        ) : (
          <StyledVideo
            key="landscape"
            publicId={mainVideo}
            // Needed for autoPlay to work in modern browsers
            muted
            // Needed for Safari to auto play
            playsInline
            // Beware: load-events may not work properly! The video element is
            // part of the SSG code, so the video is likely to be loaded (and
            // even start playing) before the React app is started and event
            // handlers can be added. If you need to know is the video is
            // loaded, use a ref and check the ready state.
            data-testid="portfolioHeader.video"
            data-orientation="landscape"
            data-ended={isEnded}
            // Hide Edge intrusive PIP button
            disablePictureInPicture
            // Do not preload since we need to wait for the bundle to load to
            // determine if we need to play this video or the landscape version
            preload={isPortrait === undefined ? 'none' : 'auto'}
            ref={
              isPortrait !== undefined
                ? (elem) => setVideo(elem || undefined)
                : undefined
            }
            transformations={['q_80']}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore It's an experimental attribute
            fetchpriority="high"
          />
        )}

        {scrollImageSequence && isClientSideRender && (
          <StyledScrollImageSequence
            sequence={scrollImageSequence}
            scrollVelocity={scrollVelocity}
            ready={isReady}
            showFirstFrame={isReady && isEnded}
            data-testid="portfolioHeader.frame"
          />
        )}

        <EdgeBorderOverlay progress={disappearProgress} />
      </VideoContainer>

      <TextOverlay
        data-testid="portfolioHeader.textOverlay"
        disappear={!isAtTop}
        className={!isEnded ? 'hidden' : undefined}
      >
        <Name>
          {text.split('\n').map((line, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <AnimateText key={index} delay={index * 120}>
              {line}
            </AnimateText>
          ))}
        </Name>
        <StyledScrollDownIndicator>Scroll down</StyledScrollDownIndicator>
      </TextOverlay>
    </Container>
  )
}

export default PortfolioHeader
