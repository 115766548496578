import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { isSiteXplorer, isSiteYachtSupport } from '../themes'

const Title = styled('h2', {
  shouldForwardProp: (prop) => prop !== 'colorScheme',
})<{
  colorScheme?: 'light' | 'dark'
}>(({ theme, colorScheme }) => [
  css`
    ${theme.text.heading2(theme)}
    margin: ${theme.spacing.x5}px 0 ${theme.spacing.x3}px;
  `,
  isSiteXplorer(theme) &&
    css`
      color: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.black};
    `,
  isSiteYachtSupport(theme) &&
    css`
      text-align: center;
      color: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.black};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 16px;
      }
    `,
])
const Text = styled('div', {
  shouldForwardProp: (prop) => prop !== 'colorScheme',
})<{
  colorScheme?: 'light' | 'dark'
}>(({ theme, colorScheme }) => [
  css`
    ${theme.text.body(theme)}
    margin-top: ${theme.spacing.x3}px;
    color: ${theme.colors.amels.deepBayAqua};
  `,
  isSiteXplorer(theme) &&
    css`
      color: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.black};
    `,
  isSiteYachtSupport(theme) &&
    css`
      text-align: center;
      color: ${colorScheme === 'dark'
        ? theme.colors.yachtSupport.white
        : theme.colors.yachtSupport.black};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 16px;
      }
    `,
])

interface Props {
  title: ReactNode
  text: ReactNode
  colorScheme?: 'light' | 'dark'
}

const FormResult = ({ title, text, colorScheme }: Props) => (
  <>
    <Title colorScheme={colorScheme}>{title}</Title>
    <Text colorScheme={colorScheme}>{text}</Text>
  </>
)

export default FormResult
