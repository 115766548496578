/* eslint-disable security/detect-object-injection */
/* eslint-disable no-param-reassign */
import {
  CanvasHTMLAttributes,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import getImageSequenceLoadOrder from '../utils/getImageSequenceLoadOrder'

import preloadImage from '../utils/preloadImage'
import { makeVar } from '../utils/reactiveVar'

const MAX_SIMULTANEOUSLY_LOADING_IMAGES = 5
/**
 * When true, the scroll image sequence is loading. This will be set to false
 * when the scroll image sequence stops loading new frames. This can occur
 * because ALL frames have been loaded (unlikely), or because the user scrolled
 * down beyond the image sequence.
 *
 * Note this will only be set to false once. If the user then scrolls up again
 * to load more frames, it will not be set back to true.
 */
export const isScrollImageSequenceLoadingCompleteVar = makeVar(true)

/**
 * This (pre)loads the images that will be displayed in the image sequence. It
 * tries to load images in a smart way so that the image sequence works on all
 * possible bandwidths. It achieves this by loading a low FPS count initially,
 * and, as more time is available, it loads additional frames to incrementally
 * improve the FPS. This way the user gets the best possible FPS for their
 * connection speed.
 * See `getImageSequenceLoadOrder` and its tests to see in what order images are
 * loaded.
 * For optimal loading, several images are loaded simultaneously. This can be
 * configured with the MAX_SIMULTANEOUSLY_LOADING_IMAGES constant.
 */
const useLoadImages = (
  urls: string[],
  visibleFrameNo: number,
  active = true,
) => {
  const [images, setImages] = useState<Array<HTMLImageElement | undefined>>(
    urls.map(() => undefined),
  )
  const loadOrder = useMemo(
    () =>
      getImageSequenceLoadOrder(urls.length, MAX_SIMULTANEOUSLY_LOADING_IMAGES),
    [urls],
  )
  /**
   * An array of frameNos that are in the process of being loaded or have
   * already been loaded. This is different from images, which only includes
   * images that have been loaded.
   * In addition this allows us to use a ref, which avoids updates of the
   * image loader useEffect each time an image finishes loading.
   */
  const loadingFrames = useRef<number[]>([])
  // When urls change we need to load different images
  useEffect(() => {
    setImages(urls.map(() => undefined))
    loadingFrames.current = []
  }, [urls])
  const visibleFrameNoRef = useRef(visibleFrameNo)
  useEffect(() => {
    visibleFrameNoRef.current = visibleFrameNo
  }, [visibleFrameNo])

  const loadOrderIndex = useRef(0)
  useEffect(() => {
    loadOrderIndex.current = 0
  }, [visibleFrameNo])
  const getNextFrame = useCallback(() => {
    let frame: number
    do {
      frame = loadOrder[loadOrderIndex.current]
      if (frame === undefined) {
        // No more frames to load - all done!
        return undefined
      }

      loadOrderIndex.current += 1
    } while (
      // Skip images we are already loading
      loadingFrames.current.includes(frame) ||
      // Don't load frames we already passed
      frame < visibleFrameNoRef.current
    )
    return frame
  }, [loadOrder, visibleFrameNoRef])
  const loadFrame = useCallback(
    async (frame: number) => {
      loadingFrames.current.push(frame)

      const image = await preloadImage(urls[frame])

      setImages((images) => {
        const newImages = [...images]
        newImages[frame] = image
        return newImages
      })
    },
    [urls],
  )

  const processes = useRef<unknown[]>([])
  // console.log('Active processes:', processes.current.length)
  /**
   * This starts multiple simultaneous frame loading "processes" that continue
   * until there's nothing left to load and are then removed.
   * If the user scrolls down, any frames before their current point (defined by
   * visibleFrameNoRef) will not be loaded since they likely won't be shown,
   * giving us more bandwidth to fetch more future frames.
   * If the user scrolls back up, those older frames may still be loaded. This
   * effect also takes care of restarting the processes when the user scrolls
   * up.
   */
  useEffect(() => {
    if (!active) {
      return
    }

    const recursivelyLoadNextFrame = async () => {
      const frame = getNextFrame()
      if (frame === undefined) {
        // Nothing left to load so we're done.
        return
      }

      // console.log('Loading frame', frame)
      await loadFrame(frame)

      await recursivelyLoadNextFrame()
    }
    const startRecursiveProcess = (recursiveProcess: () => Promise<void>) => {
      const executor = recursiveProcess()
      processes.current.push(executor)
      // Since the process is recursive, its promise lasts as long as the
      // process needs, so a simple then will be sufficient to detect the
      // process ending.
      executor.then(() => {
        // console.log('Process done.')
        processes.current = processes.current.filter(
          (process) => process !== executor,
        )
        if (processes.current.length === 0) {
          isScrollImageSequenceLoadingCompleteVar(false)
        }
      })
    }

    const numMissingProcesses =
      MAX_SIMULTANEOUSLY_LOADING_IMAGES - processes.current.length
    for (let i = 0; i < numMissingProcesses; i += 1) {
      startRecursiveProcess(recursivelyLoadNextFrame)
    }
    // visibleFrameNo is required to start the processes when scrolling up and
    // frames may be missing
  }, [visibleFrameNo, getNextFrame, loadFrame, processes, active])

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'Loading image sequence...',
      loadOrder.length,
      'frames,',
      MAX_SIMULTANEOUSLY_LOADING_IMAGES,
      'frames loading simultaneously. Frame load order:',
      loadOrder,
    )
  }, [loadOrder])

  useEffect(() => {
    const numLoaded = images.reduce(
      (sum, image) => sum + (image !== undefined ? 1 : 0),
      0,
    )
    if (numLoaded % 5 !== 0) {
      return
    }
    const numFrames = images.length
    // eslint-disable-next-line no-console
    console.log(
      'Image sequence loading progress',
      `${numLoaded}/${numFrames} frames -`,
      `${Math.floor((numLoaded / numFrames) * 100)}%`,
      `(${((numLoaded / numFrames) * 25).toFixed(1)} FPS)`,
    )
  }, [images])

  return images
}
const useAutoSizeCanvas = (
  canvas: MutableRefObject<HTMLCanvasElement | null>,
  width: number | undefined,
  height: number | undefined,
) => {
  useEffect(() => {
    if (!canvas.current || !width || !height) {
      return
    }

    canvas.current.width = width
    canvas.current.height = height
  }, [canvas, width, height])
}
const getFrameByScrollPos = (scrollVelocity: number) =>
  typeof document !== 'undefined'
    ? Math.floor(document.documentElement.scrollTop / scrollVelocity)
    : 0
const useFrameNoByScrollPos = (
  scrollVelocity: number,
  frameCount: number,
  showFirstFrame = false,
) => {
  const offset = showFirstFrame ? 0 : 1
  const getFrame = useCallback(() => {
    // We use frame 0 to hide the image sequence, so we need 1 extra frame to
    // be able to show all frames
    const frameByScrollPos = getFrameByScrollPos(scrollVelocity) + offset
    const maxFrameIndex = frameCount - 1 + offset

    const frameNo =
      Math.max(0, Math.min(maxFrameIndex, frameByScrollPos)) - offset

    if (frameNo < offset) {
      return undefined
    }

    return frameNo
  }, [scrollVelocity, frameCount, offset])

  const [frame, setFrame] = useState(getFrame)
  useEffect(() => {
    setFrame(getFrame)
    const updateFrame = () => setFrame(getFrame)

    document.addEventListener('scroll', updateFrame, { passive: true })
    return () => document.removeEventListener('scroll', updateFrame)
  }, [getFrame])

  return frame
}
const useRenderFrame = (
  canvas: MutableRefObject<HTMLCanvasElement | null>,
  frame: HTMLImageElement | undefined,
) => {
  const pendingFrame = useRef<typeof frame>()
  useEffect(() => {
    pendingFrame.current = frame
  }, [frame])

  // Hide the canvas when there's no frame to show (e.g. when at the top of the
  // page).
  useEffect(() => {
    if (!canvas.current) {
      return
    }
    if (!frame) {
      canvas.current.style.display = 'none'
    } else {
      canvas.current.style.display = 'block'
    }
  }, [canvas, frame])

  const pendingAnimationFrame =
    useRef<ReturnType<typeof requestAnimationFrame>>()
  useEffect(() => {
    if (pendingAnimationFrame.current) {
      // When we already have a pending frame, wait for that
      // Since the animation frame handler uses pendingFrame, we will always
      // render the most recently requested frame.
      return
    }

    pendingAnimationFrame.current = requestAnimationFrame(() => {
      pendingAnimationFrame.current = undefined
      if (!canvas.current) {
        return
      }
      const context = canvas.current.getContext('2d')
      if (!context) {
        return
      }
      if (!pendingFrame.current) {
        return
      }
      context.drawImage(pendingFrame.current, 0, 0)
    })
  }, [canvas, frame])
}

const getFrame = (
  images: Array<HTMLImageElement | undefined>,
  frameNo: number,
) => {
  // Get the last loaded frame
  for (let i = frameNo; i >= 0; i -= 1) {
    if (images[i]) {
      // console.log('Showing frame', i, 'requested', frameNo)
      return images[i]
    }
  }

  return undefined
}

interface Props extends CanvasHTMLAttributes<HTMLCanvasElement> {
  sequence: string[]
  scrollVelocity: number
  ready?: boolean
  showFirstFrame?: boolean
}

const ScrollImageSequence = ({
  sequence,
  scrollVelocity,
  ready,
  showFirstFrame,
  ...others
}: Props) => {
  const frameNo = useFrameNoByScrollPos(
    scrollVelocity,
    sequence.length,
    showFirstFrame,
  )

  const images = useLoadImages(sequence, frameNo || 0, ready)
  const canvas = useRef<HTMLCanvasElement | null>(null)
  const firstImage = images.find((image) => image !== undefined)
  useAutoSizeCanvas(canvas, firstImage?.width, firstImage?.height)

  const frame = frameNo !== undefined ? getFrame(images, frameNo) : undefined
  useRenderFrame(canvas, frame)

  return <canvas ref={canvas} data-frame-no={frameNo} {...others} />
}

export default ScrollImageSequence
