import {
  ContactStoryblok,
  ContactReferenceStoryblok,
} from '../bloks/storyblok.generated'
import { getRequiredImageFallback, mapImage } from './mapImage'
import { StoryblokStory } from './storyblok'
import storyblokLinkResolver from './storyblokLinkResolver'

const normaliseContactStorybloks = (
  contactStorybloks: (ContactStoryblok | ContactReferenceStoryblok)[],
  rels: StoryblokStory[],
) =>
  contactStorybloks.flatMap((block) => {
    const contact =
      block.component === 'contact'
        ? block
        : rels.find(
            (rel): rel is StoryblokStory<ContactStoryblok> =>
              rel.uuid === block.contact,
          )?.content ?? null

    return contact
      ? {
          key: contact._uid,
          name: contact.name,
          photo: mapImage(contact.photo) ?? getRequiredImageFallback(),
          jobTitle: contact.jobTitle,
          contactOptions: contact.contactOption?.map((option) => ({
            key: option._uid,
            type: option.type,
            link: option.link && {
              href: storyblokLinkResolver(option.link),
            },
            value: option.value,
          })),
        }
      : []
  })

export default normaliseContactStorybloks
