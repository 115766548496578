import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import AnimateText, { hiddenClassName } from './AnimateText'

const Container = styled.div`
  display: inline-flex;
  flex-flow: column;
  align-items: center;
  --animation-delay: 0ms;
`
const Line = styled.div`
  position: relative;
  width: 2px;
  height: 50px;

  @media (prefers-reduced-motion: no-preference) {
    transform-origin: top;
    transform: scaleY(0);
    :not(.${hiddenClassName} &) {
      animation: ${keyframes`
        0% {
          transform: scaleY(0);
        }
        16%, 100% {
          transform: scaleY(1);
        }
      `} 5000ms ease-in-out infinite;
      animation-delay: var(--animation-delay, 0ms);
    }
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    opacity: 0.4;
  }
`
const ScrollPosition = styled.div`
  height: 20px;
  background: currentColor;
  transform: translateY(30px);
`
const Dot = styled.div`
  background: currentColor;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-top: 4px;
  @media (prefers-reduced-motion: no-preference) {
    opacity: 0;
    will-change: opacity;
    :not(.${hiddenClassName} &) {
      animation: ${keyframes`
        0%, 8% {
          opacity: 0;
        }
        16%, 100% {
          opacity: 1;
        }
      `} 5000ms ease-in-out infinite;
      animation-delay: var(--animation-delay, 0ms);
    }
  }
`
const Text = styled.div`
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: currentColor;
  margin-top: 8px;
`

interface Props extends ComponentProps<typeof Container> {
  children: ReactNode
}

const ScrollDownIndicator = ({ children, ...others }: Props) => (
  <Container {...others}>
    <Line>
      <ScrollPosition />
    </Line>
    <Dot />

    <Text>
      <AnimateText direction="up">{children}</AnimateText>
    </Text>
  </Container>
)

export default ScrollDownIndicator
