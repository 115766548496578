import { css, Global } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useEffect, useRef, useState } from 'react'

import useElementHeight from '../utils/useElementHeight'
import Image from '../components/Image'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Button, {
  invisibleButtonCss,
  whiteOutlineButtonCss,
} from '../components/Button'
import CarouselPager from '../components/CarouselPager'
import ScrollDownIndicator from '../components/ScrollDownIndicator'
import SidePanelTransition from '../components/SidePanelTransition'
import SidePanelFixed from '../components/SidePanelFixed'
import Section from '../components/Section'
import { blockNavigationBarVar } from '../components/StickyNavigationBar'
import NavigationBar from '../components/NavigationBar/NavigationBar'
import { getMenuItems } from '../bloks/NavigationBarBlok'
import { usePageContext } from '../bloks/PageContext'

const CarouselContainer = styled(Section)`
  position: relative;
  width: 100%;
  overflow: visible; // needed for position sticky
`
const PagerContainer = styled.div(
  () => css`
    position: absolute;
    width: 100%;
    height: 100%;
  `,
)
const StyledPager = styled(CarouselPager)(
  ({ theme }) => css`
    position: sticky;
    top: 24.3vh;
    left: ${theme.spacing.x4}px;
    &.vertical {
      top: 18.2vh;
      height: 63.6vh;
      margin-bottom: 18.2vh;
    }
    z-index: 1;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      left: 2.8%;
      height: 51.3vh;
    }
  `,
)
const Slide = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  line-height: 1.75;
  scroll-snap-align: start;
`
const Content = styled.div(
  ({ theme }) => css`
    position: absolute;
    z-index: 1;
    top: 28.4%;
    left: ${theme.spacing.x7}px;
    right: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      top: 27.8%;
      left: 10.9%;
      right: auto;
      max-width: 580px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    color: ${theme.colors.amels.silk};
    letter-spacing: 0;
    margin: 0;
    margin-bottom: ${theme.spacing.x2}px;
    line-height: normal;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      line-height: 0.925;
    }
  `,
)
const CallToAction = styled(Button)(({ theme }) => whiteOutlineButtonCss(theme))
const ScrollDownIndicatorContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x6}px;
    margin-left: 24px; // align it in the middle below the CTA button
    color: ${theme.colors.amels.silk};
  `,
)
const ScrollDownIndicatorButton = styled(Button)([
  invisibleButtonCss,
  css`
    // Buttons aren't supposed to have a pointer cursor from accessibility best
    // practices, but the layout of this item does not suggest at all that it
    // would otherwise be clickable.
    cursor: pointer;
  `,
])
const SidePanelTitle = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.amels.clearBayAqua};
    margin: ${theme.spacing.x10}px 0 ${theme.spacing.x6}px;
  `,
)
const SidePanelText = styled.div`
  font-weight: 300;
`

interface Props
  extends Omit<
    ComponentProps<typeof CarouselContainer>,
    'children' | 'sectionIndicatorVariant'
  > {
  slides: Array<{
    id: string
    image: {
      src: string
      alt?: string
    }
    title?: string
    callToActionText: ReactNode
    sidePanel?: {
      title: ReactNode
      text: ReactNode
    }
  }>
  speed?: number
}

const PageCarousel = ({ slides, speed = 1000, ...others }: Props) => {
  const numSlides = slides.length
  const [slide, setSlide] = useState(0)
  const [activeSlide, setActiveSlide] = useState(0)

  // Track slide height since on iOS 100vh does not equal window.innerHeight or
  // any alternatives when the address bar is expanded
  const slideRef = useRef<HTMLDivElement>(null)
  const slideHeight = useElementHeight(slideRef)

  useEffect(() => {
    const handleScroll = () => {
      if (!slideHeight) {
        return
      }

      const slide = Math.min(
        slides.length - 1,
        Math.round(window.scrollY / slideHeight),
      )
      setSlide(slide)
      setActiveSlide(slide)
    }

    window.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [slideHeight, slides])
  const handleScrollIndicatorClick = (slide: number) => () => {
    window.scrollTo(0, slide * slideHeight)
  }

  const [sidePanelSlide, setSidePanelSlide] = useState<number | undefined>(
    undefined,
  )
  const sidePanel =
    // eslint-disable-next-line security/detect-object-injection
    sidePanelSlide !== undefined && slides[sidePanelSlide].sidePanel

  const { siteStory } = usePageContext()
  useEffect(() => {
    blockNavigationBarVar((value) => value + 1)
    return () => {
      blockNavigationBarVar((value) => Math.max(0, value - 1))
    }
  }, [])

  return (
    <>
      {/**
       * The entire page needs to have scroll snap behavior since this is
       * intended as a page carousel. We also want to make the footer
       * accessable. It's not a pretty solution, but it works damn well.
       */}
      <Global
        styles={css`
          html {
            scroll-behavior: smooth;
            scroll-snap-type: y mandatory;
          }
          footer {
            scroll-snap-align: start;
            // Firefox has a bug where using "scroll-snap-align: start;" would
            // make the browser scroll up whenever you click on the footer if
            // the footer takes less than 50% of the window height. While
            // setting scroll-snap-align to "center" would fix that situation,
            // it makes the footer really awkward to use when it is larger than
            // the screen height, such as on mobile. "start" is essential for
            // mobile.
            // This workaround ensures the footer is big enough to not trigger
            // the bug in Firefox. It also matches the height of the other
            // slides, making it fit better in the carousel. It's only slightly
            // kind of ugly.
            min-height: 100vh;
            display: flex;
            flex-flow: column;
            justify-content: center;
          }
        `}
      />

      <CarouselContainer
        data-testid="pageCarousel"
        sectionIndicatorVariant="none"
        as="main"
        {...others}
      >
        <PagerContainer>
          <StyledPager
            numSlides={numSlides}
            activeSlide={slide}
            vertical
            onClickPage={(slide) => handleScrollIndicatorClick(slide)()}
            speed={speed}
          />
        </PagerContainer>

        {slides.map(
          ({ id, image, title, callToActionText, sidePanel }, index) => {
            const titleLines = title ? title.split('\n') : []

            return (
              <Slide
                key={id}
                data-testid={`pageCarousel.slide.${index}`}
                data-active={index === activeSlide}
                className={index !== activeSlide ? hiddenClassName : undefined}
                ref={index === 0 ? slideRef : undefined}
              >
                {siteStory && (
                  <NavigationBar
                    menuItems={getMenuItems(siteStory.story.content)}
                    variant="light"
                    aria-hidden={index !== 0 ? true : undefined}
                    fullLogo={index === 0}
                  />
                )}

                <Image
                  src={image.src}
                  alt={image.alt}
                  style={{ objectFit: 'cover' }}
                  fill
                  priority={index === 0}
                  loading={index !== 0 ? 'eager' : undefined}
                />

                <Content>
                  {title && (
                    <Title>
                      {titleLines.map((line, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <AnimateText key={index} delay={index * 120}>
                          {line}
                        </AnimateText>
                      ))}
                    </Title>
                  )}

                  {callToActionText && sidePanel && (
                    <AnimateText delay={titleLines.length * 120 + 120}>
                      <CallToAction
                        onClick={() => setSidePanelSlide(index)}
                        data-testid="pageCarousel.callToAction"
                      >
                        {callToActionText}
                      </CallToAction>
                    </AnimateText>
                  )}

                  {/** Designers want this on every slide ._. */}
                  <ScrollDownIndicatorContainer>
                    <ScrollDownIndicatorButton
                      onClick={handleScrollIndicatorClick(index + 1)}
                      data-testid="pageCarousel.scrollDown"
                    >
                      <ScrollDownIndicator
                        style={{
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          ['--animation-delay' as any]: `${
                            titleLines.length * 120 + 240
                          }ms`,
                        }}
                      >
                        Scroll down
                      </ScrollDownIndicator>
                    </ScrollDownIndicatorButton>
                  </ScrollDownIndicatorContainer>
                </Content>
              </Slide>
            )
          },
        )}
      </CarouselContainer>

      <SidePanelTransition isOpen={Boolean(sidePanel)}>
        {sidePanel && (
          <SidePanelFixed
            close={() => setSidePanelSlide(undefined)}
            data-testid="pageCarousel.sidePanel"
          >
            <AnimateText delay={300}>
              <SidePanelTitle>{sidePanel.title}</SidePanelTitle>
            </AnimateText>

            <AnimateText delay={700}>
              <SidePanelText>{sidePanel.text}</SidePanelText>
            </AnimateText>
          </SidePanelFixed>
        )}
      </SidePanelTransition>
    </>
  )
}

export default PageCarousel
