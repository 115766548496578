import { ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import ImageWithHeadingYachtSupport from './ImageWithHeading.yachtsupport'

export interface ImageWithHeadingProps {
  heading: ReactNode
  overline?: string
  backgroundImage: { src: string; alt?: string }
}
const ImageWithHeading = (props: ImageWithHeadingProps) => {
  switch (currentSite) {
    case Site.YachtSupport:
      return <ImageWithHeadingYachtSupport {...props} />
    default:
      return <ImageWithHeadingYachtSupport {...props} />
  }
}
export default ImageWithHeading
