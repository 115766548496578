import { SubHeaderStoryblok } from '../storyblok.generated'
import SubHeader from '../../patterns/SubHeader'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: SubHeaderStoryblok
}

const SubHeaderBlok = ({
  content: { backgroundImage, overlayImage, heading, subHeadingText },
  ...others
}: Props) => {
  return (
    <SubHeader
      heading={heading}
      subHeadingText={subHeadingText}
      backgroundImage={mapImage(backgroundImage)}
      overlayImage={mapImage(overlayImage)}
      {...others}
    />
  )
}

export default SubHeaderBlok
