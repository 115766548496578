import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { TransitionGroup, Transition } from 'react-transition-group'
import { EXITED, EXITING } from 'react-transition-group/Transition'

import squarePolygon from '../utils/squarePolygon'
import useDimensions from '../utils/useDimensions'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Backdrop from '../components/Backdrop'
import IconButton from '../components/IconButton'
import ChevronRight from '../icons/ChevronRight.svg'
import Portal from '../components/Portal'
import DesignTextWithExpandableImageModal, {
  ImageContainer,
} from './DesignTextWithExpandableImageModal'
import useIsMobile from '../utils/useIsMobile'
import Image from '../components/Image'
import Section from '../components/Section'

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'imageLocation',
})<{ imageLocation: 'left' | 'right' }>(
  ({ theme, imageLocation }) => css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px;
      display: flex;
      flex-direction: ${imageLocation === 'left' ? 'row-reverse' : 'row'};
      gap: ${theme.spacing.x6}px;
      justify-content: center;
      margin: ${theme.spacing.x10}px 0;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x4}px;
      gap: ${theme.spacing.x15}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x6}px;
      gap: ${theme.spacing.x20}px;
    }
  `,
)
const ContentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 1 1 50%;
      padding: ${theme.spacing.x7}px 0;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x14}px 0;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.amels.beach};
    letter-spacing: 0;
    margin-top: ${theme.spacing.x2}px;
    margin-bottom: ${theme.spacing.x7}px;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      margin-left: ${theme.spacing.x14}px;
    }
  `,
)
const SubTitle = styled.div(
  ({ theme }) => css`
    ${theme.text.subHeading(theme)};

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      margin-left: ${theme.spacing.x14}px;
    }
  `,
)
const Text = styled.div(
  ({ theme }) =>
    css`
      margin-bottom: ${theme.spacing.x6}px;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        margin-left: ${theme.spacing.x14}px;
      }
    `,
)
const StyledButton = styled(IconButton)(
  ({ theme }) =>
    css`
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        margin-left: ${theme.spacing.x14}px;
      }
    `,
)
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`
const MobileImageContainer = styled.div(
  ({ theme }) =>
    css`
      display: block;
      margin-bottom: ${theme.spacing.x7}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: none;
      }

      @media (prefers-reduced-motion: no-preference) {
        > * {
          transform: scale(1.2);
          transform-origin: top right;
        }

        :not(.${hiddenClassName} &) {
          clip-path: ${squarePolygon(0, 0, 0, 1)};
          animation: ${keyframes`
            from {
              clip-path: ${squarePolygon(0, 0, 0, 1)};
            }
            to {
              clip-path: ${squarePolygon(0, 0, 0, 0)};
            }
          `} 1400ms ease-in-out forwards;

          > * {
            animation: ${keyframes`
              from {
                transform: scale(1.2);
              }
              to {
                transform: scale(1.1);
              }
            `} 1400ms ease-in-out forwards;
          }
        }
      }
    `,
)
const TabletImageColumn = styled.div(
  ({ theme }) => css`
    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
      flex: 1 1 50%;
    }
  `,
)
const TabletImagePositioner = styled.div(
  () =>
    css`
      height: 80vh;
      min-height: 100%;
      position: relative;
      overflow: hidden;
      --margin: 40px;
      --width: calc(100vw - var(--margin) * 2);

      @media (prefers-reduced-motion: no-preference) {
        clip-path: ${squarePolygon(0, 0, 0, 1)};
        > * {
          transform: scale(1.2);
          transform-origin: top right;
        }

        :not(.${hiddenClassName} &) {
          animation: ${keyframes`
            from {
              clip-path: ${squarePolygon(0, 0, 0, 1)};
            }
            to {
              clip-path: ${squarePolygon(0, 0, 0, 0)};
            }
          `} 1400ms ease-in-out forwards;

          > * {
            animation: ${keyframes`
              from {
                transform: scale(1.2);
              }
              to {
                transform: scale(1.1);
              }
            `} 1400ms ease-in-out forwards;
          }
        }
      }
    `,
)
const StyledBackdrop = styled(Backdrop)`
  opacity: 0;
  will-change: opacity;
  animation: ${keyframes`
    to {
      opacity: 1;
    }
  `} 800ms ease-in-out forwards;
`

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'imageLocation' | 'title' | 'sectionIndicatorVariant' | 'children'
  > {
  title: ReactNode
  subTitle: ReactNode
  modalSubTitle: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
  imageLocation?: 'left' | 'right'
  buttonText: ReactNode
  carouselImages?: ComponentProps<
    typeof DesignTextWithExpandableImageModal
  >['carouselImages']
  'data-testid'?: string
}

const DesignTextWithExpandableImage = ({
  title,
  subTitle,
  modalSubTitle,
  text,
  buttonText,
  image,
  imageLocation = 'right',
  carouselImages,
  className,
  'data-testid': testid = 'designTextWithExpandableImage',
  ...others
}: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const [active, setActive] = useState(false)
  const [imagePositionerRef, { left, top, width, height }] = useDimensions()
  // While this is generally bad practice, in this case it's the only way to get
  // a ref to the correct element. The result is only used on click, so this
  // shouldn't affect SSR.
  const isMobile = useIsMobile()

  const exitTransitionDuration = 120

  return (
    <>
      <Container
        imageLocation={imageLocation}
        ref={containerRef}
        className={`${className || ''} ${!inView ? hiddenClassName : ''}`}
        data-testid={testid}
        sectionIndicatorVariant="dark"
        {...others}
      >
        <ContentContainer>
          <div>
            <AnimateText>
              <SubTitle>{subTitle}</SubTitle>
            </AnimateText>
            <AnimateText delay={60}>
              <Title>{title}</Title>
            </AnimateText>

            <MobileImageContainer
              ref={isMobile ? imagePositionerRef : undefined}
              onClick={() => setActive(true)}
            >
              <Image
                src={image.src}
                alt={image.alt}
                width={358}
                height={300}
                style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
              />
            </MobileImageContainer>

            <Text>
              <AnimateText delay={300}>{text}</AnimateText>
            </Text>
            {buttonText && (
              <StyledButton
                icon={<StyledChevronRight aria-label="" />}
                onClick={() => setActive(true)}
                data-testid={`${testid}.callToAction`}
              >
                {buttonText}
              </StyledButton>
            )}
          </div>
        </ContentContainer>

        <TabletImageColumn>
          <TabletImagePositioner
            ref={!isMobile ? imagePositionerRef : undefined}
            onClick={() => setActive(true)}
          >
            <ImageContainer>
              <Image
                src={image.src}
                alt={image.alt}
                fill
                style={{ objectFit: 'cover' }}
              />
            </ImageContainer>
          </TabletImagePositioner>
        </TabletImageColumn>
      </Container>

      <TransitionGroup component={null}>
        {active && left && top && (
          <Transition timeout={exitTransitionDuration}>
            {(transitionState) => (
              <Portal>
                <div
                  style={{
                    transition:
                      transitionState === EXITING || transitionState === EXITED
                        ? `opacity ${exitTransitionDuration}ms ease-in`
                        : undefined,
                    opacity:
                      transitionState === EXITING || transitionState === EXITED
                        ? 0
                        : 1,
                  }}
                >
                  <StyledBackdrop onClick={() => setActive(false)} />
                  <DesignTextWithExpandableImageModal
                    style={{
                      left: left + window.scrollX,
                      top: top + window.scrollY,
                      width,
                      height,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      ['--scroll-y' as any]: `${window.scrollY}px`,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      ['--image-top' as any]: `${top + window.scrollY}px`,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      ['--image-height' as any]: `${height}px`,
                    }}
                    title={title}
                    subTitle={modalSubTitle}
                    image={image}
                    imageLocation={imageLocation}
                    carouselImages={carouselImages}
                    close={() => setActive(false)}
                    data-testid={`${testid}.modal`}
                  />
                </div>
              </Portal>
            )}
          </Transition>
        )}
      </TransitionGroup>
    </>
  )
}

export default DesignTextWithExpandableImage
