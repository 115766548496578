import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode } from 'react'
import AnimateText from '../components/AnimateText'
import Image from '../components/Image'

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;
      align-items: center;
    }
  `,
)
const ItemImageContainer = styled.div(
  ({ theme }) => css`
    margin: 0 ${theme.spacing.x5}px 0 0;

    @media screen and (max-width: ${theme.breakpoints.tabletMax}px) {
      margin: 0 0 ${theme.spacing.x5}px 0;

      > img {
        width: 100%;
        height: auto;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      margin: 0 ${theme.spacing.x10}px 0 0;
    }
  `,
)
const ItemText = styled.div(
  () => css`
    flex: 1;
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  text: ReactNode
  image?: {
    src: string
    alt?: string
  }
}

const HarmonicaItem = ({ id, text, image, ...others }: Props) => {
  return (
    <Container id={id} data-testid="harmonicaItem" {...others}>
      {image && (
        <ItemImageContainer>
          <Image
            src={image.src}
            alt={image.alt}
            width={450}
            height={300}
            style={{ objectFit: 'cover' }}
            priority
          />
        </ItemImageContainer>
      )}
      <ItemText>
        <AnimateText direction="down">{text}</AnimateText>
      </ItemText>
    </Container>
  )
}

export default HarmonicaItem
