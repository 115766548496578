import { css, keyframes, Theme } from '@emotion/react'
import styled from '@emotion/styled'
import SlickCarousel from '@ant-design/react-slick'
import {
  Children,
  ComponentProps,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import ArrowRight from '../icons/yachting/ArrowRight.svg'
import { defaultSpeed } from './Carousel'
import {
  isSiteYachting,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'
import CarouselPager, { ActivePage } from './CarouselPager'
import { hiddenClassName } from './AnimateText'
import useViewportSize from '../utils/useViewportSize'

const Arrows = styled.div`
  display: flex;
`
const arrowsCenteredCss = (theme: Theme) => css`
  padding: 0 ${theme.spacing.x8}px;

  @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
    padding: 0 ${theme.spacing.x11}px;
  }

  ${Arrows} {
    position: absolute;
    justify-content: space-between;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
`
const arrowsBottomRightCss = (theme: Theme) => css`
  display: flex;
  flex-direction: column-reverse;

  ${Arrows} {
    justify-content: flex-end;
    gap: ${theme.spacing.x2}px;
    margin-top: ${theme.spacing.x4}px;
  }
`
const Container = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'arrowsPosition' && prop !== 'customDots',
})<{
  arrowsPosition: Props['arrowsPosition']
  customDots: Props['customDots']
}>(({ theme, arrowsPosition, customDots }) => [
  css`
    position: relative;
    width: 100%;

    .slick-track {
      display: flex;
    }
  `,
  arrowsPosition === 'event-carousel' &&
    !customDots &&
    css`
      ${arrowsCenteredCss(theme)};

      ${Arrows} {
        top: 191px;
      }
    `,
  arrowsPosition === 'vertical-centered' &&
    !customDots &&
    css`
      ${arrowsCenteredCss(theme)};
    `,
  arrowsPosition === 'responsive' &&
    !customDots &&
    css`
      @media screen and (max-width: ${theme.breakpoints.tabletMax}px) {
        ${arrowsBottomRightCss(theme)};
      }
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        ${arrowsCenteredCss(theme)};
      }
    `,
  arrowsPosition === 'bottom-right' &&
    !customDots &&
    arrowsBottomRightCss(theme),
])
const CarouselContainer = styled.div(
  () => css`
    position: relative;
    overflow: hidden;
    width: 100%;
  `,
)
const carouselButton = (theme: Theme) => css`
  position: relative;
  width: 40px;
  height: 40px;
  border: none;
  padding: ${theme.spacing.x1}px;
  cursor: pointer;

  :disabled {
    cursor: default;
  }

  > svg {
    transition: transform 300ms ease-in;
  }

  ${isSiteYachting(theme) &&
  css`
    color: ${theme.colors.yachting.orange};
    :disabled {
      color: ${theme.colors.yachting.silver};
    }
  `}

  ${isThemeYachtingWomenInYachtingLandingspage(theme) &&
  css`
    color: ${theme.colors.womenInYachtingPage.terraBlush};
    :disabled {
      color: ${theme.colors.womenInYachtingPage.shell};
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 32px;
      height: 60px;
      padding: 0;
    }
  `}
`
const ButtonRight = styled.button(
  ({ theme }) => css`
    ${carouselButton(theme)};
    right: 0;

    @media (prefers-reduced-motion: no-preference) {
      :not(:disabled):hover > svg {
        transform: translateX(8px);
      }
    }
  `,
)
const ButtonLeft = styled.button(
  ({ theme }) => css`
    ${carouselButton(theme)};
    left: 0;

    > svg {
      transform: rotate(180deg);
    }

    @media (prefers-reduced-motion: no-preference) {
      :not(:disabled):hover > svg {
        transform: rotate(180deg) translateX(8px);
      }
    }
  `,
)

const OverlayPager = styled(CarouselPager)(({ theme }) => [
  css`
    position: absolute;
    top: ${theme.spacing.x4}px;
    left: ${theme.spacing.x4}px;
    z-index: 1;
    width: calc(
      100% - ${theme.spacing.x4}px * 2 - var(--pager-margin-right, 0px)
    );

    ${ActivePage} {
      background: ${theme.colors.yachting.darkBlue};
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      top: 7.5%;
      width: 70%;
      left: 8.6%;
    }

    @media (prefers-reduced-motion: no-preference) {
      will-change: opacity;
      :not(.${hiddenClassName} &) {
        opacity: 0;
        animation: ${keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      `} 400ms ease-in-out forwards;
      }
    }
  `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      ${ActivePage} {
        background: ${theme.colors.womenInYachtingPage.terraBlush};
      }
    `,
])
const BelowPager = styled(OverlayPager)<{
  customDotsPosition?:
    | 'left'
    | 'right'
    | 'center'
    | 'alertBoatShow'
    | 'testimonial'
    | 'product'
    | 'quote'
}>(({ theme, customDotsPosition }) => [
  css`
    position: relative;
    top: auto;
    left: 0;
    color: ${theme.colors.yachting.silver};
    width: 100%;
    margin-right: ${theme.spacing.x3}px;
    margin-left: ${theme.spacing.x3}px;
    max-width: 50%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      left: 0;
    }
  `,
  customDotsPosition === 'testimonial' &&
    css`
      left: 50%;
      transform: translateX(-50%);
      margin-top: ${theme.spacing.x3}px;
      margin-left: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 144px;
        left: 50%;
        transform: translateX(-50%);
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        left: calc(((100% - 96px) / 2) - 314px / 2);
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        left: calc(((100% - 96px) / 2) - 358px / 2);
      }
    `,
  customDotsPosition === 'right' &&
    css`
      top: auto;
      right: 0;
      width: 100%;
      max-width: 50%;
      margin-right: 0;
      margin-left: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        left: auto;
        right: 0;
        margin-left: ${theme.spacing.x3}px;
      }
    `,
  customDotsPosition === 'product' &&
    css`
      top: auto;
      right: 0;
      width: 100%;
      max-width: 50%;
      margin-right: 0;
      margin-left: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        left: auto;
        right: 0;
        margin-left: ${theme.spacing.x3}px;
        max-width: 144px;
      }
    `,
  customDotsPosition === 'quote' &&
    css`
      left: 50%;
      transform: translateX(-50%);
      margin-left: 0;
      margin-right: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        left: 50%;
      }
    `,
  customDotsPosition === 'center' &&
    css`
      left: 50%;
      transform: translateX(-50%);
      margin-left: 0;
      margin-right: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        left: 50%;
      }
    `,
  customDotsPosition === 'alertBoatShow' &&
    css`
      left: 50%;
      transform: translateX(-50%);
      margin-left: 0;
      margin-right: 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        left: 50%;
        width: 144px;
      }
    `,
])

const CarouselFooter = styled.div<{
  customDotsPosition?:
    | 'left'
    | 'right'
    | 'center'
    | 'alertBoatShow'
    | 'testimonial'
    | 'product'
    | 'quote'
}>(({ theme, customDotsPosition }) => [
  css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-right: ${theme.spacing.x4}px;
    margin-top: ${theme.spacing.x3}px;

    > * {
      margin-top: ${theme.spacing.x3}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: ${theme.spacing.x5}px;
      > * {
        margin-top: 0;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding-right: ${theme.spacing.x10}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding-right: ${theme.spacing.x15}px;
    }
  `,
  customDotsPosition === 'testimonial' &&
    css`
      padding-right: 0;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding-right: 0;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding-right: 0;
      }
    `,
  (customDotsPosition === 'right' || customDotsPosition === 'product') &&
    css`
      padding-right: 0;
      justify-content: flex-start;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        justify-content: flex-end;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding-right: 0;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding-right: 0;
      }
    `,
  (customDotsPosition === 'center' || customDotsPosition === 'alertBoatShow') &&
    css`
      padding-right: 0;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding-right: 0;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding-right: 0;
      }
    `,
  customDotsPosition === 'quote' &&
    css`
      padding-right: 0;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding-right: 0;
      }

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding-right: 0;
      }
    `,
])

interface Props extends ComponentProps<typeof SlickCarousel> {
  children: ReactElement | ReactElement[]
  arrowsPosition:
    | 'responsive'
    | 'bottom-right'
    | 'vertical-centered'
    | 'event-carousel'
  'data-testid'?: string
  'aria-hidden'?: boolean
  id?: string
  customArrows?: boolean
  customDots?: boolean
  customDotsPosition?:
    | 'left'
    | 'right'
    | 'center'
    | 'alertBoatShow'
    | 'testimonial'
    | 'product'
    | 'quote'
  moreButton?: ReactNode
  customArrow?: ReactNode
}

const YachtingCarousel = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      arrowsPosition,
      'data-testid': testid = 'carousel',
      'aria-hidden': ariaHidden,
      id,
      speed = defaultSpeed,
      slidesToShow = 1,
      slidesToScroll = 1,
      customArrows = true,
      customDots = false,
      customDotsPosition = 'left',
      moreButton,
      customArrow,
      ...others
    }: Props,
    ref,
  ) => {
    const [currentSlide, setCurrentSlide] = useState(0)
    const carouselRef = useRef<SlickCarousel>(null)
    const childCount = Children.count(children)
    const [slidesPerWindow, setSlidesPerWindow] = useState(1)
    const viewportSize = useViewportSize()

    const calculateSlidesPerWindow = useCallback(() => {
      if (!carouselRef.current) return childCount

      const { breakpoint } = carouselRef.current.state as {
        breakpoint: number | null
      }
      const responsiveConfig =
        (breakpoint &&
          others.responsive?.find((i) => i.breakpoint === breakpoint)) ||
        null

      const slidesPerWindow =
        (responsiveConfig &&
          responsiveConfig.settings !== 'unslick' &&
          responsiveConfig.settings.slidesToShow) ||
        slidesToShow

      return slidesPerWindow as number
    }, [childCount, others.responsive, slidesToShow])

    const hasMoreSlides = (slideIndex: number) => {
      if (!carouselRef.current) return false

      const { breakpoint } = carouselRef.current.state as {
        breakpoint: number | null
      }
      const responsiveConfig =
        (breakpoint &&
          others.responsive?.find((i) => i.breakpoint === breakpoint)) ||
        null

      const slidesPerWindow =
        (responsiveConfig &&
          responsiveConfig.settings !== 'unslick' &&
          responsiveConfig.settings.slidesToShow) ||
        slidesToShow

      return slideIndex + 1 + (slidesPerWindow - 1) < childCount
    }

    useEffect(() => {
      setSlidesPerWindow(calculateSlidesPerWindow())
    }, [calculateSlidesPerWindow, viewportSize])

    return (
      <Container
        data-testid={testid}
        aria-hidden={ariaHidden}
        arrowsPosition={arrowsPosition}
        customDots={customDots}
        id={id}
        ref={ref}
      >
        {childCount > 1 && customArrows && (
          <Arrows>
            <ButtonLeft
              onClick={() => carouselRef.current?.slickPrev()}
              disabled={currentSlide === 0}
            >
              {customArrow || <ArrowRight />}
            </ButtonLeft>
            <ButtonRight
              onClick={() => carouselRef.current?.slickNext()}
              disabled={!hasMoreSlides(currentSlide)}
            >
              {customArrow || <ArrowRight />}
            </ButtonRight>
          </Arrows>
        )}
        <CarouselContainer>
          {/* eslint-disable @typescript-eslint/ban-ts-comment */}
          {/** @ts-ignore @ant-design/react-slick types haven't been updated to support react18 yet */}
          <SlickCarousel
            infinite={false}
            arrows={false}
            variableWidth
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            speed={speed}
            ref={carouselRef}
            beforeChange={(current, next) => {
              setCurrentSlide(next)
            }}
            dots={false}
            {...others}
          >
            {Children.map(children, (child) => (
              <div>{child}</div>
            ))}
          </SlickCarousel>
        </CarouselContainer>
        <CarouselFooter customDotsPosition={customDotsPosition}>
          {customDots && childCount > 1 && childCount > slidesToShow && (
            <BelowPager
              numSlides={
                Math.round(slidesPerWindow) > 1
                  ? childCount - Math.round(slidesToShow) + 1
                  : childCount
              }
              slidesPerItem={1}
              activeSlide={currentSlide}
              onClickPage={(slide) => carouselRef.current?.slickGoTo(slide)}
              speed={speed}
              customDotsPosition={customDotsPosition}
            />
          )}
          {moreButton && moreButton}
        </CarouselFooter>
      </Container>
    )
  },
)

YachtingCarousel.displayName = 'YachtingCarousel'

export default YachtingCarousel
