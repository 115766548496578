import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { heading2, heading5 } from '../../themes/xplorer/text'
import Image from '../../components/Image'
import Quote from '../../icons/xplorer/Quote.svg'
import { ImagesWithQuoteProps } from './index'

const Container = styled.div<{ noPadding?: boolean }>(
  ({ theme, noPadding }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
    gap: ${theme.spacing.x3}px;
    padding: ${noPadding ? 0 : `${theme.spacing.x8}px ${theme.spacing.x3}px`};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x5}px;
      padding: ${noPadding
        ? 0
        : `${theme.spacing.x20}px ${theme.spacing.x3}px`};
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      grid-template-columns: repeat(2, minmax(290px, 1fr));
      padding: ${noPadding
        ? 0
        : `${theme.spacing.x20}px ${theme.spacing.x10}px`};
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
  `,
)

const ContainerQuoteStart = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.xplorer.secondaryXPBronze};
    position: absolute;
    top: ${theme.spacing.x4}px;
    left: ${theme.spacing.x4}px;
  `,
)

const ContainerQuoteEnd = styled(ContainerQuoteStart)(
  ({ theme }) => css`
    top: auto;
    left: auto;
    right: ${theme.spacing.x4}px;
    bottom: ${theme.spacing.x4}px;
    transform: rotate(180deg);
  `,
)

const QuoteIcon = styled(Quote)`
  width: 58px;
`

const ImageCard = styled.div`
  height: 546px;
`
const QuoteCard = styled.div(
  ({ theme }) => css`
    padding: calc(${theme.spacing.x4}px + ${theme.spacing.x8}px)
      ${theme.spacing.x4}px; // vertical padding include quote icon height
    gap: ${theme.spacing.x3}px;
    background: ${theme.colors.xplorer.primaryXPLavaBlack};
    height: 546px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `,
)

const QuoteCardHeading = styled.div(
  ({ theme }) => css`
    ${heading2(theme)}
    color: ${theme.colors.xplorer.white};
    margin: 0;
    line-height: 1.2;
    text-transform: uppercase;
    overflow-wrap: break-word;
    max-width: 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 28px;
    }
  `,
)

const QuoteCardSubHeading = styled.div(
  ({ theme }) => css`
    ${heading5(theme)}
    color: ${theme.colors.xplorer.secondaryXPBronze};
    margin: 0;
    text-transform: uppercase;
  `,
)

const ImagesWithQuote = ({
  columns,
  noPadding,
  ...others
}: ImagesWithQuoteProps) => {
  return (
    <Container noPadding={noPadding} {...others}>
      {columns.map((column) => {
        return column.type === 'image' ? (
          <ImageCard key={column.key}>
            <Image
              src={column.image.src}
              alt={column.image.alt ?? ''}
              width={603}
              height={546}
              style={{ objectFit: 'cover', width: '100%' }}
              sizes="(max-width: 767px) 603px, 546px"
            />
          </ImageCard>
        ) : (
          <QuoteCard key={column.key}>
            <ContainerQuoteStart>
              <QuoteIcon />
            </ContainerQuoteStart>
            <ContainerQuoteEnd>
              <QuoteIcon />
            </ContainerQuoteEnd>
            {column?.subHeading && (
              <QuoteCardSubHeading>{column.subHeading}</QuoteCardSubHeading>
            )}
            <QuoteCardHeading>{column.heading}</QuoteCardHeading>
          </QuoteCard>
        )
      })}
    </Container>
  )
}

export default ImagesWithQuote
