import { ComponentProps, ReactNode } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

import { isSiteYachting } from '../themes'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import Section from '../components/Section'

interface TextWithImageProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant' | 'title'
  > {
  overline?: string
  sectionTitle?: string
  title: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
  usps: {
    key: string
    image: {
      src: string
      alt?: string
    }
    title: ReactNode
    text: ReactNode
  }[]
}

const Container = styled(Section)(
  ({ theme }) =>
    isSiteYachting(theme) && [
      css`
        z-index: 0;
        padding: ${theme.spacing.x6}px ${theme.spacing.x4}px;
        background: ${theme.colors.yachting.silver30};

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          padding: ${theme.spacing.x20}px ${theme.spacing.x10}px;
        }
        @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
          padding: ${theme.spacing.x20}px;
        }
      `,
    ],
)
const ImageContainer = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) && [
      css`
        position: relative;
        display: flex;
        flex: 0 0 auto;
        max-width: 100%;
        overflow: hidden;
        justify-content: center;
        width: 100%;

        @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
          flex-direction: column;
          align-items: center;
          max-height: 540px;

          img {
            width: 100%;
            height: auto;
          }
        }
      `,
    ],
)
const Content = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 600px;
    }
  `,
)
const Overline = styled.span(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      display: block;
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `}
  `,
)
const Title = styled.h2(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin: 0 0 ${theme.spacing.x4}px;
    `,
)
const Text = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `,
)
const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
`

const ImageAnimationContainer = styled(AnimateText)``
const ContentContainer = styled.div(({ theme }) => [
  css`
    display: flex;
    position: relative;
    gap: ${theme.spacing.x7}px;
    justify-content: center;
    flex-flow: column;

    ${Overline},
    ${Text} {
      margin-left: ${theme.spacing.x2}px;
    }
    align-items: stretch;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row-reverse;
      align-items: center;

      ${Content} {
        width: 40%;
      }
      ${ImageAnimationContainer} {
        width: 50%;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      ${Overline},
      ${Text} {
        margin-left: ${theme.spacing.x3}px;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x10}px;
    }
  `,
])

const UspsContainer = styled.ul(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.spacing.x10}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-direction: row;
      justify-content: start;
    }
  `,
])

const Usp = styled.li(({ theme }) => [
  css`
    display: flex;
    position: relative;
    gap: ${theme.spacing.x3}px;
    align-items: center;
    flex-flow: column;
    text-align: center;
    width: 100%;

    &:not(:first-of-type) {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        top: -${theme.spacing.x5}px;
        width: 100%;
        height: 1px;
        background: ${theme.colors.yachting.darkBlue30};
      }
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 33.33%;

      &:not(:first-of-type) {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -${theme.spacing.x5}px;
          height: 100%;
          width: 1px;
        }
      }
    }
  `,
])

const StyledUspImage = styled(Image)`
  width: 100%;
  height: auto;
`

const UspImageWrapper = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      max-width: 86px;
    `,
)

const UspText = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue70};

      p:first-of-type {
        margin-top: 0;
      }
    `,
)

const UspTitle = styled.h4(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.heading4(theme)}
      color: ${theme.colors.yachting.darkBlue70};
      margin: 0;
    `,
)

const ContentWrapper = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.layout.maxContentWidth(theme)};
      display: flex;
      flex-flow: column;
      gap: ${theme.spacing.x7}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        gap: ${theme.spacing.x13}px;
      }
    `,
)

const TextWithUsps = ({
  overline,
  title,
  text,
  image,
  usps,
  ...others
}: TextWithImageProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="textWithUsps"
      ref={containerRef}
      {...others}
    >
      <ContentWrapper>
        <ContentContainer>
          <ImageAnimationContainer direction="static">
            <ImageContainer>
              <StyledImage
                src={image.src}
                alt={image.alt}
                width={585}
                height={780}
                style={{ objectFit: 'cover' }}
              />
            </ImageContainer>
          </ImageAnimationContainer>
          <Content>
            <AnimateText>
              {overline && <Overline>{overline}</Overline>}
              <Title>{title}</Title>
            </AnimateText>
            <AnimateText delay={400}>
              <Text>{text}</Text>
            </AnimateText>
          </Content>
        </ContentContainer>
        <UspsContainer>
          {usps.map(({ image, title, text, key }) => (
            <Usp key={key}>
              <UspImageWrapper>
                <StyledUspImage
                  src={image.src}
                  alt={image.alt}
                  width={86}
                  height={86}
                  style={{ objectFit: 'contain' }}
                />
              </UspImageWrapper>
              <UspTitle>{title}</UspTitle>
              <UspText>{text}</UspText>
            </Usp>
          ))}
        </UspsContainer>
      </ContentWrapper>
    </Container>
  )
}

export default TextWithUsps
