import Head from 'next/head'

import { getPreloadUrl } from '../components/HighlightVideo'
import { isScrollImageSequenceLoadingCompleteVar } from '../components/ScrollImageSequence'
import { useReactiveVar } from '../utils/reactiveVar'

interface Props {
  buttons: Array<{ video?: string }>
}

const PortfolioHighlightsPreloading = ({ buttons, ...others }: Props) => {
  const isScrollImageSequenceLoading = useReactiveVar(
    isScrollImageSequenceLoadingCompleteVar,
  )
  if (isScrollImageSequenceLoading) {
    return null
  }

  return (
    <div {...others}>
      {buttons.map(
        (button) =>
          button.video && (
            <Head key={button.video}>
              <link rel="prefetch" href={getPreloadUrl(button.video)} />
            </Head>
          ),
      )}
    </div>
  )
}

export default PortfolioHighlightsPreloading
