import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { body, heading1 } from '../../themes/xplorer/text'
import Image from '../../components/Image'
import { SubHeaderProps } from './index'
import ImageAspectRatio from '../../components/ImageAspectRatio'
import LocationIcon from '../../icons/xplorer/Location.svg'

const Container = styled.div(
  () =>
    css`
      position: relative;
    `,
)

const Title = styled.div(
  ({ theme }) =>
    css`
      ${heading1(theme)};
      color: ${theme.colors.xplorer.white};
    `,
)

const SubHeading = styled.p(
  ({ theme }) =>
    css`
      ${body(theme)}
      margin: 0;
      display: flex;
      align-items: center;
      gap: ${theme.spacing.x1}px;
    `,
)

const BackgroundImageDesktopContainer = styled(ImageAspectRatio)(
  ({ theme }) =>
    css`
      display: none;

      @media screen and (min-width: ${theme.breakpoints.mobileMax}px) {
        display: block;
      }
    `,
)
const BackgroundImageMobileContainer = styled(ImageAspectRatio)(
  ({ theme }) =>
    css`
      @media screen and (min-width: ${theme.breakpoints.mobileMax}px) {
        display: none;
      }
    `,
)

const OverlayImageContainer = styled(ImageAspectRatio)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Content = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: ${theme.spacing.x2}px;
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      color: ${theme.colors.xplorer.white};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: 1280px;
        margin: 0 auto;
        padding: ${theme.spacing.x10}px;
      }
    `,
)

const SubHeadingIcon = styled(LocationIcon)(
  ({ theme }) => css`
    width: 24px;
    height: 24px;
    color: ${theme.colors.xplorer.white};
  `,
)

const SubHeaderXplorer = ({
  heading,
  subHeadingText,
  backgroundImage,
  overlayImage,
  ...others
}: SubHeaderProps) => {
  return (
    <Container {...others}>
      {backgroundImage && (
        <>
          <BackgroundImageDesktopContainer aspectRatio="16:9">
            <Image
              src={backgroundImage.src}
              alt={backgroundImage.alt ?? ''}
              style={{ objectFit: 'cover', width: '100%' }}
              fill
              aspectRatio="16:9"
            />
          </BackgroundImageDesktopContainer>
          <BackgroundImageMobileContainer aspectRatio="375:667">
            <Image
              src={backgroundImage.src}
              alt={backgroundImage.alt ?? ''}
              style={{ objectFit: 'cover', width: '100%' }}
              fill
              aspectRatio="375:667"
            />
          </BackgroundImageMobileContainer>
        </>
      )}
      {overlayImage && (
        <OverlayImageContainer aspectRatio="16:9">
          <Image
            src={overlayImage.src}
            alt={overlayImage.alt ?? ''}
            style={{ objectFit: 'cover', width: '100%' }}
            fill
            aspectRatio="16:9"
          />
        </OverlayImageContainer>
      )}
      <Content>
        <SubHeading>
          <SubHeadingIcon />
          {subHeadingText}
        </SubHeading>
        <Title>{heading}</Title>
      </Content>
    </Container>
  )
}

export default SubHeaderXplorer
