import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Section from '../../components/Section'
import SpecificationGroup from '../../components/SpecificationGroup'
import SpecificationText from '../../components/SpecificationText'
import { isSiteYachting } from '../../themes'
import { PlainSpecificationsProps } from './index'

const Container = styled(Section)(({ theme }) => [
  css`
    color: ${theme.colors.amels.deepBayAqua};
    margin: ${theme.spacing.x20}px auto;
    max-width: 1200px;
    text-align: center;
  `,
  isSiteYachting(theme) &&
    css`
      max-width: none;
      margin: 0;
      background-color: ${theme.colors.yachting.silver30};
    `,
])

const Wrapper = styled('div')(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      column-gap: ${theme.spacing.x4}px;
      padding: ${theme.spacing.x10}px ${theme.spacing.x5}px;
      text-align: left;
      max-width: 1200px;
      margin: 0 auto;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x20}px ${theme.spacing.x5}px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    `,
])

const PlainSpecifications = ({
  groups,
  ...others
}: PlainSpecificationsProps) => (
  <Container sectionIndicatorVariant="dark" {...others}>
    <Wrapper>
      {groups.map(({ key, title, values, text }) =>
        values ? (
          <SpecificationGroup key={key} title={title} values={values} />
        ) : (
          <SpecificationText key={key} title={title}>
            {text}
          </SpecificationText>
        ),
      )}
    </Wrapper>
  </Container>
)

export default PlainSpecifications
