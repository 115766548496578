import RichText from '../../components/RichText'
import TextWithUsps from '../../patterns/TextWithUsps'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { TextWithUspsStoryblok } from '../storyblok.generated'

interface Props {
  content: TextWithUspsStoryblok
}

const TextWithUspsBlok = ({
  content: { sectionTitle, image, title, text, overline, usps },
  ...others
}: Props) => (
  <TextWithUsps
    id={slugify(sectionTitle)}
    overline={overline}
    image={mapImage(image) ?? getRequiredImageFallback()}
    title={reactStringReplace(title, '\n', <br />)}
    text={<RichText>{text}</RichText>}
    usps={usps.map(({ image, title, text, _uid }) => ({
      image: mapImage(image) ?? getRequiredImageFallback(),
      title,
      text: <RichText>{text}</RichText>,
      key: _uid,
    }))}
    {...others}
  />
)

export default TextWithUspsBlok
