import SitemapTree from '../../patterns/SitemapTree'
import { SitemapHtmlLink } from '../../utils/storyBlokSitemapHtmlBuilder'

interface Props {
  content: {
    sitemapItems: SitemapHtmlLink[]
  }
}

const SitemapTreeBlok = ({ content: { sitemapItems }, ...others }: Props) => (
  <SitemapTree items={sitemapItems} {...others} />
)

export default SitemapTreeBlok
