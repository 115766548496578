import RichText from '../../components/RichText'
import NavigationTeaserWithText from '../../patterns/NavigationTeaserWithText'
import { NavigationTeaserWithTextStoryblok } from '../storyblok.generated'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: NavigationTeaserWithTextStoryblok
}

const NavigationTeaserWithTextBlok = ({
  content: {
    title,
    subTitle,
    description,
    navigationTeaserBlok: [{ columns, ...teaserOthers }],
  },
  ...others
}: Props) => (
  <NavigationTeaserWithText
    title={<RichText>{title}</RichText>}
    subTitle={subTitle}
    description={<RichText>{description}</RichText>}
    navigationTeaserProps={{
      columns: columns.map(
        ({
          _uid,
          image,
          mobileImage,
          logo,
          title,
          subtitle,
          link,
          linkText,
          hoverTitle,
          hoverSubtitle,
          hoverText,
        }) => ({
          key: _uid,
          title,
          subTitle: subtitle,
          logo: mapImage(logo),
          image: mapImage(image) ?? getRequiredImageFallback(),
          mobileImage: mapImage(mobileImage),
          link: storyblokLinkResolver(link),
          linkText,
          hoverTitle,
          hoverSubtitle,
          hoverText: <RichText>{hoverText}</RichText>,
        }),
      ),
      ...teaserOthers,
    }}
    {...others}
  />
)

export default NavigationTeaserWithTextBlok
