import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import Section from '../../components/Section'
import { isSiteAmels, isSiteYachting } from '../../themes'
import AnimateText, { hiddenClassName } from '../../components/AnimateText'
import LinkWithArrow, { Arrow } from '../../components/LinkWithArrow'
import Image from '../../components/Image'
import Link from '../../components/Link'
import LinkButton from '../../components/LinkButton'
import { ArticleFeedProps } from '.'

const AutomatedArticlesContainer = styled(Section)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding: ${theme.spacing.x15}px;
      }
    `,
)
const AutomatedArticlesWrapper = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      max-width: 1086px;
      margin: 0 auto;
    `,
)
const Container = styled(Section)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding: ${theme.spacing.x15}px;
      }
    `,
)
const TitleBar = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.x7}px;
    gap: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-direction: row;
      align-items: center;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.yachting.darkBlue};
    margin: 0;
  `,
)
const StyledLinkButtonWithArrow = styled(LinkButton)(
  () => css`
    font-size: 24px;
    font-weight: 300;
  `,
)
const AutomatedArticles = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  `,
)
const AutomatedArticlesButtonContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    margin-top: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: ${theme.spacing.x6}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      justify-content: flex-end;
    }
  `,
)
const Articles = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  `,
)
const ArticleLink = styled(Link)`
  display: block;
  height: 100%;

  > div {
    height: 100%;
  }

  img {
    transition: transform ease-out 3000ms;
  }

  :hover {
    text-decoration: none;

    img {
      transform: scale(1.2);
    }
  }
`
const StyledLinkWithArrow = styled(LinkWithArrow)(
  ({ theme }) => css`
    color: ${theme.colors.yachting.black};
    font-size: 24px;
    font-weight: 300;

    ${Arrow} {
      color: ${theme.colors.yachting.orange};
    }
  `,
)
const AutomatedArticle = styled.article(
  () => css`
    display: flex;
    flex-flow: column;
    height: 100%;
  `,
)
const AutomatedArticleContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    flex-grow: 2;
    padding: ${theme.spacing.x3}px 0;
    gap: ${theme.spacing.x2}px;
  `,
)
const Article = styled.article(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x3}px;
    height: 100%;
  `,
)
const ArticleImageContainer = styled('div')(
  () => css`
    overflow: hidden;
  `,
)
const ArticleImage = styled(Image)(
  () => css`
    position: relative;
    width: 100%;
    height: auto;
  `,
)
const ArticleTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading3(theme)}
    margin: 0;
  `,
  isSiteYachting(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-weight: 400;
      }
    `,
])
const ArticleDetails = styled.div(
  () => css`
    margin-top: auto;
  `,
)
const ArticleInfo = styled.div(({ theme }) => [
  css`
    display: inline-block;
    margin-right: ${theme.spacing.x6}px;
    letter-spacing: 5px;
  `,
  isSiteAmels(theme) &&
    css`
      text-transform: uppercase;
      font-weight: 500;
      color: ${theme.colors.amels.beach};
      letter-spacing: 3.43px;
      font-size: 12px;
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)}
      color: ${theme.colors.yachting.silver};
      position: relative;
      margin-right: 0;

      :not(:last-of-type) {
        padding-right: ${theme.spacing.x2 + 5}px;
        margin-right: ${theme.spacing.x2 + 5}px;

        ::after {
          content: '';
          position: absolute;
          right: 0;
          top: -1px;
          display: block;
          background-image: url("data:image/svg+xml,%3Csvg width='12' height='27' viewBox='0 0 12 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4893 0.519424L1.00027 26.4806' stroke='%23EC7404'/%3E%3C/svg%3E%0A");
          width: 12px;
          height: 27px;
          transform: translateX(50%);
        }
      }
    `,
])

const FilterButton = styled.button<{ active: boolean }>(({ theme, active }) => [
  css`
    padding: 0 ${theme.spacing.x1}px;
    border: 1px solid;

    &:hover {
      cursor: pointer;
    }
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      border-color: ${theme.colors.yachting.orange};
      color: ${theme.colors.yachting.orange};

      &:hover {
        background-color: ${theme.colors.yachting.orange30};
      }
    `,
  active &&
    css`
      background-color: ${theme.colors.yachting.orange30};
    `,
])

const Filters = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.x2}px;
    margin-bottom: ${theme.spacing.x8}px;
  `,
])

const Overline = styled.div(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)};
      color: ${theme.colors.yachting.orange};
    `}
  `,
)

const ArticleFeed = ({
  title,
  viewAll,
  articles,
  variant,
  automatedArticles,
  ...others
}: ArticleFeedProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const { locale } = useRouter()
  const [activeFilter, setActiveFilter] = useState(
    automatedArticles?.[0]?.articleFeedFilter ?? undefined,
  )

  // This should be retrieved from Algolia as a facet in order to improve performance further, but currently a heavy call
  const allCategories = useMemo(
    () =>
      Array.from(new Set(automatedArticles?.map((x) => x.articleFeedFilter))),
    [automatedArticles],
  )

  // We add this check to prevent the site from breaking when deploying the templates
  // The default return in this file is the 'old' component before the rebranding and should be removed when possible
  if (automatedArticles && automatedArticles?.length > 0) {
    const filteredArticles = activeFilter
      ? automatedArticles.filter(
          (article) => article.articleFeedFilter === activeFilter,
        )
      : automatedArticles

    const articleFeedFilters = allCategories

    /* eslint-disable no-plusplus */
    let articleCounter = 0
    let feedFilterCounter = 0

    return (
      <AutomatedArticlesContainer
        sectionIndicatorVariant="light"
        className={!inView ? hiddenClassName : undefined}
        data-testid="articleFeed"
        ref={containerRef}
        {...others}
      >
        <AutomatedArticlesWrapper>
          <TitleBar>
            {title && (
              <AnimateText>
                <Title>{title}</Title>
              </AnimateText>
            )}
          </TitleBar>
          {/* Only show the filters when it is a "Feed" (e.g. not related) */}
          {variant === 'feed' && (
            <Filters>
              {articleFeedFilters.map((feedFilter) => (
                <AnimateText
                  key={feedFilter}
                  delay={100 + ++feedFilterCounter * 60}
                >
                  <FilterButton
                    type="button"
                    active={activeFilter === feedFilter}
                    onClick={() => setActiveFilter(feedFilter)}
                  >
                    {feedFilter}
                  </FilterButton>
                </AnimateText>
              ))}
            </Filters>
          )}
          <AutomatedArticles>
            {filteredArticles.map(
              ({
                key,
                variant,
                slug,
                title,
                image,
                publishedAt,
                articleFeedFilter,
              }) => (
                <AnimateText key={key} delay={200 + ++articleCounter * 60}>
                  <ArticleLink
                    href={
                      variant === 'article'
                        ? `/news/${slug}`
                        : `/events/${slug}`
                    }
                  >
                    <AutomatedArticle>
                      <ArticleImageContainer>
                        <ArticleImage
                          src={image}
                          alt={title}
                          width={376}
                          height={212}
                          aspectRatio="376:212"
                          style={{ objectFit: 'cover' }}
                        />
                      </ArticleImageContainer>
                      <AutomatedArticleContent>
                        <Overline>{articleFeedFilter}</Overline>
                        <ArticleTitle>{title}</ArticleTitle>
                        <ArticleDetails>
                          {publishedAt && (
                            <ArticleInfo>
                              {new Date(publishedAt).toLocaleDateString(
                                locale,
                                {
                                  day: 'numeric',
                                },
                              )}{' '}
                              {new Date(publishedAt).toLocaleDateString(
                                locale,
                                {
                                  month: 'long',
                                },
                              )}{' '}
                              {new Date(publishedAt).toLocaleDateString(
                                locale,
                                {
                                  year: 'numeric',
                                },
                              )}
                            </ArticleInfo>
                          )}
                        </ArticleDetails>
                      </AutomatedArticleContent>
                    </AutomatedArticle>
                  </ArticleLink>
                </AnimateText>
              ),
            )}
          </AutomatedArticles>
          {viewAll ? (
            <AutomatedArticlesButtonContainer>
              <AnimateText delay={400}>
                <StyledLinkButtonWithArrow
                  variant="primary"
                  href={viewAll.href}
                  target={viewAll.target}
                >
                  {viewAll.title}
                </StyledLinkButtonWithArrow>
              </AnimateText>
            </AutomatedArticlesButtonContainer>
          ) : undefined}
        </AutomatedArticlesWrapper>
      </AutomatedArticlesContainer>
    )
  }

  if (!articles) {
    return null
  }

  // TODO: Investigate if we can deprecate this (uses full contanier)
  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="articleFeed"
      ref={containerRef}
      {...others}
    >
      <TitleBar>
        {title && (
          <AnimateText>
            <Title>{title}</Title>
          </AnimateText>
        )}
        {viewAll && (
          <AnimateText delay={600}>
            <StyledLinkWithArrow href={viewAll.href} target={viewAll.target}>
              {viewAll.title}
            </StyledLinkWithArrow>
          </AnimateText>
        )}
      </TitleBar>
      <Articles>
        {articles.map(
          ({ key, variant, slug, title, image, publishedAt }, index) => (
            <ArticleLink
              key={key}
              href={variant === 'article' ? `/news/${slug}` : `/events/${slug}`}
            >
              <AnimateText delay={1200 + index * 600}>
                <Article>
                  <ArticleImageContainer>
                    <ArticleImage
                      src={image}
                      alt={title}
                      width={376}
                      height={212}
                      aspectRatio="376:212"
                      style={{ objectFit: 'cover' }}
                    />
                  </ArticleImageContainer>
                  <ArticleTitle>{title}</ArticleTitle>
                  <ArticleDetails>
                    {publishedAt && (
                      <ArticleInfo>
                        {new Date(publishedAt).toLocaleDateString(locale, {
                          day: 'numeric',
                        })}{' '}
                        {new Date(publishedAt).toLocaleDateString(locale, {
                          month: 'long',
                        })}{' '}
                        {new Date(publishedAt).toLocaleDateString(locale, {
                          year: 'numeric',
                        })}
                      </ArticleInfo>
                    )}
                  </ArticleDetails>
                </Article>
              </AnimateText>
            </ArticleLink>
          ),
        )}
      </Articles>
    </Container>
  )
}

export default ArticleFeed
