import { useEffect, useRef } from 'react'

// Based on https://usehooks.com/useMemoCompare/ (unlicensed)
const useMemoCompare = <T>(
  nextValue: T,
  compare: (previousValue: T, nextValue: T) => boolean,
) => {
  const previousRef = useRef<T>()
  const previous = previousRef.current

  const isEqual = previous && compare(previous, nextValue)

  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = nextValue
    }
  })

  return isEqual ? previous : nextValue
}

export default useMemoCompare
