import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ColumnContentProps } from '.'
import Image from '../../components/Image'
import Link from '../../components/Link'
import { secondaryButtonCssYachtSupport } from '../../components/Button'
import VisualDivider from '../VisualDivider'
import { isSiteYachtSupport } from '../../themes'

const Container = styled.div(
  () => css`
    display: flex;
    justify-content: center;
  `,
)

const Section = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1440px;
    padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
    gap: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x6}px ${theme.spacing.x4}px;
      gap: ${theme.spacing.x3}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;
      padding: ${theme.spacing.x6}px ${theme.spacing.x10}px;
      gap: ${theme.spacing.x4}px;
    }
  `,
)

const HorizontalVisualDivider = styled(VisualDivider)(
  ({ theme }) => css`
    padding: ${theme.spacing.x2}px ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }

    &:last-of-type {
      display: none;
    }
  `,
)

const VerticalVisualDivider = styled(VisualDivider)(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: flex;
    }

    &:last-of-type {
      display: none;
    }
  `,
)

const Column = styled.div(
  ({ theme }) => css`
    gap: ${theme.spacing.x6}px;
    display: flex;
    text-align: center;
    flex-direction: column;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-direction: row;
      padding: ${theme.spacing.x3}px 0;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: column;
      padding: ${theme.spacing.x9}px 0;
    }
  `,
)

const Content = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: 0;
    }
  `,
)

const Overline = styled.div(
  ({ theme }) =>
    css`
      ${isSiteYachtSupport(theme) &&
      css`
        ${theme.text.overLineSmall(theme)};
      `}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-bottom: ${theme.spacing.x3}px;
    `,
)

const Heading = styled.h3(
  ({ theme }) =>
    css`
      ${theme.text.heading3(theme)};
      margin: 0 0 ${theme.spacing.x4}px 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        font-size: 28px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin: 0 0 ${theme.spacing.x5}px 0;
      }
    `,
)

const Description = styled.span(
  ({ theme }) =>
    css`
      ${isSiteYachtSupport(theme) &&
      css`
        ${theme.text.bodySmall(theme)};
      `}
      color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};
    `,
)

const StyledButton = styled(Link)(
  ({ theme }) =>
    css`
      ${secondaryButtonCssYachtSupport(theme)};
      margin-top: ${theme.spacing.x4}px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    `,
)

const MobileOrDesktopImage = styled(Image)(
  ({ theme }) =>
    css`
      margin: auto;
      display: flex;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: none;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        display: flex;
      }
    `,
)

const TabletImage = styled(Image)(
  ({ theme }) =>
    css`
      margin: auto;
      display: none;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: flex;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        display: none;
      }
    `,
)

export const ColumnContent = ({ columns }: ColumnContentProps) => (
  <Container data-testid="columnContent">
    <Section>
      {columns.map(
        ({
          key,
          image,
          overline,
          heading,
          description,
          buttonLabel,
          buttonLink,
        }) => (
          <>
            <Column key={key}>
              <MobileOrDesktopImage
                src={image.src}
                alt={image.alt ?? ''}
                width={280}
                height={280}
                aspectRatio="280:280"
              />
              <TabletImage
                src={image.src}
                alt={image.alt ?? ''}
                width={342}
                height={342}
                aspectRatio="342:342"
              />
              <Content>
                {overline && <Overline>{overline}</Overline>}
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                {buttonLabel && buttonLink && (
                  <StyledButton href={buttonLink}>{buttonLabel}</StyledButton>
                )}
              </Content>
            </Column>
            <HorizontalVisualDivider />
            <VerticalVisualDivider vertical />
          </>
        ),
      )}
    </Section>
  </Container>
)

export default ColumnContent
