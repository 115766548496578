import ContactHeader from '../../patterns/ContactHeader'
import RichText from '../../components/RichText'
import { StoryblokStory } from '../../utils/storyblok'
import ContactFormBlok from '../ContactFormBlok'
import { usePageContext } from '../PageContext'
import {
  ContactHeaderStoryblok,
  FormContactStoryblok,
  FormStoryblok,
} from '../storyblok.generated'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: ContactHeaderStoryblok
}

const ContactHeaderBlok = ({
  content: { title, text, image, formReference, linkText, overline },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  const form = rels.find(
    (rel) => rel.uuid === formReference,
  ) as StoryblokStory<FormStoryblok>

  return (
    <ContactHeader
      title={title}
      overline={overline}
      text={text && <RichText>{text}</RichText>}
      image={mapImage(image)}
      linkText={linkText}
      form={
        <ContactFormBlok
          form={form.content.formBlock[0] as FormContactStoryblok}
        />
      }
      {...others}
    />
  )
}

export default ContactHeaderBlok
