import { currentSite, Site } from '../../sites'
import QuoteCarouselYachtSupport from './QuoteCarousel.yachtsupport'

export type QuoteTextColorVariants = 'white' | 'oceanBlue'
export type BackgroundWaveColorVariants = 'white' | 'lightGreen' | 'darkGreen'
export interface QuoteCarouselProps {
  carouselItems: Array<{
    heading: string
    overline?: string
    key: string
  }>
  backgroundWaveColorVariant: BackgroundWaveColorVariants
  quoteTextColorVariant: QuoteTextColorVariants
}
const ImageWithHeading = (props: QuoteCarouselProps) => {
  switch (currentSite) {
    case Site.YachtSupport:
      return <QuoteCarouselYachtSupport {...props} />
    default:
      return <QuoteCarouselYachtSupport {...props} />
  }
}
export default ImageWithHeading
