import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, useState } from 'react'

import Button from '../components/Button'
import ImageCarousel from '../components/ImageCarousel'
import Section from '../components/Section'

const Container = styled(Section)(
  ({ theme }) => css`
    padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
    background: ${theme.colors.amels.oyster};
  `,
)
const Tabs = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    gap: ${theme.spacing.x10}px;
    margin-bottom: ${theme.spacing.x5}px;
  `,
)
const TabButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => [
  css`
    border: 0;
    border-bottom: 2px solid transparent;
    color: ${theme.colors.amels.beach};
    padding: ${theme.spacing.x1}px 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 3.43px;
  `,
  active &&
    css`
      border-bottom-color: currentColor;
    `,
])
const ImageCarouselContainer = styled.div(
  ({ theme }) => css`
    margin: 0 auto;
    position: relative;
    width: var(--width);
    height: var(--height);
    --width: calc(100vw - 32px * 2);
    --height: 560px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      --width: min(1088px, calc(100vw - 32px * 2));
      --height: 726px;
    }
  `,
)

interface Tab {
  key: string
  title: string
  images: Array<{
    key: string
    src: string
    alt?: string
  }>
}

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant'
  > {
  tabs: Tab[]
}

const TabbedCarousel = ({ tabs, ...others }: Props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  // eslint-disable-next-line security/detect-object-injection
  const activeTab = tabs[activeTabIndex]

  return (
    <Container
      data-testid="tabbedCarousel"
      sectionIndicatorVariant="dark"
      {...others}
    >
      {tabs.length > 1 && (
        <Tabs>
          {tabs.map(({ key, title }, index) => (
            <TabButton
              key={key}
              onClick={() => setActiveTabIndex(index)}
              active={activeTabIndex === index}
              data-testid={`tabbedCarousel.tab.${index}`}
            >
              {title}
            </TabButton>
          ))}
        </Tabs>
      )}

      <div>
        <ImageCarouselContainer>
          <ImageCarousel
            key={activeTabIndex}
            images={activeTab.images.map(({ key, src, alt }) => ({
              key,
              image: { src, alt },
            }))}
            previewSecondSlide
          />
        </ImageCarouselContainer>
      </div>
    </Container>
  )
}

export default TabbedCarousel
