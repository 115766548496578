import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MutableRefObject, ReactNode, useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { Transition, TransitionGroup } from 'react-transition-group'
import { ENTERED, ENTERING } from 'react-transition-group/Transition'

import { useReactiveVar } from '../utils/reactiveVar'
import smoothScrollTo from '../utils/smoothScrollTo'
import useDisableBodyScroll from '../utils/useDisableBodyScroll'
import useElementScrollLock from '../utils/useElementScrollLock'
import useViewportSize from '../utils/useViewportSize'
import { highlightVideoPresentingVar } from './PortfolioHighlights'
import { isSiteXplorer, isSiteYachtSupport } from '../themes'

const StyledAnimateHeight = styled(AnimateHeight)(({ theme }) => [
  css`
    background: ${theme.colors.amels.clearBayAqua};
    color: ${theme.colors.amels.silk};
  `,
  isSiteXplorer(theme) &&
    css`
      background: ${theme.colors.xplorer.secondaryXPMistGrey};
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
    `,
  isSiteYachtSupport(theme) &&
    css`
      background: ${theme.colors.yachtSupport.white};
      color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
    `,
])
const MobileInformationContainer = styled.div(({ theme }) => [
  css`
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: ${theme.spacing.x5}px ${theme.spacing.x4}px;
  `,
  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x4}px ${theme.spacing.x3}px;
    `,
])
const MobileInformationTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading1(theme)}
    color: ${theme.colors.amels.bayGold};
    margin: 0 0 ${theme.spacing.x3}px;
    font-size: ${theme.spacing.x6}px;
  `,
  isSiteXplorer(theme) &&
    css`
      color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading2(theme)}
      text-transform: none;
    `,
])

interface Props {
  title: ReactNode
  text: ReactNode
  containerRef: MutableRefObject<HTMLElement | null>
  imageContainerRef: MutableRefObject<HTMLElement | null>
}

const PortfolioHighlightsMobileInformation = ({
  title,
  text,
  containerRef,
  imageContainerRef,
  ...others
}: Props) => {
  const isPresenting = useReactiveVar(highlightVideoPresentingVar)

  const [infoContainerHeight, setInfoContainerHeight] = useState(0)
  const mobileInfoTransitionDuration = 2000
  const viewportSize = useViewportSize()
  useEffect(() => {
    const elem = imageContainerRef.current
    if (!elem || !viewportSize) {
      return
    }

    setInfoContainerHeight(
      Math.round(viewportSize.height - elem.getBoundingClientRect().height),
    )
  }, [imageContainerRef, viewportSize])
  useEffect(() => {
    const container = containerRef.current
    if (!container) {
      return
    }
    if (!isPresenting) {
      smoothScrollTo(container, mobileInfoTransitionDuration)
    }
  }, [isPresenting, containerRef])
  // Lock scroll position to the highlights image when showing details
  useElementScrollLock(
    imageContainerRef,
    isPresenting,
    mobileInfoTransitionDuration,
  )
  const scrollContainerRef = useDisableBodyScroll()

  return (
    <TransitionGroup component={null} {...others}>
      {isPresenting && (
        <Transition timeout={mobileInfoTransitionDuration}>
          {(transitionState) => (
            <StyledAnimateHeight
              duration={mobileInfoTransitionDuration}
              easing="cubic-bezier(0.65, 0, 0.35, 1)"
              height={
                transitionState === ENTERING || transitionState === ENTERED
                  ? infoContainerHeight
                  : 0
              }
            >
              <MobileInformationContainer
                style={{
                  height: infoContainerHeight,
                }}
                ref={scrollContainerRef}
              >
                {title && (
                  <MobileInformationTitle data-testid="highlightsBlok.aside.title">
                    {title}
                  </MobileInformationTitle>
                )}
                {text}
              </MobileInformationContainer>
            </StyledAnimateHeight>
          )}
        </Transition>
      )}
    </TransitionGroup>
  )
}

export default PortfolioHighlightsMobileInformation
