import { VisualDividerStoryblok } from '../storyblok.generated'
import VisualDivider from '../../patterns/VisualDivider'

interface Props {
  content: VisualDividerStoryblok
}

const VisualDividerBlok = ({ content: { hasPadding }, ...others }: Props) => {
  return <VisualDivider hasPadding={hasPadding} {...others} />
}

export default VisualDividerBlok
