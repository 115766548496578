import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import Link from '../components/Link'
import Image from '../components/Image'

import Waves from '../icons/Waves.svg'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'

const Container = styled(Section)(
  ({ theme }) => css`
    // For the wavez
    position: relative;
    z-index: 0;
    overflow: hidden;

    text-align: center;
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    background: ${theme.colors.amels.body};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x5}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x20}px;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    margin: 0;
    margin-bottom: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x9}px;
    }
  `,
)
const Items = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 20px;
      margin: 0 auto;
      max-width: 1058px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x8}px;
    }
  `,
)
const Item = styled(Link)(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ theme }) => css`
    text-align: left;
    flex-grow: 1;
    text-decoration: none;

    img {
      transition: transform ease-in-out 2s;
    }

    :hover {
      text-decoration: none;

      img {
        transform: scale(1.2);
      }
    }
  `,
)
const ItemTitle = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    font-size: ${theme.spacing.x4}px;
    margin: 8px 0 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: ${theme.spacing.x4}px;
    }
  `,
)
const ItemSubTitle = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fonts.Zermatt.style.fontFamily};
    font-weight: 500;
    color: ${theme.colors.amels.beach};
    text-transform: uppercase;
    font-size: ${theme.spacing.x2}px;
    letter-spacing: 4px;
  `,
)
const StyledWaves = styled(Waves)(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    transform: translateX(-67%);
    width: 130vw;
    top: -67vw;
    z-index: -1;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      transform: translateX(-74%);
      width: 115vw;
      top: -58vw;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      transform: translateX(-67%);
      width: 70vw;
      top: -11.5vw;
    }
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  items: Array<{
    key: string
    title: ReactNode
    subTitle: ReactNode
    image: {
      src: string
      alt?: string
    }
    href: string
  }>
}

const Showcases = ({ title, items, ...others }: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-300px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      id="showcases"
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      sectionIndicatorVariant="dark"
      {...others}
    >
      <StyledWaves aria-label="" />

      <Title>
        <AnimateText>{title}</AnimateText>
      </Title>

      <Items>
        {items?.map(({ key, title, subTitle, image, href }, index) => (
          <Item key={key} href={href}>
            <article>
              <AnimateText delay={500 + index * 600}>
                <Image
                  src={image.src}
                  alt={image.alt}
                  width={358}
                  height={272}
                  style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
                />
              </AnimateText>
              <AnimateText delay={1000 + index * 600}>
                <ItemTitle>{title}</ItemTitle>
              </AnimateText>
              <AnimateText delay={1500 + index * 600}>
                <ItemSubTitle>{subTitle}</ItemSubTitle>
              </AnimateText>
            </article>
          </Item>
        ))}
      </Items>
    </Container>
  )
}

export default Showcases
