import { css } from '@emotion/react'
import styled from '@emotion/styled'

import AnimateText from '../../components/AnimateText'
import Section from '../../components/Section'
import SpecificationGroup from '../../components/SpecificationGroup'
import SpecificationText from '../../components/SpecificationText'
import { PlainSpecificationsProps } from './index'

const Container = styled(Section)(
  ({ theme }) => css`
    background: ${theme.colors.xplorer.primaryXPLavaBlack};
    padding: ${theme.spacing.x10}px ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
    }
  `,
)
const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`
const SpecificationGroupWrapper = styled('div')<{ isHalved?: boolean }>(
  ({ theme, isHalved }) => [
    css`
      width: 100%;
    `,
    isHalved &&
      css`
        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          display: inline-flex;

          * > div {
            flex-flow: row;
          }
        }
        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          display: flex;
          width: calc(50% - 20px);

          &:nth-of-type(odd) {
            margin-right: ${theme.spacing.x5}px;
          }
        }
      `,
  ],
)
const Title = styled('h2')(({ theme }) => [
  css`
    ${theme.text.heading2(theme)};
    color: ${theme.colors.xplorer.secondaryXPBronze};
    margin: 0 0 ${theme.spacing.x7}px;
    text-align: center;
  `,
])

const PlainSpecifications = ({
  title,
  groups,
  ...others
}: PlainSpecificationsProps) => {
  return (
    <Container id="specifications" sectionIndicatorVariant="light" {...others}>
      <Wrapper>
        <AnimateText>
          <Title>{title}</Title>
        </AnimateText>
        {groups?.map(({ key, title, values, text, halved }) =>
          values ? (
            <SpecificationGroupWrapper
              key={key}
              isHalved={halved && values.length !== 4}
            >
              <SpecificationGroup key={key} title={title} values={values} />
            </SpecificationGroupWrapper>
          ) : (
            <SpecificationText key={key} title={title}>
              {text}
            </SpecificationText>
          ),
        )}
      </Wrapper>
    </Container>
  )
}

export default PlainSpecifications
