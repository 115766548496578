import TextWithImageCards from '../../patterns/TextWithImageCards'
import { TextWithImageCardsStoryblok } from '../storyblok.generated'

interface Props {
  content: TextWithImageCardsStoryblok
}

const TextWithImageCardsBlok = ({
  content: {
    layoutVariant,
    backgroundColorVariants,
    heading,
    cards,
    ...others
  },
}: Props) => {
  return (
    <TextWithImageCards
      layoutVariant={layoutVariant}
      backgroundColorVariants={backgroundColorVariants}
      heading={heading}
      cards={cards}
      {...others}
    />
  )
}

export default TextWithImageCardsBlok
