/**
 * This preloads the entire video. While standard browser preloading usually
 * suffices, as it only loads the chunk needed to be able to play through the
 * entire video without buffering, this seems to not work well in the highlights
 * section and cause the video to stutter massively. When the video can't be
 * loaded in correctly, it completely breaks the reverse video as well. Loading
 * the entire video ahead of time makes the video the most reliable.
 */
const preloadVideo = async (
  url: string,
  onProgress: (progress: number) => void,
) => {
  onProgress(0)
  const response = await fetch(url)
  if (!response.body) {
    return
  }

  const reader = response.body.getReader()

  const contentLength = Number(response.headers.get('Content-Length')) || 1

  let receivedLength = 0
  // We don't need the result, just let the video exist in cache
  // const chunks: Uint8Array[] = []
  const readChunk = async () => {
    const { done, value } = await reader.read()

    if (done) {
      return
    }

    // chunks.push(value)
    receivedLength += value.length

    const progress = receivedLength / contentLength
    onProgress(progress)

    await readChunk()
  }
  await readChunk()
}

export default preloadVideo
