import { css } from '@emotion/react'
import styled from '@emotion/styled'

import Section from '../../components/Section'
import SpecificationGroup from '../../components/SpecificationGroup'
import SpecificationText from '../../components/SpecificationText'
import { PlainSpecificationsProps } from './index'
import AnimateText from '../../components/AnimateText'
import { isSiteYachtSupport } from '../../themes'

const Container = styled(Section)(({ theme }) => [
  css`
    display: flex;
    justify-content: center;
    text-align: center;
    color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
    background: ${theme.colors.yachtSupport.ysWarmGrey10Solid};
    padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    }
  `,
])

const Wrapper = styled('div')(({ theme }) => [
  css`
    display: grid;
    flex: 1;
    gap: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 1280px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      gap: ${theme.spacing.x3}px;

      > div:first-of-type {
        width: 100%;
      }
    }
  `,
])

const Title = styled('h2')(({ theme }) => [
  css`
    ${theme.text.heading2(theme)};
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    text-align: center;
    ${isSiteYachtSupport(theme) &&
    css`
      margin-top: 0;
      margin-bottom: ${theme.spacing.x4}px;
    `}
  `,
])

const SubTitle = styled.span(({ theme }) => [
  css`
    ${isSiteYachtSupport(theme) &&
    css`
      ${theme.text.overLineMedium(theme)};
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    `}
  `,
])

const PlainSpecifications = ({
  title,
  subTitle,
  groups,
  ...others
}: PlainSpecificationsProps) => (
  <Container sectionIndicatorVariant="dark" {...others}>
    <Wrapper>
      <SubTitle>{subTitle}</SubTitle>
      <AnimateText>
        <Title>{title}</Title>
      </AnimateText>
      {groups.map(({ key, title, values, text }) =>
        values ? (
          <SpecificationGroup
            key={key}
            title={title}
            values={values}
            collapsible
            defaultIsExpanded
          />
        ) : (
          <SpecificationText key={key} title={title}>
            {text}
          </SpecificationText>
        ),
      )}
    </Wrapper>
  </Container>
)

export default PlainSpecifications
