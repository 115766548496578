import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import Link from 'next/link'
import { hiddenClassName } from '../../components/AnimateText'
import Image from '../../components/Image'
import Section from '../../components/Section'

import type { NavigationTeaserProps } from './index'
import { body, overLineMedium } from '../../themes/yachtsupport/text'

const Container = styled(Section)(() => css``)
const Column = styled.li`
  overflow: hidden;
`
const Menu = styled.menu<{ hasParallax?: boolean }>(
  ({ theme, hasParallax }) => css`
    display: grid;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    gap: ${theme.spacing.x3}px;
    max-width: 1280px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;

      ${hasParallax
        ? css`
            ${Column} {
              :not(:first-of-type):not(:last-of-type) {
                margin-top: ${theme.spacing.x10}px;
              }
            }
          `
        : ''}
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x5}px;
    }
  `,
)
const DesktopOverlay = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${theme.colors.yachtSupport.ysOceanBlue80};
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

    @media (prefers-reduced-motion: no-preference) {
      opacity: 0;
      will-change: opacity;
      transition: opacity 300ms ease-in-out;
    }

    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const ContentWithOverlay = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: ${theme.spacing.x6}px ${theme.spacing.x3}px;

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.4;
      background: linear-gradient(45deg, rgba(0, 0, 0, 0), #000);
      mix-blend-mode: multiply;
    }

    @media (prefers-reduced-motion: no-preference) {
      opacity: 1;
      will-change: opacity;
      transition: opacity 300ms ease-in-out;
    }
  `,
)
const DesktopContent = styled(ContentWithOverlay)(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const MobileContent = styled(ContentWithOverlay)(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
)
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  height: 100%;
`
const ContentOverlay = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
const Card = styled.div(
  ({ theme }) => css`
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      :hover ${DesktopOverlay} {
        opacity: 1;
      }

      :hover ${DesktopContent} {
        opacity: 0;
      }

      :hover ${ContentWithOverlay} {
        opacity: 0;
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      img {
        transform: scale(1.1);
        transform-origin: top right;
      }

      :not(.${hiddenClassName} &) {
        img {
          animation: ${keyframes`
          from {
            transform: scale(1.1);
          }
          to {
            transform: scale(1.0);
          }
        `} 1400ms ease-in-out forwards;
          animation-delay: calc(var(--index) * 250ms);
        }
      }
    }
  `,
)
const DesktopImageContainer = styled.div(
  ({ theme }) => css`
    line-height: 0;
    display: none;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const MobileImageContainer = styled.div(
  ({ theme }) => css`
    display: block;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
)
const MobileLinkContainer = styled(Link)(
  ({ theme }) => css`
    display: block;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    line-height: 1;
    color: ${theme.colors.yachtSupport.white};
    margin: 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 30px;
    }
  `,
)
const SubTitle = styled.div(
  ({ theme }) => css`
    ${overLineMedium(theme)}
    color: ${theme.colors.yachtSupport.white};
    margin-bottom: ${theme.spacing.x2}px;
  `,
)
const OverlayTitle = styled(Title)``
const OverlayText = styled.div(
  ({ theme }) => css`
    ${body(theme)}
    color: ${theme.colors.yachtSupport.white};
    margin-top: ${theme.spacing.x3}px;
  `,
)
const Logo = styled.div``
const StyledLink = styled(Link)(
  ({ theme }) => css`
    ${body(theme)}
    font-weight: 500;
    text-transform: uppercase;
    background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    color: ${theme.colors.yachtSupport.white};
    padding: 12px ${theme.spacing.x3}px;
    margin-top: ${theme.spacing.x5}px;
    align-self: center;
    :hover {
      background: ${theme.colors.yachtSupport.white};
      color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
      text-decoration: inherit;
    }
  `,
)

const NavigationTeaser = ({
  columns,
  hasParallax,
  ...others
}: NavigationTeaserProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
  })
  return (
    <Container
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      sectionIndicatorVariant="dark"
      {...others}
    >
      <Menu hasParallax={hasParallax}>
        {columns.map(
          (
            {
              key,
              title,
              subTitle,
              logo,
              image,
              mobileImage,
              hoverTitle,
              hoverSubtitle,
              hoverText,
              link,
              linkText,
            },
            index,
          ) => {
            return (
              <Column key={key}>
                <Card
                  style={{
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ['--index' as any]: index,
                  }}
                >
                  <DesktopImageContainer>
                    <Image
                      src={image.src}
                      alt={image.alt ?? ''}
                      width={400}
                      height={580}
                      aspectRatio="400:580"
                      style={{ objectFit: 'cover', maxWidth: '100%' }}
                    />
                  </DesktopImageContainer>
                  <MobileImageContainer>
                    <MobileLinkContainer href={link}>
                      <Image
                        src={(mobileImage || image).src}
                        alt={(mobileImage || image).alt ?? ''}
                        width={1124}
                        height={503}
                        // 1:1 works best for mobile/tablet (it sometimes is a little larger/smaller)
                        aspectRatio="1:1"
                        style={{ objectFit: 'cover', width: '100%' }}
                        sizes="(max-width: 767px) 704px, 1124px"
                      />
                      <MobileContent>
                        {logo?.src ? (
                          <Content>
                            <Logo>
                              <Image
                                src={logo.src}
                                alt={logo.alt ?? ''}
                                width={200}
                                height={44}
                                aspectRatio="200:44"
                              />
                            </Logo>
                          </Content>
                        ) : (
                          <Content>
                            {subTitle && <SubTitle>{subTitle}</SubTitle>}
                            <Title>{title}</Title>
                          </Content>
                        )}
                      </MobileContent>
                    </MobileLinkContainer>
                  </MobileImageContainer>
                  <DesktopContent>
                    {logo?.src ? (
                      <Content>
                        <Logo>
                          <Image
                            src={logo.src}
                            alt={logo.alt ?? ''}
                            width={220}
                            height={49}
                            aspectRatio="220:49"
                          />
                        </Logo>
                      </Content>
                    ) : (
                      <Content>
                        {subTitle && <SubTitle>{subTitle}</SubTitle>}
                        <Title>{title}</Title>
                      </Content>
                    )}
                  </DesktopContent>
                  <DesktopOverlay>
                    <ContentOverlay>
                      {logo?.src ? (
                        <Logo>
                          <Image
                            src={logo.src}
                            alt={logo.alt ?? ''}
                            width={220}
                            height={49}
                            aspectRatio="220:49"
                          />
                        </Logo>
                      ) : (
                        <>
                          {hoverSubtitle && (
                            <SubTitle>{hoverSubtitle}</SubTitle>
                          )}
                          <OverlayTitle aria-hidden>{hoverTitle}</OverlayTitle>
                        </>
                      )}
                      <OverlayText>{hoverText}</OverlayText>
                      <StyledLink href={link}>{linkText}</StyledLink>
                    </ContentOverlay>
                  </DesktopOverlay>
                </Card>
              </Column>
            )
          },
        )}
      </Menu>
    </Container>
  )
}

export default NavigationTeaser
