import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

const Container = styled.div`
  width: 100%;
  padding-top: var(--aspect-ratio);
  position: relative;
`

interface Props extends ComponentProps<typeof Container> {
  aspectRatio: string
  children: ReactNode
}

const parseAspectRatio = (aspectRatio: string) => {
  const parts = aspectRatio.split(':')
  if (parts.length !== 2) {
    throw new Error(
      'Aspect ratio is misformatted. Aspect ratio should be numeric "width:height".',
    )
  }

  const [width, height] = parts

  return `${((Number(height) / Number(width)) * 100).toFixed(2)}%`
}

const ImageAspectRatio = ({
  aspectRatio,
  children,
  style,
  ...others
}: Props) => (
  <Container
    style={{
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ['--aspect-ratio' as any]: parseAspectRatio(aspectRatio),
      ...style,
    }}
    {...others}
  >
    {children}
  </Container>
)

export default ImageAspectRatio
