import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Section from '../../components/Section'
import { TestimonialProps } from './index'
import Image from '../../components/Image'
import Quote from '../../icons/xplorer/Quote.svg'

const StyledSection = styled(Section)<{
  backgroundColor: TestimonialProps['backgroundColorVariants']
}>(({ theme, backgroundColor }) => [
  backgroundColor === 'lightGreen' &&
    css`
      background: ${theme.colors.xplorer.secondaryXPMistGrey};
    `,
  backgroundColor === 'white' &&
    css`
      background: ${theme.colors.xplorer.white};

      path {
        fill: ${theme.colors.xplorer.secondaryXPMistGrey};
      }
    `,
  backgroundColor === 'lightSand' &&
    css`
      background: ${theme.colors.xplorer.xpBronze10};
    `,
  css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      gap: ${theme.spacing.x10}px;
    }
  `,
])

const Container = styled.div<{
  imagePosition: TestimonialProps['imagePosition']
}>(
  ({ theme, imagePosition }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing.x6}px;
    margin: 0 auto;
    max-width: 1280px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: ${imagePosition === 'right' ? 'row-reverse' : 'row'};
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x10}px;
    }
  `,
)

const StyledImage = styled(Image)(
  ({ theme }) => css`
    object-fit: cover;

    @media screen and (min-width: ${theme.breakpoints
        .tablet}px) and (max-width: ${theme.breakpoints.desktop}px) {
      width: 240px;
      height: 240px;
    }
  `,
)

const ContentWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing.x7}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x6}px;
    }
  `,
)

const IconQuote = styled(Quote)(
  ({ theme }) => css`
    width: 115px;
    height: 80px;
    transform: rotate(180deg);
    color: ${theme.colors.xplorer.white};
  `,
)

const QuoteText = styled('h3')<{
  textColor: TestimonialProps['quoteTextColorVariants']
}>(({ theme, textColor }) => [
  css`
    ${theme.text.heading3(theme)}
    margin: 0;
    color: ${theme.colors.xplorer.secondaryXPBronze};
    font-size: 26px;

    p {
      margin: 0;
      ::before {
        content: '“';
      }

      ::after {
        content: '”';
      }
    }
  `,
  textColor === 'bronze' &&
    css`
      color: ${theme.colors.xplorer.secondaryXPBronze};
    `,
  textColor === 'darkOcean' &&
    css`
      color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
    `,
  textColor === 'lavaBlack' &&
    css`
      color: ${theme.colors.xplorer.primaryXPLavaBlack};
    `,
])

const Author = styled.p(
  ({ theme }) => css`
    ${theme.text.heading6 && theme.text.heading6(theme)}
    margin: 0;
    color: ${theme.colors.xplorer.primaryXPLavaBlack};
  `,
)

const Testimonial = ({
  image,
  quote,
  quoteeName,
  quoteeFunction,
  imagePosition,
  backgroundColorVariants,
  quoteTextColorVariants,
  ...others
}: TestimonialProps) => {
  const separator = quoteeName && quoteeFunction ? '-' : ''

  return (
    <StyledSection
      sectionIndicatorVariant="none"
      backgroundColor={backgroundColorVariants}
      {...others}
    >
      <Container imagePosition={imagePosition}>
        <StyledImage
          src={image.src}
          alt={image.alt}
          width={420}
          height={420}
          aspectRatio="420:420"
        />
        <ContentWrapper>
          <IconQuote />
          <QuoteText textColor={quoteTextColorVariants}>{quote}</QuoteText>
          <Author>
            {quoteeName}
            {quoteeFunction && (
              <>
                {' '}
                {separator} {quoteeFunction}
              </>
            )}
          </Author>
        </ContentWrapper>
      </Container>
    </StyledSection>
  )
}

export default Testimonial
