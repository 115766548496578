import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import Image from '../components/Image'
import { whiteOutlineButtonCss } from '../components/Button'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'

const Container = styled(Section)(
  ({ theme }) => css`
    position: relative;
    height: 834px;
    padding: ${theme.spacing.x5}px ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x11}px ${theme.spacing.x10}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x20}px;
      height: 1000px;
    }
  `,
)
const Content = styled.div`
  position: relative;
  z-index: 1;
  text-align: right;
`
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading1Light(theme)};
    margin: 0;
    margin-bottom: ${theme.spacing.x7}px;
    line-height: 1;
    font-size: 80px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x10}px;
    }

    p {
      margin: 0;
    }
  `,
)
const CallToActionButton = styled.button(({ theme }) =>
  whiteOutlineButtonCss(theme),
)

interface ImageInfo {
  src: string
  alt?: string
}

interface Props
  extends Omit<ComponentProps<typeof Container>, 'children' | 'title'> {
  backgroundImage: ImageInfo
  title: ReactNode
  callToActionText?: ReactNode
  onCallToActionClick?: () => void
  'data-testid'?: string
}

const ImageSection = ({
  backgroundImage,
  title,
  callToActionText,
  onCallToActionClick,
  'data-testid': testid = 'imageSection',
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-100px 0px',
    threshold: 0.4,
    triggerOnce: true,
  })

  return (
    <Container
      data-testid={testid}
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      {...others}
    >
      <Image
        src={backgroundImage.src}
        alt={backgroundImage.alt}
        fill
        style={{ objectFit: 'cover' }}
      />

      <Content>
        <Title>
          <AnimateText>{title}</AnimateText>
        </Title>
        <AnimateText delay={120}>
          <CallToActionButton
            onClick={onCallToActionClick}
            data-testid={`${testid}.callToAction`}
          >
            {callToActionText}
          </CallToActionButton>
        </AnimateText>
      </Content>
    </Container>
  )
}

export default ImageSection
