import FullWidthImage, { AspectRatio } from '../../patterns/FullWidthImage'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import slugify from '../../utils/slugify'
import { FullWidthImageStoryblok } from '../storyblok.generated'

interface Props {
  content: FullWidthImageStoryblok
}

const FullWidthImageBlok = ({
  content: { sectionTitle, image, mobileImage, aspectRatio },
  ...others
}: Props) => (
  <FullWidthImage
    id={slugify(sectionTitle)}
    image={mapImage(image) ?? getRequiredImageFallback()}
    mobileImage={mapImage(mobileImage)}
    aspectRatio={aspectRatio as AspectRatio}
    {...others}
  />
)

export default FullWidthImageBlok
