import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { summaryWithLinkContentLocation, SummaryWithLinkProps } from './index'
import { body, heading2, overlineMedium } from '../../themes/xplorer/text'
import Image from '../../components/Image'
import { Props } from '../../components/NavigationBar/NavigationBar'
import ImageAspectRatio from '../../components/ImageAspectRatio'
import {
  secondaryOutlineButtonCssXplorer,
  secondaryOutlineInvertedButtonCssXplorer,
} from '../../components/Button'
import Link from '../../components/Link'

const Container = styled.div<{
  contentLocation: summaryWithLinkContentLocation
}>(
  ({ theme, contentLocation }) => css`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;
      align-items: center;
      justify-content: ${contentLocation === 'left'
        ? 'flex-start'
        : 'flex-end'};
      padding: ${theme.spacing.x10}px;
    }
  `,
)

const Title = styled.h5<Pick<Props, 'variant'>>(
  ({ theme, variant }) =>
    css`
      ${heading2(theme)}
      color: ${variant === 'light'
        ? theme.colors.xplorer.primaryXPDarkOceanGreen
        : theme.colors.xplorer.white};
      margin: 0;

      p {
        margin: 0;
      }
    `,
)

const SubTitle = styled.div(
  ({ theme }) =>
    css`
      ${overlineMedium(theme)}
      color: ${theme.colors.xplorer.secondaryXPBronze};
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
)

const Description = styled.div(
  ({ theme }) => css`
    ${body(theme)}
    margin: ${theme.spacing.x4}px 0 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin: ${theme.spacing.x7}px 0 0;
    }
  `,
)

const Card = styled.div<Pick<Props, 'variant'>>(
  ({ theme, variant }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: ${theme.spacing.x7}px ${theme.spacing.x3}px;
      background: ${variant === 'light'
        ? theme.colors.xplorer.secondaryXPMistGrey
        : theme.colors.xplorer.primaryXPDarkOceanGreen};
      color: ${variant === 'light'
        ? theme.colors.xplorer.primaryXPLavaBlack
        : theme.colors.xplorer.white};
      order: 1;
      position: relative;
      z-index: 3;
      text-align: center;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        max-width: 648px;
        width: 50%;
        min-height: 640px;
        padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
        background: ${variant === 'light'
          ? 'rgba(255, 255, 255, 0.9)'
          : theme.colors.xplorer.primaryXPDarkOceanGreen};
      }
    `,
)

const OverlayImageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'contentLocation',
})<{ contentLocation?: summaryWithLinkContentLocation }>(
  ({ theme, contentLocation }) => css`
    position: absolute;
    top: 28px;
    left: ${contentLocation === 'left' ? 0 : ''};
    right: ${contentLocation === 'right' ? 0 : ''};
    bottom: 28px;
    width: 50%;
    z-index: 2;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      :not(:has(img)):after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        opacity: 0.4;
        background-image: radial-gradient(
          ${theme.colors.xplorer.white} 1.5px,
          transparent 0
        );
        background-size: 34px 40px;
        background-repeat: repeat;
      }
    }
  `,
)

const BackgroundImageContainer = styled(ImageAspectRatio)(
  ({ theme }) => css`
    order: 2;
    height: 100%;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  `,
)

const StyledLink = styled(Link)<Pick<Props, 'variant'>>(
  ({ theme, variant }) => [
    css`
      pointer-events: auto;
      margin-top: ${theme.spacing.x4}px;

      :hover {
        text-decoration: none;
      }
    `,
    variant === 'light'
      ? secondaryOutlineButtonCssXplorer(theme)
      : secondaryOutlineInvertedButtonCssXplorer(theme),
  ],
)

const SummaryWithLink = ({
  variant = 'light',
  contentLocation = 'left',
  backgroundImage,
  overlayImage,
  summaryWithLinkCard: { subTitle, title, description, link, linkText },
  ...others
}: SummaryWithLinkProps) => (
  <Container contentLocation={contentLocation} {...others}>
    <BackgroundImageContainer aspectRatio="16:9">
      <Image
        src={backgroundImage.src}
        alt={backgroundImage.alt}
        fill
        style={{ objectFit: 'cover' }}
        aspectRatio="16:9"
      />
    </BackgroundImageContainer>
    {overlayImage && (
      <OverlayImageContainer contentLocation={contentLocation}>
        <Image
          src={overlayImage.src}
          alt={overlayImage.alt}
          width={720}
          height={844}
          aspectRatio="720:844"
          style={{ objectFit: 'cover', width: '100%' }}
        />
      </OverlayImageContainer>
    )}
    <Card variant={variant}>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
      <Title variant={variant}>{title}</Title>
      {description && <Description>{description}</Description>}
      <StyledLink variant={variant} href={link}>
        {linkText}
      </StyledLink>
    </Card>
  </Container>
)

export default SummaryWithLink
