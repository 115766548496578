import makeCloudinaryImageUrl from './makeCloudinaryImageUrl'

const remakeCloudinaryImageUrl = ({
  url,
  transformations = [],
}: {
  url: string
  transformations?: string[]
}) => {
  const publicId = url.substring(
    url.indexOf('/image/upload/') + '/image/upload/'.length,
  )

  return makeCloudinaryImageUrl({ publicId, transformations })
}

export default remakeCloudinaryImageUrl
