import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import squarePolygon from '../utils/squarePolygon'
import Section from '../components/Section'

const Container = styled(Section, {
  shouldForwardProp: (prop) => prop !== 'imageLocation',
})<{ imageLocation: 'left' | 'right' }>(
  ({ theme, imageLocation }) => css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px;
      display: flex;
      flex-direction: ${imageLocation === 'left' ? 'row-reverse' : 'row'};
      gap: ${theme.spacing.x6}px;
      justify-content: center;
      margin: ${theme.spacing.x10}px 0;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x6}px;
      gap: ${theme.spacing.x20}px;
    }
  `,
)
const ContentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex: 1 1 50%;
      padding: ${theme.spacing.x7}px 0;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x14}px 0;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.amels.beach};
    letter-spacing: 0;
    margin-top: ${theme.spacing.x2}px;
    margin-bottom: ${theme.spacing.x7}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-left: ${theme.spacing.x14}px;
    }
  `,
)
const SubTitle = styled.div(
  ({ theme }) => css`
    ${theme.text.subHeading(theme)};

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-left: ${theme.spacing.x6}px;
    }
  `,
)
const Text = styled.div(
  ({ theme }) =>
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-left: ${theme.spacing.x14}px;
      }
    `,
)
const MobileImageContainer = styled.div(
  ({ theme }) =>
    css`
      display: block;
      margin-bottom: ${theme.spacing.x7}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: none;
      }

      @media (prefers-reduced-motion: no-preference) {
        > * {
          transform: scale(1.1);
          transform-origin: top right;
        }

        :not(.${hiddenClassName} &) {
          clip-path: ${squarePolygon(0, 0, 0, 1)};
          animation: ${keyframes`
            from {
              clip-path: ${squarePolygon(0, 0, 0, 1)};
            }
            to {
              clip-path: ${squarePolygon(0, 0, 0, 0)};
            }
          `} 1400ms ease-in-out forwards;

          > * {
            animation: ${keyframes`
              from {
                transform: scale(1.1);
              }
              to {
                transform: scale(1.0);
              }
            `} 1400ms ease-in-out forwards;
          }
        }
      }
    `,
)
const TabletImageColumn = styled.div(
  ({ theme }) => css`
    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
      flex: 1 1 50%;
    }
  `,
)
const TabletImagePositioner = styled.div(
  () =>
    css`
      height: 80vh;
      min-height: 100%;
      max-height: 810px;
      position: relative;

      @media (prefers-reduced-motion: no-preference) {
        clip-path: ${squarePolygon(0, 0, 0, 1)};
        > * {
          transform: scale(1.1);
          transform-origin: top right;
        }

        :not(.${hiddenClassName} &) {
          animation: ${keyframes`
            from {
              clip-path: ${squarePolygon(0, 0, 0, 1)};
            }
            to {
              clip-path: ${squarePolygon(0, 0, 0, 0)};
            }
          `} 1400ms ease-in-out forwards;

          > * {
            animation: ${keyframes`
              from {
                transform: scale(1.1);
              }
              to {
                transform: scale(1.0);
              }
            `} 1400ms ease-in-out forwards;
          }
        }
      }
    `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'imageLocation' | 'title' | 'children' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  subTitle: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
  imageLocation?: 'left' | 'right'
  'data-testid'?: string
}

const DesignTextWithImage = ({
  title,
  subTitle,
  text,
  image,
  imageLocation = 'right',
  className,
  'data-testid': testid = 'designTextWithImage',
  ...others
}: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      imageLocation={imageLocation}
      ref={containerRef}
      className={`${className || ''} ${!inView ? hiddenClassName : ''}`}
      data-testid={testid}
      sectionIndicatorVariant="dark"
      {...others}
    >
      <ContentContainer>
        <div>
          <AnimateText>
            <SubTitle>{subTitle}</SubTitle>
          </AnimateText>
          <AnimateText delay={60}>
            <Title>{title}</Title>
          </AnimateText>

          <MobileImageContainer>
            <Image
              src={image.src}
              alt={image.alt}
              width={358}
              height={300}
              style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
            />
          </MobileImageContainer>

          <Text>
            <AnimateText delay={300}>{text}</AnimateText>
          </Text>
        </div>
      </ContentContainer>

      <TabletImageColumn>
        <TabletImagePositioner>
          <Image
            src={image.src}
            alt={image.alt}
            fill
            style={{ objectFit: 'cover' }}
            sizes="50vw"
          />
        </TabletImagePositioner>
      </TabletImageColumn>
    </Container>
  )
}

export default DesignTextWithImage
