import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../../components/AnimateText'
import Image from '../../components/Image'
import Section from '../../components/Section'

import type { TextWithImageProps } from './index'
import Link from '../../components/Link'
import { secondaryButtonCssYachtSupport } from '../../components/Button'

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  imageLocation: TextWithImageProps['imageLocation']
  backgroundImage: TextWithImageProps['backgroundImage']
}>(({ imageLocation, backgroundImage }) => [
  css`
    width: 100%;
    height: auto;
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    backgroundImage &&
    css`
      z-index: 1;
    `,
])
const ImageContainer = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  imageLocation: TextWithImageProps['imageLocation']
  backgroundImage: TextWithImageProps['backgroundImage']
}>(({ theme, imageLocation, backgroundImage }) => [
  css`
    position: relative;
    display: flex;
    flex: 0 1 auto;
    overflow: hidden;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
      flex-direction: column;
      align-items: center;
      max-height: 540px;

      ${StyledImage} {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    backgroundImage &&
    css`
      overflow: visible;
    `,
])
const LogoContainer = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x5}px;
  `,
)

const Overline = styled('span')(({ theme }) => [
  css`
    ${theme.text.overLineSmall && theme.text.overLineSmall(theme)}
    display: block;
    margin-bottom: ${theme.spacing.x2}px;
    text-transform: uppercase;
    color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
])
const Title = styled('h2')(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: 900;
    margin: 0 0 ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.mobileMedium}px) {
      font-size: 28px;
      margin: 0 0 ${theme.spacing.x6}px;
    }
  `,
])
const Text = styled.div(
  ({ theme }) =>
    css`
      ${theme.text.body(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};

      p {
        margin: 0;
        text-align: center;
      }
    `,
)
const Content = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.colors.yachtSupport.grey03};
    padding: ${theme.spacing.x6}px ${theme.spacing.x3}px ${theme.spacing.x7}px
      ${theme.spacing.x3}px;

    ${Overline},
    ${Title} {
      text-align: center;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x12}px ${theme.spacing.x8}px;
      max-width: 480px;
      width: 100%;
    }
  `,
)
const ContentContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'imageLocation',
})<{
  imageLocation: TextWithImageProps['imageLocation']
}>(({ theme, imageLocation }) => [
  css`
    width: 375px;
    padding: ${theme.spacing.x5}px ${theme.spacing.x4}px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-direction: row;
      width: 1440px;
      height: 676px;
    }
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    css`
      align-items: stretch;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-flow: ${imageLocation === 'left' ? 'row' : 'row-reverse'};
      }
    `,
])

const Container = styled(Section, {
  shouldForwardProp: (prop) =>
    prop !== 'imageLocation' && prop !== 'backgroundImage',
})<{
  background: TextWithImageProps['background']
}>(({ theme, background }) => [
  css`
    z-index: 0;
    display: flex;
    justify-content: center;
  `,
  background === 'white' &&
    css`
      background: ${theme.colors.yachtSupport.white};
    `,
  background === 'silver10' &&
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px;
      }
      background: ${theme.colors.yachtSupport.ysCarbonBlack10Solid};
    `,
])

const StyledLinkWrapper = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: ${theme.spacing.x6}px;
    }
  `,
)

const StyledLink = styled(Link)(({ theme }) => [
  css`
    ${secondaryButtonCssYachtSupport(theme)}
    width: fit-content;
  `,
])

const TextWithImage = ({
  overline,
  title,
  text,
  image,
  imageLocation = 'right',
  background,
  backgroundImage,
  logo,
  link,
  triggerFormCallToAction,
  ...others
}: TextWithImageProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  const imageOrientation =
    imageLocation === 'left' || imageLocation === 'right'
      ? 'portrait'
      : 'landscape'

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      background={background}
      data-testid="textWithImagex"
      ref={containerRef}
      {...others}
    >
      <ContentContainer imageLocation={imageLocation}>
        <ImageContainer
          imageLocation={imageLocation}
          backgroundImage={backgroundImage}
        >
          {imageOrientation === 'portrait' && (
            <StyledImage
              src={image.src}
              alt={image.alt}
              width={896}
              height={570}
              style={{ objectFit: 'cover' }}
              imageLocation={imageLocation}
              backgroundImage={backgroundImage}
            />
          )}
          {imageOrientation === 'landscape' && (
            <StyledImage
              src={image.src}
              alt={image.alt}
              width={1200}
              height={800}
              style={{ objectFit: 'cover' }}
              imageLocation={imageLocation}
              backgroundImage={backgroundImage}
            />
          )}
        </ImageContainer>
        <Content>
          {logo?.src ? (
            <AnimateText>
              <LogoContainer>
                <Image
                  src={logo.src}
                  alt={logo.alt ?? ''}
                  width={272}
                  height={60}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    maxWidth: '272px',
                  }}
                />
              </LogoContainer>
            </AnimateText>
          ) : (
            <AnimateText>
              {overline && <Overline>{overline}</Overline>}
              <Title>{title}</Title>
            </AnimateText>
          )}
          <AnimateText delay={400}>
            <Text>{text}</Text>
          </AnimateText>
          <StyledLinkWrapper>
            {triggerFormCallToAction || (
              <AnimateText delay={800}>
                {link && (
                  <StyledLink href={link.href} target={link?.target}>
                    {link.title}
                  </StyledLink>
                )}
              </AnimateText>
            )}
          </StyledLinkWrapper>
        </Content>
      </ContentContainer>
    </Container>
  )
}

export default TextWithImage
