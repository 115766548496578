import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../../components/AnimateText'
import Section from '../../components/Section'

import type { TextWithVideoProps } from './index'
import { isSiteYachting } from '../../themes'
import VideoPlayer from '../../components/VideoPlayer'
import makeVideoSources from '../../utils/makeVideoSources'
import { createImageLoader } from '../../utils/createImageLoader'

const Container = styled(Section)<{
  imageLocation: TextWithVideoProps['imageLocation']
  background: TextWithVideoProps['background']
}>(
  ({ theme, background, imageLocation }) =>
    isSiteYachting(theme) && [
      css`
        background: ${theme.colors.yachting.white};
        padding: ${theme.spacing.x6}px ${theme.spacing.x4}px;
        z-index: 0;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          padding: ${theme.spacing.x20}px ${theme.spacing.x10}px;
        }
        @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
          padding: ${theme.spacing.x20}px;
        }
      `,
      (imageLocation === 'left' || imageLocation === 'right') &&
        background === 'beach10' &&
        css`
          background: ${theme.colors.yachting.beach10};
        `,
      (imageLocation === 'left' || imageLocation === 'right') &&
        background === 'white' &&
        css`
          background: ${theme.colors.yachting.white};
        `,
    ],
)
const ImageContainer = styled.div<{
  imageLocation: TextWithVideoProps['imageLocation']
}>(
  ({ theme, imageLocation }) =>
    isSiteYachting(theme) && [
      css`
        position: relative;
        display: flex;
        flex: 0 0 auto;
        max-width: 100%;
        overflow: hidden;
        justify-content: center;
        width: 100%;

        @media screen and (max-width: ${theme.breakpoints.mobileMax}px) {
          flex-direction: column;
          align-items: center;
          max-height: 540px;

          img {
            width: 100%;
            height: auto;
          }
        }
      `,
      (imageLocation === 'top' || imageLocation === 'bottom') &&
        css`
          max-height: 800px;

          ::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 8px;
            background: ${theme.colors.yachting.beach};
          }

          img {
            width: 100%;
            height: auto;
          }
        `,
    ],
)
const Content = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 600px;
    }
  `,
)
const Overline = styled.span(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)}
      display: block;
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `}
  `,
)
const Title = styled.h2(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin: 0 0 ${theme.spacing.x4}px;
    `,
)
const Text = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `,
)
const ContentContainer = styled.div<{
  imageLocation: TextWithVideoProps['imageLocation']
}>(({ theme, imageLocation }) => [
  css`
    ${theme.layout.maxContentWidth(theme)};
    display: flex;
    position: relative;
    gap: ${theme.spacing.x4}px;
    justify-content: center;
    flex-flow: column;

    ${Overline},
    ${Text} {
      margin-left: ${theme.spacing.x2}px;
    }
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      ${Overline},
      ${Text} {
        margin-left: ${theme.spacing.x3}px;
      }
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: ${theme.spacing.x10}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      gap: ${theme.spacing.x15}px;
    }
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    css`
      align-items: stretch;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-flow: ${imageLocation === 'left' ? 'row' : 'row-reverse'};
        align-items: center;

        > div {
          width: 50%;
        }
      }
    `,
  (imageLocation === 'top' || imageLocation === 'bottom') &&
    css`
      flex-flow: ${imageLocation === 'top' ? 'column' : 'column-reverse'};
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        gap: ${theme.spacing.x4}px;
      }
    `,
])

const TextWithVideo = ({
  overline,
  title,
  text,
  image,
  imageLocation = 'right',
  background,
  video,
  ...others
}: TextWithVideoProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  const imageLoader = createImageLoader(image.src)

  const poster = imageLoader.loader
    ? imageLoader.loader({ src: imageLoader.src as string, width: 1920 })
    : image.src

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="textWithVideo"
      imageLocation={imageLocation}
      background={background}
      ref={containerRef}
      {...others}
    >
      <ContentContainer imageLocation={imageLocation}>
        <AnimateText direction="static">
          <ImageContainer imageLocation={imageLocation}>
            <VideoPlayer poster={poster}>{makeVideoSources(video)}</VideoPlayer>
          </ImageContainer>
        </AnimateText>
        <Content>
          <AnimateText>
            {overline && <Overline>{overline}</Overline>}
            <Title>{title}</Title>
          </AnimateText>
          <AnimateText delay={400}>
            <Text>{text}</Text>
          </AnimateText>
        </Content>
      </ContentContainer>
    </Container>
  )
}

export default TextWithVideo
