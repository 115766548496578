import { ComponentProps, ReactNode } from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import Section from '../components/Section'
import {
  isSiteYachting,
  isSiteAmels,
  isSiteXplorer,
  isSiteYachtSupport,
} from '../themes'
import SidePanelButton from '../components/SidePanelButton'
import {
  buttonCssXplorer,
  primaryOutlineButtonCssYachtSupport,
  secondaryOutlineButtonCss,
} from '../components/Button'
import { body, heading2, overlineMedium } from '../themes/xplorer/text'
import { overLineMedium } from '../themes/yachtsupport/text'
import { Site, currentSite } from '../sites'

const Container = styled(Section)<{
  background?: SplitTextWithImageProps['background']
}>(({ theme, background }) => [
  css`
    z-index: 2;
    overflow: visible;
  `,
  isSiteXplorer(theme) &&
    css`
      padding: 0 0 ${theme.spacing.x8}px;

      @media screen and (max-width: ${theme.breakpoints.tablet}px) {
        background: ${background === 'lightGreen'
          ? theme.colors.xplorer.secondaryXPMistGrey
          : theme.colors.xplorer.secondaryXPBronze10Extra};
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: 0 ${theme.spacing.x4}px ${theme.spacing.x8}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x10}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin: 0 auto;
      padding: ${theme.spacing.x3}px;
      max-width: 1440px;
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x4}px;
      }
    `,
])
const ImageContainer = styled.div(
  ({ theme }) =>
    css`
      position: relative;
      display: flex;
      max-width: 100%;
      overflow: hidden;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      @media screen and (max-width: ${theme.breakpoints.tabletMax}px) {
        position: relative;
        flex-direction: column;
        align-items: center;
        max-height: 540px;

        img {
          position: relative;
          width: 100%;
          height: auto;
        }
      }
    `,
)

const Content = styled('div')(({ theme }) => [
  css`
    padding: ${theme.spacing.x6}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 600px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      text-align: center;
      width: 100%;
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px ${theme.spacing.x5}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x12}px ${theme.spacing.x4}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      border: 1px solid ${theme.colors.yachtSupport.grey03};
      text-align: center;
      width: 100%;
      padding: ${theme.spacing.x5}px ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: none;
        padding: ${theme.spacing.x15}px ${theme.spacing.x6}px;
      }
    `,
])
const Overline = styled('span')(({ theme }) => [
  css`
    position: relative;
    display: block;
    margin-bottom: ${theme.spacing.x2}px;
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)}
    `,
  isSiteXplorer(theme) &&
    css`
      ${overlineMedium(theme)};
      text-transform: uppercase;
      color: ${theme.colors.xplorer.secondaryXPBronze};
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${overLineMedium(theme)};
      text-transform: uppercase;
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
])
const Title = styled('h2')(({ theme }) => [
  css`
    ${theme.text.heading2(theme)};
    margin: 0 0 ${theme.spacing.x4}px;
  `,
  isSiteXplorer(theme) &&
    css`
      ${heading2(theme)};
      color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
      margin: 0 0 ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x5}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
      margin: 0 0 ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x5}px;
      }
    `,
])
const Text = styled('div')(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.darkBlue};
    `,
  isSiteXplorer(theme) &&
    css`
      ${body(theme)};
      margin: 0 0 ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0 0 ${theme.spacing.x5}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.body(theme)};
      color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};
      margin: 0 0 ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0 0 ${theme.spacing.x5}px;
      }
    `,
])
const StyledSidePanelButton = styled(SidePanelButton)(({ theme }) => [
  css`
    margin-top: ${theme.spacing.x4}px;
  `,
  isSiteAmels(theme) && secondaryOutlineButtonCss(theme),
  isSiteXplorer(theme) && buttonCssXplorer(theme, 'secondary'),
  isSiteXplorer(theme) &&
    css`
      width: fit-content;
      margin-top: 0;
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${primaryOutlineButtonCssYachtSupport(theme)}
      width: fit-content;
      margin-top: 0;
    `,
])
const StyledAnimateText = styled(AnimateText)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin-top: -${theme.spacing.x10}px;
        margin-bottom: -${theme.spacing.x10}px;
        overflow: visible;
      }
    `,
)
const ContentWrapper = styled('div')(({ theme }) => [
  css`
    display: flex;

    ${Overline},
    ${Text},
    ${StyledSidePanelButton} {
      margin-left: ${theme.spacing.x2}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      ${Overline},
      ${Text},
      ${StyledSidePanelButton} {
        margin-left: ${theme.spacing.x3}px;
      }
    }
  `,
  isSiteXplorer(theme) &&
    css`
      ${Overline},
      ${Text},
    ${StyledSidePanelButton} {
        margin-left: 0;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        ${Overline},
        ${Text},
      ${StyledSidePanelButton} {
          margin-left: 0;
        }
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${Overline},
      ${Text},
    ${StyledSidePanelButton} {
        margin-left: 0;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        ${Overline},
        ${Text},
      ${StyledSidePanelButton} {
          margin-left: 0;
        }
      }
    `,
])
const ContentContainer = styled('div')<{
  imageLocation: SplitTextWithImageProps['imageLocation']
  background: SplitTextWithImageProps['background']
}>(({ theme, imageLocation, background }) => [
  css`
    display: flex;
    position: relative;
    justify-content: center;
    flex-flow: column-reverse;

    ${background === 'darkBlue' &&
    css`
      background: ${theme.colors.yachting.darkBlue};

      ${Overline},
      ${Text},
      ${Title} {
        color: ${theme.colors.yachting.white};
      }
    `}

    ${background === 'beach30' &&
    css`
      background: ${theme.colors.yachting.beach30};
    `}

    ${background === 'silver30' &&
    css`
      background: ${theme.colors.yachting.silver30};
    `}

    ${background === 'lightGreen' &&
    css`
      background: ${theme.colors.xplorer.secondaryXPMistGrey};
    `}

    ${background === 'sandLight' &&
    css`
      background: ${theme.colors.xplorer.secondaryXPBronze10Extra};
    `}
  `,
  (imageLocation === 'left' || imageLocation === 'right') &&
    css`
      align-items: stretch;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        flex-flow: ${imageLocation === 'left' ? 'row' : 'row-reverse'};
        justify-content: stretch;

        > div {
          width: 50%;
        }
      }

      ${!isSiteXplorer(theme) && !isSiteYachtSupport(theme) && ContentWrapper} {
        justify-content: ${imageLocation === 'left' ? 'start' : 'end'};

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          padding-left: ${imageLocation === 'left' ? theme.spacing.x8 : 0}px;
          padding-right: ${imageLocation === 'right' ? theme.spacing.x8 : 0}px;
        }
      }

      ${isSiteXplorer(theme) && ContentWrapper} {
        justify-content: center;
      }

      ${isSiteYachtSupport(theme) && ContentWrapper} {
        justify-content: center;
      }
    `,
])

const StyledTitle = ({ title }: { title: string }) => {
  const delimiter = '—'
  const lines = title.split(delimiter)
  const lineAfterDelimiter = lines.pop()
  const LineAfterDelimiterSpan = styled.span(
    ({ theme }) =>
      css`
        color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      `,
  )

  return (
    <Title>
      {lines.length === 0 && title}
      {lines.length > 0 && lines + delimiter}
      {lines.length > 0 && (
        <LineAfterDelimiterSpan>{lineAfterDelimiter}</LineAfterDelimiterSpan>
      )}
    </Title>
  )
}

export interface SplitTextWithImageProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant' | 'title'
  > {
  overline?: string
  sectionTitle?: string
  title: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
  formButton?: {
    label: ReactNode
    form: ReactNode
  }
  imageLocation: 'left' | 'right'
  background?:
    | ''
    | 'darkBlue'
    | 'silver30'
    | 'beach30'
    | 'lightGreen'
    | 'sandLight'
}

const SplitTextWithImage = ({
  overline,
  title,
  text,
  background,
  image,
  formButton,
  imageLocation,
  ...others
}: SplitTextWithImageProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="splitTextWithImage"
      ref={containerRef}
      background={background}
      {...others}
    >
      <ContentContainer imageLocation={imageLocation} background={background}>
        <StyledAnimateText direction="static">
          <ImageContainer>
            <Image
              src={image.src}
              alt={image.alt}
              width={720}
              height={798}
              aspectRatio="720:798"
              style={{ objectFit: 'cover' }}
            />
          </ImageContainer>
        </StyledAnimateText>
        <ContentWrapper>
          <Content>
            <AnimateText>
              {overline && <Overline>{overline}</Overline>}
              {currentSite === Site.YachtSupport ? (
                <StyledTitle title={title as string} />
              ) : (
                <Title>{title}</Title>
              )}
            </AnimateText>
            <AnimateText delay={400}>
              <Text>{text}</Text>
            </AnimateText>
            {formButton && (
              <AnimateText delay={600}>
                <StyledSidePanelButton
                  sidePanel={formButton.form}
                  data-testid="formButton"
                  variant="primary"
                >
                  {formButton.label}
                </StyledSidePanelButton>
              </AnimateText>
            )}
          </Content>
        </ContentWrapper>
      </ContentContainer>
    </Container>
  )
}

export default SplitTextWithImage
