import styled from '@emotion/styled'
import { css, SerializedStyles, Theme } from '@emotion/react'

import { CollageWithTextProps } from './index'
import { body, heading2, overLineMedium } from '../../themes/yachtsupport/text'
import Image from '../../components/Image'
import Link from '../../components/Link'

const Container = styled.section(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.x3}px;
      gap: ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x4}px;
        gap: ${theme.spacing.x9}px;
      }
    `,
)

const Content = styled.div(
  () =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1000px;
      margin: 0 auto;
      text-align: center;
    `,
)

const Title = styled.div(
  ({ theme }) =>
    css`
      ${heading2(theme)}
      margin-top: 0;
      margin-bottom: ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x5}px;
      }
    `,
)

const StyledTitle = ({ title }: { title: string }) => {
  const delimiter = '—'
  const lines = title.split(delimiter)
  const lineAfterDelimiter = lines.pop()
  const LineAfterDelimiterSpan = styled.span(
    ({ theme }) =>
      css`
        color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      `,
  )

  return (
    <Title>
      {lines.length === 0 && title}
      {lines.length > 0 && lines + delimiter}
      {lines.length > 0 && (
        <LineAfterDelimiterSpan>{lineAfterDelimiter}</LineAfterDelimiterSpan>
      )}
    </Title>
  )
}

const Overline = styled.div(
  ({ theme }) =>
    css`
      ${overLineMedium(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
)

const Description = styled.div(
  ({ theme }) => css`
    ${body(theme)}
    margin-bottom: ${theme.spacing.x5}px;
    color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};
  `,
)

const layoutStyles = (theme: Theme): Record<string, SerializedStyles> => ({
  rowSmallLarge: css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: minmax(0, 0.5fr) minmax(0, 1fr);
      grid-template-areas: 'slot1 slot2';
    }
  `,
  rowLargeSmall: css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 0.5fr);
      grid-template-areas: 'slot1 slot2';
    }
  `,
})

const Collage = styled.div(
  ({ theme }) => css`
    ${body(theme)}
    margin-bottom: ${theme.spacing.x5}px;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x4}px;
  `,
)

const DesktopImageContainer = styled.div<{ imageLayoutVariant: string }>(
  ({ theme, imageLayoutVariant }) => css`
    display: none;
    gap: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: grid;
      ${layoutStyles(theme)[`${imageLayoutVariant}`]}
    }
  `,
)

const MobileImageContainer = styled.div<{ imageLayoutVariant: string }>(
  ({ theme, imageLayoutVariant }) => css`
    display: grid;
    ${layoutStyles(theme)[`${imageLayoutVariant}`]}
    gap: ${theme.spacing.x4}px;
    img {
      width: 100%;
      height: 100%;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)

const Button = styled(Link)(
  ({ theme }) =>
    css`
      position: relative;
      padding: 12px ${theme.spacing.x3}px;
      border: 1px solid ${theme.colors.yachtSupport.grey03};
      display: flex;
      justify-content: center;
      align-items: center;
      ${body(theme)}
      color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      font-weight: 500;
      text-transform: uppercase;

      :hover {
        color: ${theme.colors.yachtSupport.white};
        background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
        text-decoration: none;
      }
    `,
)

const CollageWithText = (props: CollageWithTextProps) => {
  const {
    title,
    overline,
    description,
    imageLayoutVariant,
    imageOne,
    imageTwo,
    buttonLabel,
    buttonLink,
    ...others
  } = props

  return (
    <Container {...others} data-testid="collageWithText">
      <Content>
        {overline && <Overline>{overline}</Overline>}
        <StyledTitle title={title} />
        {description && <Description>{description}</Description>}
        {buttonLink && buttonLabel && (
          <Button href={buttonLink}>{buttonLabel}</Button>
        )}
      </Content>
      <Collage>
        <DesktopImageContainer imageLayoutVariant={imageLayoutVariant}>
          <Image
            src={imageOne.src}
            alt={imageOne.alt ?? ''}
            width={451}
            height={564}
            aspectRatio="451:564"
            style={{ objectFit: 'cover', width: '100%' }}
          />
          <Image
            src={imageTwo.src}
            alt={imageTwo.alt ?? ''}
            width={893}
            height={475}
            aspectRatio="893:475"
            style={{ objectFit: 'cover', width: '100%' }}
          />
        </DesktopImageContainer>
        <MobileImageContainer imageLayoutVariant={imageLayoutVariant}>
          <Image
            src={imageOne.src}
            alt={imageOne.alt ?? ''}
            width={335}
            height={503}
            aspectRatio="335:503"
            style={{ objectFit: 'cover', maxWidth: '100%' }}
          />
          <Image
            src={imageTwo.src}
            alt={imageTwo.alt ?? ''}
            width={335}
            height={503}
            aspectRatio="335:503"
            style={{ objectFit: 'cover', maxWidth: '100%' }}
          />
        </MobileImageContainer>
      </Collage>
    </Container>
  )
}

export default CollageWithText
