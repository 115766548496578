import styled from '@emotion/styled'
import { css, SerializedStyles, Theme } from '@emotion/react'
import { ImageWithTextGridProps } from './index'
import { body, heading2, overLineMedium } from '../../themes/yachtsupport/text'
import Image from '../../components/Image'
import { primaryButtonCssYachtSupport } from '../../components/Button'
import Link from '../../components/Link'

function getBackgroundColor(theme: Theme, backgroundColorVariant: string) {
  switch (backgroundColorVariant) {
    case 'sand':
      return css`
        background-color: ${theme.colors.yachtSupport
          .secondaryDamenYachtingOrange};
      `
    case 'green':
      return css`
        background-color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      `
    case 'white':
    default:
      return css`
        background-color: ${theme.colors.yachtSupport.white};
      `
  }
}

const layoutStyles: (theme: Theme) => Record<string, SerializedStyles> = (
  theme,
) => ({
  twoColumnLargeLeft2: css`
    grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.5fr);
    grid-template-rows: minmax(0, 1fr);
    grid-template-areas: 'slot1 slot2';

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 0.5fr);
      grid-template-rows: minmax(0, 0.2fr) minmax(0, 1fr);
      grid-template-areas:
        'slot1 .'
        'slot1 slot2';
    }
  `,
  twoColumnLargeRight2: css`
    grid-template-columns: minmax(0, 0.5fr) minmax(0, 0.5fr);
    grid-template-rows: minmax(0, 1fr);
    grid-template-areas: 'slot1 slot2';

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      grid-template-columns: minmax(0, 0.5fr) minmax(0, 1fr);
      grid-template-rows: minmax(0, 0.2fr) minmax(0, 1fr);
      grid-template-areas:
        '. slot2'
        'slot1 slot2';
    }
  `,
  twoColumnEqual4: css`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: minmax(0, 0.75fr) minmax(0, 0.75fr);
    grid-template-areas:
      'slot1 slot2'
      'slot3 slot4';

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: minmax(0, 0.1fr) minmax(0, 0.5fr) minmax(0, 0.5fr) minmax(
          0,
          0.1fr
        );
      grid-template-areas:
        '. slot2'
        'slot1 slot2'
        'slot3 slot4'
        'slot3 .';
    }
  `,
  twoColumnLargeLeft4: css`
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-template-rows: minmax(0, 0.75fr) minmax(0, 0.75fr);
    grid-template-areas:
      'slot1 slot2'
      'slot3 slot4';

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-template-rows: minmax(0, 0.2fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
          0,
          0.2fr
        );
      grid-template-areas:
        '. slot2 slot2'
        'slot1 slot2 slot2'
        'slot3 slot3 slot4'
        'slot3 slot3 .';
    }
  `,
})

const Section = styled.div<{ layoutVariant: string }>(
  ({ theme, layoutVariant }) => css`
    display: flex;
    flex-direction: column;
    grid-template-areas: none;
    gap: ${theme.spacing.x3}px;
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: grid;
      gap: ${theme.spacing.x5}px;
      ${layoutStyles(theme)[`${layoutVariant}`]}
    }
  `,
)

const Slot = styled.div<{ area: string }>`
  grid-area: ${(props) => props.area};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: ${(props) =>
      props.theme.breakpoints.mobileMax}px) {
    :nth-of-type(n + 3) {
      display: none;
    }
  }
`

const Title = styled.h5(
  ({ theme }) =>
    css`
      ${heading2(theme)}
      color: ${theme.colors.yachtSupport.white};
      margin: 0 0 ${theme.spacing.x1}px;
    `,
)

const Overline = styled.div(
  ({ theme }) =>
    css`
      ${overLineMedium(theme)}
      color: ${theme.colors.yachtSupport.white};
      margin-bottom: ${theme.spacing.x2}px;
    `,
)

const Description = styled.div(
  ({ theme }) => css`
    ${body(theme)}
    color: ${theme.colors.yachtSupport.white};
  `,
)

const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: ${theme.spacing.x2}px;
    padding: ${theme.spacing.x4}px;
    height: 100%;
    background: linear-gradient(
      45deg,
      ${theme.colors.yachtSupport.primaryYSCarbonBlack} 0%,
      ${theme.colors.yachtSupport.primaryYSOceanBlue} 97.6%
    );

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x6}px;
    }
  `,
)

const Container = styled.div<{ backgroundColorVariant: string }>(
  ({ theme, backgroundColorVariant }) => css`
    padding: ${theme.spacing.x3}px;
    ${getBackgroundColor(theme, backgroundColorVariant)}
    position: relative;

    @media screen and (min-width: ${theme.breakpoints.tabletMax}px) {
      padding: ${theme.spacing.x4}px;
    }
  `,
)

const ImageContainer = styled('div')(
  () =>
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      > img {
        width: 100%;
        height: 100%;
      }
    `,
)

const Button = styled(Link)(
  ({ theme }) => css`
    ${primaryButtonCssYachtSupport(theme)}
    margin-top: ${theme.spacing.x4}px;
    width: fit-content;
    align-self: center;
    background-color: transparent;
  `,
)

const LayoutSection = ({ layoutVariant, content }: ImageWithTextGridProps) => {
  return (
    <Section layoutVariant={layoutVariant}>
      {content.map(
        (
          {
            key,
            image,
            contentItemType,
            linkWithTitle,
            overline,
            title,
            description,
          },
          index,
        ) => (
          <Slot area={`slot${index + 1}`} key={key}>
            {contentItemType === 'image' && image && (
              <Image
                src={image.src}
                alt={image?.alt}
                width={824}
                height={1030}
                // We would like to use aspect ratio h ere but on desktop/mobile this is a lot different depending on which image is loaded
                // e.g. right 'rectangle' or more 'square'
                // aspectRatio="824:1030"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                sizes="(min-width: 1280px) 824px, (min-width: 768px) 704px, 400px"
              />
            )}

            {contentItemType !== 'image' && (
              <Content>
                {overline && <Overline>{overline}</Overline>}
                <Title>{title}</Title>
                <Description>{description}</Description>
                {linkWithTitle?.href && (
                  <Button
                    href={linkWithTitle?.href}
                    target={linkWithTitle?.target}
                  >
                    {linkWithTitle?.title}
                  </Button>
                )}
              </Content>
            )}
          </Slot>
        ),
      )}
    </Section>
  )
}

const ImageWithTextGrid = ({
  layoutVariant,
  backgroundImage,
  backgroundColor,
  content,
  ...others
}: ImageWithTextGridProps) => (
  <Container backgroundColorVariant={backgroundColor} {...others}>
    {backgroundImage && (
      <ImageContainer>
        <Image
          src={backgroundImage.src}
          alt={backgroundImage.alt ?? ''}
          width={1280}
          height={960}
          aspectRatio="1280:960"
          style={{ objectFit: 'cover' }}
        />
      </ImageContainer>
    )}
    <LayoutSection
      layoutVariant={layoutVariant}
      backgroundColor={backgroundColor}
      backgroundImage={backgroundImage}
      content={content}
    />
  </Container>
)

export default ImageWithTextGrid
