import RichText from '../../components/RichText'
import SplitTextWithImage from '../../patterns/SplitTextWithImage'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import {
  FormStoryblok,
  SplitTextWithImageStoryblok,
} from '../storyblok.generated'
import { usePageContext } from '../PageContext'
import FormBlok from '../FormBlok'
import { StoryblokStory } from '../../utils/storyblok'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: SplitTextWithImageStoryblok
}

const SplitTextWithImageBlok = ({
  content: {
    sectionTitle,
    image,
    title,
    text,
    imageLocation,
    overline,
    formButton,
    background,
  },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  return (
    <SplitTextWithImage
      id={slugify(sectionTitle)}
      overline={overline}
      image={mapImage(image) ?? getRequiredImageFallback()}
      background={background}
      title={reactStringReplace(title, '\n', <br />)}
      text={<RichText>{text}</RichText>}
      formButton={
        formButton?.length
          ? {
              label: formButton[0].label,
              form: (
                <FormBlok
                  form={
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    rels.find(
                      (rel): rel is StoryblokStory<FormStoryblok> =>
                        rel.uuid === formButton[0].form,
                    )!
                  }
                />
              ),
            }
          : undefined
      }
      imageLocation={imageLocation}
      {...others}
    />
  )
}

export default SplitTextWithImageBlok
