import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import Section from '../components/Section'
import TextWithLink from '../components/TextWithLink'

// Added padding bottom to the container separately cause for some reason the shorthand did not work
const Container = styled(Section)(
  ({ theme }) => css`
    padding: ${theme.spacing.x7}px ${theme.spacing.x4}px;
    padding-bottom: ${theme.spacing.x10}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x7}px ${theme.spacing.x5}px;
      padding-bottom: ${theme.spacing.x15}px;
    }
  `,
)
const Wrapper = styled.div(
  ({ theme }) => css`
    max-width: 1089px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto;
    }
  `,
)
const TextWithLinkWrapper = styled.div(
  ({ theme }) => css`
    max-width: 533px;
    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing.x8}px;
    }
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  blocks: Array<{
    key: string
    title: ReactNode
    text: ReactNode
    link: {
      url: string
      title?: string
    }
  }>
}

const TextWithLinkList = ({ blocks, ...others }: Props) => (
  <Container
    sectionIndicatorVariant="dark"
    data-testid="textWithLinkList"
    {...others}
  >
    <Wrapper>
      {blocks.map((block) => (
        <TextWithLinkWrapper key={block.key}>
          <TextWithLink
            title={block.title}
            text={block.text}
            link={block.link}
          />
        </TextWithLinkWrapper>
      ))}
    </Wrapper>
  </Container>
)

export default TextWithLinkList
