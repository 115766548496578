import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import reactStringReplace from '../utils/reactStringReplace'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import ScrollDownIndicator from '../components/ScrollDownIndicator'
import Section from '../components/Section'
import useViewportSize from '../utils/useViewportSize'

const Container = styled(Section)(
  ({ theme }) => css`
    position: relative;
    padding-top: 142%;
    background: ${theme.colors.amels.silk};
  `,
)
const Content = styled.div`
  position: absolute;
  top: ${(283 / 2048) * 100}%;
  left: ${(117 / 1440) * 100}%;
`
const Text = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    margin: 0 0 ${theme.spacing.x4}px;
  `,
)
const StyledScrollDownIndicator = styled(ScrollDownIndicator)(
  ({ theme }) => css`
    margin: 0 auto;
    display: flex;
    color: ${theme.colors.amels.deepBayAqua};
    --animation-delay: 400ms;
  `,
)

const useHeroImageScale = () => {
  const [scale, setScale] = useState(1)

  const viewportSize = useViewportSize()
  useEffect(() => {
    const handleScroll = () => {
      if (!viewportSize) {
        return
      }

      const heroImageSize = (viewportSize.width / 1440) * 2048
      // Stop moving when the bottom has moved within viewport
      const adjustedHeroImageSize = heroImageSize - viewportSize.height

      const progress = Math.min(
        1,
        Math.max(0, window.scrollY / adjustedHeroImageSize),
      )

      const scaleTo = 0.6 // how small the image can get, in percentage of regular size
      setScale(1 - progress * (1 - scaleTo))
    }

    window.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [viewportSize])

  return scale
}

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant'
  > {
  image: {
    src: string
    alt?: string
  }
  text: string
  scrollIndicatorText: ReactNode
}

const LargeHeroImageHeader = ({
  image,
  text,
  scrollIndicatorText,
  ...others
}: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  const scale = useHeroImageScale()

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="largeHeroImageHeader"
      ref={containerRef}
      {...others}
    >
      <Image
        src={image.src}
        alt={image.alt}
        fill
        style={{
          transform: `scale(${scale})`,
          transformOrigin: 'center 80%',
        }}
      />
      <Content>
        <Text>
          <AnimateText>{reactStringReplace(text, '\n', <br />)}</AnimateText>
        </Text>
        <StyledScrollDownIndicator>
          {scrollIndicatorText}
        </StyledScrollDownIndicator>
      </Content>
    </Container>
  )
}

export default LargeHeroImageHeader
