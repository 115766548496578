import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'
import SpecificationGroup from '../components/SpecificationGroup'
import SpecificationText from '../components/SpecificationText'

const Container = styled(Section)(
  ({ theme }) => css`
    background: ${theme.colors.amels.deepBayAqua};
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    color: ${theme.colors.amels.silk};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      padding: ${theme.spacing.x20}px ${theme.spacing.x7}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x20}px;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading1(theme)};
    font-size: ${theme.spacing.x6}px;
    color: ${theme.colors.amels.bayGold};
    margin: 0;
    margin-bottom: ${theme.spacing.x4}px;
  `,
)
const SubTitle = styled.div(
  ({ theme }) => css`
    ${theme.text.subHeading(theme)};
    margin-bottom: ${theme.spacing.x1}px;
    color: ${theme.colors.amels.beach};
    font-size: 14px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 18px;
      color: ${theme.colors.amels.oyster};
      margin-bottom: ${theme.spacing.x2}px;
    }
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    max-width: 708px;
    margin: 0 auto;
    margin-bottom: ${theme.spacing.x8}px;
    color: ${theme.colors.amels.silk};
    font-size: 14px;
    font-weight: 400;

    > p:first-of-type {
      margin-top: 0;
    }
    > p:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 16px;
      margin-bottom: ${theme.spacing.x11}px;
    }
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  subTitle: ReactNode
  text: ReactNode
  groups: Array<{
    key: string
    title: ReactNode
    values?: Array<{
      key: string
      label: ReactNode
      value: ReactNode
    }>
    text?: ReactNode
  }>
}

const PortfolioSpecifications = ({
  title,
  subTitle,
  text,
  groups,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-300px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      id="specifications"
      sectionIndicatorVariant="light"
      {...others}
    >
      <AnimateText>
        <Title>{title}</Title>
      </AnimateText>
      <AnimateText delay={120}>
        <SubTitle>{subTitle}</SubTitle>
      </AnimateText>
      <AnimateText delay={240}>
        <Text>{text}</Text>
      </AnimateText>
      {groups?.map(({ key, title, values, text }, index) =>
        values ? (
          <SpecificationGroup
            key={key}
            collapsible
            defaultIsExpanded={index === 0}
            title={title}
            values={values}
          />
        ) : (
          <SpecificationText key={key} title={title}>
            {text}
          </SpecificationText>
        ),
      )}
    </Container>
  )
}

export default PortfolioSpecifications
