import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { VideoHighlightProps } from './index'
import {
  heading2,
  overLineMedium,
  overLineSmall,
} from '../../themes/yachtsupport/text'
import AnimateText from '../../components/AnimateText'
import Image from '../../components/Image'
import Link from '../../components/Link'

const Container = styled.section(
  ({ theme }) =>
    css`
      background: ${theme.colors.yachtSupport.ysCarbonBlack10Solid};
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      gap: ${theme.spacing.x5}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x13}px ${theme.spacing.x4}px;
        gap: ${theme.spacing.x8}px;
      }
    `,
)

const Content = styled.div(
  () =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 1000px;
      margin: 0 auto;
      text-align: center;
    `,
)

const Heading = styled.div(
  ({ theme }) =>
    css`
      ${heading2(theme)}
      margin-top: 0;
    `,
)

const StyledHeading = ({ heading }: { heading: string }) => {
  const delimiter = '—'
  const lines = heading.split(delimiter)
  const lineAfterDelimiter = lines.pop()
  const LineAfterDelimiterSpan = styled.span(
    ({ theme }) =>
      css`
        color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      `,
  )

  return (
    <Heading>
      {lines.length === 0 && heading}
      {lines.length > 0 && lines + delimiter}
      {lines.length > 0 && (
        <LineAfterDelimiterSpan>{lineAfterDelimiter}</LineAfterDelimiterSpan>
      )}
    </Heading>
  )
}

const Overline = styled.div(
  ({ theme }) =>
    css`
      ${overLineSmall(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        ${overLineMedium(theme)}
        color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
)

const VideoInnerWrapper = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: ${theme.spacing.x6}px;
      justify-content: center;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        flex-direction: row;
        gap: ${theme.spacing.x4}px;
      }
    `,
)

const VideoImageContainer = styled('div')(
  () => css`
    overflow: hidden;
    position: relative;
  `,
)

const VideoTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading3(theme)}
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    margin: 0;
    transition: linear 200ms;
  `,
])

const VideoDescription = styled.p(({ theme }) => [
  css`
    ${theme.text.body(theme)}
    color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};
    font-size: 18px;
    line-height: 1.5;
    margin: 0;
  `,
  css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-weight: 400;
    }
  `,
])

const VideoLink = styled(Link)(
  ({ theme }) =>
    css`
      display: block;
      height: 100%;
      max-width: 672px;

      > div {
        height: 100%;
      }

      img {
        transition: transform ease-out 3000ms;
      }

      :hover {
        text-decoration: none;

        img {
          transform: scale(1.2);
        }

        ${VideoImageContainer} {
          filter: none;
        }

        ${VideoTitle} {
          color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
        }

        button {
          background-color: ${theme.colors.yachtSupport.grey01};
        }
      }
    `,
)

const Video = styled.article(
  () => css`
    display: flex;
    flex-flow: column;
    height: 100%;
  `,
)

const VideoImage = styled(Image)(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: auto;
    max-height: 211px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-height: 378px;
    }
  `,
)

const VideoContent = styled.div(({ theme }) => [
  css`
    background: ${theme.colors.yachtSupport.white};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x2}px;
    padding: ${theme.spacing.x3}px;
    border: 1px solid var(--Grey-03, ${theme.colors.yachtSupport.grey03});
  `,
])

const VideoButton = styled.div(({ theme }) => [
  css`
    width: fit-content;
    color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    border: 1px solid ${theme.colors.yachtSupport.grey03};
    padding: 12px ${theme.spacing.x3}px;
    margin-top: ${theme.spacing.x3}px;

    :before {
      display: none;
    }

    :hover {
      color: ${theme.colors.yachtSupport.white};
      text-decoration: none;
      border: 1px solid
        ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
      background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    }
  `,
])

const VideoInfo = styled.div(({ theme }) => [
  css`
    display: inline-block;
  `,
  css`
    ${theme.text.body(theme)}
    font-size: 12px;
    color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    position: relative;
    text-transform: uppercase;
  `,
])

const VideoHighlight = (props: VideoHighlightProps) => {
  const { heading, overline, video, ...others } = props

  return (
    <Container {...others} data-testid="videoHighlight">
      <Content>
        {overline && <Overline>{overline}</Overline>}
        <StyledHeading heading={heading} />
      </Content>
      <VideoInnerWrapper>
        <VideoLink href={`${video.link}`}>
          <AnimateText delay={1200}>
            <Video>
              <VideoImageContainer>
                <VideoImage
                  src={video.image}
                  alt={video.title}
                  width={672}
                  height={378}
                  aspectRatio="672:378"
                  style={{ objectFit: 'cover' }}
                />
              </VideoImageContainer>
              <VideoContent>
                {video.caption && <VideoInfo>{video.caption}</VideoInfo>}
                <VideoTitle>{video.title}</VideoTitle>
                <VideoDescription>{video.summary}</VideoDescription>
                <VideoButton>{video.buttonLabel}</VideoButton>
              </VideoContent>
            </Video>
          </AnimateText>
        </VideoLink>
      </VideoInnerWrapper>
    </Container>
  )
}

export default VideoHighlight
