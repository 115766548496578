import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'
import { isThemeAmelsLandingspage } from '../themes'

type Color = '' | 'light' | 'dark' | 'white'
type Position = '' | 'left' | 'center' | 'right'

const getColor = (theme: Theme, color?: Color) => {
  switch (color) {
    case 'light':
      return css`
        ${isThemeAmelsLandingspage(theme) &&
        `
            background: ${theme.colors.landingspage.shell};
          `}
      `

    case 'dark':
      return css`
        ${isThemeAmelsLandingspage(theme) &&
        `
            background: ${theme.colors.landingspage.beach};
          `}
      `

    case 'white':
    default:
      return css`
        ${isThemeAmelsLandingspage(theme) &&
        `
            background: ${theme.colors.landingspage.body};
          `}
      `
  }
}

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'position',
})<{
  position?: Position
  color?: Color
}>(({ theme, position, color }) => [
  css`
    position: relative;
    overflow: visible;
    margin: 0 ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 ${theme.spacing.x7}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin: 0 ${theme.spacing.x20}px;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 2;
      width: 2px;
      height: 80px;
      transform: translateY(-50%);
      ${getColor(theme, color)}
    }
  `,
  position === 'left' &&
    css`
      &::after {
        left: 50px;
      }
    `,
  position === 'center' &&
    css`
      &::after {
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
      }
    `,
  position === 'right' &&
    css`
      &::after {
        right: 50px;
      }
    `,
])

interface Props {
  position?: Position
  color?: Color
}

const VisualLine = ({ position, color, ...others }: Props) => (
  <Container position={position} color={color} {...others} />
)

export default VisualLine
