import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode } from 'react'
import AnimateText from '../components/AnimateText'
import ContactCard, { ContactCardProps } from '../components/ContactCard'

const Container = styled.div(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing.x3}px;
  `,
)
const ItemText = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x5}px;
  `,
)
const ContactsList = styled.ul(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-direction: row;
      justify-content: start;
      flex-wrap: wrap;
    }
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  text: ReactNode
  contacts?: ContactCardProps['contact'][]
}

const HarmonicaItemWithContacts = ({
  id,
  text,
  contacts,
  ...others
}: Props) => {
  return (
    <Container id={id} data-testid="harmonicaItemWithContacts" {...others}>
      <ItemText>
        <AnimateText direction="down">{text}</AnimateText>
      </ItemText>
      <ContactsList>
        {contacts?.map((contact, index) => (
          <ContactCard
            key={contact.key}
            contact={contact}
            animationDelay={(index + 1) * 400}
          />
        ))}
      </ContactsList>
    </Container>
  )
}

export default HarmonicaItemWithContacts
