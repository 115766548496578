import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes, ReactNode } from 'react'

import SimpleHeader from './SimpleHeader'
import NavigationTeaser, { NavigationTeaserProps } from './NavigationTeaser'
import TextCallToAction, { TextCallToActionProps } from './TextCallToAction'

const StyledSimpleHeader = styled(SimpleHeader)`
  min-height: 640px;
`
const ExtraContentContainer = styled.div(({ theme }) => [
  css`
    position: relative;
    z-index: 1;
    margin-top: -170px;
    padding-bottom: ${theme.spacing.x8}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-top: -280px;
      padding-bottom: ${theme.spacing.x15}px;
    }
  `,
])

const CallToActionContainer = styled.div(
  ({ theme }) => css`
    margin-top: -${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-top: -${theme.spacing.x7}px;
    }

    > section {
      padding-top: 0;
      padding-bottom: 0;
    }
  `,
)

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'children'> {
  title: ReactNode
  text?: ReactNode
  navigationTeaserProps: NavigationTeaserProps
  textCallToActionProps?: TextCallToActionProps
}

const ServicesHeader = ({
  title,
  text,
  navigationTeaserProps,
  textCallToActionProps,
  ...others
}: Props) => (
  <>
    <StyledSimpleHeader
      title={title}
      text={text}
      data-testid="servicesHeader"
      {...others}
    />
    <ExtraContentContainer>
      <NavigationTeaser {...navigationTeaserProps} />

      {textCallToActionProps && (
        <CallToActionContainer>
          <TextCallToAction {...textCallToActionProps} />
        </CallToActionContainer>
      )}
    </ExtraContentContainer>
  </>
)

export default ServicesHeader
