import Harmonica from '../../patterns/Harmonica'
import slugify from '../../utils/slugify'
import { HarmonicaStoryblok } from '../storyblok.generated'
import BlokZone from '../../components/BlokZone'
import patterns from '.'

interface Props {
  content: HarmonicaStoryblok
}

const HarmonicaBlok = ({
  content: { sectionTitle, title, items },
  ...others
}: Props) => (
  <Harmonica
    id={slugify(sectionTitle)}
    title={title}
    items={items.map((block) => ({
      key: block._uid,
      title: block.title,
      itemContent: <BlokZone components={patterns} content={[block]} />,
    }))}
    {...others}
  />
)

export default HarmonicaBlok
