import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import NavigateXplorer from './Navigate.xplorer'
import Section from '../../components/Section'
import { ImagesWithQuoteProps } from '../ImagesWithQuote'

export type quoteLocation = 'off' | 'top' | 'bottom'

export interface NavigateProps
  extends Omit<
    ComponentProps<typeof Section>,
    'title' | 'sectionIndicatorVariant' | 'children'
  > {
  title: string
  description: ReactNode
  image?: { src: string; alt?: string }
  quickLinks: Array<{
    key: string
    title: ReactNode
    link: string
    linkText: string
    image: { src: string; alt?: string }
  }>
  quoteLocation: quoteLocation
  imagesWithQuoteProps?: ImagesWithQuoteProps
}

const Navigate = (props: NavigateProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <NavigateXplorer {...props} />
    default:
      return <NavigateXplorer {...props} />
  }
}

export default Navigate
