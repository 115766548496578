import RichText from '../../components/RichText'
import TextWithImage from '../../patterns/TextWithImage'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { TextWithImageStoryblok } from '../storyblok.generated'
import ContactCallToActionActionBlok from './ContactCallToActionActionBlok'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: TextWithImageStoryblok
}

const TextWithImageBlok = ({
  content: {
    sectionTitle,
    image,
    title,
    text,
    imageLocation,
    overline,
    background,
    backgroundImage,
    logo,
    linkWithTitle,
    triggerFormCallToAction,
  },
  ...others
}: Props) => {
  const triggerFormCallToActionEl = triggerFormCallToAction?.[0] ? (
    <ContactCallToActionActionBlok content={triggerFormCallToAction[0]} />
  ) : undefined

  const link = linkWithTitle?.[0]

  return (
    <TextWithImage
      id={slugify(sectionTitle)}
      overline={overline}
      image={mapImage(image) ?? getRequiredImageFallback()}
      title={reactStringReplace(title, '\n', <br />)}
      text={<RichText>{text}</RichText>}
      imageLocation={imageLocation}
      background={background as 'white' | 'beach10' | 'silver10' | 'darkBlue'}
      backgroundImage={mapImage(backgroundImage)}
      logo={mapImage(logo)}
      link={
        link
          ? {
              target: link.linkTarget,
              title: link.title,
              href: storyblokLinkResolver(link.link),
            }
          : undefined
      }
      triggerFormCallToAction={triggerFormCallToActionEl}
      {...others}
    />
  )
}

export default TextWithImageBlok
