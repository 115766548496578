import { FunctionComponent } from 'react'

import PortfolioHeaderBlok from './PortfolioHeaderBlok'
import PortfolioIntroductionBlok from './PortfolioIntroductionBlok'
import PortfolioHighlightsBlok from './PortfolioHighlightsBlok'
import FullWidthCarouselBlok from './FullWidthCarouselBlok'
import ShowcaseBlok from './ShowcaseBlok'
import PortfolioSpecificationsBlok from './PortfolioSpecificationsBlok'
import PortfolioRequestBrochureBlok from './PortfolioRequestBrochureBlok'
import FullPageCarouselBlok from './FullPageCarouselBlok'
import DesignTextWithCarouselBlok from './DesignTextWithCarouselBlok'
import FullCustomHeaderBlok from './FullCustomHeaderBlok'
import IntroductionBlok from './IntroductionBlok'
import TabbedCarouselBlok from './TabbedCarouselBlok'
import TextBlok from './TextBlok'
import PlainSpecificationsBlok from './PlainSpecificationsBlok'
import PortfolioFloorplanBlok from './PortfolioFloorplanBlok'
import HeroImageHeaderBlok from './HeroImageHeaderBlok'
import TabbedContentBlok from './TabbedContentBlok'
import DesignTextWithImageBlok from './DesignTextWithImageBlok'
import DesignTextWithExpandableImageBlok from './DesignTextWithExpandableImageBlok'
import QuoteBlok from './QuoteBlok'
import TextWithWideImageBlok from './TextWithWideImageBlok'
import SimpleHeaderBlok from './SimpleHeaderBlok'
import LargeHeroImageHeaderBlok from './LargeHeroImageHeaderBlok'
import LogoWithTextBlok from './LogoWithTextBlok'
import TextWithImageBlok from './TextWithImageBlok'
import TextWithVideoBlok from './TextWithVideoBlok'
import ImageWithTextOverlayBlok from './ImageWithTextOverlayBlok'
import ContactInformationBlok from './ContactInformationBlok'
import TextWithLinkListBlok from './TextWithLinkListBlok'
import ContentGroupBlok from './ContentGroupBlok'
import ContactHeaderBlok from './ContactHeaderBlok'
import NavigationTeaserBlok from './NavigationTeaserBlok'
import FloatingCallToActionBlok from './FloatingCallToActionBlok'
import ServicesHeaderBlok from './ServicesHeaderBlok'
import AlertBoatshowBlok from './AlertBoatshowBlok'
import TestimonialCarouselBlok from './TestimonialCarouselBlok'
import SplitTextWithImageBlok from './SplitTextWithImageBlok'
import CallToActionBlok from './CallToActionBlok'
import HarmonicaBlok from './HarmonicaBlok'
import HarmonicaItemBlok from './HarmonicaItemBlok'
import HarmonicaItemWithContactsBlok from './HarmonicaItemWithContactsBlok'
import FeatureWithModalListBlok from './FeatureWithModalListBlok'
import ContactsBlok from './ContactsBlok'
import ProductCarouselBlok from './ProductCarouselBlok'
import ContactCallToActionBlok from './ContactCallToActionBlok'
import ArticleFeedBlok from './ArticleFeedBlok'
import TextCallToActionBlok from './TextCallToActionBlok'
import PressInformationBlok from './PressInformationBlok'
import TimelineBlok from './TimelineBlok'
import TimelineItemBlok from './TimelineItemBlok'
import SocialShareBlok from './SocialShareBlok'
import VisualLineBlok from './VisualLineBlok'
import FullWidthImageBlok from './FullWidthImageBlok'
import HeroImageHeaderWithLogoBlok from './HeroImageHeaderWithLogoBlok'
import FollowUsBlok from './FollowUsBlok'
import LogoBarBlok from './LogoBarBlok'
import TextWithUspsBlok from './TextWithUspsBlok'
import SitemapTreeBlok from './SitemapTreeBlok'
import EventCarouselBlok from './EventCarouselBlok'
import PageFormBlok from './PageFormBlok'
import NavigationTeaserWithTextBlok from './NavigationTeaserWithTextBlok'
import ImagesWithQuoteBlok from './ImagesWithQuoteBlok'
import NavigateBlok from './NavigateBlok'
import SummaryWithLinkBlok from './SummaryWithLinkBlok'
import ContactCallToActionWithImageBlok from './ContactCallToActionWithImageBlok'
import ImageWithTextGridBlok from './ImageWithTextGridBlok'
import contactCallToActionActionBlok from './ContactCallToActionActionBlok'
import TestimonialBlok from './TestimonialBlok'
import ExploreTeaserBlok from './ExploreTeaserBlok'
import SubHeaderBlok from './SubHeaderBlok'
import TextWithImageCardsBlok from './TextWithImageCardsBlok'
import ImageWithHeadingBlok from './ImageWithHeadingBlok'
import CollageWithTextBlok from './CollageWithTextBlok'
import QuoteCarouselBlok from './QuoteCarouselBlok'
import VideoFeedBlok from './VideoFeedBlok'
import VisualDividerBlok from './VisualDividerBlok'
import ColumnContentBlok from './ColumnContentBlok'
import TwoColumnsTextBlok from './TwoColumnsTextBlok'
import VideoHighlightBlok from './VideoHighlightBlok'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const patterns: Record<string, FunctionComponent<any>> = {
  fullPageCarousel: FullPageCarouselBlok,
  portfolioHeader: PortfolioHeaderBlok,
  servicesHeader: ServicesHeaderBlok,
  portfolioIntroduction: PortfolioIntroductionBlok,
  portfolioHighlight: PortfolioHighlightsBlok,
  portfolioFloorplan: PortfolioFloorplanBlok,
  fullWidthCarousel: FullWidthCarouselBlok,
  portfolioSpecifications: PortfolioSpecificationsBlok,
  portfolioCampaignBrochureDownload: PortfolioRequestBrochureBlok,
  designTextWithCarousel: DesignTextWithCarouselBlok,
  designTextWithImage: DesignTextWithImageBlok,
  designTextWithExpandableImage: DesignTextWithExpandableImageBlok,
  tabbedContent: TabbedContentBlok,
  fullCustomHeader: FullCustomHeaderBlok,
  introduction: IntroductionBlok,
  showCases: ShowcaseBlok,
  tabbedCarousel: TabbedCarouselBlok,
  text: TextBlok,
  plainSpecifications: PlainSpecificationsBlok,
  heroImageHeader: HeroImageHeaderBlok,
  heroImageHeaderWithLogo: HeroImageHeaderWithLogoBlok,
  largeHeroImageHeader: LargeHeroImageHeaderBlok,
  logoWithText: LogoWithTextBlok,
  quote: QuoteBlok,
  textWithImage: TextWithImageBlok,
  textWithVideo: TextWithVideoBlok,
  textWithWideImage: TextWithWideImageBlok,
  imageWithTextOverlay: ImageWithTextOverlayBlok,
  simpleHeader: SimpleHeaderBlok,
  contactHeader: ContactHeaderBlok,
  textWithLinkList: TextWithLinkListBlok,
  contactInformation: ContactInformationBlok,
  contentGroup: ContentGroupBlok,
  navigationTeaser: NavigationTeaserBlok,
  navigationTeaserWithText: NavigationTeaserWithTextBlok,
  floatingCallToAction: FloatingCallToActionBlok,
  alertBoatshow: AlertBoatshowBlok,
  testimonialCarousel: TestimonialCarouselBlok,
  callToAction: CallToActionBlok,
  contacts: ContactsBlok,
  harmonica: HarmonicaBlok,
  harmonicaItem: HarmonicaItemBlok,
  harmonicaItemWithContacts: HarmonicaItemWithContactsBlok,
  featureWithModalList: FeatureWithModalListBlok,
  splitTextWithImage: SplitTextWithImageBlok,
  articleFeed: ArticleFeedBlok,
  contactCallToAction: ContactCallToActionBlok,
  contactCallToActionAction: contactCallToActionActionBlok,
  contactCallToActionWithImage: ContactCallToActionWithImageBlok,
  productCarousel: ProductCarouselBlok,
  textCallToAction: TextCallToActionBlok,
  pressInformation: PressInformationBlok,
  timeline: TimelineBlok,
  timelineItem: TimelineItemBlok,
  socialShare: SocialShareBlok,
  visualLine: VisualLineBlok,
  fullWidthImage: FullWidthImageBlok,
  followUs: FollowUsBlok,
  logoBar: LogoBarBlok,
  textWithUsps: TextWithUspsBlok,
  sitemapTree: SitemapTreeBlok,
  eventCarousel: EventCarouselBlok,
  pageForm: PageFormBlok,
  imagesWithQuote: ImagesWithQuoteBlok,
  navigate: NavigateBlok,
  summaryWithLink: SummaryWithLinkBlok,
  imageWithTextGrid: ImageWithTextGridBlok,
  testimonial: TestimonialBlok,
  exploreTeaser: ExploreTeaserBlok,
  subHeader: SubHeaderBlok,
  textWithImageCards: TextWithImageCardsBlok,
  imageWithHeading: ImageWithHeadingBlok,
  collageWithText: CollageWithTextBlok,
  quoteCarousel: QuoteCarouselBlok,
  videoFeed: VideoFeedBlok,
  visualDivider: VisualDividerBlok,
  columnContent: ColumnContentBlok,
  twoColumnsText: TwoColumnsTextBlok,
  videoHighlight: VideoHighlightBlok,
}

export default patterns
