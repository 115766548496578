import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

import { hiddenClassName } from '../../components/AnimateText'
import Image from '../../components/Image'
import IconLink from '../../components/IconLink'
import Section from '../../components/Section'
import ChevronRight from '../../icons/ChevronRight.svg'
import squarePolygon from '../../utils/squarePolygon'
import ImageAspectRatio from '../../components/ImageAspectRatio'

import type { NavigationTeaserProps } from './index'

const Container = styled(Section)(
  ({ theme }) => css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x12}px ${theme.spacing.x4}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
    }
  `,
)
const Menu = styled.menu(
  ({ theme }) => css`
    display: grid;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    gap: 32px;
    max-width: 1200px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      gap: 40px;
    }
  `,
)
const Column = styled.li(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      :not(:first-of-type):not(:last-of-type) {
        margin-top: ${theme.spacing.x10}px;
      }
    }
  `,
)
const DesktopOverlay = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${theme.colors.amels.marble};
    padding: ${theme.spacing.x5}px ${theme.spacing.x3}px;

    @media (prefers-reduced-motion: no-preference) {
      opacity: 0;
      will-change: opacity;
      transition: opacity 300ms ease-in-out;
    }

    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const MobileLinkContainer = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.amels.marble};
    padding: ${theme.spacing.x2}px;
    display: block;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x3}px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: none;
    }
  `,
)
const OverlayTitle = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin-top: 0;
    margin-bottom: 40px;

    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const OverlayText = styled.div(
  ({ theme }) => css`
    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`
const Card = styled.div`
  position: relative;

  :hover ${DesktopOverlay} {
    opacity: 1;
  }

  @media (prefers-reduced-motion: no-preference) {
    clip-path: ${squarePolygon(0, 0, 0, 1)};

    img {
      transform: scale(1.1);
      transform-origin: top right;
    }

    :not(.${hiddenClassName} &) {
      animation: ${keyframes`
        from {
          clip-path: ${squarePolygon(0, 0, 0, 1)};
        }
        to {
          clip-path: ${squarePolygon(0, 0, 0, 0)};
        }
      `} 1400ms ease-in-out forwards;
      animation-delay: calc(var(--index) * 250ms);

      img {
        animation: ${keyframes`
          from {
            transform: scale(1.1);
          }
          to {
            transform: scale(1.0);
          }
        `} 1400ms ease-in-out forwards;
        animation-delay: calc(var(--index) * 250ms);
      }
    }
  }
`
const DesktopImageContainer = styled.div(
  ({ theme }) => css`
    line-height: 0;
    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const MobileImageContainer = styled(ImageAspectRatio)(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading3(theme)}
    margin: 0;
    position: absolute;
    left: ${theme.spacing.x3}px;
    bottom: ${theme.spacing.x5}px;
    color: ${theme.colors.amels.body};

    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
    }
  `,
)

const NavigationTeaser = ({ columns, ...others }: NavigationTeaserProps) => {
  const { ref, inView } = useInView({
    rootMargin: '-300px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      sectionIndicatorVariant="dark"
      {...others}
    >
      <Menu>
        {columns.map(
          (
            {
              key,
              title,
              image,
              mobileImage,
              hoverTitle,
              hoverText,
              link,
              linkText,
            },
            index,
          ) => (
            <Column key={key}>
              <Card
                style={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ['--index' as any]: index,
                }}
              >
                <DesktopImageContainer>
                  <Image
                    src={image.src}
                    alt={image.alt ?? ''}
                    width={373}
                    height={559}
                    style={{ objectFit: 'cover' }}
                  />
                </DesktopImageContainer>
                <MobileImageContainer aspectRatio="16:9">
                  <Image
                    src={(mobileImage || image).src}
                    alt={(mobileImage || image).alt ?? ''}
                    fill
                    style={{ objectFit: 'cover' }}
                    aspectRatio="16:9"
                  />
                </MobileImageContainer>
                <Title>{title}</Title>

                <DesktopOverlay>
                  <OverlayTitle aria-hidden>{hoverTitle}</OverlayTitle>
                  <OverlayText>{hoverText}</OverlayText>

                  <IconLink
                    href={link}
                    icon={<StyledChevronRight aria-label="" />}
                  >
                    {linkText}
                  </IconLink>
                </DesktopOverlay>
                <MobileLinkContainer>
                  <IconLink
                    href={link}
                    icon={<StyledChevronRight aria-label="" />}
                  >
                    {linkText}
                  </IconLink>
                </MobileLinkContainer>
              </Card>
            </Column>
          ),
        )}
      </Menu>
    </Container>
  )
}

export default NavigationTeaser
