import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes, ReactNode, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import Section from '../components/Section'
import { isSiteYachting } from '../themes'
import LinkButton from '../components/LinkButton'
import YachtingCarousel from '../components/YachtingCarousel'
import { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'

const Container = styled(Section)(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding: ${theme.spacing.x15}px;
      }
    `}
  `,
)
const ContentContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.x6}px ${theme.spacing.x5}px;
    width: 100%;
    max-width: ${theme.layout.contentWidth}px;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x8}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;

      > div:first-of-type {
        padding-right: ${theme.spacing.x8}px;
      }

      > div:nth-of-type(2) {
        align-self: end;
      }
    }
  `,
)
const Overline = styled.span(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      display: block;
      ${theme.text.overlineSmall(theme)};
      margin-bottom: ${theme.spacing.x2}px;
    `}
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin: 0 0 ${theme.spacing.x6}px 0;
      font-size: ${theme.spacing.x5}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        margin: 0;
        font-size: 46px;
      }
    `}
  `,
)

const ImageContainer = styled('div')(
  () => css`
    display: flex;
    overflow: hidden;
  `,
)
const StyledImage = styled(Image)(
  () => css`
    position: relative;
    width: 100%;
    height: auto;
  `,
)
const ItemContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex-grow: 12;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;
    }
  `,
)
const ItemContentContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.x3}px;
    align-items: flex-start;
    border-left: 1px solid ${theme.colors.yachting.silver};
    border-right: 1px solid ${theme.colors.yachting.silver};
    border-bottom: 1px solid ${theme.colors.yachting.silver};
    flex-grow: 1;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;
      align-items: center;
      padding: ${theme.spacing.x3}px ${theme.spacing.x6}px;
      gap: ${theme.spacing.x8}px;
      border-top: 1px solid ${theme.colors.yachting.silver};
      border-right: 1px solid ${theme.colors.yachting.silver};
      border-bottom: 1px solid ${theme.colors.yachting.silver};
      border-left: 0;

      > div:nth-of-type(2) {
        align-self: end;
      }
    }
  `,
)
const CarouselWrapper = styled.div(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      position: relative;
      display: block;
      width: 100%;
      max-width: ${theme.layout.contentWidth}px;
      margin: 0 auto;

      .slick-track {
        display: flex;
      }

      .slick-list {
        margin: 0 -${theme.spacing.x3}px;
      }

      .slick-slide {
        margin: 0 ${theme.spacing.x3}px;
        height: inherit;

        > div {
          height: 100%;

          > div {
            height: 100%;
          }
        }

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          display: block;
          height: auto;
        }
      }
    `}
  `,
)

const TextWrapper = styled.div(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x2}px 0;
      gap: 16px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex: 1;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: 0;
      }
    `}
  `,
)

interface SliderItem {
  key: string
  title: ReactNode
  overline: ReactNode
  link: {
    title: ReactNode
    href?: string
    target?: string
  }
  image: {
    src: string
    alt: string | undefined
  }
}

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'children'> {
  title: ReactNode
  overline: ReactNode
  link: {
    title: ReactNode
    href?: string
    target?: string
  }
  items: SliderItem[]
}

const AlertBoatshow = ({ title, overline, link, items, ...others }: Props) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const sliderRef = useRef(null)

  return (
    <Container
      sectionIndicatorVariant="dark"
      data-testid="alertBoatshow"
      ref={containerRef}
      {...others}
    >
      {/* This rendering should be removed in the future,
      it is only here as a fallback for the new items field */}
      {(!items || items?.length === 0) && (
        <ContentContainer>
          <div>
            <Overline>{overline}</Overline>
            <Title>{title}</Title>
          </div>
          <div>
            {link.href && (
              <LinkButton
                variant="primary"
                href={link.href}
                target={link.target}
              >
                {link.title}
              </LinkButton>
            )}
          </div>
        </ContentContainer>
      )}
      {items && items?.length > 0 && (
        <CarouselWrapper>
          <YachtingCarousel
            arrowsPosition="bottom-right"
            data-testid="alertBoatshowCarousel"
            className={!inView ? hiddenClassName : undefined}
            ref={sliderRef}
            slidesToShow={1}
            slidesToScroll={1}
            variableWidth={false}
            waitForAnimate={false}
            adaptiveHeight
            draggable
            centerMode={false}
            customArrows={false}
            customDots
            customDotsPosition="alertBoatShow"
          >
            {items?.map(({ key, link, title, image, overline }) => (
              <ItemContainer key={key}>
                <ImageContainer>
                  <StyledImage
                    src={image.src}
                    alt={image.alt}
                    width={272}
                    height={217}
                    aspectRatio="272:217"
                    style={{ objectFit: 'cover' }}
                  />
                </ImageContainer>
                <ItemContentContainer>
                  <TextWrapper>
                    <Overline>{overline}</Overline>
                    <Title>{title}</Title>
                  </TextWrapper>
                  <div>
                    {link.href && (
                      <LinkButton
                        variant="primary"
                        href={link.href}
                        target={link.target}
                      >
                        {link.title}
                      </LinkButton>
                    )}
                  </div>
                </ItemContentContainer>
              </ItemContainer>
            ))}
          </YachtingCarousel>
        </CarouselWrapper>
      )}
    </Container>
  )
}

export default AlertBoatshow
