import { ComponentProps, ReactNode } from 'react'
import Section from '../../components/Section'
import { currentSite, Site } from '../../sites'
import TextWithImageAmels from './TextWithImage.amels'
import TextWithImageYachting from './TextWithImage.yachting'
import TextWithImageYachtsupport from './TextWithImage.yachtsupport'
import TextWithImageXplorer from './TextWithImage.xplorer'

export interface TextWithImageProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant' | 'title'
  > {
  overline?: string
  sectionTitle?: string
  title: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
  imageLocation?: 'left' | 'right' | 'bottom' | 'top'
  background?:
    | 'white'
    | 'beach10'
    | 'silver10'
    | 'darkBlue'
    | 'lightGreen'
    | 'darkGreen'
  backgroundImage?: {
    src: string
    alt?: string
  }
  logo?: {
    src: string
    alt?: string
  }
  link?: {
    title: ReactNode
    href: string
    target?: string
  }
  triggerFormCallToAction?: ReactNode
}

const TextWithImage = (props: TextWithImageProps) => {
  switch (currentSite) {
    case Site.Yachting:
      return <TextWithImageYachting {...props} />
    case Site.Xplorer:
      return <TextWithImageXplorer {...props} />
    case Site.YachtSupport:
      return <TextWithImageYachtsupport {...props} />
    default:
      return <TextWithImageAmels {...props} />
  }
}

export default TextWithImage
