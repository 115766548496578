import { VideoFeedStoryblok } from '../storyblok.generated'
import VideoFeed from '../../patterns/VideoFeed'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: VideoFeedStoryblok
}

const VideoFeedBlok = ({
  content: { heading, overline, videos, wantToKnowMore },
  ...others
}: Props) => {
  return (
    <VideoFeed
      heading={heading}
      overline={overline}
      videos={videos.map((video) => {
        return {
          key: video._uid,
          title: video.title,
          image: mapImage(video.image) ?? getRequiredImageFallback(),
          link: storyblokLinkResolver(video.link),
          buttonLabel: video.buttonLabel,
          summary: video.summary,
          caption: video.caption,
        }
      })}
      wantToKnowMore={
        wantToKnowMore && wantToKnowMore?.length > 0
          ? {
              heading: wantToKnowMore[0].heading,
              description: wantToKnowMore[0].description,
              buttonLabel: wantToKnowMore[0].buttonLabel,
              link: wantToKnowMore[0].link
                ? storyblokLinkResolver(wantToKnowMore[0].link)
                : '',
            }
          : undefined
      }
      {...others}
    />
  )
}

export default VideoFeedBlok
