import { currentSite } from '../../sites'
import VideoFeedYachtSupport from './VideoFeed.yachtsupport'

export interface VideoFeedProps {
  heading: string
  overline?: string
  videos: Array<{
    key: string
    title: string
    image: {
      src: string
      alt?: string
    }
    link: string
    buttonLabel: string
    summary?: string
    caption?: string
  }>
  wantToKnowMore?: {
    heading: string
    description: string
    buttonLabel: string
    link: string
  }
}

const VideoFeed = (props: VideoFeedProps) => {
  switch (currentSite) {
    case 'yachtsupport':
      return <VideoFeedYachtSupport {...props} />
    default:
      return <VideoFeedYachtSupport {...props} />
  }
}

export default VideoFeed
