import {
  ContactCallToActionStoryblok,
  FormStoryblok,
} from '../storyblok.generated'
import ContactCallToAction from '../../patterns/ContactCallToAction'
import { usePageContext } from '../PageContext'
import { StoryblokStory } from '../../utils/storyblok'
import FormBlok from '../FormBlok'

interface Props {
  content: ContactCallToActionStoryblok
}

const ContactCallToActionBlok = ({
  content: { overline, title, buttons },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  return (
    <ContactCallToAction
      overline={overline}
      title={title}
      buttons={buttons.map((button) => {
        const form = rels.find(
          (rel) => rel.uuid === button.formReference,
        ) as StoryblokStory<FormStoryblok>
        return {
          key: button._uid,
          title: button.title,
          form: <FormBlok form={form} />,
        }
      })}
      {...others}
    />
  )
}

export default ContactCallToActionBlok
