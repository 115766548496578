import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import {
  ComponentProps,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import CloseButton from '../components/CloseButton'
import ImageCarousel from '../components/ImageCarousel'
import PortalRoot from '../components/PortalRoot'
import Image from '../components/Image'
import mergeRefs from '../utils/mergeRefs'
import useElementScrollLock from '../utils/useElementScrollLock'
import {
  ignoreScrollUpVar,
  showNavigationBarVar,
} from '../components/StickyNavigationBar'

const Container = styled.div(
  ({ theme }) => css`
    z-index: 20;
    overflow: hidden;

    position: absolute;
    --image-top: 0; // filled via style
    --image-height: 0; // filled via style
    --margin: 0px;
    --left: var(--margin);
    --width: calc(100vw - var(--margin) * 2);
    --height: 100vh;
    --carousel-height: var(--height);
    // Center on top of the image
    --top: var(--scroll-y);
    left: var(--left);
    top: var(--top);
    width: var(--width);
    height: var(--height);
    background: ${theme.colors.amels.deepBayAqua};

    // The initial; left/top/width/height values are set via style, and only
    // animation props overrule that
    animation: ${keyframes`
      to {
        left: var(--left);
        top: var(--top);
        width: var(--width);
        height: var(--height);
      }
    `} 1200ms ease-in-out forwards;
    @media (prefers-reduced-motion: reduce) {
      animation-duration: 0ms;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      position: absolute;
      --margin: 40px;
      --left: var(--margin);
      --width: calc(100vw - var(--margin) * 2);
      --height: min(var(--image-height), calc(100vh - var(--margin) * 2));
      // Center on top of the image
      --top: calc(var(--image-top) + (var(--image-height) - var(--height)) / 2);
    }
  `,
)
const Preview = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
export const ImageContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // Full width from the start so the image framing doesn't change during the animation
    width: var(--width);
    height: 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      left: 0;
      right: 0;
    }
  `,
)
const ModalImageContainer = styled(ImageContainer)`
  @media (prefers-reduced-motion: no-preference) {
    transform: scale(1.1);
    transform-origin: top right;
    animation: ${keyframes`
      from {
        transform: scale(1.1);
      }
      to {
        transform: scale(1.0);
      }
    `} 1200ms ease-in-out forwards;
  }
`
const Content = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: ${theme.spacing.x4}px;
    bottom: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      left: ${theme.spacing.x15}px;
      bottom: 195px;
    }
  `,
)
const Title = styled.h3(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    font-size: ${theme.spacing.x5}px;
    margin: 0 0 ${theme.spacing.x2}px;
    color: ${theme.colors.amels.silk};
    max-width: 600px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: ${theme.spacing.x10}px;
    }
  `,
)
const SubTitle = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.amels.silk};
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;

    p {
      margin: 0;
    }
  `,
)
const Line = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.amels.silk};
    margin-left: 0;
    margin-right: ${theme.spacing.x2}px;
    width: 100px;
    height: 1px;
    // Offset for the line-height not being centered
    margin-top: -2px;

    @media (prefers-reduced-motion: no-preference) {
      transform: scaleX(0);
      transform-origin: left;
      animation: ${keyframes`
        to {
          transform: scaleX(1);
        }
      `} 800ms ease-in-out forwards;
      animation-delay: 1200ms;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-left: ${theme.spacing.x20}px;
      width: 200px;
    }
  `,
)
const CarouselContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: 100%;
    top: 0;
    width: 100%;
    height: 100%;

    --height: var(--carousel-height);
    --pager-margin-right: 80px;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      --pager-margin-right: 0px;
    }
  `,
)
const carouselTransitionDuration = 800 // ms
const Mover = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'isCarouselVisible' && prop !== 'imageLocation',
})<{
  isCarouselVisible?: boolean
  imageLocation: 'right' | 'left'
}>(({ isCarouselVisible, imageLocation }) => [
  css`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: transform ${carouselTransitionDuration}ms ease-in-out;
  `,
  imageLocation === 'right'
    ? css`
        transform: translateX(${isCarouselVisible ? -100 : 0}%);
      `
    : css`
        transform: translateX(${isCarouselVisible ? 0 : -100}%);
        ${Preview} {
          left: 100%;
        }
        ${CarouselContainer} {
          left: 0;
        }
      `,
])
const StyledCloseButton = styled(CloseButton)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing.x4}px;
    transform: translate(50%, -50%);
    right: ${theme.spacing.x4}px;
    z-index: 1;
    width: ${theme.spacing.x6}px;
    height: ${theme.spacing.x6}px;
    --animation-delay: 600ms;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      top: 7.5%;
      transform: translateY(-50%);
      right: ${theme.spacing.x3}px;
    }
  `,
)

// openCarousel is used by Cypress to skip the timeout
declare global {
  interface Window {
    openCarousel?: () => void
  }
}

interface Props extends Omit<ComponentProps<typeof Container>, 'title'> {
  title: ReactNode
  subTitle: ReactNode
  image: {
    src: string
    alt?: string
  }
  imageLocation: 'left' | 'right'
  carouselImages?: ComponentProps<typeof ImageCarousel>['images']
  close: () => void
  'data-testid'?: string
}

// eslint-disable-next-line react/display-name
const DesignTextWithExpandableImageModal = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      subTitle,
      image,
      imageLocation,
      carouselImages,
      close,
      'data-testid': testid = 'textWithImageModal',
      ...others
    }: Props,
    ref,
  ) => {
    const [isCarouselVisible, setCarouselVisible] = useState(false)
    const [isCarouselAnimationFinished, setCarouselAnimationFinished] =
      useState(false)

    useEffect(() => {
      if (!carouselImages || carouselImages.length === 0) {
        return undefined
      }
      const openCarousel = () => {
        setCarouselVisible(true)
        setTimeout(() => {
          setCarouselAnimationFinished(true)
        }, carouselTransitionDuration)
      }

      const timer = setTimeout(openCarousel, 1200 + 1000)
      window.openCarousel = openCarousel
      return () => clearTimeout(timer)
    }, [carouselImages])

    const containerRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      const elem = containerRef.current
      if (!elem) {
        return
      }
      disableBodyScroll(elem, {
        reserveScrollBarGap: true,
      })
      // eslint-disable-next-line consistent-return
      return () => enableBodyScroll(elem)
    }, [containerRef])
    useEffect(() => {
      showNavigationBarVar(false)
      ignoreScrollUpVar(true)
      return () => {
        ignoreScrollUpVar(false)
      }
    }, [])
    useElementScrollLock(containerRef, true, 2000, true)

    return (
      <Container
        data-testid={testid}
        ref={mergeRefs(ref, containerRef)}
        {...others}
      >
        <StyledCloseButton
          variant="light"
          onClick={close}
          data-testid={`${testid}.close`}
        />

        <PortalRoot>
          <Mover
            isCarouselVisible={isCarouselVisible}
            imageLocation={imageLocation}
          >
            {carouselImages && (
              <CarouselContainer>
                <ImageCarousel
                  images={carouselImages}
                  isVisible={isCarouselAnimationFinished}
                  className={
                    !isCarouselAnimationFinished ? hiddenClassName : undefined
                  }
                  navigationVariant="static"
                  textPresentation="modal"
                  data-testid={`${testid}.carousel`}
                  aria-hidden={!isCarouselVisible}
                />
              </CarouselContainer>
            )}

            <Preview aria-hidden={isCarouselVisible}>
              <ModalImageContainer>
                <Image
                  src={image.src}
                  alt={image.alt}
                  fill
                  style={{ objectFit: 'cover' }}
                />
              </ModalImageContainer>

              <Content>
                <Title>
                  <AnimateText delay={600}>{title}</AnimateText>
                </Title>
                <SubTitle>
                  <Line />
                  <AnimateText delay={1400} direction="static">
                    {subTitle}
                  </AnimateText>
                </SubTitle>
              </Content>
            </Preview>
          </Mover>
        </PortalRoot>
      </Container>
    )
  },
)

export default DesignTextWithExpandableImageModal
