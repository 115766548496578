import { CollageWithTextStoryblok } from '../storyblok.generated'
import CollageWithText from '../../patterns/CollageWithText'
import RichText from '../../components/RichText'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: CollageWithTextStoryblok
}

const CollageWithTextBlok = ({
  content: {
    title,
    overline,
    description,
    imageLayoutVariant,
    imageOne,
    imageTwo,
    buttonLabel,
    buttonLink,
  },
  ...others
}: Props) => (
  <CollageWithText
    title={title}
    overline={overline}
    description={
      typeof description === 'object' ? (
        <RichText as="span">{description}</RichText>
      ) : (
        description
      )
    }
    imageLayoutVariant={imageLayoutVariant}
    imageOne={mapImage(imageOne) ?? getRequiredImageFallback()}
    imageTwo={mapImage(imageTwo) ?? getRequiredImageFallback()}
    buttonLabel={buttonLabel}
    buttonLink={buttonLink ? storyblokLinkResolver(buttonLink) : undefined}
    {...others}
  />
)

export default CollageWithTextBlok
