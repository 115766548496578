import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import AnimateText from '../components/AnimateText'
import ScrollDownIndicator from '../components/ScrollDownIndicator'
import Image from '../components/Image'
import Section from '../components/Section'

const Header = styled(Section)(
  ({ theme }) => css`
    color: ${theme.colors.amels.deepBayAqua};
    padding: 283px ${theme.spacing.x4}px ${theme.spacing.x9}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      padding: 283px ${theme.spacing.x5}px ${theme.spacing.x20}px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    font-size: ${theme.spacing.x7}px;
    margin: 0 auto;
    max-width: 738px;
  `,
)
const SubTitle = styled.h3(
  ({ theme }) => css`
    ${theme.text.subHeadingWide(theme)};
    margin: ${theme.spacing.x2}px auto ${theme.spacing.x8}px;
    max-width: 738px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: ${theme.spacing.x2}px auto ${theme.spacing.x7}px;
    }
  `,
)
const ImageWrapper = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto ${theme.spacing.x2}px;
      max-width: 738px;
    }
  `,
)
const ScrollIndicatorWrapper = styled.div`
  text-align: center;
`

interface Props
  extends Omit<
    ComponentProps<typeof Header>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  subTitle: ReactNode
  image: string
  scrollIndicatorText: ReactNode
}

const FullCustomHeader = ({
  title,
  subTitle,
  image,
  scrollIndicatorText,
  ...others
}: Props) => (
  <Header
    sectionIndicatorVariant="dark"
    data-testid="fullCustomHeader"
    {...others}
  >
    <Title>
      <AnimateText delay={300}>{title}</AnimateText>
    </Title>
    <SubTitle>
      <AnimateText delay={500}>{subTitle}</AnimateText>
    </SubTitle>

    <AnimateText delay={1000} direction="static">
      <ImageWrapper>
        <Image
          src={image}
          alt=""
          width={738}
          height={240}
          style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
        />
      </ImageWrapper>
    </AnimateText>

    {scrollIndicatorText && (
      <ScrollIndicatorWrapper>
        <ScrollDownIndicator
          style={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ['--animation-delay' as any]: '1400ms',
          }}
        >
          {scrollIndicatorText}
        </ScrollDownIndicator>
      </ScrollIndicatorWrapper>
    )}
  </Header>
)

export default FullCustomHeader
