import { ComponentProps } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Section from '../components/Section'
import { heading5 } from '../themes/xplorer/text'
import Image from '../components/Image'
import {
  TextWithImageCardStoryblok,
  TextWithImageImageCardStoryblok,
} from '../bloks/storyblok.generated'
import Link from '../components/Link'
import {
  secondaryButtonCssXplorer,
  secondaryButtonCssYachtSupport,
} from '../components/Button'
import { mapImage } from '../utils/mapImage'
import { isSiteXplorer, isSiteYachtSupport } from '../themes'
import storyblokLinkResolver from '../utils/storyblokLinkResolver'

type layoutVariants = 'oneRow' | 'twoRows'
type BackgroundColorVariants = '' | 'lightGreen' | 'lightBronze'

export interface TextWithImageCardsProps
  extends Omit<
    ComponentProps<typeof Section>,
    'title' | 'sectionIndicatorVariant' | 'children'
  > {
  layoutVariant: layoutVariants
  backgroundColorVariants: BackgroundColorVariants
  heading?: string
  cards: (TextWithImageCardStoryblok | TextWithImageImageCardStoryblok)[]
}

const Container = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;

    gap: ${theme.spacing.x3}px;
    padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x5}px;
      padding: ${theme.spacing.x20}px ${theme.spacing.x3}px;
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x10}px;
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x4}px ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x8}px ${theme.spacing.x10}px;
      }
    `,
])

const Content = styled.div(({ theme }) => [
  css`
    display: grid;
    grid-template-columns: repeat(1, minmax(250px, 1fr));
    gap: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x5}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      grid-template-columns: repeat(3, minmax(290px, 1fr));
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      gap: 20px;
    `,
])

const SecondRowWrapper = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      gap: 20px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        gap: ${theme.spacing.x5}px;
      }
    `,
])

const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin: 0 0 ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 0 ${theme.spacing.x5}px;
    }
  `,
)

const ImageCard = styled.div(({ theme }) => [
  css`
    height: 726px;
  `,
  isSiteYachtSupport(theme) &&
    css`
      height: 436px;
      img {
        height: 100%;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        height: 100%;
      }
    `,
])

const StyledImage = styled(Image)``

const TextWithImageCard = styled('div', {
  shouldForwardProp: (prop) => prop !== 'layoutVariant',
})<{
  layoutVariant?: layoutVariants
}>(({ theme, layoutVariant }) => [
  css`
    display: flex;
    flex-direction: column;

    ${StyledImage} {
      width: 100%;
      height: 227px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      flex-direction: ${layoutVariant === 'twoRows' ? 'row' : 'column'};

      ${StyledImage} {
        width: 50%;
        height: auto;
      }
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      ${StyledImage} {
        height: 225px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        flex-direction: ${layoutVariant === 'twoRows' ? 'row' : 'column'};

        ${StyledImage} {
          width: 50%;
          height: auto;
        }
      }
    `,
])

const TextWithImageCardContent = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'layoutVariant' && prop !== 'backgroundColorVariants',
})<{
  layoutVariant?: layoutVariants
  backgroundColorVariants?: BackgroundColorVariants
}>(({ theme, layoutVariant, backgroundColorVariants }) => [
  css`
    padding: ${theme.spacing.x7}px ${theme.spacing.x4}px;
    background: ${backgroundColorVariants === 'lightGreen'
      ? theme.colors.xplorer.secondaryXPMistGrey
      : theme.colors.xplorer.xpBronze10};
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
  `,
  layoutVariant === 'twoRows' &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
        align-items: center;
        justify-content: center;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      border: 1px solid ${theme.colors.yachtSupport.grey03};
      background: ${theme.colors.yachtSupport.white};
    `,
])

const CardHeading = styled.div(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
    margin-top: ${theme.spacing.x2}px;
    line-height: 1.2;
    text-transform: uppercase;
    overflow-wrap: break-word;
    max-width: 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 28px;
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading3(theme)}
    `,
])

const CardSubHeading = styled.div(({ theme }) => [
  !isSiteYachtSupport(theme) &&
    css`
      ${heading5(theme)}
      color: ${theme.colors.xplorer.secondaryXPBronze};
      margin: 0;
      text-transform: uppercase;
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.overLineMedium(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    `,
])
const CardDescription = styled.div(({ theme }) => [
  css`
    ${theme.text.body(theme)}
    color: ${theme.colors.xplorer.primaryXPLavaBlack};
    margin-top: ${theme.spacing.x5}px;
  `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};
      text-align: center;
    `,
])

const StyledLink = styled(Link)(({ theme }) => [
  isSiteXplorer(theme) &&
    css`
      ${secondaryButtonCssXplorer(theme)}
    `,
  css`
    margin: 0 auto;
    margin-top: ${theme.spacing.x5}px;
    width: fit-content;

    :hover {
      text-decoration: none;
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      ${secondaryButtonCssYachtSupport(theme)}
    `,
])

const TextWithImageCards = ({
  layoutVariant,
  backgroundColorVariants,
  heading,
  cards,
  ...others
}: TextWithImageCardsProps) => {
  const filteredCards = layoutVariant === 'oneRow' ? cards : cards.slice(0, 3)
  const secondRowCard = cards[3]

  return (
    <Container {...others}>
      {heading && <Title>{heading}</Title>}
      <SecondRowWrapper>
        <Content>
          {filteredCards.map((card) => {
            return card.component === 'textWithImageImageCard' ? (
              <ImageCard key={card._uid}>
                <Image
                  src={mapImage(card.image)?.src ?? ''}
                  alt={mapImage(card.image)?.alt ?? ''}
                  width={400}
                  height={726}
                  aspectRatio="200:363"
                  style={{ objectFit: 'cover', width: '100%' }}
                />
              </ImageCard>
            ) : (
              <TextWithImageCard key={card._uid}>
                {card.image && (
                  <Image
                    src={mapImage(card.image)?.src ?? ''}
                    alt={mapImage(card.image)?.alt ?? ''}
                    width={400}
                    height={225}
                    aspectRatio="16:9"
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                )}
                <TextWithImageCardContent
                  backgroundColorVariants={backgroundColorVariants}
                >
                  <CardSubHeading>{card.overline}</CardSubHeading>
                  <CardHeading>{card.heading}</CardHeading>
                  <CardDescription>{card.description}</CardDescription>
                  {card.linkWithTitle && card.linkWithTitle[0]?.link && (
                    <StyledLink
                      href={storyblokLinkResolver(card.linkWithTitle[0]?.link)}
                      target={card.linkWithTitle[0]?.linkTarget}
                    >
                      {card.linkWithTitle[0]?.title}
                    </StyledLink>
                  )}
                </TextWithImageCardContent>
              </TextWithImageCard>
            )
          })}
        </Content>
        {layoutVariant === 'twoRows' &&
          secondRowCard.component === 'textWithImageCard' && (
            <TextWithImageCard
              key={secondRowCard._uid}
              layoutVariant={layoutVariant}
            >
              <StyledImage
                src={mapImage(secondRowCard.image)?.src ?? ''}
                alt={mapImage(secondRowCard.image)?.alt ?? ''}
                width={640}
                height={600}
                aspectRatio="64:51"
                style={{ objectFit: 'cover' }}
              />
              <TextWithImageCardContent
                layoutVariant={layoutVariant}
                backgroundColorVariants={backgroundColorVariants}
              >
                <CardSubHeading>{secondRowCard.overline}</CardSubHeading>
                <CardHeading>{secondRowCard.heading}</CardHeading>
                <CardDescription>{secondRowCard.description}</CardDescription>
                {secondRowCard.linkWithTitle && (
                  <StyledLink
                    href={storyblokLinkResolver(
                      secondRowCard.linkWithTitle[0]?.link,
                    )}
                    target={secondRowCard.linkWithTitle[0]?.linkTarget}
                  >
                    {secondRowCard.linkWithTitle[0]?.title}
                  </StyledLink>
                )}
              </TextWithImageCardContent>
            </TextWithImageCard>
          )}
      </SecondRowWrapper>
    </Container>
  )
}

export default TextWithImageCards
