import { currentSite, Site } from '../../sites'
import VisualDividerYachtSupport from './VisualDivider.yachtsupport'

export interface VisualDividerProps {
  hasPadding?: boolean
  vertical?: boolean
}

const VisualDivider = (props: VisualDividerProps) => {
  switch (currentSite) {
    case Site.YachtSupport:
      return <VisualDividerYachtSupport {...props} />
    default:
      return <VisualDividerYachtSupport {...props} />
  }
}

export default VisualDivider
