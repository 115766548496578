import reactStringReplace from '../../utils/reactStringReplace'
import { HarmonicaItemWithContactsStoryblok } from '../storyblok.generated'
import HarmonicaItemWithContacts from '../../patterns/HarmonicaItemWithContacts'
import { usePageContext } from '../PageContext'
import normaliseContactStorybloks from '../../utils/normaliseContactStorybloks'

interface Props {
  content: HarmonicaItemWithContactsStoryblok
}

const HarmonicaItemWithContactsBlok = ({
  content: { _uid, text, contacts },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  return (
    <HarmonicaItemWithContacts
      id={_uid}
      text={reactStringReplace(text, '\n', <br />)}
      contacts={normaliseContactStorybloks(contacts, rels)}
      {...others}
    />
  )
}

export default HarmonicaItemWithContactsBlok
