import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import SummaryWithLinkXplorer from './SummaryWithLink'
import Section from '../../components/Section'

export type summaryWithLinkVariant = 'light' | 'dark'
export type summaryWithLinkContentLocation = 'left' | 'right'

export interface SummaryWithLinkProps
  extends Omit<
    ComponentProps<typeof Section>,
    'sectionIndicatorVariant' | 'children'
  > {
  variant: summaryWithLinkVariant
  contentLocation?: summaryWithLinkContentLocation
  backgroundImage: { src: string; alt?: string }
  overlayImage?: { src: string; alt?: string }
  summaryWithLinkCard: {
    title: ReactNode
    subTitle?: string
    description?: ReactNode
    link: string
    linkText: string
  }
}

const SummaryWithLink = (props: SummaryWithLinkProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <SummaryWithLinkXplorer {...props} />
    default:
      return <SummaryWithLinkXplorer {...props} />
  }
}

export default SummaryWithLink
