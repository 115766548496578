import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'

import { isSiteAmels, isSiteYachting } from '../themes'
import SocialsList from '../components/SocialsList'
import TwoColorString from '../components/TwoColorString'
import { SocialLinkStoryblok } from '../bloks/storyblok.generated'
import storyblokLinkResolver from '../utils/storyblokLinkResolver'

const Container = styled('section')(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      margin: ${theme.spacing.x15}px ${theme.spacing.x4}px ${theme.spacing.x7}px;
      text-align: center;
    `,
  isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
      text-align: center;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x20}px ${theme.spacing.x4}px;
      }
    `,
])
const Title = styled('div')(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      font-weight: 500;
      letter-spacing: 1px;
      margin-bottom: ${theme.spacing.x2}px;
    `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.heading3(theme)};
      font-weight: 300;
      margin-bottom: ${theme.spacing.x4}px;
    `,
])

interface FollowUsProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'title'> {
  title?: string
  titleColorChangeAfterWord?: string
  socials?: SocialLinkStoryblok[]
}

const FollowUs = ({
  title,
  titleColorChangeAfterWord,
  socials,
  ...others
}: FollowUsProps) => {
  const mappedSocials = socials?.map((social) => ({
    url: storyblokLinkResolver(social.link),
    type: social.icon as
      | 'vimeo'
      | 'facebook'
      | 'instagram'
      | 'linkedin'
      | 'twitter'
      | 'youtube',
  }))

  return (
    <Container data-testid="followUs" {...others}>
      {title && (
        <Title>
          <TwoColorString
            string={title}
            colorChangeAfterWord={
              titleColorChangeAfterWord
                ? Number(titleColorChangeAfterWord)
                : undefined
            }
          />
        </Title>
      )}
      <SocialsList socials={mappedSocials} />
    </Container>
  )
}

export default FollowUs
