import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import NavigationTeaserWithTextXplorer from './NavigationTeaserWithText.xplorer'
import NavigationTeaserWithTextYachtSupport from './NavigationTeaserWithText.yachtsupport'
import NavigationTeaser, { NavigationTeaserProps } from '../NavigationTeaser'

export interface NavigationTeaserWithTextProps
  extends Omit<
    ComponentProps<typeof NavigationTeaser>,
    'title' | 'columns' | 'sectionIndicatorVariant' | 'children'
  > {
  title: ReactNode
  subTitle?: ReactNode
  description?: ReactNode
  navigationTeaserProps: NavigationTeaserProps
}

const NavigationTeaserWithText = (props: NavigationTeaserWithTextProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <NavigationTeaserWithTextXplorer {...props} />
    case Site.YachtSupport:
      return <NavigationTeaserWithTextYachtSupport {...props} />
    default:
      return <NavigationTeaserWithTextXplorer {...props} />
  }
}

export default NavigationTeaserWithText
