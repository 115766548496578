import LogoWithText from '../../patterns/LogoWithText'
import RichText from '../../components/RichText'
import slugify from '../../utils/slugify'
import { LogoWithTextStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: LogoWithTextStoryblok
}

const LogoWithTextBlok = ({
  content: { sectionTitle, text, background, logo },
  ...others
}: Props) => (
  <LogoWithText
    id={slugify(sectionTitle)}
    background={background}
    logo={mapImage(logo) ?? getRequiredImageFallback()}
    {...others}
  >
    <RichText>{text}</RichText>
  </LogoWithText>
)

export default LogoWithTextBlok
