const preloadImage = (src: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    // Use the Image class instead of fetch to load the image so the browser
    // passes the correct Accept header and the Next image proxy can provide
    // the most optimal image type.
    const image = new Image()
    image.src = src
    const handleLoad = async () => {
      resolve(image)
      image.removeEventListener('load', handleLoad)
    }
    image.addEventListener('load', handleLoad)
    image.addEventListener('error', reject)
  })

export default preloadImage
