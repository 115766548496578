// Based on: https://github.com/Swizec/useDimensions

import { useState, useCallback, useEffect } from 'react'

import useBrowserLayoutEffect from './useBrowserLayoutEffect'
import useViewportSize from './useViewportSize'

export interface DimensionObject {
  width: number
  height: number
  top: number
  left: number
  x: number
  y: number
  right: number
  bottom: number
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rectAny: any = rect
  return {
    width: rect.width,
    height: rect.height,
    left: 'x' in rect ? rect.x : rectAny.left,
    top: 'y' in rect ? rect.y : rectAny.top,
    x: 'x' in rect ? rect.x : rectAny.left,
    y: 'y' in rect ? rect.y : rectAny.top,
    right: rect.right,
    bottom: rect.bottom,
  }
}

const defaultDimensions = {
  width: undefined,
  height: undefined,
  top: undefined,
  left: undefined,
  x: undefined,
  y: undefined,
  right: undefined,
  bottom: undefined,
}

function useDimensions() {
  const [dimensions, setDimensions] = useState<
    DimensionObject | typeof defaultDimensions
  >(defaultDimensions)
  const [node, setNode] = useState<HTMLElement | null>(null)

  const measure = useCallback(() => {
    if (!node) {
      return
    }

    const id = window.requestAnimationFrame(() =>
      setDimensions(getDimensionObject(node)),
    )
    // eslint-disable-next-line consistent-return
    return () => window.cancelAnimationFrame(id)
  }, [node])
  useBrowserLayoutEffect(() => {
    measure()
    document.addEventListener('scroll', measure, { passive: true })
    return () => document.removeEventListener('scroll', measure)
  }, [measure])
  const viewportSize = useViewportSize()
  useEffect(measure, [measure, viewportSize])

  return [setNode, dimensions, node] as const
}

export default useDimensions
