import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { isSiteYachting } from '../themes'

const ColoredString = styled.span(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      color: ${theme.colors.yachting.silver};
    `,
])

interface Props {
  string: string
  colorChangeAfterWord?: number
}

const TwoColorString = ({ string, colorChangeAfterWord }: Props) => {
  const splitString = string.split(' ')
  const firstPartOfString = colorChangeAfterWord
    ? splitString.slice(0, colorChangeAfterWord).join(' ')
    : string
  const secondPartOfString = colorChangeAfterWord
    ? splitString
        .slice(colorChangeAfterWord)
        .join(' ')
        .padStart(
          splitString.slice(colorChangeAfterWord).join(' ').length + 1,
          ' ',
        )
    : null
  return (
    <>
      {firstPartOfString}
      {secondPartOfString && (
        <ColoredString>{secondPartOfString}</ColoredString>
      )}
    </>
  )
}

export default TwoColorString
