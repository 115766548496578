import RichText from '../../components/RichText'
import NavigationTeaser from '../../patterns/NavigationTeaser'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import slugify from '../../utils/slugify'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { NavigationTeaserStoryblok } from '../storyblok.generated'

interface Props {
  content: NavigationTeaserStoryblok
}

const NavigationTeaserBlok = ({
  content: { sectionTitle, columns, hasParallax },
  ...others
}: Props) => (
  <NavigationTeaser
    id={slugify(sectionTitle)}
    hasParallax={hasParallax}
    columns={columns.map(
      ({
        _uid,
        image,
        mobileImage,
        title,
        logo,
        subtitle,
        link,
        linkText,
        hoverTitle,
        hoverSubtitle,
        hoverText,
      }) => ({
        key: _uid,
        title,
        subtitle,
        image: mapImage(image) ?? getRequiredImageFallback(),
        logo: mapImage(logo),
        mobileImage: mapImage(mobileImage),
        link: storyblokLinkResolver(link),
        linkText,
        hoverTitle,
        hoverSubtitle,
        hoverText: <RichText>{hoverText}</RichText>,
      }),
    )}
    {...others}
  />
)

export default NavigationTeaserBlok
