import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes, ReactNode } from 'react'

import Section from '../components/Section'
import { isSiteYachting } from '../themes'

const Container = styled(Section)(({ theme }) => [
  css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x5}px;
  `,
])

const ContentContainer = styled('div')(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
      border-top: solid 1px ${theme.colors.yachting.orange};
      border-bottom: solid 1px ${theme.colors.yachting.orange};
      padding: ${theme.spacing.x4}px ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x6}px ${theme.spacing.x3}px;
        max-width: 860px;
      }
    `,
)
const Overline = styled.span(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)};
      display: block;
      margin-bottom: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x5}px;
      }
    `,
)
const Title = styled('h3')(
  ({ theme }) =>
    css`
      ${theme.text.heading3(theme)};
      color: ${theme.colors.yachting.darkBlue};
      margin: 0 0 ${theme.spacing.x1}px 0;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0 0 ${theme.spacing.x2}px 0;
      }
    `,
)
const Text = styled.div`
  > div > ul {
    /* allow same spacing of rich text paragraphs and lists */
    margin: 16px 0;
  }
`

export interface PressInformationProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'title'> {
  overline: string
  title?: ReactNode
  text: ReactNode
}

const PressInformation = ({
  overline,
  title,
  text,
  ...others
}: PressInformationProps) => (
  <Container
    sectionIndicatorVariant="dark"
    data-testid="pressInformation"
    {...others}
  >
    <ContentContainer {...others}>
      <Overline>{overline}</Overline>
      {title && <Title>{title}</Title>}
      <Text>{text}</Text>
    </ContentContainer>
  </Container>
)

export default PressInformation
