import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes, ReactNode } from 'react'

import Section from '../components/Section'
import Link from '../components/Link'
import { isSiteYachting } from '../themes'
import ArrowSlant from '../icons/yachting/ArrowSlant.svg'

const Container = styled(Section)<{
  variant: TextCallToActionProps['variant']
}>(({ theme, variant }) => [
  css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x5}px;
    }
  `,
  variant === 'white' &&
    css`
      background: ${theme.colors.yachting.white};
    `,
  variant === 'lightblue' &&
    css`
      background: ${theme.colors.yachting.silver10};
    `,
])
const StyledLink = styled(Link)(
  ({ theme }) =>
    css`
      display: flex;
      margin: 0 auto;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      border-top: solid 1px ${theme.colors.yachting.orange};
      border-bottom: solid 1px ${theme.colors.yachting.orange};
      padding: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: 540px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        max-width: 630px;
      }

      :hover {
        text-decoration: none;
      }

      > svg {
        flex: 0 0 auto;
        margin-left: ${theme.spacing.x4}px;
        transition: transform 300ms ease-in;
      }

      @media (prefers-reduced-motion: no-preference) {
        :hover > svg {
          transform: translateX(8px);
        }
      }
    `,
)
const Overline = styled.span(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)};
      display: block;
      color: ${theme.colors.yachting.orange};
      margin-bottom: ${theme.spacing.x2}px;
    `,
)
const Content = styled('div')(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      max-width: 540px;
      font-size: 20px;
      font-weight: 300;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 24px;
      }
    `,
)
const StyledArrowSlant = styled(ArrowSlant)(
  ({ theme }) =>
    css`
      align-self: end;
      position: relative;
      color: ${theme.colors.yachting.orange};
      width: 44px;
    `,
)

export interface TextCallToActionProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'children'> {
  variant?: '' | 'white' | 'lightblue'
  overline?: string
  link: {
    title: ReactNode
    href?: string
    target?: string
  }
}

const TextCallToAction = ({
  variant,
  overline,
  link,
  ...others
}: TextCallToActionProps) => (
  <Container
    sectionIndicatorVariant="dark"
    data-testid="textCallToAction"
    variant={variant}
    {...others}
  >
    <StyledLink href={link.href ?? ''} target={link.target}>
      <Content>
        {overline && <Overline>{overline}</Overline>}
        {link.title}
      </Content>
      <StyledArrowSlant />
    </StyledLink>
  </Container>
)

export default TextCallToAction
