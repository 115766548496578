import { useEffect, useState } from 'react'

const useMousePosition = () => {
  const [mousePosition, setMousePosition] = useState<{
    x: number | undefined
    y: number | undefined
  }>({
    x: undefined,
    y: undefined,
  })

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePosition({
        x: e.clientX,
        y: e.clientY,
      })
    }

    document.addEventListener('mousemove', handleMouseMove, {
      passive: true,
    })
    return () => document.removeEventListener('mousemove', handleMouseMove)
  }, [])

  return mousePosition
}

export default useMousePosition
