import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Image from '../components/Image'
import { isSiteYachting } from '../themes'

const Container = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
  `,
)
const Overline = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)};
      margin-left: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-left: ${theme.spacing.x3}px;
      }
    `,
  css`
    font-size: 20px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x1}px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-size: 20px;
    }
  `,
])
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)};
    margin: 0 0 ${theme.spacing.x4}px 0;
  `,
)
const Text = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      margin-left: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-left: ${theme.spacing.x3}px;
      }
    `,
])
const ImageContainer = styled(AnimateText)(({ theme }) => [
  css`
    margin-top: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-top: ${theme.spacing.x5}px;
    }

    > img {
      width: 100%;
      height: auto;
    }
  `,
  isSiteYachting(theme) &&
    css`
      margin-left: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-left: ${theme.spacing.x3}px;
      }
    `,
])

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  overline: ReactNode
  title: ReactNode
  text: ReactNode
  image?: {
    src: string
    alt?: string
  }
}

const TimelineItem = ({ overline, title, text, image, ...others }: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  return (
    <Container
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      data-testid="timelineItem"
      {...others}
    >
      <Overline>
        <AnimateText direction="up">{overline}</AnimateText>
      </Overline>
      <Title>
        <AnimateText direction="up" delay={100}>
          {title}
        </AnimateText>
      </Title>
      <Text>
        <AnimateText direction="up" delay={200}>
          {text}
        </AnimateText>
      </Text>
      {image && (
        <ImageContainer direction="up" delay={400}>
          <Image
            src={image.src}
            alt={image.alt}
            width={450}
            height={300}
            style={{ objectFit: 'cover' }}
            aspectRatio="16:9"
            priority
          />
        </ImageContainer>
      )}
    </Container>
  )
}

export default TimelineItem
