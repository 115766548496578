import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useInView } from 'react-intersection-observer'

import Section from '../../components/Section'
import AnimateText, { hiddenClassName } from '../../components/AnimateText'
import Image from '../../components/Image'
import Link from '../../components/Link'
import LinkButton from '../../components/LinkButton'
import { ArticleFeedProps } from '.'

const ArticlesInnerWrapper = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: ${theme.spacing.x6}px;
      max-width: 1376px;
      margin: 0 auto;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        flex-direction: row;
        gap: ${theme.spacing.x4}px;
      }
    `,
)
const ArticleHeading = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        max-width: 360px;
      }
    `,
)

const Container = styled(Section)(
  ({ theme }) =>
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
      }
    `,
)
const TitleBar = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.x4}px;
    gap: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      flex-direction: row;
      align-items: center;
      margin-bottom: ${theme.spacing.x7}px;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    margin: 0;
  `,
)
const SubTitle = styled.p(
  ({ theme }) => css`
    ${theme.text.subHeading(theme)}
    line-height: 21px;
    color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
    margin-bottom: ${theme.spacing.x2}px;
    text-transform: uppercase;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x3}px;
    }
  `,
)

const AutomatedArticlesButtonContainer = styled.div(
  () => css`
    display: flex;
    width: 100%;
    margin-top: 12px;
  `,
)
const Articles = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr;
    gap: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: ${theme.spacing.x4}px;
    }
  `,
)
const ArticleImageContainer = styled('div')(
  () => css`
    overflow: hidden;
    position: relative;
  `,
)
const ArticleTitle = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading4(theme)}
    font-family: ${theme.fonts.Rubik.style.fontFamily};
    color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
    margin: 0;
    transition: linear 200ms;
  `,
])
const ArticleLink = styled(Link)(
  ({ theme }) =>
    css`
      display: block;
      height: 100%;

      > div {
        height: 100%;
      }

      img {
        transition: transform ease-out 3000ms;
      }

      :hover {
        text-decoration: none;

        img {
          transform: scale(1.2);
        }

        ${ArticleImageContainer} {
          filter: none;
        }

        ${ArticleTitle} {
          color: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
        }

        button {
          background-color: ${theme.colors.yachtSupport.grey01};
        }

        a {
          color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
          border-bottom: 1px solid
            ${theme.colors.yachtSupport.primaryYSOceanBlue};
        }
      }
    `,
)

const Article = styled.article(
  ({ theme }) => css`
    display: flex;
    flex-flow: column;
    gap: ${theme.spacing.x3}px;
    height: 100%;
  `,
)
const ArticleImage = styled(Image)(
  () => css`
    position: relative;
    width: 100%;
    height: auto;
    min-height: 282px;
  `,
)
const ArticleDescription = styled.p(({ theme }) => [
  css`
    ${theme.text.body(theme)}
    font-size: 12px;
    color: ${theme.colors.yachtSupport.secondaryYSWarmGrey};
    margin: 0;
  `,
  css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      font-weight: 400;
    }
  `,
])

const ArticleContent = styled.div(({ theme }) => [
  css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x3}px;
  `,
])
const ArticleButton = styled.div(
  ({ theme }) => css`
    width: fit-content;
    color: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    background-color: transparent;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    border-bottom: 1px solid ${theme.colors.yachtSupport.grey03};
    padding-bottom: 4px;

    :before {
      display: none;
    }

    :hover {
      color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.yachtSupport.primaryYSOceanBlue};
    }
  `,
)
const ArticleInfo = styled.div(({ theme }) => [
  css`
    display: inline-block;
  `,
  css`
    ${theme.text.body(theme)}
    font-size: 12px;
    color: ${theme.colors.yachtSupport.grey04};
    position: relative;

    :not(:last-of-type) {
      padding-right: ${theme.spacing.x2 + 5}px;
      margin-right: ${theme.spacing.x2 + 5}px;

      ::after {
        content: '';
        position: absolute;
        right: 0;
        top: -1px;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='27' viewBox='0 0 12 27' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.4893 0.519424L1.00027 26.4806' stroke='%23EC7404'/%3E%3C/svg%3E%0A");
        width: 12px;
        height: 27px;
        transform: translateX(50%);
      }
    }
  `,
])

const ArticleFeed = ({
  title,
  subTitle,
  viewAll,
  articles,
  automatedArticles,
  ...others
}: ArticleFeedProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  const { locale } = useRouter()

  const activeArticles =
    automatedArticles && automatedArticles?.length > 0
      ? automatedArticles
      : articles

  if (!activeArticles) {
    return null
  }
  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="articleFeed"
      ref={containerRef}
      {...others}
    >
      <ArticlesInnerWrapper>
        <ArticleHeading>
          {subTitle && (
            <AnimateText>
              <SubTitle>{subTitle}</SubTitle>
            </AnimateText>
          )}
          <TitleBar>
            {title && (
              <AnimateText>
                <Title>{title}</Title>
              </AnimateText>
            )}
          </TitleBar>
          {viewAll ? (
            <AutomatedArticlesButtonContainer>
              <AnimateText delay={400}>
                <LinkButton
                  variant="primary"
                  href={viewAll.href}
                  target={viewAll.target}
                >
                  {viewAll.title}
                </LinkButton>
              </AnimateText>
            </AutomatedArticlesButtonContainer>
          ) : undefined}
        </ArticleHeading>
        <Articles>
          {activeArticles.map(
            (
              { key, variant, slug, title, image, summary, publishedAt },
              index,
            ) => (
              <ArticleLink
                key={key}
                href={
                  variant === 'article' ? `/news/${slug}` : `/events/${slug}`
                }
              >
                <AnimateText delay={1200 + index * 600}>
                  <Article>
                    <ArticleImageContainer>
                      <ArticleImage
                        src={image}
                        alt={title}
                        width={285}
                        height={253}
                        aspectRatio="285:253"
                        style={{ objectFit: 'cover' }}
                      />
                    </ArticleImageContainer>
                    <ArticleContent>
                      {publishedAt && (
                        <ArticleInfo>
                          {new Date(publishedAt).toLocaleDateString(locale, {
                            day: 'numeric',
                          })}{' '}
                          {new Date(publishedAt).toLocaleDateString(locale, {
                            month: 'long',
                          })}{' '}
                          {new Date(publishedAt).toLocaleDateString(locale, {
                            year: 'numeric',
                          })}
                        </ArticleInfo>
                      )}
                      <ArticleTitle>{title}</ArticleTitle>
                      <ArticleDescription>{summary}</ArticleDescription>
                    </ArticleContent>
                    <ArticleButton>Read more</ArticleButton>
                  </Article>
                </AnimateText>
              </ArticleLink>
            ),
          )}
        </Articles>
      </ArticlesInnerWrapper>
    </Container>
  )
}

export default ArticleFeed
