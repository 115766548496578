import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useState } from 'react'
import { NODE_PARAGRAPH } from 'storyblok-rich-text-react-renderer'

import Image from '../components/Image'
import RichText, { dummyResolver } from '../components/RichText'
import FormFieldSelect from '../components/FormFieldSelect'
import FormFieldText from '../components/FormFieldText'
import { FormServiceRequestStoryblok } from './storyblok.generated'
import stripUndefined from '../utils/stripUndefined'
import FormFieldConditionsCheckbox from '../components/FormFieldConditionsCheckbox'
import Button, {
  primaryOutlineButtonCss,
  primaryButtonCssYachting,
} from '../components/Button'
import AnimateText from '../components/AnimateText'
import FormResult from '../components/FormResult'
import { useRecaptchaForm } from '../utils/useRecaptchaForm'
import RecaptchaLoader from '../components/RecaptchaLoader'
import RecaptchaDisclaimer from '../components/RecaptchaDisclaimer'
import RecaptchaContainer from '../components/RecaptchaContainer'
import FormFieldSelectCountry from '../components/FormFieldSelectCountry'
import { isSiteAmels, isSiteXplorer, isSiteYachting } from '../themes'
import FormFieldTextarea from '../components/FormFieldTextarea'
import { Site, currentSite } from '../sites'
import { operations } from '../api/form2lead.generated'
import { mapImage } from '../utils/mapImage'

const Form = styled.form``
const Title = styled.h3(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
    margin: ${theme.spacing.x2}px 0 ${theme.spacing.x3}px;
  `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.heading3(theme)}
    `,
])
const Disclaimer = styled.div`
  margin-top: 24px;
`
const Submit = styled(Button)(({ theme }) => [
  css`
    margin-top: ${theme.spacing.x3}px;
  `,
  isSiteAmels(theme) && primaryOutlineButtonCss(theme),
  isSiteYachting(theme) && primaryButtonCssYachting(theme),
])

interface Props {
  form: FormServiceRequestStoryblok
}

const ServiceRequestFormBlok = ({
  form: {
    title,
    warrantyBlock,
    titleBlock,
    firstNameBlock,
    lastNameBlock,
    emailBlock,
    phoneBlock,
    messageBlock,
    countryBlock,
    privacyPolicyBlock,
    submitBlock,
    pardotConfiguration,
    thankYouBlock,
    errorBlock,
    ...others
  },
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [greatSuccess, setGreatSuccess] = useState(false)
  const [formError, setFormError] = useState(false)
  interface FieldValues {
    title: string
    firstName: string
    lastName: string
    email: string
    phone: string
    message: string
    country: string
    acceptPrivacyPolicy?: boolean
    warranty: string
  }
  const {
    recaptchaElementId,
    isLoading,
    getRecaptchaToken,
    register,
    handleSubmit,
    formState: { errors },
  } = useRecaptchaForm<FieldValues>()
  const onSubmit = async (data: FieldValues) => {
    if (!data.acceptPrivacyPolicy) {
      return
    }

    setIsSubmitting(true)

    try {
      const recaptchaResponse = await getRecaptchaToken()
      // We could also map this on API, but it would be nice to keep fieldnames in sync with backend API
      const request: NonNullable<
        operations['CreateProspect']['requestBody']
      >['content']['application/json'] = {
        salutation: data.title,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        country: data.country,
        message: data.message,
        pardotEndpoint: pardotConfiguration,
        typeOfEnquiry: data.warranty,
        ...recaptchaResponse,
      }
      const response = await fetch('/api/prospect', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(stripUndefined(request)),
      })
      const result = await response.json()

      if (!result.success) {
        setFormError(true)
        return
      }
      setGreatSuccess(true)
    } catch (err) {
      setFormError(true)
      throw err
    } finally {
      setIsSubmitting(false)
    }
  }

  const thankYouBlockImage = mapImage(thankYouBlock[0].image)

  if (greatSuccess) {
    return (
      <div data-testid="serviceRequestForm.success">
        {thankYouBlockImage && (
          <Image
            src={thankYouBlockImage.src}
            alt={thankYouBlockImage.alt}
            width={200}
            height={200}
            style={{ objectFit: 'cover' }}
          />
        )}

        <FormResult
          title={thankYouBlock[0].title}
          text={thankYouBlock[0].text}
        />
      </div>
    )
  }

  if (formError) {
    return (
      <div data-testid="contactForm.error">
        <FormResult title={errorBlock[0].title} text={errorBlock[0].text} />
      </div>
    )
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} {...others}>
      <AnimateText delay={100}>
        <Title>{title}</Title>
      </AnimateText>
      <AnimateText delay={220}>
        <FormFieldSelect
          label={warrantyBlock[0].label}
          options={warrantyBlock[0].options.map(({ id, value }) => ({
            key: id,
            value,
          }))}
          error={errors.title}
          {...register('title')}
          data-testid="contactForm.warranty"
        />
      </AnimateText>
      <AnimateText delay={280}>
        <FormFieldSelect
          label={titleBlock[0].label}
          options={titleBlock[0].options.map(({ id, value }) => ({
            key: id,
            value,
          }))}
          error={errors.title}
          {...register('title')}
          autoComplete="honorific-prefix"
          data-testid="contactForm.title"
          fullWidth={currentSite !== Site.Amels}
        />
      </AnimateText>
      <AnimateText delay={340}>
        <FormFieldText
          label={firstNameBlock[0].label}
          error={errors.firstName}
          {...register('firstName', {
            required: firstNameBlock[0].errorText,
          })}
          autoComplete="given-name"
          required
          data-testid="contactForm.firstName"
        />
      </AnimateText>
      <AnimateText delay={400}>
        <FormFieldText
          label={lastNameBlock[0].label}
          error={errors.lastName}
          {...register('lastName', {
            required: lastNameBlock[0].errorText,
          })}
          autoComplete="family-name"
          required
          data-testid="contactForm.lastName"
        />
      </AnimateText>
      <AnimateText delay={460}>
        <FormFieldText
          label={emailBlock[0].label}
          error={errors.email}
          {...register('email', {
            required: emailBlock[0].errorText,
          })}
          type="email"
          autoComplete="email"
          required
          data-testid="contactForm.email"
        />
      </AnimateText>
      <AnimateText delay={520}>
        <FormFieldText
          label={phoneBlock[0].label}
          error={errors.phone}
          {...register('phone', {
            required: phoneBlock[0].errorText,
          })}
          type="tel"
          autoComplete="tel"
          required
          data-testid="contactForm.phone"
        />
      </AnimateText>
      <AnimateText delay={580}>
        <FormFieldTextarea
          label={messageBlock[0].label}
          error={errors.message}
          {...register('message', {
            required: messageBlock[0].errorText,
          })}
          type="text"
          autoComplete="off"
          required
          data-testid="contactForm.message"
        />
      </AnimateText>
      <AnimateText delay={640}>
        {countryBlock[0].component === 'formFieldSelect' ? (
          <FormFieldSelect
            label={countryBlock[0].label}
            options={countryBlock[0].options.map(({ id, value }) => ({
              key: id,
              value,
            }))}
            error={errors.country}
            {...register('country')}
            autoComplete="country"
            data-testid="requestBrochureForm.country"
          />
        ) : (
          <FormFieldSelectCountry
            label={countryBlock[0].label}
            error={errors.country}
            {...register('country')}
            autoComplete="country"
            data-testid="requestBrochureForm.country"
          />
        )}
      </AnimateText>
      <AnimateText delay={700}>
        {privacyPolicyBlock[0].text && (
          <Disclaimer>
            <RichText>{privacyPolicyBlock[0].text}</RichText>
          </Disclaimer>
        )}
      </AnimateText>
      <AnimateText delay={760}>
        <RecaptchaLoader />
        <RecaptchaDisclaimer />
        <RecaptchaContainer id={recaptchaElementId} />
      </AnimateText>
      <AnimateText delay={820}>
        <FormFieldConditionsCheckbox
          label={
            <RichText nodeResolvers={{ [NODE_PARAGRAPH]: dummyResolver }}>
              {privacyPolicyBlock[0].label}
            </RichText>
          }
          error={errors.acceptPrivacyPolicy}
          {...register('acceptPrivacyPolicy', {
            required: privacyPolicyBlock[0].errorText,
          })}
          required
          data-testid="contactForm.acceptPrivacyPolicy"
        />
      </AnimateText>

      <AnimateText delay={880}>
        <Submit
          type="submit"
          data-testid="contactForm.submit"
          disabled={isSubmitting || isLoading}
          variant="secondary"
        >
          {submitBlock[0].title}
        </Submit>
      </AnimateText>
    </Form>
  )
}

export default ServiceRequestFormBlok
