import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ComponentProps,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'

import arraysEqual from '../utils/arraysEqual'
import notEmpty from '../utils/notEmpty'
import { makeVar, useReactiveVar } from '../utils/reactiveVar'
import useViewportSize from '../utils/useViewportSize'
import Link from './Link'

const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant',
})<{ variant: 'light' | 'dark' }>(({ theme, variant }) => [
  css`
    color: #fff;
    font-size: 12px;
    letter-spacing: 3.43px;
    line-height: 1.7;
    font-weight: 500;
  `,
  variant === 'dark' &&
    css`
      color: ${theme.colors.amels.clearBayAqua};
    `,
])
const ScrollBar = styled.div(
  ({ theme }) => css`
    position: absolute;
    // The 0.1em offset is to compensate for the line-height
    top: calc(0.7em - 0.1em);
    left: ${theme.spacing.x6}px;
    width: 3px;
    height: calc(100% - 1.4em);

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: currentColor;
      opacity: 0.5;
    }
  `,
)
const ScrollPosition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: currentColor;
`
const ListContainer = styled.div(
  ({ theme }) => css`
    margin-left: ${theme.spacing.x8}px;
    overflow: hidden;
  `,
)
const List = styled('menu', {
  shouldForwardProp: (prop) => prop !== 'expanded',
})<{ expanded?: boolean }>(({ expanded }) => {
  const expandedCss = css`
    opacity: 1;
    transform: translateX(0px);
    width: 100%;
    animation: none;
    // Remove the transition delay from width so it appears immediately
    // The delay serves to delay the disappearing width, which is not used for
    // the animation but only for the clickable area size.
    transition: transform 400ms ease-in-out, opacity 400ms ease-in-out,
      width 0ms ease-in-out;
  `

  return [
    css`
      list-style: none;
      padding: 0;
      white-space: nowrap;
      opacity: 0;
      will-change: opacity;
      overflow: hidden;
      width: 0;
      transform: translateX(-100%);
      transition: transform 400ms ease-in-out, opacity 400ms ease-in-out,
        width 0ms ease-in-out 400ms;

      ${Container}:hover & {
        ${expandedCss}
      }
    `,
    expanded && expandedCss,
  ]
})
const Item = styled('li', {
  shouldForwardProp: (prop) => prop !== 'active',
})<{ active?: boolean }>(({ theme, active }) => [
  css`
    text-transform: uppercase;
    opacity: 0.2;
    transition: opacity 400ms ease-out;

    &:not(:first-of-type) {
      margin-top: ${theme.spacing.x6}px;
    }
  `,
  active &&
    css`
      opacity: 1;
    `,
])
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

interface Section {
  id: string
  label: string
}

const isVisible = (elem: HTMLElement, windowHeight: number) =>
  elem.getBoundingClientRect().top < windowHeight &&
  elem.getBoundingClientRect().bottom >= 1
const getDistanceFromViewportCenter = (
  elem: HTMLElement,
  windowHeight: number,
) => {
  const middleWindow = window.scrollY + windowHeight / 2
  const centerPoint =
    elem.getBoundingClientRect().top +
    window.scrollY +
    elem.getBoundingClientRect().height / 2
  const distance = middleWindow - centerPoint

  return distance
}
const getMostCenteredElem = (elems: HTMLElement[], windowHeight: number) => {
  return elems.reduce<HTMLElement>((match, elem) => {
    const distance = getDistanceFromViewportCenter(elem, windowHeight)

    if (
      Math.abs(getDistanceFromViewportCenter(match, windowHeight)) >
      Math.abs(distance)
    ) {
      return elem
    }
    return match
  }, elems[0])
}

const useActivePatterns = (patterns: Section[], windowHeight: number) => {
  const [activePatterns, setActivePatterns] = useState<Section[]>([])
  const updateActivePatterns = useCallback(() => {
    const activePatterns = patterns.filter(({ id }) => {
      const elem = document.getElementById(id)
      if (!elem) {
        return false
      }

      return isVisible(elem, windowHeight)
    })

    setActivePatterns((oldActivePatterns) => {
      if (arraysEqual(oldActivePatterns, activePatterns)) {
        // Keep old reference when same so this doesn't trigger unnecessary renders
        return oldActivePatterns
      }

      return activePatterns
    })
  }, [patterns, windowHeight])
  useLayoutEffect(updateActivePatterns, [updateActivePatterns])
  useEffect(() => {
    document.addEventListener('scroll', updateActivePatterns, {
      passive: true,
    })
    return () => document.removeEventListener('scroll', updateActivePatterns)
  }, [updateActivePatterns])

  return activePatterns
}
const useScrollIndicatorHeight = (
  patterns: Section[],
  windowHeight: number,
) => {
  const [scrollIndicatorHeight, setScrollIndicatorHeight] = useState(0)
  useLayoutEffect(() => {
    const sectionElems = patterns
      .map(({ id }) => document.getElementById(id))
      .filter(notEmpty)
    if (sectionElems.length === 0) {
      return
    }

    // The viewport height is the height of the visible content relative to the
    // scrollable area of the SectionIndicator.
    const firstSection = sectionElems[0]
    const lastSection = sectionElems[sectionElems.length - 1]
    const scrollableArea =
      lastSection.getBoundingClientRect().bottom -
      firstSection.getBoundingClientRect().top
    const scrollIndicatorHeight = windowHeight / scrollableArea
    setScrollIndicatorHeight(scrollIndicatorHeight)
  }, [patterns, windowHeight])

  return scrollIndicatorHeight
}
const useScrollPos = (
  patterns: Section[],
  windowHeight: number,
  scrollIndicatorHeight: number,
) => {
  const [scrollPos, setScrollPos] = useState(0)
  const updateScrollPos = useCallback(() => {
    const sectionElems = patterns
      .map(({ id }) => document.getElementById(id))
      .filter(notEmpty)
    if (sectionElems.length === 0 || !windowHeight) {
      return
    }

    const itemPercentage = 1 / (sectionElems.length - 1)

    const mostCenteredElem = getMostCenteredElem(sectionElems, windowHeight)
    const previousElem =
      sectionElems[sectionElems.indexOf(mostCenteredElem) - 1]
    const nextElem = sectionElems[sectionElems.indexOf(mostCenteredElem) + 1]

    const elemCenter =
      window.scrollY +
      mostCenteredElem.getBoundingClientRect().top +
      mostCenteredElem.getBoundingClientRect().height / 2
    const viewportCenter = window.scrollY + windowHeight / 2
    const isElemAboveCenter = elemCenter > viewportCenter

    let scrollPos =
      // The first item is at the top of the line, so to center the scroll pos
      // we need to place it 50% higher
      -scrollIndicatorHeight / 2 +
      sectionElems.indexOf(mostCenteredElem) * itemPercentage
    const max =
      (sectionElems.length - 1) * itemPercentage - scrollIndicatorHeight

    if (isElemAboveCenter) {
      const offset =
        mostCenteredElem.getBoundingClientRect().top /
        Math.min(
          windowHeight,
          // Use the shared height of both connected elements to make the switching to the next most centered elem smooth
          previousElem
            ? mostCenteredElem.getBoundingClientRect().height / 2 +
                previousElem.getBoundingClientRect().height / 2
            : mostCenteredElem.getBoundingClientRect().height,
        )
      const top = Math.max(0, offset)
      scrollPos -= top * itemPercentage
    } else {
      const offset =
        mostCenteredElem.getBoundingClientRect().bottom /
        Math.min(
          windowHeight,
          // Use the shared height of both connected elements to make the switching to the next most centered elem smooth
          nextElem
            ? mostCenteredElem.getBoundingClientRect().height / 2 +
                nextElem.getBoundingClientRect().height / 2
            : mostCenteredElem.getBoundingClientRect().height,
        )
      const bottom = Math.max(0, 1 - offset)
      scrollPos += bottom * itemPercentage
    }

    setScrollPos(Math.max(0, Math.min(max, scrollPos)))
  }, [scrollIndicatorHeight, windowHeight, patterns])

  // Position on render and resize
  useLayoutEffect(updateScrollPos, [updateScrollPos])
  // Position on scroll
  useEffect(() => {
    let pendingFrame: number | undefined
    const handleScroll = () => {
      if (pendingFrame) {
        return
      }

      pendingFrame = requestAnimationFrame(() => {
        updateScrollPos()
        pendingFrame = undefined
      })
    }

    document.addEventListener('scroll', handleScroll, {
      passive: true,
    })
    return () => document.removeEventListener('scroll', handleScroll)
  }, [updateScrollPos])

  return scrollPos
}

export const showSectionIndicatorVar = makeVar(true)

interface Props extends Omit<ComponentProps<typeof Container>, 'children'> {
  patterns: Section[]
  variant: 'dark' | 'light'
  expanded?: boolean
}

const SectionIndicator = ({
  patterns,
  variant,
  expanded,
  ...others
}: Props) => {
  const viewportSize = useViewportSize()
  const scrollIndicatorHeight = useScrollIndicatorHeight(
    patterns,
    viewportSize?.height || 800,
  )
  const scrollPos = useScrollPos(
    patterns,
    viewportSize?.height || 800,
    scrollIndicatorHeight,
  )

  const activePatterns = useActivePatterns(patterns, viewportSize?.height || 0)

  const showSectionIndicator = useReactiveVar(showSectionIndicatorVar)
  if (!showSectionIndicator) {
    return null
  }

  return (
    <Container variant={variant} {...others}>
      <ScrollBar>
        <ScrollPosition
          style={{
            height: `${Math.round(scrollIndicatorHeight * 100)}%`,
            // Transform doesn't work since it needs to be relative to the top elem, not itself
            top: `${Math.round(scrollPos * 100)}%`,
          }}
        />
      </ScrollBar>

      <ListContainer>
        <List expanded={expanded}>
          {patterns.map((section) => {
            const { id, label } = section

            return (
              <Item key={id} active={activePatterns.includes(section)}>
                <StyledLink href={`#${id}`}>{label}</StyledLink>
              </Item>
            )
          })}
        </List>
      </ListContainer>
    </Container>
  )
}

export default SectionIndicator
