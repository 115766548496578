import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes } from 'react'
import { useInView } from 'react-intersection-observer'
import Section from '../components/Section'
import Image from '../components/Image'
import { hiddenClassName } from '../components/AnimateText'
import { isSiteYachting } from '../themes'
import Link from '../components/Link'

const Container = styled(Section)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x6}px ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x10}px;
      }
    `,
)

const Logos = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 1086px;
      margin: 0 auto;
      gap: ${theme.spacing.x4}px;
      padding: 0 ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-direction: row;
        gap: ${theme.spacing.x9}px;
      }
    `,
)

const LogoWrapper = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 33.33%;
      }
    `,
)

const StyledImage = styled(Image)(
  ({ theme }) => css`
    margin: 0 auto;
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 180px;
    }

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 240px;
    }
  `,
)

const LogoLinkWrapper = styled(Link)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        ${StyledImage} {
          filter: grayscale(0%);
        }
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: 33.33%;
      }
    `,
)

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'children'> {
  logos?: Array<{
    key: string
    src: string
    alt?: string
  }>
  items: Array<{
    key: string
    src: string
    alt?: string
    link?: {
      href?: string
      title: string
      target?: string
    }
  }>
}

const LogoBar = ({ logos, items, ...others }: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '0px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      sectionIndicatorVariant="dark"
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      {...others}
    >
      <Logos>
        {items?.map(({ key, src, alt, link }) =>
          link?.href ? (
            <LogoLinkWrapper
              title={link.title}
              href={link.href}
              target={link.target}
              key={key}
            >
              <StyledImage
                src={src}
                alt={alt ?? ''}
                width={240}
                height={84}
                style={{ objectFit: 'contain' }}
              />
            </LogoLinkWrapper>
          ) : (
            <LogoWrapper key={key}>
              <StyledImage
                src={src}
                alt={alt ?? ''}
                width={240}
                height={84}
                style={{ objectFit: 'contain' }}
                // logo's should be silver
                transformations={['e_colorize,co_rgb:a6a7a8']}
              />
            </LogoWrapper>
          ),
        )}
        {items?.length <= 0 &&
          logos?.map(({ key, src, alt }) => (
            <LogoWrapper key={key}>
              <StyledImage
                src={src}
                alt={alt ?? ''}
                width={240}
                height={84}
                style={{ objectFit: 'contain' }}
                // logo's should be silver
                transformations={['e_colorize,co_rgb:a6a7a8']}
              />
            </LogoWrapper>
          ))}
      </Logos>
    </Container>
  )
}

export default LogoBar
