import SlickCarousel from '@ant-design/react-slick'
import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import {
  Children,
  ComponentProps,
  forwardRef,
  Fragment,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react'
import 'slick-carousel/slick/slick.css'

import { CSSTransition, TransitionGroup } from 'react-transition-group'
import CursorOverlay from './CursorOverlay'
import ChevronRight from '../icons/ChevronRight.svg'
import ChevronLeftCircled from '../icons/ChevronLeftCircled.svg'
import YachtingChevronRightCircled from '../icons/yachting/ChevronRightCircled.svg'
import XplorerChevronRightCircled from '../icons/xplorer/XplorerChevronRightCircled.svg'
import XplorerChevronRight from '../icons/xplorer/ChevronRight.svg'
import YachtSupportChevronLeft from '../icons/yachtsupport/ChevronLeft.svg'
import YachtSupportChevronRight from '../icons/yachtsupport/ChevronRight.svg'
import useIsClientSideRender from '../utils/useIsClientSideRender'
import CarouselPager from './CarouselPager'
import { hiddenClassName } from './AnimateText'
import Button, { invisibleButtonCss } from './Button'
import { currentSite, Site } from '../sites'
import { isSiteYachtSupport } from '../themes'

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  // Fixes a bug in react-slick where sometimes the last slide is inaccessible
  .slick-track {
    display: flex;
  }
`
const OverlayPager = styled(CarouselPager)(({ theme }) => [
  css`
    position: absolute;
    top: ${theme.spacing.x4}px;
    left: ${theme.spacing.x4}px;
    z-index: 1;
    width: calc(
      100% - ${theme.spacing.x4}px * 2 - var(--pager-margin-right, 0px)
    );

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      top: 7.5%;
      width: 70%;
      left: 8.6%;
    }

    @media (prefers-reduced-motion: no-preference) {
      will-change: opacity;
      :not(.${hiddenClassName} &) {
        opacity: 0;
        animation: ${keyframes`
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      `} 400ms ease-in-out forwards;
      }
    }
  `,
])
const BelowPager = styled(OverlayPager)(
  ({ theme }) => css`
    position: relative;
    top: auto;
    left: auto;
    color: ${theme.colors.amels.beach};
    width: 100%;
    margin-top: ${theme.spacing.x3}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 100%;
      left: auto;
    }
  `,
)
const PreviousPane = styled(CursorOverlay)`
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;

  @media (hover: none) {
    display: none;
  }
`
const NextPane = styled(CursorOverlay)`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;

  @media (hover: none) {
    display: none;
  }
`
const transitionDuration = 800
const transitionName = 'fade'
const PreviousButton = styled(Button)([
  invisibleButtonCss,
  ({ theme }) => css`
    position: absolute;
    left: ${theme.spacing.x3}px;
    top: 50%;
    transform: translateY(-50%);
    width: calc(32px + ${theme.spacing.x1}px * 2);
    height: calc(32px + ${theme.spacing.x1}px * 2);
    padding: ${theme.spacing.x1}px;
    margin-left: -${theme.spacing.x1}px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.${transitionName}-enter {
      opacity: 0;
      will-change: opacity;
    }
    &.${transitionName}-enter-active {
      opacity: 1;
      transition: opacity ${transitionDuration}ms ease-in-out;
    }
    &.${transitionName}-exit {
      opacity: 1;
      will-change: opacity;
    }
    &.${transitionName}-exit-active {
      opacity: 0;
      transition: opacity ${transitionDuration}ms ease-in-out;
    }

    ${!isSiteYachtSupport(theme) &&
    css`
      display: none;
    `}

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: flex;
    }

    ${isSiteYachtSupport(theme) &&
    css`
      :hover {
        --foreground: ${theme.colors.yachtSupport.primaryYSCarbonBlack};
        --background: ${theme.colors.yachtSupport.white};
      }

      @media screen and (max-width: ${theme.breakpoints.tablet}px) {
        width: ${theme.spacing.x4}px;
        height: ${theme.spacing.x4}px;
        padding: 5px;
      }
    `}
  `,
])
const NextButton = styled(PreviousButton)(
  ({ theme }) => css`
    left: auto;
    right: ${theme.spacing.x3}px;
    margin-left: 0;
    margin-right: -${theme.spacing.x1}px;
  `,
)
const ChevronLeft = styled(ChevronRight)`
  transform: rotate(180deg);
`
const ChevronRightCircled = styled(ChevronLeftCircled)`
  transform: rotate(180deg);
`
const YachtingChevronLeftCircled = styled(YachtingChevronRightCircled)`
  transform: rotate(180deg);
`

const XplorerChevronLeft = styled(XplorerChevronRight)`
  transform: rotate(180deg);
`

const XplorerChevronLeftCircled = styled(XplorerChevronRightCircled)`
  transform: rotate(180deg);
`

const NextButtonIcon = () => {
  switch (currentSite) {
    case Site.Yachting:
      return <YachtingChevronRightCircled aria-label="Next" />
    case Site.Xplorer:
      return <XplorerChevronRightCircled aria-label="Next" />
    case Site.YachtSupport:
      return <YachtSupportChevronRight aria-label="Next" />
    case Site.Amels:
    default:
      return <ChevronRightCircled aria-label="Next" />
  }
}

const PrevButtonIcon = () => {
  switch (currentSite) {
    case Site.Yachting:
      return <YachtingChevronLeftCircled aria-label="Previous" />
    case Site.Xplorer:
      return <XplorerChevronLeftCircled aria-label="Previous" />
    case Site.YachtSupport:
      return <YachtSupportChevronLeft aria-label="Previous" />
    case Site.Amels:
    default:
      return <ChevronLeftCircled aria-label="Previous" />
  }
}

const getPreviousPaneIcon = () => {
  switch (currentSite) {
    case Site.Yachting:
      return YachtingChevronLeftCircled
    case Site.Xplorer:
      return XplorerChevronLeft
    case Site.Amels:
    default:
      return ChevronLeft
  }
}

const getNextPaneIcon = () => {
  switch (currentSite) {
    case Site.Yachting:
      return YachtingChevronRightCircled
    case Site.Xplorer:
      return XplorerChevronRight
    case Site.Amels:
    default:
      return ChevronRight
  }
}

const getPaneIconHeight = () => {
  switch (currentSite) {
    case Site.Yachting:
      return 40
    case Site.Xplorer:
      return 24
    case Site.Amels:
    default:
      return 14
  }
}

export const defaultSpeed = 1000

interface Props extends ComponentProps<typeof SlickCarousel> {
  children: ReactElement | ReactElement[]
  slidesPerItem?: number
  'data-testid'?: string
  'aria-hidden'?: boolean
  id?: string
  /**
   * Whether the carousel is currently visible. Defaults to true. This should
   * be used whenever the carousel may be hidden to properly toggle the cursor
   * overlay.
   */
  isVisible?: boolean
  navigationVariant?: 'overlay' | 'static'
  pagerVariant?: 'overlay' | 'below'
  extra?: ReactNode
}

// eslint-disable-next-line react/display-name
const Carousel = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      initialSlide,
      slidesPerItem = 1,
      'data-testid': testid = 'carousel',
      'aria-hidden': ariaHidden,
      id,
      speed = defaultSpeed,
      isVisible: active = true,
      navigationVariant = 'overlay',
      pagerVariant = 'overlay',
      extra,
      ...others
    }: Props,
    ref,
  ) => {
    const isClientSideRender = useIsClientSideRender()
    const carouselRef = useRef<SlickCarousel>(null)
    const numSlides = Children.count(children)
    const [incomingSlide, setIncomingSlide] = useState(initialSlide || 0)
    const [activeSlide, setActiveSlide] = useState(initialSlide || 0)

    const previousPaneIcon = getPreviousPaneIcon()
    const nextPaneIcon = getNextPaneIcon()
    const paneIconHeight = getPaneIconHeight()

    return (
      <>
        <Container
          data-testid={testid}
          aria-hidden={ariaHidden}
          id={id}
          ref={ref}
        >
          {pagerVariant === 'overlay' && Children.count(children) > 1 && (
            <OverlayPager
              numSlides={numSlides}
              slidesPerItem={slidesPerItem}
              activeSlide={incomingSlide}
              onClickPage={(slide) => carouselRef.current?.slickGoTo(slide)}
              speed={speed}
            />
          )}

          {/* eslint-disable @typescript-eslint/ban-ts-comment */}
          {/** @ts-ignore @ant-design/react-slick types haven't been updated to support react18 yet */}
          <SlickCarousel
            infinite={false}
            arrows={false}
            variableWidth
            initialSlide={initialSlide}
            beforeChange={(oldSlide: number, newSlide: number) => {
              setIncomingSlide(newSlide)
              // We can't use afterChange because it's bugged, so we track it manually
              // See https://github.com/akiran/react-slick/issues/1262
              setTimeout(() => {
                setActiveSlide(newSlide)
              }, speed)
            }}
            speed={speed}
            ref={carouselRef}
            {...others}
          >
            {Children.map(children, (child, index) => (
              <div
                className={activeSlide !== index ? hiddenClassName : undefined}
              >
                {child}
              </div>
            ))}
          </SlickCarousel>

          {isClientSideRender && navigationVariant === 'overlay' && (
            <Fragment key={String(active)}>
              {incomingSlide !== 0 && (
                <PreviousPane
                  icon={previousPaneIcon}
                  iconWidth="auto"
                  iconHeight={paneIconHeight}
                  onClick={() => carouselRef.current?.slickPrev()}
                  data-testid={`${testid}.previousPane`}
                />
              )}
              {incomingSlide !== numSlides - 1 && (
                <NextPane
                  icon={nextPaneIcon}
                  iconWidth="auto"
                  iconHeight={paneIconHeight}
                  onClick={() => carouselRef.current?.slickNext()}
                  data-testid={`${testid}.nextPane`}
                />
              )}
            </Fragment>
          )}
          {navigationVariant === 'static' && Children.count(children) > 1 && (
            <TransitionGroup component={null}>
              {incomingSlide !== 0 && (
                <CSSTransition
                  classNames={transitionName}
                  timeout={transitionDuration}
                >
                  <PreviousButton
                    onClick={() => carouselRef.current?.slickPrev()}
                  >
                    <PrevButtonIcon />
                  </PreviousButton>
                </CSSTransition>
              )}
              {incomingSlide !== numSlides - 1 && (
                <CSSTransition
                  classNames={transitionName}
                  timeout={transitionDuration}
                >
                  <NextButton onClick={() => carouselRef.current?.slickNext()}>
                    <NextButtonIcon />
                  </NextButton>
                </CSSTransition>
              )}
            </TransitionGroup>
          )}

          {extra}
        </Container>

        {pagerVariant === 'below' && Children.count(children) > 1 && (
          <BelowPager
            numSlides={numSlides}
            slidesPerItem={slidesPerItem}
            activeSlide={incomingSlide}
            onClickPage={(slide) => carouselRef.current?.slickGoTo(slide)}
            speed={speed}
          />
        )}
      </>
    )
  },
)

export default Carousel
