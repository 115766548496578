import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { HTMLAttributes, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import Section from '../components/Section'
import Image from '../components/Image'
import YachtingCarousel from '../components/YachtingCarousel'
import { hiddenClassName } from '../components/AnimateText'
import { isSiteYachting } from '../themes'
import LinkButton from '../components/LinkButton'
import Link from '../components/Link'
import * as breakpoints from '../themes/breakpoints'
import { mediumButtonCssYachting } from '../components/Button'

const Container = styled(Section)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.silver10};
      padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
    `,
)
const ContentContainer = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.x5}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-direction: row;
    }
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.layout.maxContentWidth(theme)};
    `,
])
const InfoContainer = styled.div(
  ({ theme }) =>
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: 30%;
      }
    `,
)
const StyledLinkButton = styled(LinkButton)(
  ({ theme }) =>
    css`
      margin-top: ${theme.spacing.x5}px;
      ${mediumButtonCssYachting(theme)};
    `,
)
const CarouselContainer = styled.div(
  ({ theme }) =>
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: 70%;
      }
    `,
)
const SlideContainer = styled.div(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.x2}px 0 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: 0 ${theme.spacing.x2}px;
    }
  `,
)
const Slide = styled(Link)(
  () => css`
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    img {
      transition: transform ease-out 3000ms;
    }

    :hover {
      text-decoration: none;

      img {
        transform: scale(1.2);
      }
    }
  `,
)
const Overline = styled.div(({ theme }) => [
  css`
    margin-bottom: ${theme.spacing.x1}px;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin-bottom: ${theme.spacing.x2}px;
    }
  `,
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)};
    `,
])
const Title = styled.div(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin: 0 0 ${theme.spacing.x2}px 0;
  `,
)
const Text = styled.div``

const SlideImageContainer = styled.div(
  () => css`
    > div {
      display: flex;
      justify-content: center;
      overflow: hidden;
      aspect-ratio: 3/2;
    }

    img {
      width: 100%;
      height: auto;
    }
  `,
)
const SlideInfoContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.x3}px 0;
    gap: ${theme.spacing.x1}px;
  `,
)
const SlideTitle = styled.div(
  ({ theme }) => css`
    ${theme.text.heading4(theme)}
  `,
)
const SlideDescription = styled.div(
  ({ theme }) => css`
    color: ${theme.colors.yachting.black};
    font-weight: 300;
    font-size: 20px;
  `,
)

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'children'> {
  overline?: string
  title: string
  text?: ReactNode
  link?: {
    title: string
    href: string
    target?: string
  }
  products: Array<{
    key: string
    title: string
    description?: string
    link: {
      href: string
      target?: string
    }
    image: {
      src: string
      alt?: string
    }
  }>
}

const ProductCarousel = ({
  overline,
  title,
  text,
  link,
  products,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })
  return (
    <Container sectionIndicatorVariant="dark" {...others}>
      <ContentContainer>
        <InfoContainer>
          <Overline>{overline}</Overline>
          <Title>{title}</Title>
          <Text>{text}</Text>
          {link && (
            <StyledLinkButton
              variant="primary"
              href={link.href}
              target={link.target}
            >
              {link.title}
            </StyledLinkButton>
          )}
        </InfoContainer>
        <CarouselContainer>
          <YachtingCarousel
            arrowsPosition="bottom-right"
            className={!inView ? hiddenClassName : undefined}
            data-testid="productCarousel"
            ref={ref}
            slidesToShow={3}
            slidesToScroll={1}
            variableWidth={false}
            waitForAnimate={false}
            draggable
            customArrows={false}
            customDots
            customDotsPosition="product"
            responsive={[
              {
                breakpoint: breakpoints.tablet,
                settings: {
                  slidesToShow: 1.1,
                },
              },
              {
                breakpoint: breakpoints.desktop,
                settings: {
                  slidesToShow: 2.1,
                },
              },
            ]}
          >
            {products.map(({ key, image, title, description, link }) => (
              <SlideContainer key={key}>
                <Slide href={link.href} target={link.target}>
                  <SlideImageContainer>
                    <div>
                      <Image
                        src={image.src}
                        alt={image.alt}
                        width={460}
                        height={460}
                        style={{ objectFit: 'cover' }}
                      />
                    </div>
                  </SlideImageContainer>
                  <SlideInfoContainer>
                    <SlideTitle>{title}</SlideTitle>
                    <SlideDescription>{description}</SlideDescription>
                  </SlideInfoContainer>
                </Slide>
              </SlideContainer>
            ))}
          </YachtingCarousel>
        </CarouselContainer>
      </ContentContainer>
    </Container>
  )
}

export default ProductCarousel
