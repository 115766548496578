import ImageWithTextOverlay from '../../patterns/ImageWithTextOverlay'
import RichText from '../../components/RichText'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { ImageWithTextOverlayStoryblok } from '../storyblok.generated'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: ImageWithTextOverlayStoryblok
}

const ImageWithTextOverlayBlok = ({
  content: { sectionTitle, image, mobileImage, title, text },
  ...others
}: Props) => (
  <ImageWithTextOverlay
    id={slugify(sectionTitle)}
    image={mapImage(image) ?? getRequiredImageFallback()}
    mobileImage={mapImage(mobileImage)}
    title={reactStringReplace(title, '\n', <br />)}
    text={<RichText>{text}</RichText>}
    {...others}
  />
)

export default ImageWithTextOverlayBlok
