import {
  ContactCallToActionWithImageStoryblok,
  FormStoryblok,
} from '../storyblok.generated'
import ContactCallToActionWithImage from '../../patterns/ContactCallToActionWithImage'
import { usePageContext } from '../PageContext'
import { StoryblokStory } from '../../utils/storyblok'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import FormBlok from '../FormBlok'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: ContactCallToActionWithImageStoryblok
}

const ContactCallToActionWithImageBlok = ({
  content: { asideImage, contactBlockType, contactFormReference, triggerForm },
  ...others
}: Props) => {
  const { rels } = usePageContext()
  const inlineForm = rels.find(
    (rel) => rel.uuid === contactFormReference,
  ) as StoryblokStory<FormStoryblok>

  const triggerFormContent = triggerForm?.[0]
  return (
    <ContactCallToActionWithImage
      asideImage={mapImage(asideImage) ?? getRequiredImageFallback()}
      contactBlockType={contactBlockType}
      contactForm={
        inlineForm !== undefined ? (
          <FormBlok form={inlineForm} colorScheme="dark" />
        ) : null
      }
      triggerFormContent={
        triggerFormContent !== undefined
          ? {
              title: triggerFormContent.title,
              overline: triggerFormContent.overline,
              description: triggerFormContent.description,
              image: mapImage(triggerFormContent.image),
              linkWithTitle: triggerFormContent.linkWithTitle?.[0]
                ? {
                    title: triggerFormContent.linkWithTitle?.[0].title,
                    href: storyblokLinkResolver(
                      triggerFormContent.linkWithTitle?.[0].link,
                    ),
                    target: triggerFormContent.linkWithTitle?.[0].linkTarget,
                  }
                : undefined,
              triggerModalForm: triggerFormContent.triggerModalForm.map(
                (button) => {
                  const form = rels.find(
                    (rel) => rel.uuid === button.formReference,
                  ) as StoryblokStory<FormStoryblok>
                  return {
                    key: button._uid,
                    title: button.title,
                    form: <FormBlok form={form} />,
                  }
                },
              )[0],
            }
          : null
      }
      {...others}
    />
  )
}

export default ContactCallToActionWithImageBlok
