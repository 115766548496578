import { ReactElement } from 'react'
import { StoryblokStory } from '../utils/storyblok'
import ContactFormBlok from './ContactFormBlok'
import {
  FormContactStoryblok,
  FormDownloadStoryblok,
  FormNewsletterStoryblok,
  FormServiceRequestStoryblok,
  FormNewsletterWomenInYachtingStoryblok,
  FormStoryblok,
} from './storyblok.generated'
import NewsletterFormBlok from './NewsletterFormBlok'
import ServiceRequestFormBlok from './ServiceRequestFormBlok'
import RequestBrochureFormBlok from './RequestBrochureFormBlok'
import NewsletterFormWiYBlok from './NewsletterFormWiYBlok'

interface Props {
  form: StoryblokStory<FormStoryblok>
  colorScheme?: 'light' | 'dark'
}

// We don't need switch-eslint types since TypeScript already requires the
// switch to be exhaustive.
// eslint-disable-next-line consistent-return
const FormBlok = ({ form, colorScheme }: Props): ReactElement => {
  // eslint-disable-next-line default-case
  switch (form.content.formBlock[0].component) {
    case 'formContact':
      return (
        <ContactFormBlok
          colorScheme={colorScheme}
          form={form.content.formBlock[0] as FormContactStoryblok}
        />
      )
    case 'formDownload':
      return (
        <RequestBrochureFormBlok
          form={form.content.formBlock[0] as FormDownloadStoryblok}
        />
      )
    case 'formNewsletter':
      return (
        <NewsletterFormBlok
          form={form.content.formBlock[0] as FormNewsletterStoryblok}
        />
      )
    case 'formServiceRequest':
      return (
        <ServiceRequestFormBlok
          form={form.content.formBlock[0] as FormServiceRequestStoryblok}
        />
      )
    case 'formNewsletterWomenInYachting':
      return (
        <NewsletterFormWiYBlok
          form={
            form.content.formBlock[0] as FormNewsletterWomenInYachtingStoryblok
          }
        />
      )
  }
}

export default FormBlok
