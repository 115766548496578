import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import PlainSpecificationsDefault from './PlainSpecifications'
import PlainSpecificationsXplorer from './PlainSpecifications.xplorer'
import PlainSpecificationsYachtSupport from './PlainSpecifications.yachtsupport'
import Section from '../../components/Section'

export interface PlainSpecificationsProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant'
  > {
  title?: string
  subTitle?: string
  groups: Array<{
    key: string
    title: ReactNode
    values?: Array<{
      key: string
      label: ReactNode
      value: ReactNode
    }>
    text?: ReactNode
    halved?: boolean
  }>
}

const PlainSpecifications = (props: PlainSpecificationsProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <PlainSpecificationsXplorer {...props} />
    case Site.YachtSupport:
      return <PlainSpecificationsYachtSupport {...props} />
    default:
      return <PlainSpecificationsDefault {...props} />
  }
}

export default PlainSpecifications
