import { ComponentProps } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { isSiteYachting } from '../themes'
import Image from './Image'
import Link from './Link'
import AnimateText from './AnimateText'
import ContactIcon from './ContactIcon'
import { ContactContactOptionStoryblok } from '../bloks/storyblok.generated'

const StyledContactCard = styled.li(({ theme }) => [
  css`
    display: flex;
    gap: ${theme.spacing.x3}px;
    flex-direction: column;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      gap: ${theme.spacing.x4}px;
    }
  `,
])
const PhotoContainer = styled.div(
  () => css`
    display: flex;
    justify-content: center;
  `,
)
const Photo = styled(AnimateText)(
  () => css`
    display: flex;
    position: relative;
    overflow: hidden;
  `,
)
const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    color: ${theme.colors.yachting.darkBlue};
    text-align: center;
  `,
)
const Name = styled.h4(
  ({ theme }) => css`
    ${theme.text.heading4(theme)}
    margin: 0 0 ${theme.spacing.x2}px 0;
  `,
)
const JobTitle = styled.div(({ theme }) => [
  isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)};
    `,
  css`
    color: ${theme.colors.yachting.darkBlue};
    margin: 0 0 ${theme.spacing.x3}px 0;
  `,
])
const ContactIcons = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `,
)
const StyledContactLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'type',
})<{ type: ComponentProps<typeof ContactIcon>['icon'] }>(({ theme, type }) => [
  css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin-right: 12px;
    color: ${theme.colors.yachting.silver};
    border: solid 1px ${theme.colors.yachting.silver};
    border-radius: 50%;
    transition: all 200ms;
    overflow: hidden;

    > svg {
      width: 20px;
    }

    :hover {
      color: ${theme.colors.yachting.white};
      background-color: ${theme.colors.yachting.darkBlue};
      border-color: ${theme.colors.yachting.darkBlue};
    }
  `,
  type === 'linkedin' &&
    css`
      > svg {
        width: 22px;
      }
    `,
])

export interface ContactCardProps extends ComponentProps<'li'> {
  animationDelay?: number
  contact: {
    key: string
    name: string
    photo: {
      src: string
      alt?: string
    }
    jobTitle?: string
    contactOptions?: Array<{
      key: string
      type: ContactContactOptionStoryblok['type']
      link?: {
        href: string
      }
      value?: string
    }>
  }
}

const ContactCard = ({
  contact,
  animationDelay,
  ...others
}: ContactCardProps) => {
  const animateDelay = animationDelay ?? 0
  return (
    <StyledContactCard {...others}>
      <PhotoContainer>
        <Photo delay={animateDelay}>
          <div>
            <Image
              src={contact.photo.src}
              alt={contact.photo.alt}
              width={208}
              height={208}
              aspectRatio="1:1"
              priority
            />
          </div>
        </Photo>
      </PhotoContainer>
      <Content>
        <AnimateText delay={animateDelay + 100}>
          <Name>{contact.name}</Name>
        </AnimateText>
        {contact.jobTitle && (
          <AnimateText delay={animateDelay + 200}>
            <JobTitle>{contact.jobTitle}</JobTitle>
          </AnimateText>
        )}
        <AnimateText delay={animateDelay + 300}>
          <ContactIcons>
            {contact.contactOptions?.map(({ key, type, link }) =>
              type && link?.href ? (
                <StyledContactLink key={key} href={link.href} type={type}>
                  <ContactIcon icon={type} aria-label={type} />
                </StyledContactLink>
              ) : null,
            )}
          </ContactIcons>
        </AnimateText>
      </Content>
    </StyledContactCard>
  )
}

export default ContactCard
