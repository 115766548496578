import Text from '../../patterns/Text'
import RichText from '../../components/RichText'
import slugify from '../../utils/slugify'
import { TextStoryblok } from '../storyblok.generated'

interface Props {
  content: TextStoryblok
}

const TextBlok = ({
  content: { sectionTitle, text, align, layout, width, background },
  ...others
}: Props) => (
  <Text
    id={slugify(sectionTitle)}
    align={align}
    width={width}
    layout={layout}
    background={background}
    {...others}
  >
    <RichText>{text}</RichText>
  </Text>
)

export default TextBlok
