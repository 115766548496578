import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { SVGProps } from 'react'
import Icon from '../icons/yachting/Plus.svg'

const PlusIcon = styled(Icon, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})<{ isExpanded: boolean }>(({ theme, isExpanded }) => [
  css`
    width: 20px;
    height: 20px;
    color: ${theme.colors.yachting.orange};
    transition: transform 400ms;

    rect {
      transition: opacity 200ms;
    }
  `,
  isExpanded &&
    css`
      transform: rotate(90deg);

      rect.horizontal {
        opacity: 0;
      }
    `,
])

interface Props extends SVGProps<SVGElement> {
  isExpanded: boolean
}

const ExpandIcon = (props: Props) => <PlusIcon {...props} />

export default ExpandIcon
