import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'

import AnimateText from '../components/AnimateText'
import ScrollDownIndicator from '../components/ScrollDownIndicator'
import Image from '../components/Image'

const Header = styled.header(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(100vh - ${theme.spacing.x8}px);
    color: ${theme.colors.amels.body};
    padding: 240px ${theme.spacing.x4}px ${theme.spacing.x4}px;
    position: relative; // needed by the image

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      align-items: center;
      padding: 240px ${theme.spacing.x5}px ${theme.spacing.x5}px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading1(theme)};
    color: ${theme.colors.amels.body};
    max-width: 738px;
    margin: 0;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto;
    }
  `,
)
const SubTitle = styled.h3(
  ({ theme }) => css`
    ${theme.text.subHeadingWide(theme)};
    color: ${theme.colors.amels.body};
    margin: ${theme.spacing.x2}px 0 ${theme.spacing.x8}px;
    max-width: 738px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: ${theme.spacing.x2}px auto ${theme.spacing.x7}px;
    }
  `,
)
const ScrollIndicatorWrapper = styled.div`
  text-align: center;
`

interface Props {
  title: ReactNode
  subTitle: ReactNode
  id?: string
  image: {
    src: string
    alt?: string
  }
  scrollIndicatorText: ReactNode
}

const HeroImageHeader = ({
  id = 'heroImageHeader',
  title,
  subTitle,
  image,
  scrollIndicatorText,
  ...others
}: Props) => (
  <Header id={id} data-testid="heroImageHeaderBlok" {...others}>
    <Image
      src={image.src}
      alt={image?.alt ?? ''}
      fill
      style={{ objectFit: 'cover' }}
    />

    <Title>
      <AnimateText delay={200}>{title}</AnimateText>
    </Title>
    <SubTitle>
      <AnimateText delay={400}>{subTitle}</AnimateText>
    </SubTitle>

    {scrollIndicatorText && (
      <ScrollIndicatorWrapper>
        <ScrollDownIndicator
          style={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ['--animation-delay' as any]: '800ms',
          }}
        >
          {scrollIndicatorText}
        </ScrollDownIndicator>
      </ScrollIndicatorWrapper>
    )}
  </Header>
)

export default HeroImageHeader
