const arraysEqual = <T>(a: T[] | undefined, b: T[] | undefined) => {
  if (a === b) {
    return true
  }
  if (a == null || b == null) {
    return false
  }
  if (a.length !== b.length) {
    return false
  }

  for (let i = 0; i < a.length; i += 1) {
    // eslint-disable-next-line security/detect-object-injection
    if (a[i] !== b[i]) {
      return false
    }
  }
  return true
}

export default arraysEqual
