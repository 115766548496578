import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { body, heading2, heading3 } from '../../themes/xplorer/text'
import ImagesWithQuote from '../ImagesWithQuote'
import { NavigateProps, quoteLocation } from './index'
import Image from '../../components/Image'
import Link from '../../components/Link'

const Container = styled.section(
  ({ theme }) =>
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      position: relative;
      overflow: hidden;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px;
      }
    `,
)

const Title = styled.div(
  ({ theme }) =>
    css`
      ${heading2(theme)}
      max-width: 238px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: 388px;
      }
    `,
)

const TitleLastLine = styled.span(
  ({ theme }) =>
    css`
      color: ${theme.colors.xplorer.secondaryXPBronze};
    `,
)

const StyledTitle = ({ title }: { title: string }) => {
  const lines = title.split('\n')

  // Get the last line
  const lastLine = lines.pop()

  return (
    <Title>
      {lines.join('\n')}
      {lines.length > 0 && <br />}
      <TitleLastLine>{lastLine}</TitleLastLine>
    </Title>
  )
}

const Description = styled.p(
  ({ theme }) =>
    css`
      ${body(theme)}
      max-width: 840px;
      margin: 0;
    `,
)

const DesktopImageContainer = styled.div(
  ({ theme }) => css`
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
)
const MobileImageContainer = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)

const StyledImage = styled(Image)(
  ({ theme }) => css`
    object-position: top;
    object-fit: cover;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      object-position: center;
      object-fit: contain;
    }
  `,
)

const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`

const Content = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.x7}px;
      position: relative;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: 1280px;
        margin: 0 auto;
        gap: ${theme.spacing.x10}px;
      }
    `,
)

const Intro = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        gap: ${theme.spacing.x7}px;
      }
    `,
)

const StyledImagesWithQuote = styled(ImagesWithQuote)``

const Row = styled.div<{ quoteLocation: quoteLocation }>(
  ({ theme, quoteLocation }) =>
    css`
      display: flex;
      flex-direction: ${quoteLocation === 'top' || quoteLocation === 'off'
        ? 'column-reverse'
        : 'column'};
      gap: ${theme.spacing.x5}px;
      ${StyledImagesWithQuote} {
        display: ${quoteLocation === 'off' ? 'none' : ''};
      }
    `,
)

const QuickLinks = styled.div(
  ({ theme }) =>
    css`
      display: grid;
      gap: ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        grid-template-columns: 1fr 1fr;
        gap: ${theme.spacing.x5}px;
      }
    `,
)

const Card = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.x5}px;
      height: 268px;
      position: relative;
      pointer-events: none;
      overflow: hidden;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        height: 502px;
      }
    `,
)

const CardImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: filter 200ms linear;
`

const CardOverlay = styled.div(
  ({ theme }) =>
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      padding: ${theme.spacing.x5}px;
      gap: ${theme.spacing.x3}px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      pointer-events: none;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        :before {
          content: '';
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          opacity: 1;
          transition: opacity 200ms ease-in-out;
        }

        :hover:before {
          opacity: 0;
        }

        &:hover ~ ${CardImage} {
          filter: grayscale(100%);
        }
      }
    `,
)

const CardTitle = styled.div(
  ({ theme }) =>
    css`
      ${heading3(theme)}
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.x5}px;
      color: ${theme.colors.xplorer.white};
    `,
)

const CardLink = styled(Link)(
  ({ theme }) =>
    css`
      ${body(theme)}
      font-weight: 600;
      padding: 12px ${theme.spacing.x3}px;
      border-radius: 24px;
      background-color: transparent;
      border: 2px solid ${theme.colors.xplorer.white};
      color: ${theme.colors.xplorer.white};
      transition: all 200ms linear;
      cursor: pointer;
      pointer-events: auto;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        background-color: ${theme.colors.xplorer.secondaryDamenYachtingOrange};
        border: none;

        :hover {
          background-color: ${theme.colors.xplorer.primaryXPLavaBlack};
          text-decoration: none;
        }
      }
    `,
)

const NavigateXplorer = ({
  title,
  description,
  image,
  quickLinks,
  quoteLocation,
  imagesWithQuoteProps,
  ...others
}: NavigateProps) => {
  return (
    <Container data-testid="navigate" {...others}>
      {image && (
        <BackgroundImageContainer>
          <StyledImage
            src={image.src}
            alt={image.alt}
            width={1517}
            height={966}
            aspectRatio="1517:966"
            style={{ width: '100%' }}
          />
        </BackgroundImageContainer>
      )}
      <Content>
        <Intro>
          <StyledTitle title={title} />
          <Description>{description}</Description>
        </Intro>
        <Row quoteLocation={quoteLocation}>
          <QuickLinks>
            {quickLinks.map(({ key, title, link, linkText, image }) => (
              <Card key={key}>
                <CardOverlay>
                  <CardTitle>{title}</CardTitle>
                  <CardLink href={link}>{linkText}</CardLink>
                </CardOverlay>
                <CardImage>
                  <MobileImageContainer>
                    <Image
                      src={image.src}
                      alt={image.alt}
                      height={268}
                      width={620}
                      sizes="100vw"
                      aspectRatio="16:9"
                      style={{ objectFit: 'cover', width: '100%' }}
                    />
                  </MobileImageContainer>
                  <DesktopImageContainer>
                    <Image
                      src={image.src}
                      alt={image.alt}
                      width={620}
                      height={502}
                      aspectRatio="620:502"
                      style={{ objectFit: 'cover', width: '100%' }}
                    />
                  </DesktopImageContainer>
                </CardImage>
              </Card>
            ))}
          </QuickLinks>
          {imagesWithQuoteProps && imagesWithQuoteProps.columns?.length ? (
            <StyledImagesWithQuote noPadding {...imagesWithQuoteProps} />
          ) : null}
        </Row>
      </Content>
    </Container>
  )
}

export default NavigateXplorer
