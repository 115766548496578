import RichText from '../../components/RichText'
import TextWithVideo from '../../patterns/TextWithVideo'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import { mapVideo } from '../../utils/mapVideo'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { TextWithVideoStoryblok } from '../storyblok.generated'

interface Props {
  content: TextWithVideoStoryblok
}

const TextWithVideoBlok = ({
  content: {
    sectionTitle,
    image,
    title,
    text,
    imageLocation,
    video,
    overline,
    background,
  },
  ...others
}: Props) => (
  <TextWithVideo
    id={slugify(sectionTitle)}
    overline={overline}
    image={mapImage(image) ?? getRequiredImageFallback()}
    title={reactStringReplace(title, '\n', <br />)}
    text={<RichText>{text}</RichText>}
    video={mapVideo(video).src}
    imageLocation={imageLocation}
    background={background as 'white' | 'beach10'}
    {...others}
  />
)

export default TextWithVideoBlok
