import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode, useEffect, useState } from 'react'

import { hiddenClassName } from './AnimateText'
import DelayRender from './DelayRender'
import HighlightIcon from '../icons/HighlightIcon.svg'
import HighlightIconXplorer from '../icons/xplorer/HighlightIcon.svg'
import HighlightIconYachtSupport from '../icons/yachtsupport/HighlightIcon.svg'
import { isSiteXplorer } from '../themes'
import { currentSite, Site } from '../sites'

/**
 * The highlight button has the following animation states:
 *
 * - New: it just appeared and will animate in
 * - Attention grabbing: it is randomly triggered to grab attention
 * - Idle: the idle animation just finished, and we need an animation to
 * restore to the normal (idle) state.
 * - Hover: the user hovered the button
 * - Active: the user clicked the button
 */

const Button = styled.button(
  ({ theme }) => css`
    color: ${theme.colors.amels.hazySunsetOrange};
    display: flex;
    align-items: center;
    gap: ${theme.spacing.x2}px;
    border: 0;
    cursor: pointer;
    --padding: ${theme.spacing.x1}px;
    padding: var(--padding);

    --icon-size: ${theme.spacing.x3}px;

    transform: translate(
      calc((var(--icon-size) + var(--padding) * 2) / 2 * -1),
      calc((var(--icon-size) + var(--padding) * 2) / 2 * -1)
    );

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      --icon-size: ${theme.spacing.x4}px;
    }
  `,
)
const Label = styled.div(({ theme }) => [
  css`
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 3.76px;
    line-height: 1;
    margin-bottom: -4px;

    display: none;
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      display: none;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        display: none;
      }
    `,
])
const LoadingIndicator = styled.div(
  ({ theme }) => css`
    background: ${theme.colors.amels.deepBayAqua};
    height: 2px;
    width: calc(var(--progress, 0) * 80px);
    margin-left: -8px; // The text has a 16px gap, but it looks weird for the loading indicator
  `,
)
const ring1Length = currentSite === Site.YachtSupport ? 800 : 522
const ringAppearCss = css`
  @media (prefers-reduced-motion: no-preference) {
    .ring1 {
      stroke-dasharray: ${ring1Length};
      stroke-dashoffset: -${ring1Length};
      transform: rotate(180deg);
      :not(.${hiddenClassName} &) {
        animation: ${keyframes`
          0% {
            stroke-dashoffset: -${ring1Length};
          }
          30% {
            transform: rotate(180deg);
          }
          to {
            stroke-dashoffset: 0;
            transform: rotate(0deg);
          }
        `} 1000ms ease-in-out forwards;
      }
    }
  }
`
const appearCss = css`
  @media (prefers-reduced-motion: no-preference) {
    .background,
    .circle {
      opacity: 0;
      will-change: opacity;
      :not(.${hiddenClassName} &) {
        animation: ${keyframes`
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        `} 700ms ease-in-out forwards;
        animation-delay: 300ms;
      }
    }
    ${ringAppearCss}
  }
`
const ringGrowAnimation = keyframes`
  from {
    r: 83;
    opacity: 1;
  }
  to {
    r: 120;
    opacity: 0;
  }
`
const innerRingGrowAnimation = keyframes`
  0% {
    r: 58;
    opacity: 1;
  }
  // Wait with transitioning the opacity so it isn't already slightly gone when
  // it first becomes visible
  20% {
    opacity: 1;
  }
  to {
    r: 120;
    opacity: 0;
  }
`
const idleAnimationDuration = 600
const idleAnimationTotalDuration =
  idleAnimationDuration + idleAnimationDuration * (400 / 600)
const grabAttentionCss = css`
  @media (prefers-reduced-motion: no-preference) {
    .ring1 {
      r: 83;
      :not(.${hiddenClassName} &) {
        animation: ${ringGrowAnimation} ${idleAnimationDuration}ms linear
          forwards;
      }
    }
    .ring2 {
      r: 58;
      display: block;
      :not(.${hiddenClassName} &) {
        animation: ${innerRingGrowAnimation} ${idleAnimationDuration}ms linear
          forwards;
        animation-delay: ${idleAnimationDuration * (200 / 600)}ms;
      }
    }
    .ring3 {
      r: 58;
      display: block;
      :not(.${hiddenClassName} &) {
        animation: ${innerRingGrowAnimation} ${idleAnimationDuration}ms linear
          forwards;
        animation-delay: ${idleAnimationDuration * (400 / 600)}ms;
      }
    }
  }
`
const getHighlightIcon = () => {
  switch (currentSite) {
    case Site.YachtSupport:
      return HighlightIconYachtSupport
    case Site.Xplorer:
      return HighlightIconXplorer
    default:
      return HighlightIcon
  }
}
const AnimatedHighlightIcon = styled(getHighlightIcon())(
  ({ theme }) => css`
    width: var(--icon-size);
    overflow: visible;

    .ring1,
    .ring2,
    .ring3 {
      transform-origin: center;
    }
    .ring2,
    .ring3 {
      display: none;
    }
    .icon {
      color: ${theme.colors.amels.silk};
    }
  `,
)
const buttonHoverCss = css`
  ${AnimatedHighlightIcon} {
    @media (prefers-reduced-motion: no-preference) {
      .circle,
      .chevron,
      .ring1 {
        transition: opacity 500ms;
      }
      .chevron {
        transition-delay: 100ms;
      }
      .background {
        transition: r 600ms;
      }
    }
    .chevron {
      opacity: 0;
    }
  }
  ${Label} {
    opacity: 0;
    @media (prefers-reduced-motion: no-preference) {
      transform: translateY(1em);
      transition: 800ms;
      transition-delay: 600ms;
    }
  }
  :not(.${hiddenClassName} &) {
    :hover,
    :active {
      ${AnimatedHighlightIcon} {
        .circle {
          // Remove the appear animation
          animation: none;
          opacity: 0;
        }

        .chevron {
          opacity: 1;
        }
        .background {
          r: 89;
        }
        .ring1,
        .ring2,
        .ring3 {
          opacity: 0;
        }
      }
      ${Label} {
        opacity: 1;
        transform: translateY(0%);
      }
    }
    :active {
      // TODO: Animate chevron right
    }
  }
`

interface Props extends ComponentProps<typeof Button> {
  children: ReactNode
  grabAttention?: boolean
  loadingProgress?: number
}

enum HighlightButtonState {
  New,
  GrabAttention,
  Idle,
}

const HighlightButton = ({
  children,
  grabAttention,
  loadingProgress,
  ...others
}: Props) => {
  const [state, setState] = useState<HighlightButtonState>(
    HighlightButtonState.New,
  )
  useEffect(() => {
    if (grabAttention) {
      setState(HighlightButtonState.GrabAttention)
      setTimeout(() => {
        setState(HighlightButtonState.Idle)
      }, idleAnimationTotalDuration + 250)
    }
  }, [grabAttention])

  return (
    <Button type="button" css={buttonHoverCss} {...others}>
      <AnimatedHighlightIcon
        css={[
          state === HighlightButtonState.New && appearCss,
          state === HighlightButtonState.GrabAttention && grabAttentionCss,
          state === HighlightButtonState.Idle && ringAppearCss,
        ]}
      />
      {loadingProgress === undefined ? (
        <Label>{children}</Label>
      ) : (
        <DelayRender delay={1000}>
          <LoadingIndicator
            style={{
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ['--progress' as any]: loadingProgress,
            }}
          />
        </DelayRender>
      )}
    </Button>
  )
}

export default HighlightButton
