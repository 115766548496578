import { ComponentProps, ReactNode } from 'react'
import { currentSite, Site } from '../../sites'
import ExploreTeaserXplorer from './ExploreTeaser.xplorer'
import Section from '../../components/Section'

export interface ExploreTeaserProps
  extends Omit<
    ComponentProps<typeof Section>,
    'title' | 'sectionIndicatorVariant' | 'children'
  > {
  heading: ReactNode
  backgroundImage?: { src: string; alt?: string }
  overlayImage?: { src: string; alt?: string }
  link: string
}
const ExploreTeaser = (props: ExploreTeaserProps) => {
  switch (currentSite) {
    case Site.Xplorer:
      return <ExploreTeaserXplorer {...props} />
    default:
      return <ExploreTeaserXplorer {...props} />
  }
}
export default ExploreTeaser
