/* eslint-disable camelcase */
import {
  ArticleFeedStoryblok,
  ArticleStoryblok,
  EventStoryblok,
} from '../storyblok.generated'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import ArticleFeed from '../../patterns/ArticleFeed'
import { AlgoliaArticle } from '../../utils/algolia/updateNewsIndex'
import { usePageContext } from '../PageContext'
import { StoryblokStory } from '../../utils/storyblok'
import notEmpty from '../../utils/notEmpty'
import { serializePublicationDate } from '../pages/ArticlePage'
import { mapImage } from '../../utils/mapImage'
import { hasBynderAsset } from '../../utils/hasBynderAsset'

type ExtendedArticleFeedStoryblok = ArticleFeedStoryblok & {
  automatedArticles: AlgoliaArticle[]
}
interface Props {
  content: ExtendedArticleFeedStoryblok
}

const ArticleFeedBlok = ({
  content: {
    title,
    subTitle,
    viewAll,
    mode,
    articles,
    automatedArticles,
    variant,
    background,
  },
  ...others
}: Props) => {
  const { rels } = usePageContext()

  return (
    <ArticleFeed
      title={title}
      variant={variant || 'feed'}
      background={background}
      subTitle={subTitle}
      mode={mode}
      viewAll={
        viewAll?.[0] && {
          title: viewAll[0].title,
          href: storyblokLinkResolver(viewAll[0].link),
          target: viewAll[0].linkTarget,
        }
      }
      automatedArticles={automatedArticles.map(
        ({
          objectID,
          slug,
          title,
          type,
          tags,
          articleFeedFilter,
          image,
          summary,
          publishedAt,
        }) => {
          return {
            key: objectID,
            variant:
              type === 'news' || type === 'video' || type === 'article'
                ? 'article'
                : 'event',
            slug,
            tags,
            articleFeedFilter,
            title,
            image: mapImage(image)?.src ?? '',
            summary,
            publishedAt,
            type,
          }
        },
      )}
      articles={articles
        ?.map((id) =>
          rels.find(
            (
              rel,
            ): rel is
              | StoryblokStory<ArticleStoryblok>
              | StoryblokStory<EventStoryblok> => rel.uuid === id,
          ),
        )
        .filter(notEmpty)
        .map(
          ({
            uuid,
            slug,
            content: {
              title,
              component,
              image,
              imageBynder,
              summary,
              ...others
            },
            first_published_at,
            created_at,
          }) => {
            const publishedAt =
              'publicationDate' in others && others.publicationDate
                ? serializePublicationDate(others.publicationDate)
                : first_published_at || created_at

            return {
              key: uuid,
              variant: component,
              slug,
              title,
              summary,
              image:
                (hasBynderAsset(imageBynder)
                  ? mapImage(imageBynder)?.src
                  : mapImage(image)?.src) ?? '',
              publishedAt,
            }
          },
        )}
      {...others}
    />
  )
}

export default ArticleFeedBlok
