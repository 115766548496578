import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../../components/AnimateText'
import Section from '../../components/Section'
import Waves from '../../icons/Waves.svg'

import type { TextWithVideoProps } from './index'
import VideoPlayer from '../../components/VideoPlayer'
import makeVideoSources from '../../utils/makeVideoSources'
import { createImageLoader } from '../../utils/createImageLoader'

const Container = styled(Section)<{
  imageLocation: TextWithVideoProps['imageLocation']
}>(({ theme, imageLocation }) => [
  css`
    background: ${theme.colors.amels.silk};
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    display: flex;
    gap: ${theme.spacing.x4}px;
    justify-content: center;
    position: relative;
    z-index: 0;
    flex-flow: column;
    align-items: center;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-flow: row;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      gap: ${theme.spacing.x10}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x15}px;
      gap: ${theme.spacing.x15}px;
    }
  `,
  imageLocation === 'right' &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        flex-flow: row-reverse;
      }
    `,
  // TODO: add support for bottom and top alignment
])
const DesktopImageContainer = styled.div(
  ({ theme }) => css`
    flex: 0 0 auto;
    display: none;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: block;
      max-width: 50%;
    }
  `,
)
const MobileImageContainer = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      display: none;
    }
  `,
)
const Content = styled.div(
  ({ theme }) => css`
    flex: 1 1 auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 508px;
    }
  `,
)
const Title = styled.h1(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin: 0 0 ${theme.spacing.x4}px;
  `,
)
const StyledWaves = styled(Waves)(
  ({ theme }) => css`
    position: absolute;
    left: 0;
    transform: translateX(-67%);
    width: 350vw;
    top: -7vw;
    z-index: -1;
    opacity: 0.2;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      transform: translateX(-74%);
      width: 345vw;
      top: -57vw;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      transform: translateX(-60%);
      width: 180vw;
      top: -40vw;
    }
  `,
)

const TextWithVideo = ({
  title,
  text,
  image,
  video,
  imageLocation = 'right',
  ...others
}: TextWithVideoProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  const imageLoader = createImageLoader(image.src)

  const poster = imageLoader.loader
    ? imageLoader.loader({ src: imageLoader.src as string, width: 1920 })
    : image.src

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      imageLocation={imageLocation}
      data-testid="textWithVideo"
      ref={containerRef}
      {...others}
    >
      <DesktopImageContainer>
        <AnimateText direction="static" delay={800}>
          <VideoPlayer poster={poster}>{makeVideoSources(video)}</VideoPlayer>
        </AnimateText>
      </DesktopImageContainer>
      <Content>
        <Title>
          <AnimateText>{title}</AnimateText>
        </Title>
        <MobileImageContainer>
          <AnimateText direction="static" delay={800}>
            <VideoPlayer poster={poster}>{makeVideoSources(video)}</VideoPlayer>
          </AnimateText>
        </MobileImageContainer>
        <AnimateText delay={400}>{text}</AnimateText>
      </Content>

      <StyledWaves aria-label="" />
    </Container>
  )
}

export default TextWithVideo
