import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ComponentProps,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import ChevronRight from '../icons/ChevronRight.svg'
import AnimateText from '../components/AnimateText'
import IconLink from '../components/IconLink'
import Section from '../components/Section'
import useInterval from '../utils/useInterval'
import Link from '../components/Link'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachtSupport,
  isSiteYachting,
} from '../themes'

const Container = styled(Section)(({ theme }) => [
  css`
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x10}px ${theme.spacing.x5}px;
    }
  `,
  isSiteXplorer(theme) &&
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      background-color: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
      color: ${theme.colors.xplorer.white};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px ${theme.spacing.x5}px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;
      background-color: ${theme.colors.yachtSupport.primaryYSOceanBlue};
      color: ${theme.colors.yachtSupport.white};

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px 0;
      }
    `,
])
const Wrapper = styled.div(({ theme }) => [
  css`
    max-width: 1089px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: 0 auto;
    }
  `,
  isSiteYachting(theme) &&
    css`
      max-width: 640px;
    `,
])
const Title = styled.div(({ theme }) => [
  isSiteAmels(theme) &&
    css`
      ${theme.text.body(theme)}
      font-weight: 600;
    `,
  isSiteYachting(theme) &&
    css`
      font-size: 20px;
      color: ${theme.colors.yachting.darkBlue};
      margin-bottom: ${theme.spacing.x2}px;
    `,
  isSiteXplorer(theme) &&
    css`
      ${theme.text.body(theme)}
      font-weight: 600;
      margin-bottom: ${theme.spacing.x2}px;
    `,
  isSiteYachtSupport(theme) &&
    css`
      ${theme.text.heading6(theme)}
      line-height: 1.1;
      color: ${theme.colors.yachtSupport.white};
      margin-bottom: ${theme.spacing.x2}px;
    `,
])
const ContentWrapper = styled.div(({ theme }) => [
  css`
    ${theme.text.body(theme)}

    & p:last-child {
      margin-bottom: 0;
    }
  `,
  isSiteYachtSupport(theme) &&
    css`
      color: ${theme.colors.yachtSupport.white};
    `,
])
const IntroductionText = styled.div(
  ({ theme }) => css`
    ${theme.text.body(theme)}
    margin-bottom: ${theme.spacing.x8}px;
  `,
)
const StyledChevronRight = styled(ChevronRight)`
  height: 16px;
`
const StyledIconLink = styled(IconLink)(
  ({ theme }) => css`
    display: block;
    margin-top: ${theme.spacing.x3}px;
  `,
)
const Block = styled.div(
  ({ theme }) => css`
    gap: ${theme.spacing.x6}px;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: calc(50% - ${theme.spacing.x2}px);
    }
  `,
)
const Blocks = styled.div(({ theme }) => [
  css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.x7}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      ${Block} {
        &:nth-of-type(1) {
          order: 1;
        }
        &:nth-of-type(2) {
          order: 3;
        }
        &:nth-of-type(3) {
          order: 2;
        }
        &:nth-of-type(4) {
          order: 4;
        }
      }
    }
  `,
  isSiteXplorer(theme) &&
    css`
      max-width: 714px;
      margin: 0 auto;
    `,
  isSiteYachtSupport(theme) &&
    css`
      max-width: 714px;
      margin: 0 auto;
    `,
])

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  text?: ReactNode
  visitingAddressTitle: ReactNode
  postalAddressTitle: ReactNode
  googleMapsTitle: ReactNode
  callUs: {
    callUsTitle: ReactNode
    callUsText: ReactNode
  }
  phone?: {
    phoneTitle?: ReactNode
    phoneNumber?: ReactNode
    localTimeText?: string
  }
  visitingAddress: {
    city: string
    street: string
    houseNumber?: string
    postalCode: string
    country: string
    googleMapsLocation?: string
  }
  postalAddress?: {
    city: string
    street: string
    houseNumber?: string
    postalCode: string
    country: string
    googleMapsLocation?: string
  }
}

const ContactInformation = ({
  visitingAddressTitle,
  postalAddressTitle,
  googleMapsTitle,
  text,
  phone,
  callUs,
  visitingAddress,
  postalAddress,
  ...others
}: Props) => {
  const [locationTime, setLocationTime] = useState('')
  // https://stackoverflow.com/questions/10087819/convert-date-to-another-timezone-in-javascript#answer-54127122
  function convertTZ(date: string | Date, tzString: string) {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone: tzString },
      ),
    )
  }

  const intervalChangeHandler = useCallback(() => {
    const date = new Date()
    const amsterdamDate = convertTZ(date, 'Europe/Amsterdam')
    const hours = amsterdamDate.getHours()
    const minutes = amsterdamDate.getMinutes()
    const paddedHours = hours.toString().padStart(2, '0')
    const paddedMinutes = minutes.toString().padStart(2, '0')

    setLocationTime(`${paddedHours}:${paddedMinutes}`)
  }, [setLocationTime])

  useEffect(() => {
    intervalChangeHandler()
  }, [intervalChangeHandler])

  useInterval(intervalChangeHandler, 1000)

  const formattedLocalTimeString = phone?.localTimeText
    ?.replace('{place}', visitingAddress?.city)
    .replace('{time}', locationTime)
  return (
    <Container
      data-testid="contactInformation"
      sectionIndicatorVariant="dark"
      {...others}
    >
      <Wrapper>
        <AnimateText>
          {text && <IntroductionText>{text}</IntroductionText>}
        </AnimateText>
        <Blocks>
          {visitingAddress && (
            <Block>
              <AnimateText>
                <Title>{visitingAddressTitle}</Title>
                <ContentWrapper>
                  <div>
                    {visitingAddress.street} {visitingAddress.houseNumber ?? ''}
                  </div>
                  <div>
                    {visitingAddress.postalCode} {visitingAddress.city}
                  </div>
                  <div>{visitingAddress.country}</div>
                  {googleMapsTitle && visitingAddress.googleMapsLocation && (
                    <StyledIconLink
                      icon={<StyledChevronRight aria-label="" />}
                      href={visitingAddress.googleMapsLocation}
                    >
                      {googleMapsTitle}
                    </StyledIconLink>
                  )}
                </ContentWrapper>
              </AnimateText>
            </Block>
          )}
          {postalAddress && (
            <Block>
              <AnimateText>
                <Title>{postalAddressTitle}</Title>
                <ContentWrapper>
                  <div>
                    {postalAddress.street} {postalAddress.houseNumber ?? ''}
                  </div>
                  <div>
                    {postalAddress.postalCode} {postalAddress.city}
                  </div>
                  <div>{postalAddress.country}</div>
                </ContentWrapper>
              </AnimateText>
            </Block>
          )}
          {callUs && (
            <Block>
              <AnimateText>
                <Title>{callUs.callUsTitle}</Title>
                <ContentWrapper>{callUs.callUsText}</ContentWrapper>
              </AnimateText>
            </Block>
          )}
          {phone && (
            <Block>
              <AnimateText>
                <Title>{phone.phoneTitle}</Title>
                <ContentWrapper>
                  <div>
                    <Link
                      href={`tel:${phone.phoneNumber}`}
                      style={{ color: 'currentColor' }}
                    >
                      {phone.phoneNumber}
                    </Link>
                  </div>
                  <div>{formattedLocalTimeString}</div>
                </ContentWrapper>
              </AnimateText>
            </Block>
          )}
        </Blocks>
      </Wrapper>
    </Container>
  )
}

export default ContactInformation
