import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { heading1 } from '../../themes/xplorer/text'
import Image from '../../components/Image'
import { ExploreTeaserProps } from './index'
import ImageAspectRatio from '../../components/ImageAspectRatio'
import ChevronRightIcon from '../../icons/xplorer/ChevronRight.svg'
import Link from '../../components/Link'

const Wrapper = styled.div(
  ({ theme }) =>
    css`
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      }
    `,
)

const Container = styled(Link)(
  () =>
    css`
      position: relative;
      display: block;
    `,
)
const HeadingIcon = styled(ChevronRightIcon)(
  ({ theme }) => css`
    width: 96px;
    color: ${theme.colors.xplorer.white};
    transition: transform 200ms linear;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 148px;
    }
  `,
)
const Heading = styled.div(
  ({ theme }) =>
    css`
      ${heading1(theme)};
      line-height: 1.2;
      color: ${theme.colors.xplorer.white};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      :hover ${HeadingIcon} {
        transform: translateX(3px);
      }

      @media screen and (max-width: ${theme.breakpoints.tablet}px) {
        font-size: 20px;
      }
    `,
)
const BackgroundImageContainer = styled(ImageAspectRatio)``
const OverlayImageContainer = styled(ImageAspectRatio)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
const Content = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: ${theme.spacing.x3}px;
      padding: 0 ${theme.spacing.x2}px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      color: ${theme.colors.xplorer.white};
      max-width: 200px;
      margin: 0 auto;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        max-width: 1200px;
        padding: ${theme.spacing.x4}px;
        gap: ${theme.spacing.x5}px;
      }
    `,
)
const ExploreTeaser = ({
  heading,
  backgroundImage,
  overlayImage,
  link,
  ...others
}: ExploreTeaserProps) => {
  return (
    <Wrapper {...others}>
      <Container href={link}>
        {backgroundImage && (
          <BackgroundImageContainer aspectRatio="16:9">
            <Image
              src={backgroundImage.src}
              alt={backgroundImage.alt ?? ''}
              style={{ objectFit: 'cover', width: '100%' }}
              fill
              aspectRatio="16:9"
            />
          </BackgroundImageContainer>
        )}
        {overlayImage && (
          <OverlayImageContainer aspectRatio="16:9">
            <Image
              src={overlayImage.src}
              alt={overlayImage.alt ?? ''}
              style={{ objectFit: 'cover', width: '100%' }}
              fill
              aspectRatio="16:9"
            />
          </OverlayImageContainer>
        )}
        <Content>
          <Heading>
            {heading}
            <HeadingIcon />
          </Heading>
        </Content>
      </Container>
    </Wrapper>
  )
}
export default ExploreTeaser
