import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'
import { ComponentProps, ReactNode } from 'react'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'
import Image from '../components/Image'
import { isSiteYachting } from '../themes'
import LinkButton from '../components/LinkButton/LinkButton.yachting'
import SidePanelButton from '../components/SidePanelButton'
import { primaryButtonCssYachting } from '../components/Button'

const Container = styled(Section)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      position: relative;
      background: ${theme.colors.yachting.beach10};
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to bottom,
          rgba(249, 246, 242, 0) 0%,
          rgba(236, 227, 215, 1) 100%
        );
        clip-path: polygon(100% 0, 25% 100%, 100% 100%);
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        &::after {
          top: 0;
          width: 50%;
          clip-path: polygon(200px 0, 100% 0, 100% 100%, 0% 100%);
        }
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x10}px;
      }
      @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
        padding: ${theme.spacing.x15}px;
      }
    `,
)
const ContentContainer = styled.div(({ theme }) => [
  css`
    ${theme.layout.maxContentWidth(theme)};
    display: flex;
    position: relative;
    gap: ${theme.spacing.x6}px;
    justify-content: center;
    align-items: stretch;
    flex-flow: column;
    z-index: 1;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      flex-flow: row;
      align-items: center;
      justify-content: space-between;
      gap: ${theme.spacing.x8}px;

      > div:first-of-type {
        flex: 0;
      }
      > div:nth-of-type(2) {
        flex: 1;
      }
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      gap: ${theme.spacing.x15}px;
    }
  `,
])
const ImageContainer = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      position: relative;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      justify-content: start;
      max-width: 100%;
      width: 100%;

      img {
        width: 100%;
        height: auto;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        img {
          width: 50%;
          height: auto;
        }
      }
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        align-items: center;
        img {
          max-width: 400px;
          height: auto;
          width: auto;
        }
      }
    `,
)
const Content = styled.div(
  ({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 600px;
    }
  `,
)
const Overline = styled.div(
  ({ theme }) => css`
    ${isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)}
      margin-bottom: ${theme.spacing.x2}px;
      margin-left: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-left: ${theme.spacing.x3}px;
      }
    `}
  `,
)
const Title = styled.h2(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      margin: 0 0 ${theme.spacing.x2}px;
    `,
)
const Text = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      margin: 0 0 ${theme.spacing.x4}px ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0 0 ${theme.spacing.x4}px ${theme.spacing.x3}px;
      }
    `,
)
const StyledSidePanelButton = styled(SidePanelButton)(({ theme }) => [
  primaryButtonCssYachting(theme),
  css`
    margin-left: ${theme.spacing.x2}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-left: ${theme.spacing.x3}px;
    }
  `,
])

export interface CallToActionProps
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant' | 'title'
  > {
  overline?: string
  title: ReactNode
  text: ReactNode
  image: {
    src: string
    alt?: string
  }
  link:
    | {
        title: ReactNode
        href?: string
        target?: string
      }
    | {
        label: ReactNode
        form: ReactNode
      }
}

const CallToAction = ({
  overline,
  title,
  text,
  image,
  link,
  ...others
}: CallToActionProps) => {
  const [containerRef, inView] = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      sectionIndicatorVariant="light"
      className={!inView ? hiddenClassName : undefined}
      data-testid="callToAction"
      ref={containerRef}
      {...others}
    >
      <ContentContainer>
        <AnimateText direction="static">
          <ImageContainer>
            <Image
              src={image.src}
              alt={image.alt}
              width={640}
              height={512}
              aspectRatio="5:4"
              style={{ objectFit: 'cover' }}
            />
          </ImageContainer>
        </AnimateText>
        <Content>
          <AnimateText>
            {overline && <Overline>{overline}</Overline>}
            <Title>{title}</Title>
          </AnimateText>
          <AnimateText delay={400}>
            <Text>{text}</Text>
          </AnimateText>
          <AnimateText delay={600}>
            {'href' in link && (
              <LinkButton
                variant="secondary"
                href={link.href ?? ''}
                target={link.target}
              >
                {link.title}
              </LinkButton>
            )}
            {'form' in link && (
              <StyledSidePanelButton sidePanel={link.form} variant="secondary">
                {link.label}
              </StyledSidePanelButton>
            )}
          </AnimateText>
        </Content>
      </ContentContainer>
    </Container>
  )
}

export default CallToAction
