import { VisualLineStoryblok } from '../storyblok.generated'
import VisualLine from '../../patterns/VisualLine'

interface Props {
  content: VisualLineStoryblok
}

const VisualLineBlok = ({ content: { color, position }, ...others }: Props) => {
  return <VisualLine color={color} position={position} {...others} />
}

export default VisualLineBlok
