import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import { hiddenClassName } from '../components/AnimateText'
import ImageCarousel from '../components/ImageCarousel'
import Section from '../components/Section'
import {
  isSiteYachting,
  isSiteAmels,
  isThemeYachtingWomenInYachtingLandingspage,
  isSiteYachtSupport,
} from '../themes'
import { currentSite, Site } from '../sites'

const StyledImageCarousel = styled(ImageCarousel)(
  ({ theme }) => css`
    --width: 100vw;
    --height: max(600px, 60vh);

    ${isSiteYachtSupport(theme) &&
    css`
      --height: 211px;
    `}

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      --height: max(800px, 90vh);
    }
  `,
)

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    margin: 0;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      margin: 0;
    }

    ${isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        text-align: center;
      }
    `}
  `,
)

const Overline = styled.span(
  ({ theme }) => css`
    display: block;
    margin-bottom: ${theme.spacing.x2}px;
    margin-left: ${theme.spacing.x3}px;

    ${isSiteYachting(theme) &&
    css`
      ${theme.text.overlineMedium(theme)};
    `},

    ${isSiteAmels(theme) &&
    css`
      ${theme.text.subHeading(theme)};
    `}

    ${isSiteYachtSupport(theme) &&
    css`
      ${theme.text.overLineMedium(theme)};
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-left: 0px;
    `},
  `,
)

const Content = styled.div(
  ({ theme }) => css`
    max-width: 1089px;
    margin-bottom: ${theme.spacing.x6}px;
    padding: ${theme.spacing.x6}px ${theme.spacing.x4}px 0 ${theme.spacing.x4}px;

    ${isSiteYachtSupport(theme) &&
    css`
      max-width: none;
      padding: ${theme.spacing.x10}px ${theme.spacing.x3}px 0
        ${theme.spacing.x3}px;
    `}

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x10}px 0
        ${theme.spacing.x10}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktopLarge}px) {
      padding: ${theme.spacing.x20}px ${theme.spacing.x20}px 0
        ${theme.spacing.x20}px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin-bottom: ${theme.spacing.x13}px;
    }

    ${isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0 auto ${theme.spacing.x13}px;
      }
    `}
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Section>,
    'children' | 'sectionIndicatorVariant'
  > {
  images: ComponentProps<typeof ImageCarousel>['images']
  componentTitle?: ReactNode
  overline?: ReactNode
  navigationVariant?: 'overlay' | 'static'
}

const FullWidthCarousel = ({
  images,
  componentTitle,
  overline,
  navigationVariant,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-200px 0px',
    triggerOnce: true,
  })

  return (
    <Section id="carousel" sectionIndicatorVariant="light" {...others}>
      {componentTitle && (
        <Content>
          {overline && <Overline>{overline}</Overline>}
          {componentTitle && <Title>{componentTitle}</Title>}
        </Content>
      )}
      <StyledImageCarousel
        images={images}
        previewSecondSlide
        data-testid="fullWidthCarousel"
        ref={ref}
        navigationVariant={navigationVariant}
        className={!inView ? hiddenClassName : undefined}
        splitOnMobile={currentSite === Site.YachtSupport ? false : undefined}
      />
    </Section>
  )
}

export default FullWidthCarousel
