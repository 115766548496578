import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps } from 'react'

import Button, { invisibleButtonCss } from './Button'
import {
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
  isThemeYachtingWomenInYachtingLandingspage,
} from '../themes'

const Pager = styled('div')(({ theme }) => [
  css`
    position: relative;
    height: var(--thickness);
    --thickness: 2px;
    --gap: ${theme.spacing.x1 / 2}px;
    --page-size: calc(
      (100% - (var(--num-slides) - 1) * var(--gap)) / var(--num-slides)
    );
    color: ${theme.colors.amels.silk};

    &.vertical {
      width: var(--thickness);
      height: 100%;
    }
  `,
  isSiteYachting(theme) &&
    css`
      --thickness: 6px;
      --gap: ${theme.spacing.x1}px;
    `,
  isSiteXplorer(theme) &&
    css`
      --thickness: 6px;
      --gap: ${theme.spacing.x1}px;

      @media screen and (max-width: ${theme.breakpoints.tablet}px) {
        --thickness: 0;
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      --thickness: 3px;
      --gap: ${theme.spacing.x1}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        --thickness: 6px;
      }
    `,
])
const PageButton = styled(Button)(({ theme }) => [
  invisibleButtonCss,
  css`
    position: absolute;
    top: 0;
    bottom: 0;
    width: var(--page-size);
    left: calc((var(--page-size) + var(--gap)) * var(--index));
    overflow: hidden;
    cursor: pointer;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: currentColor;
      opacity: 0.5;

      ${isSiteYachting(theme) &&
      css`
        opacity: 1;
      `}

      ${isSiteXplorer(theme) &&
      css`
        opacity: 0;
      `}
    }

    .vertical & {
      width: 100%;
      height: var(--page-size);
      left: auto;
      top: calc((var(--page-size) + var(--gap)) * var(--index));
    }
  `,
  isSiteXplorer(theme) &&
    css`
      height: 100%;
      border-radius: 0;
      background: ${theme.colors.xplorer.white};
    `,
  isSiteYachtSupport(theme) &&
    css`
      height: 100%;
      border-radius: 0;
      background: ${theme.colors.yachtSupport.white};
      --foreground: ${theme.colors.yachtSupport.ysOceanBlue30Solid};

      :hover {
        border: 1px;
      }
    `,
])
export const ActivePage = styled('div')(({ theme }) => [
  css`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background: currentColor;
    // transform is relative to itself, not the parent, so we can only work with left/top
    transition: left 500ms ease, top 500ms ease;
    width: calc(100% / var(--slides-per-item));
    left: calc(var(--index) * -100% + 100% * var(--slide));

    .vertical & {
      width: 100%;
      height: calc(100% / var(--slides-per-item));
      left: auto;
      top: calc(var(--index) * -100% + 100% * var(--slide));
    }
  `,
  isSiteYachting(theme) &&
    css`
      background: ${theme.colors.yachting.orange};
    `,
  isThemeYachtingWomenInYachtingLandingspage(theme) &&
    css`
      background: ${theme.colors.womenInYachtingPage.terraBlush};
    `,
  isSiteXplorer(theme) &&
    css`
      background: ${theme.colors.xplorer.primaryXPDarkOceanGreen};
    `,
  isSiteYachtSupport(theme) &&
    css`
      background: ${theme.colors.yachtSupport.secondaryDamenYachtingOrange};
    `,
])

interface Props extends ComponentProps<typeof Pager> {
  numSlides: number
  /** In the design some images are split up into two slides. So one item (an image) becomes two slides, which requires this value to be 2. In any other situation this should be 1. */
  slidesPerItem?: number
  activeSlide: number
  vertical?: boolean
  hasPreview?: boolean
  speed: number
  onClickPage?: (slide: number) => void
}

const CarouselPager = ({
  numSlides,
  slidesPerItem = 1,
  activeSlide,
  vertical,
  hasPreview,
  className,
  onClickPage,
  speed,
  ...others
}: Props) => {
  const realSlides = numSlides / slidesPerItem

  return (
    <Pager
      className={`${className || ''} ${vertical ? 'vertical' : undefined}`}
      style={{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['--num-slides' as any]: realSlides,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['--slides-per-item' as any]: slidesPerItem,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ['--slide' as any]:
          (hasPreview ? Math.max(0, activeSlide - 1) : activeSlide) /
          slidesPerItem,
      }}
      {...others}
    >
      {[...Array(realSlides)].map((_, index) => (
        <PageButton
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          style={{
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ['--index' as any]: index,
          }}
          onClick={() => onClickPage?.(index)}
          role={onClickPage ? 'button' : undefined}
          data-testid={`carouselPager.page.${index}`}
          aria-label={`Go to slide ${index}`}
        >
          <ActivePage
            style={{
              transitionDuration: `${speed}ms`,
            }}
          />
        </PageButton>
      ))}
    </Pager>
  )
}

export default CarouselPager
