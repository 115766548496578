import { memo } from 'react'

import { AlertBoatshowStoryblok } from '../storyblok.generated'
import AlertBoatshow from '../../patterns/AlertBoatshow'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: AlertBoatshowStoryblok
}

const AlertBoatshowBlok = ({
  content: { title, link, overline, items },
  ...others
}: Props) => (
  <AlertBoatshow
    title={title}
    overline={overline}
    link={
      link.map(({ title, link, linkTarget }) => ({
        title,
        href: storyblokLinkResolver(link),
        target: linkTarget,
      }))[0]
    }
    items={items?.map(({ _uid, title, overline, image, link }) => ({
      key: _uid,
      title,
      overline,
      link: link.map(({ title, link, linkTarget }) => ({
        title,
        href: storyblokLinkResolver(link),
        target: linkTarget,
      }))[0],
      image: mapImage(image) ?? getRequiredImageFallback(),
    }))}
    {...others}
  />
)

export default memo(AlertBoatshowBlok)
