import { TextCallToActionStoryblok } from '../storyblok.generated'
import TextCallToAction from '../../patterns/TextCallToAction'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'

interface Props {
  content: TextCallToActionStoryblok
}

const TextCallToActionBlok = ({
  content: { overline, link, variant },
  ...others
}: Props) => (
  <TextCallToAction
    variant={variant}
    overline={overline}
    link={{
      title: link[0].title,
      href: storyblokLinkResolver(link[0].link),
      target: link[0].linkTarget,
    }}
    {...others}
  />
)

export default TextCallToActionBlok
