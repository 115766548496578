import RichText from '../../components/RichText'
import TextWithWideImage from '../../patterns/TextWithWideImage'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'
import { mapVideo } from '../../utils/mapVideo'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { TextWithWideImageStoryblok } from '../storyblok.generated'

interface Props {
  content: TextWithWideImageStoryblok
}

const TextWithWideImageBlok = ({
  content: { title, text, image, video, assetLocation, linkBlock },
  ...others
}: Props) => (
  <TextWithWideImage
    title={title}
    text={<RichText>{text}</RichText>}
    image={mapImage(image) ?? getRequiredImageFallback()}
    video={mapVideo(video)?.src}
    assetLocation={assetLocation}
    linkText={linkBlock?.[0] ? linkBlock[0]?.title : undefined}
    href={
      linkBlock?.[0] ? storyblokLinkResolver(linkBlock[0]?.link) : undefined
    }
    {...others}
  />
)

export default TextWithWideImageBlok
