import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, forwardRef, ReactNode, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import Carousel from './Carousel'
import AnimateText from './AnimateText'
import SidePanelTransition from './SidePanelTransition'
import SidePanel from './SidePanel'
import Portal from './Portal'
import useIsClientSideRender from '../utils/useIsClientSideRender'
import mergeRefs from '../utils/mergeRefs'
import ImageCarouselSlideContent, {
  CarouselImage,
} from './ImageCarouselSlideContent'
import Button, { whiteOutlineButtonCss } from './Button'
import useIsMobile from '../utils/useIsMobile'

const StyledCarousel = styled(Carousel)`
  --slide-height: var(--height);
  height: var(--slide-height);
`
const Slide = styled('figure', {
  shouldForwardProp: (prop) =>
    prop !== 'index' && prop !== 'previewSecondSlide',
})<{
  index: number
  previewSecondSlide?: boolean
}>(({ theme, index, previewSecondSlide }) => [
  css`
    position: relative;
    width: var(--width);
    height: var(--slide-height);
    overflow: hidden;
  `,
  previewSecondSlide &&
    index === 0 &&
    css`
      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        width: calc(0.92 * var(--width));
      }
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: calc(0.89 * var(--width));
      }
    `,
])
const Resizer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'left' && prop !== 'right',
})<{
  left?: boolean
  right?: boolean
}>(({ left, right }) => [
  css`
    position: relative;
    width: 200vw;
    height: 100%;
  `,
  left && css``,
  right &&
    css`
      transform: translateX(-50%);
    `,
])
const MoreInformationButton = styled(Button)(
  ({ theme }) => css`
    ${whiteOutlineButtonCss(theme)}
    position: absolute;
    bottom: ${theme.spacing.x9}px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  `,
)
const SidePanelTitle = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading2(theme)}
    color: ${theme.colors.amels.silk};
    margin: ${theme.spacing.x10}px 0 ${theme.spacing.x6}px;
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof StyledCarousel>,
    'children' | 'slidesPerItem' | 'css'
  > {
  images: Array<CarouselImage & { key: string }>
  previewSecondSlide?: boolean
  splitOnMobile?: boolean
  textPresentation?: 'static' | 'modal'
}

// eslint-disable-next-line react/display-name
const ImageCarousel = forwardRef<HTMLDivElement, Props>(
  (
    {
      images,
      previewSecondSlide,
      navigationVariant,
      pagerVariant,
      splitOnMobile = true,
      textPresentation = 'static',
      ...others
    }: Props,
    ref,
  ) => {
    const isMobile = useIsMobile()

    const [containerRef, inView] = useInView({
      rootMargin: '-200px 0px',
      triggerOnce: true,
    })

    const splitImages = isMobile && splitOnMobile

    const [sidePanel, setSidePanel] = useState<
      | {
          title: ReactNode
          text: ReactNode
        }
      | undefined
    >(undefined)
    const isClientSideRender = useIsClientSideRender()

    return (
      <>
        <StyledCarousel
          slidesPerItem={splitImages ? 2 : 1}
          ref={mergeRefs(ref, containerRef)}
          navigationVariant={navigationVariant}
          pagerVariant={pagerVariant}
          {...others}
        >
          {images.flatMap(({ key, title, text, ...others }, index) => {
            const openSidePanel = () => {
              setSidePanel({
                title,
                text,
              })
            }

            const content = (
              <ImageCarouselSlideContent
                title={title}
                text={text}
                {...others}
                textPresentation={
                  textPresentation === 'modal' && splitImages
                    ? 'none'
                    : textPresentation
                }
                openSidePanel={openSidePanel}
                // Preload future frames when the carousel gets in view
                preload={inView}
              />
            )

            if (splitImages) {
              const moreInfoSection = (
                <div>
                  <MoreInformationButton
                    onClick={openSidePanel}
                    data-testid="imageCarousel.moreInformation"
                  >
                    More information
                  </MoreInformationButton>
                </div>
              )

              return [
                <div
                  key={`${key}-left`}
                  data-testid={`imageCarousel.slide.${index}.left`}
                >
                  <Slide index={index}>
                    {textPresentation === 'modal' && title && text ? (
                      <>
                        <div
                          style={{
                            position: 'relative',
                            height: 'calc(var(--height) * 0.7)',
                          }}
                        >
                          <Resizer left>{content}</Resizer>
                        </div>
                        {moreInfoSection}
                      </>
                    ) : (
                      <Resizer left>{content}</Resizer>
                    )}
                  </Slide>
                </div>,
                <div
                  key={`${key}-right`}
                  data-testid={`imageCarousel.slide.${index}.right`}
                >
                  <Slide index={index}>
                    {textPresentation === 'modal' && title && text ? (
                      <>
                        <div
                          style={{
                            position: 'relative',
                            height: 'calc(var(--height) * 0.7)',
                          }}
                        >
                          <Resizer right>{content}</Resizer>
                        </div>
                        {moreInfoSection}
                      </>
                    ) : (
                      <Resizer right>{content}</Resizer>
                    )}
                  </Slide>
                </div>,
              ]
            }

            return (
              <div
                key={key}
                data-testid={`imageCarousel.slide.${index}`}
                data-id={key}
              >
                <Slide
                  index={index}
                  previewSecondSlide={
                    previewSecondSlide && (images.length > 1 || splitImages)
                  }
                >
                  {content}
                </Slide>
              </div>
            )
          })}
        </StyledCarousel>

        {isClientSideRender && (
          <Portal>
            <SidePanelTransition isOpen={sidePanel !== undefined}>
              {sidePanel && (
                <SidePanel
                  close={() => setSidePanel(undefined)}
                  variant="dark"
                  data-testid="imageCarousel.sidePanel"
                >
                  <AnimateText delay={300}>
                    <SidePanelTitle>{sidePanel.title}</SidePanelTitle>
                  </AnimateText>

                  <AnimateText delay={700}>
                    <div>{sidePanel.text}</div>
                  </AnimateText>
                </SidePanel>
              )}
            </SidePanelTransition>
          </Portal>
        )}
      </>
    )
  },
)

export default ImageCarousel
