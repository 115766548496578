import { QuoteCarouselStoryblok } from '../storyblok.generated'
import QuoteCarousel from '../../patterns/QuoteCarousel'

interface Props {
  content: QuoteCarouselStoryblok
}

const QuoteCarouselBlok = ({
  content: { carouselItems, backgroundWaveColorVariant, quoteTextColorVariant },
  ...others
}: Props) => (
  <QuoteCarousel
    carouselItems={carouselItems.map((carouselItem) => ({
      heading: carouselItem.heading,
      overline: carouselItem.overline,
      key: carouselItem._uid,
    }))}
    backgroundWaveColorVariant={backgroundWaveColorVariant}
    quoteTextColorVariant={quoteTextColorVariant}
    {...others}
  />
)

export default QuoteCarouselBlok
