import { ImagesWithQuoteStoryblok } from '../storyblok.generated'
import ImagesWithQuote from '../../patterns/ImagesWithQuote'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: ImagesWithQuoteStoryblok
}

const ImagesWithQuoteBlok = ({ content: { columns }, ...others }: Props) => (
  <ImagesWithQuote
    columns={columns.map((column) => {
      return column.component === 'imagesWithQuoteColumn'
        ? {
            key: column._uid,
            type: 'image',
            image: mapImage(column.image) ?? getRequiredImageFallback(),
          }
        : {
            key: column._uid,
            type: 'quote',
            heading: column.heading,
            backgroundImage: mapImage(column.backgroundImage),
            subHeading: column.subHeading,
          }
    })}
    {...others}
  />
)

export default ImagesWithQuoteBlok
