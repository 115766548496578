import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'
import AnimateText, { hiddenClassName } from './AnimateText'

const TextBlock = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.x15}px;
  `,
)
const TextBlockTitle = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading1(theme)}
    color: ${theme.colors.amels.bayGold};
    margin: 0;
    margin-bottom: ${theme.spacing.x4}px;
    font-size: ${theme.spacing.x6}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: ${theme.spacing.x8}px;
    }
  `,
)
const TextBlockText = styled.div(
  ({ theme }) => css`
    max-width: 708px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;

    > p:first-of-type {
      margin-top: 0;
    }
    > p:last-of-type {
      margin-bottom: 0;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      font-size: 16px;
    }
  `,
)

interface Props {
  title: ReactNode
  children: ReactNode
}

const SpecificationText = ({ title, children }: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  })

  return (
    <TextBlock ref={ref} className={!inView ? hiddenClassName : undefined}>
      <TextBlockTitle>
        <AnimateText>{title}</AnimateText>
      </TextBlockTitle>

      <TextBlockText>
        <AnimateText delay={120}>{children}</AnimateText>
      </TextBlockText>
    </TextBlock>
  )
}

export default SpecificationText
