import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { NavigationTeaserWithTextProps } from './index'
import NavigationTeaser from '../NavigationTeaser/NavigationTeaser.yachtsupport'
import { body, heading2, overLineMedium } from '../../themes/yachtsupport/text'

const Container = styled.section(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: column;
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      gap: ${theme.spacing.x8}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
        gap: ${theme.spacing.x10}px;
      }
    `,
)

const Content = styled.div(
  () =>
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 896px;
      margin: 0 auto;
      text-align: center;
    `,
)

const Title = styled.h3(
  ({ theme }) =>
    css`
      ${heading2(theme)}
      margin-top: 0;
      margin-bottom: ${theme.spacing.x4}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x5}px;
        letter-spacing: 0;
      }
    `,
)

const SubTitle = styled.div(
  ({ theme }) =>
    css`
      ${overLineMedium(theme)}
      color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
      margin-bottom: ${theme.spacing.x2}px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin-bottom: ${theme.spacing.x3}px;
      }
    `,
)

const Description = styled.div(
  ({ theme }) => css`
    ${body(theme)}
    margin: 0;
  `,
)

const NavigationTeaserWithText = (props: NavigationTeaserWithTextProps) => {
  const { title, subTitle, description, navigationTeaserProps, ...others } =
    props

  return (
    <Container {...others}>
      <Content>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </Content>
      <NavigationTeaser {...navigationTeaserProps} />
    </Container>
  )
}

export default NavigationTeaserWithText
