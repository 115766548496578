import CallToAction from '../../patterns/CallToAction'
import reactStringReplace from '../../utils/reactStringReplace'
import slugify from '../../utils/slugify'
import { CallToActionStoryblok, FormStoryblok } from '../storyblok.generated'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { usePageContext } from '../PageContext'
import { StoryblokStory } from '../../utils/storyblok'
import FormBlok from '../FormBlok'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: CallToActionStoryblok
}

const CallToActionBlok = ({
  content: {
    overline,
    image,
    title,
    text,
    link: [link],
  },
  ...others
}: Props) => {
  const { rels } = usePageContext()

  return (
    <CallToAction
      id={slugify(overline)}
      overline={overline}
      title={reactStringReplace(title, '\n', <br />)}
      text={text}
      image={mapImage(image) ?? getRequiredImageFallback()}
      link={
        link.component === 'linkWithTitle'
          ? {
              title: link.title,
              href: storyblokLinkResolver(link.link),
              target: link.linkTarget,
            }
          : {
              label: link.label,
              form: (
                <FormBlok
                  form={
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    rels.find(
                      (rel): rel is StoryblokStory<FormStoryblok> =>
                        rel.uuid === link.form,
                    )!
                  }
                />
              ),
            }
      }
      {...others}
    />
  )
}

export default CallToActionBlok
