import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

import { hiddenClassName } from '../../components/AnimateText'
import Image from '../../components/Image'
import Section from '../../components/Section'
import squarePolygon from '../../utils/squarePolygon'
import ImageAspectRatio from '../../components/ImageAspectRatio'
import ArrowSlant from '../../icons/yachting/ArrowSlant.svg'
import Link from '../../components/Link'
import { isSiteYachting } from '../../themes'

import type { NavigationTeaserProps } from './index'

const Container = styled(Section)(
  ({ theme }) => css`
    padding: ${theme.spacing.x8}px ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      padding: ${theme.spacing.x12}px ${theme.spacing.x4}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
    }
  `,
)
const Menu = styled.menu(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      display: grid;
      list-style: none;
      padding: 0;
      gap: ${theme.spacing.x5}px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        grid-auto-columns: minmax(0, 1fr);
        grid-auto-flow: column;
        max-width: 1086px;
        margin: 0 auto;
      }
    `,
)
const Column = styled.li``
const Title = styled.h2(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.heading2(theme)}
      color: ${theme.colors.yachting.white};
      font-size: 30px;
      font-weight: 300;
      line-height: 1.1;
      margin: 0;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        font-size: 40px;
      }
    `,
)
const DesktopOverlay = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: ${theme.colors.yachting.silver10};
      padding: ${theme.spacing.x3}px;
      color: ${theme.colors.yachting.darkBlue};

      ${Title} {
        margin: 0 0 4px 0;

        @media screen and (min-width: ${theme.breakpoints.desktop}px) {
          margin: 0 0 ${theme.spacing.x2}px 0;
        }
        color: ${theme.colors.yachting.darkBlue};
      }

      @media (prefers-reduced-motion: no-preference) {
        opacity: 0;
        will-change: opacity;
        transition: opacity 300ms ease-in-out;
      }

      display: none;
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        display: block;
      }

      > svg {
        position: absolute;
        bottom: ${theme.spacing.x5 - 9}px;
        right: ${theme.spacing.x3}px;
      }
    `,
)
const OverlayText = styled.div(
  ({ theme }) => css`
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9;
    margin-top: ${theme.spacing.x4}px;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;

    display: none;
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      display: block;
      display: -webkit-box;
      font-size: 20px;
    }
  `,
)
const StyledArrowSlant = styled(ArrowSlant)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      position: relative;
      color: ${theme.colors.yachting.orange};
      transition: transform 300ms ease-in;
      width: 44px;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: 60px;
      }
    `,
)
const DesktopImageContainer = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      position: relative;
      line-height: 0;
      display: none;

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        display: block;
      }
    `,
)
const MobileImageContainer = styled(ImageAspectRatio)(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        display: none;
      }
    `,
)
const Content = styled.div(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      position: absolute;
      display: flex;
      bottom: 0;
      width: 100%;
      padding: ${theme.spacing.x3}px ${theme.spacing.x2}px;
      color: ${theme.colors.yachting.white};
      justify-content: center;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #07314b 100%);

      > svg {
        flex: 0 0 auto;
        margin-left: 10px;
      }

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        padding: ${theme.spacing.x4}px ${theme.spacing.x3}px;
      }

      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        background: linear-gradient(
          180deg,
          rgba(217, 217, 217, 0) 0%,
          #07314b 100%
        );
      }
    `,
)
const Card = styled(Link)(
  ({ theme }) => css`
    position: relative;
    display: block;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      :hover {
        ${DesktopOverlay} {
          opacity: 1;
        }
        ${DesktopImageContainer},
        ${Content} {
          transition: opacity 300ms ease-out;
          opacity: 0;
        }
      }
    }

    @media (prefers-reduced-motion: no-preference) {
      clip-path: ${squarePolygon(0, 0, 0, 1)};

      :hover ${StyledArrowSlant} {
        transform: translateX(8px);
      }

      img {
        transform: scale(1.1);
        transform-origin: top right;
      }

      :not(.${hiddenClassName} &) {
        animation: ${keyframes`
        from {
          clip-path: ${squarePolygon(0, 0, 0, 1)};
        }
        to {
          clip-path: ${squarePolygon(0, 0, 0, 0)};
        }
      `} 1400ms ease-in-out forwards;
        animation-delay: calc(var(--index) * 250ms);

        img {
          animation: ${keyframes`
          from {
            transform: scale(1.1);
          }
          to {
            transform: scale(1.0);
          }
        `} 1400ms ease-in-out forwards;
          animation-delay: calc(var(--index) * 250ms);
        }
      }
    }
  `,
)
const Subtitle = styled.span(
  ({ theme }) =>
    isSiteYachting(theme) &&
    css`
      ${theme.text.overlineSmall(theme)}
    `,
)

const StyledImage = styled(Image)(
  ({ theme }) => css`
    width: 50%;
    height: auto;
    margin: 0 auto;

    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      width: 80%;
    }
  `,
)

const NavigationTeaser = ({ columns, ...others }: NavigationTeaserProps) => {
  const { ref, inView } = useInView({
    rootMargin: '0px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      sectionIndicatorVariant="dark"
      {...others}
    >
      <Menu>
        {columns.map(
          (
            {
              key,
              title,
              image,
              mobileImage,
              logo,
              hoverTitle,
              hoverSubtitle,
              hoverText,
              link,
            },
            index,
          ) => (
            <Column key={key}>
              <Card
                href={link}
                style={{
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ['--index' as any]: index,
                }}
              >
                <DesktopImageContainer>
                  <Image
                    src={image.src}
                    alt={image.alt ?? ''}
                    width={335}
                    height={559}
                    style={{ objectFit: 'cover', width: '100%' }}
                  />
                </DesktopImageContainer>
                <MobileImageContainer aspectRatio="311:241">
                  <Image
                    src={(mobileImage || image).src}
                    alt={(mobileImage || image).alt ?? ''}
                    fill
                    style={{ objectFit: 'cover' }}
                    aspectRatio="311:241"
                  />
                </MobileImageContainer>
                <Content>
                  {logo && logo?.src ? (
                    <StyledImage
                      src={logo.src}
                      alt={logo.alt ?? ''}
                      width={373}
                      height={559}
                      style={{ objectFit: 'contain' }}
                    />
                  ) : (
                    <Title>{title}</Title>
                  )}
                </Content>
                <DesktopOverlay>
                  <Title aria-hidden>{hoverTitle}</Title>
                  {hoverSubtitle && <Subtitle>{hoverSubtitle}</Subtitle>}
                  <OverlayText>{hoverText}</OverlayText>
                  <StyledArrowSlant />
                </DesktopOverlay>
              </Card>
            </Column>
          ),
        )}
      </Menu>
    </Container>
  )
}

export default NavigationTeaser
