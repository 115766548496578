import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { ReactNode } from 'react'
import Section from '../components/Section'
import AnimateText from '../components/AnimateText'

const Container = styled(Section)(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing.x8}px ${theme.spacing.x3}px;
    max-width: 906px;

    img {
      max-height: 327px;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: ${theme.spacing.x10}px auto;

      img {
        max-height: initial;
      }
    }
  `,
])

const ContentWrapper = styled('div')(({ theme }) => [
  css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${theme.spacing.x4}px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      flex-direction: row;
      gap: ${theme.spacing.x7}px;
    }
  `,
])

const HeaderWrapper = styled('div')(() => [
  css`
    display: flex;
    flex-direction: column;
    text-align: center;
  `,
])

const Overline = styled('div')(({ theme }) => [
  css`
    text-transform: uppercase;
    color: ${theme.colors.yachtSupport.secondaryYSCoralGreen};
  `,
])

const Header = styled('h2')(({ theme }) => [
  css`
    ${theme.text.heading2(theme)}
  `,
])

const LeftSideWrapper = styled('div')(({ theme }) => [
  css`
    ${theme.text.body(theme)}
    width: 100%;

    img {
      object-fit: cover;
      object-position: center center;
      width: 100% !important;
    }

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 50%;
    }

    p {
      margin: 0;
    }

    > div > div {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.x3}px;
    }
  `,
])

const RightSideWrapper = styled('div')(({ theme }) => [
  css`
    ${theme.text.body(theme)}
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      width: 50%;
    }

    p {
      margin: 0;
    }

    > div > div {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.x3}px;
    }
  `,
])

interface TwoColumnsTextProps {
  heading?: string
  subHeading?: string
  headingInColumn?: boolean
  hideHeading?: boolean
  leftSide: ReactNode
  rightSide: ReactNode
}

const TwoColumnsText = ({
  heading,
  subHeading,
  headingInColumn,
  hideHeading,
  leftSide,
  rightSide,
}: TwoColumnsTextProps) => (
  <Container sectionIndicatorVariant="dark" data-testid="TwoColumnsText">
    {!hideHeading && !headingInColumn && (
      <HeaderWrapper>
        <AnimateText>
          <Overline>{subHeading}</Overline>
          <Header>{heading}</Header>
        </AnimateText>
      </HeaderWrapper>
    )}
    <ContentWrapper>
      <LeftSideWrapper>
        <AnimateText delay={80}>{leftSide}</AnimateText>
      </LeftSideWrapper>
      <RightSideWrapper>
        <AnimateText delay={80}>
          {!hideHeading && headingInColumn && (
            <>
              <Overline>{subHeading}</Overline>
              <Header>{heading}</Header>
            </>
          )}
          {rightSide}
        </AnimateText>
      </RightSideWrapper>
    </ContentWrapper>
  </Container>
)

export default TwoColumnsText
