import { NavigateStoryblok } from '../storyblok.generated'
import Navigate from '../../patterns/Navigate/index'
import storyblokLinkResolver from '../../utils/storyblokLinkResolver'
import { getRequiredImageFallback, mapImage } from '../../utils/mapImage'

interface Props {
  content: NavigateStoryblok
}

const NavigateBlok = ({
  content: {
    title,
    description,
    image,
    quickLinks,
    quoteLocation,
    imagesWithQuoteBlok,
  },
  ...others
}: Props) => {
  const { columns, ...rest } = imagesWithQuoteBlok?.[0] || {}

  return (
    <Navigate
      title={title}
      description={description}
      image={mapImage(image)}
      quickLinks={quickLinks.map(({ _uid, title, image, link, linkText }) => ({
        key: _uid,
        title,
        link: storyblokLinkResolver(link),
        linkText,
        image: mapImage(image) ?? getRequiredImageFallback(),
      }))}
      quoteLocation={quoteLocation}
      imagesWithQuoteProps={
        columns && columns.length
          ? {
              columns: columns.map((column) =>
                column.component === 'imagesWithQuoteColumn'
                  ? {
                      key: column._uid,
                      type: 'image',
                      image:
                        mapImage(column.image) ?? getRequiredImageFallback(),
                    }
                  : {
                      key: column._uid,
                      type: 'quote',
                      heading: column.heading,
                      subHeading: column.subHeading,
                    },
              ),
              ...rest,
            }
          : undefined
      }
      {...others}
    />
  )
}

export default NavigateBlok
