import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'
import { useInView } from 'react-intersection-observer'

import AnimateText, { hiddenClassName } from '../components/AnimateText'
import Section from '../components/Section'

const Container = styled(Section)(
  ({ theme }) => css`
    padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
    background: ${theme.colors.amels.body};

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      text-align: center;
      padding: ${theme.spacing.x19}px ${theme.spacing.x5}px;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      padding: ${theme.spacing.x18}px ${theme.spacing.x20}px;
    }
  `,
)
const Title = styled.h2(
  ({ theme }) => css`
    ${theme.text.heading1(theme)};
    margin-top: 0;
    margin-bottom: ${theme.spacing.x3}px;
  `,
)
const SubTitle = styled.div(
  ({ theme }) => css`
    ${theme.text.subHeadingWide(theme)};
    margin-bottom: ${theme.spacing.x6}px;
  `,
)
const Text = styled.div(
  ({ theme }) => css`
    width: 100%;
    margin-bottom: ${theme.spacing.x10}px;
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1px;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 660px;
      margin: 0 auto;
      margin-bottom: ${theme.spacing.x7}px;
    }
  `,
)
const Items = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: 660px;
    display: inline-flex;
    flex-wrap: wrap;
    gap: ${theme.spacing.x3}px;
    font-size: 12px;
    letter-spacing: 3.43px;
    font-weight: 500;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      max-width: 580px;
      gap: ${theme.spacing.x4}px ${theme.spacing.x8}px;
      margin: 0 auto;
      justify-content: center;
    }
    @media screen and (min-width: ${theme.breakpoints.desktop}px) {
      max-width: 100%;
      gap: ${theme.spacing.x8}px;
    }
  `,
)
const Item = styled.article(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ theme }) => css`
    text-align: left;
    color: ${theme.colors.amels.beach};
    text-transform: uppercase;
  `,
)

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'title' | 'sectionIndicatorVariant'
  > {
  title: ReactNode
  subTitle: ReactNode
  description: ReactNode
  features: ReactNode[]
}

const PortfolioIntroductionBlok = ({
  title,
  subTitle,
  description,
  features,
  ...others
}: Props) => {
  const { ref, inView } = useInView({
    rootMargin: '-100px 0px',
    triggerOnce: true,
  })

  return (
    <Container
      ref={ref}
      className={!inView ? hiddenClassName : undefined}
      sectionIndicatorVariant="dark"
      sectionIndicatorAriaVisible
      {...others}
    >
      <Title>
        <AnimateText>{title}</AnimateText>
      </Title>
      <SubTitle>
        <AnimateText delay={100}>{subTitle}</AnimateText>
      </SubTitle>

      <Text>
        <AnimateText delay={200}>{description}</AnimateText>
      </Text>

      <Items>
        {features?.map((feature, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={index}>
            <AnimateText delay={400 + index * 50}>{feature}</AnimateText>
          </Item>
        ))}
      </Items>
    </Container>
  )
}

export default PortfolioIntroductionBlok
