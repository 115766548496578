import reactStringReplace from '../../utils/reactStringReplace'
import { TimelineItemStoryblok } from '../storyblok.generated'
import TimelineItem from '../../patterns/TimelineItem'
import { mapImage } from '../../utils/mapImage'

interface Props {
  content: TimelineItemStoryblok
}

const TimelineItemBlok = ({
  content: { _uid, overline, title, text, image },
  ...others
}: Props) => (
  <TimelineItem
    overline={overline}
    title={title}
    text={reactStringReplace(text, '\n', <br />)}
    image={mapImage(image)}
    {...others}
  />
)

export default TimelineItemBlok
