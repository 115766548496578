import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { ComponentProps, ReactNode } from 'react'

import Section from '../components/Section'
import {
  isSiteAmels,
  isSiteXplorer,
  isSiteYachting,
  isSiteYachtSupport,
} from '../themes'

const Container = styled(Section, {
  shouldForwardProp: (prop) =>
    prop !== 'align' && prop !== 'width' && prop !== 'background',
})<{
  align?: 'left' | 'center' | 'right'
  width?: 'standard' | 'wide'
  background?: '' | 'white' | 'beach10' | 'lightSand'
}>(({ theme, align, width, background }) => [
  css`
    margin: ${theme.spacing.x7}px auto;
    padding: 0 ${theme.spacing.x4}px;
    max-width: 867px;
    letter-spacing: 1px;
    overflow: visible;

    @media screen and (min-width: ${theme.breakpoints.tablet}px) {
      margin: ${theme.spacing.x15}px auto;
    }
  `,
  align === 'center' &&
    css`
      text-align: center;

      ${isSiteYachting(theme) &&
      css`
        text-align: left;

        @media screen and (min-width: ${theme.breakpoints.tablet}px) {
          text-align: center;
        }
      `}
    `,
  align === 'right' &&
    css`
      text-align: right;
    `,
  width === 'wide' &&
    css`
      max-width: 1080px;

      ${isSiteYachting(theme) &&
      css`
        max-width: none;
      `}
    `,
  isSiteYachting(theme) &&
    css`
      padding: ${theme.spacing.x10}px ${theme.spacing.x4}px;
      margin: 0;
      max-width: none;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0;
        padding: ${theme.spacing.x20}px ${theme.spacing.x4}px;
      }

      ${background === 'beach10' &&
      css`
        background-color: ${theme.colors.yachting.beach10};
      `}
    `,
  isSiteXplorer(theme) &&
    css`
      margin: 0;
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      max-width: none;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0;
        padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
      }

      ${background === 'lightSand' &&
      css`
        background-color: ${theme.colors.xplorer.secondaryXPBronze10Extra};
      `}
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin: 0;
      padding: ${theme.spacing.x8}px ${theme.spacing.x3}px;
      max-width: none;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: 0;
        padding: ${theme.spacing.x15}px ${theme.spacing.x4}px;
      }
    `,
])

const Wrapper = styled('div', {
  shouldForwardProp: (prop) =>
    prop !== 'layout' && prop !== 'width' && prop !== 'align',
})<{
  layout?: Layout
  width?: 'standard' | 'wide'
  align?: 'left' | 'center' | 'right'
}>(({ theme, layout, width, align }) => [
  isSiteAmels(theme) &&
    css`
      margin: ${theme.spacing.x7}px auto;
      max-width: 867px;

      @media screen and (min-width: ${theme.breakpoints.tablet}px) {
        margin: ${theme.spacing.x15}px auto;
      }
    `,
  isSiteYachting(theme) &&
    css`
      margin: 0 auto;
      max-width: 867px;
    `,
  isSiteXplorer(theme) &&
    css`
      margin: 0 auto;
      max-width: 867px;

      & > * {
        display: flex;
        flex-direction: column;
        ${align === 'left' &&
        css`
          align-items: flex-start;
        `}
        ${align === 'center' &&
        css`
          align-items: center;
        `}
        ${align === 'right' &&
        css`
          align-items: flex-end;
        `}
      }
    `,
  isSiteYachtSupport(theme) &&
    css`
      margin: 0 auto;
      max-width: 867px;

      & > * {
        display: flex;
        flex-direction: column;
        ${align === 'left' &&
        css`
          align-items: flex-start;
        `}
        ${align === 'center' &&
        css`
          align-items: center;
        `}
        ${align === 'right' &&
        css`
          align-items: flex-end;
        `}
      }
    `,
  layout === '1/3' &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: 33%;
      }
    `,
  layout === '1/2' &&
    css`
      @media screen and (min-width: ${theme.breakpoints.desktop}px) {
        width: 50%;
      }
    `,
  width === 'wide' &&
    css`
      ${isSiteYachting(theme) &&
      css`
        max-width: 1086px;
      `}
    `,
])

export type Layout = 'standard' | '' | '1/2' | '1/3'

interface Props
  extends Omit<
    ComponentProps<typeof Container>,
    'children' | 'sectionIndicatorVariant'
  > {
  children: ReactNode
  align?: 'left' | 'center' | 'right'
  width?: 'standard' | 'wide'
  layout?: Layout
  background?: '' | 'white' | 'beach10' | 'lightSand'
}

const Text = ({
  children,
  align,
  width,
  layout,
  background,
  ...others
}: Props) => (
  <Container
    sectionIndicatorVariant="dark"
    align={align}
    width={width}
    background={background}
    data-testid="text"
    {...others}
  >
    <Wrapper layout={layout} width={width} align={align}>
      {children}
    </Wrapper>
  </Container>
)

export default Text
