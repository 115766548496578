import { FunctionComponent, useMemo } from 'react'
import MissingBlokComponent from './MissingBlokComponent'

export interface PrefetchContext<TBlock extends Blok<string> = Blok<string>> {
  data: TBlock
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PrefetchableSliceComponent<
  TBlock extends Blok<string> = Blok<string>,
> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  FunctionComponent<any> & {
    prefetch?: (
      context: PrefetchContext<TBlock>,
    ) => Promise<Record<string, unknown>>
  }

export interface Blok<TComponent extends string> {
  _uid: string
  component: TComponent
  _editable?: string
}

interface Props {
  components: Record<string, PrefetchableSliceComponent>
  content: Blok<string>[]
}

// https://github.com/storyblok/storyblok-js/blob/main/lib/modules/editable.ts
const storyblokEditable = (blok: Blok<string>) => {
  if (typeof blok !== 'object' || typeof blok._editable === 'undefined') {
    return {}
  }

  const options = JSON.parse(
    blok._editable.replace(/^<!--#storyblok#/, '').replace(/-->$/, ''),
  )

  return {
    'data-blok-c': JSON.stringify(options),
    'data-blok-uid': `${options.id}-${options.uid}`,
  }
}

const BlokZone = ({ components, content }: Props) => {
  const renderedBloks = useMemo(
    () =>
      content.map((blok) => {
        const Component = components[blok.component]
        if (!Component) {
          return (
            <MissingBlokComponent
              key={blok._uid}
              name={blok.component}
              props={blok}
              {...storyblokEditable(blok)}
            />
          )
        }

        return (
          <Component
            key={blok._uid}
            content={blok}
            {...storyblokEditable(blok)}
          />
        )
      }),
    [content, components],
  )

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{renderedBloks}</>
}

export default BlokZone
