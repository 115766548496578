import ContactInformation from '../../patterns/ContactInformation'
import slugify from '../../utils/slugify'
import { ContactInformationStoryblok } from '../storyblok.generated'

interface Props {
  content: ContactInformationStoryblok
}

const ContactInformationBlok = ({
  content: {
    visitingAddressTitle,
    postalAddressTitle,
    googleMapsTitle,
    text,
    phoneTitle,
    phoneNumber,
    localTimeText,
    callUsTitle,
    callUsText,
    visitingAddress,
    postalAddress,
    sectionTitle,
  },
  ...others
}: Props) => {
  return (
    <ContactInformation
      id={slugify(sectionTitle)}
      text={text}
      visitingAddressTitle={visitingAddressTitle}
      postalAddressTitle={postalAddressTitle}
      googleMapsTitle={googleMapsTitle}
      callUs={{
        callUsText,
        callUsTitle,
      }}
      phone={{
        phoneTitle,
        phoneNumber,
        localTimeText,
      }}
      visitingAddress={visitingAddress[0]}
      postalAddress={postalAddress ? postalAddress[0] : undefined}
      {...others}
    />
  )
}

export default ContactInformationBlok
